import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLogout } from '../../apollo-utils'

const SignoutPage = () => {
  useLogout()
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  })

  // window.location.href = '/'

  return <p>Disconnect</p>
}

export default SignoutPage
