import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '../../Atoms/ButtonV2/Button'
import FormElement from '../../Atoms/FormElementV2/FormElementV2'

import './style.scss'

const WizardFormStepV2 = ({ formSection, formData, onSubmit, onBack, hasPrevious }) => {
  const validationResolver = formSection.validationSchema ? yupResolver(formSection.validationSchema) : null
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: formData,
    resolver: validationResolver,
  })

  const getInputForm = (input) => {
    if (input.type === 'link') {
      return (
        <Button kind="empty" className={input.className} linkTo={input.to} key={input.name}>
          {input.label}
        </Button>
      )
    }

    return (
      <div
        className={classNames({
          'wizard-form-step-v2__input': input.type !== 'hidden',
          ...input.className,
        })}
        key={input.name}
      >
        <FormElement
          isAuthenticationInput={input.isAuthenticationInput}
          key={input.name}
          {...input}
          control={control}
          register={register}
          error={errors[input.name]}
          watch={watch}
        />
      </div>
    )
  }

  return (
    <form className="wizard-form-step-v2" onSubmit={handleSubmit(onSubmit)}>
      <div className="wizard-form-step-v2__inputs-wrapper">
        {formSection.formInputs.map((groupInput) => {
          return Array.isArray(groupInput) ? (
            <div className="wizard-form-step-v2__inputs-group" key={groupInput[0].name}>
              {groupInput.map((input) => getInputForm(input))}
            </div>
          ) : (
            getInputForm(groupInput)
          )
        })}
      </div>

      <Button kind="primary" className="full-width wizard-form-step-v2__submit">
        {formSection.submitText}
      </Button>
      {hasPrevious && (
        <Button kind="empty" onClick={onBack} className="wizard-form-step-v2__back">
          Retour
        </Button>
      )}
    </form>
  )
}

const inputPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
})

export const formSectionPropTypes = PropTypes.shape({
  sectionTitle: PropTypes.string,
  submitText: PropTypes.string,
  formInputs: PropTypes.arrayOf(PropTypes.oneOfType([inputPropType, PropTypes.arrayOf(inputPropType)])),
  validationSchema: PropTypes.object,
})

WizardFormStepV2.propTypes = {
  formSection: formSectionPropTypes,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  hasPrevious: PropTypes.bool,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
}

export default WizardFormStepV2
