import { useMediaQuery } from 'react-responsive'

const TABLET_WIDTH = 768
const DESKTOP_WIDTH = 1025
const WIDE_SCREEN = 1400

const useScreenSize = () => {
  const phone = useMediaQuery({ maxWidth: TABLET_WIDTH - 1 })
  const notPhone = useMediaQuery({ minWidth: TABLET_WIDTH })
  const tablet = useMediaQuery({ minWidth: TABLET_WIDTH, maxWidth: DESKTOP_WIDTH - 1 })
  const desktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })
  const notDesktop = useMediaQuery({ maxWidth: DESKTOP_WIDTH + 1 })
  const wideScreen = useMediaQuery({ minWidth: WIDE_SCREEN })

  return { phone, notPhone, tablet, desktop, notDesktop, wideScreen }
}

export default useScreenSize
