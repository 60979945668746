import classnames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

import { ReactComponent as FleexIcon } from '../../../assets/icons/fleex.svg'
import { checkIsCurrentPath } from '../../../constants/utils'
import useClickOutside from '../../hooks/useClickOutside'
import { Tooltip } from '../../molecules/Tooltip_V2/Tooltip_V2'
import VerticalTabs from '../VerticalTabs'
import SideMenuIcon from './SideMenuIcon'
import './style.scss'

const getCurrentSection = (nav, path) => {
  for (const { id, title, sections } of nav) {
    if (sections) {
      for (const { to } of sections) {
        if (checkIsCurrentPath(to, path)) {
          return { id, title, sections }
        }
      }
    }
  }
}

const ReversedRadius = ({ bottom }) => (
  <div className={classnames('reversed-radius', { bottom, top: !bottom })}>
    <div className="reversed-radius__placeholder"></div>
  </div>
)

const SideMenu = ({ nav, expanded, onExpand, onSectionSelection, customLogo }) => {
  const location = useLocation()
  const [currentSection, setCurrentSection] = useState()

  useEffect(() => {
    const defaultSection = getCurrentSection(nav, location.pathname)
    setCurrentSection(defaultSection)
    onSectionSelection(defaultSection?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, nav])

  const ref = useRef()
  const [currentFloatingContent, setCurrentFloatingContent] = useState()
  const hideFloatingContent = () => setCurrentFloatingContent()
  useClickOutside(ref, hideFloatingContent)

  const handleSectionSelection = (section) => {
    setCurrentSection(section)
    onSectionSelection(section.id)
    onExpand()
  }

  const handleDirectLinkSelection = () => {
    setCurrentSection()
    onSectionSelection(false)
  }

  return (
    <div className="side-menu">
      <div className="side-menu__main">
        <Link to={nav[0]?.to || '/'} className="side-menu__link-logo">
          {customLogo || <FleexIcon />}
        </Link>
        {nav.map(
          ({ id, title, icon, customIcon, to, sections, childPaths, floatingContent, bottomOffset, isBottomNav }) => {
            let sideMenuItem
            let selected
            if (floatingContent) {
              selected = currentFloatingContent?.id === id || currentSection?.id === id
              const selectedExpanded = selected && expanded && sections?.length
              sideMenuItem = (
                <div
                  id={id}
                  className={classnames('side-menu__icon', {
                    selected,
                    expanded: selectedExpanded,
                  })}
                  onClick={() => setCurrentFloatingContent({ id, floatingContent, bottomOffset })}
                >
                  {selectedExpanded && <ReversedRadius />}
                  {icon ? <SideMenuIcon icon={icon} /> : customIcon}
                  {selectedExpanded && <ReversedRadius bottom />}
                </div>
              )
            } else if (sections) {
              selected =
                currentSection?.id === id ||
                childPaths?.some((path) => matchPath({ path, exact: false, strict: false }, location.pathname))
              const selectedExpanded = selected && expanded
              sideMenuItem = (
                <Link
                  to={sections[0].to}
                  className={classnames('side-menu__icon', {
                    selected,
                    expanded: selectedExpanded,
                  })}
                  onClick={() => handleSectionSelection({ id, title, sections })}
                >
                  {selectedExpanded && <ReversedRadius />}
                  {icon ? <SideMenuIcon icon={icon} filled={selected} /> : customIcon}
                  {selectedExpanded && <ReversedRadius bottom />}
                </Link>
              )
            } else if (to) {
              selected = !currentSection && checkIsCurrentPath(to, location.pathname)
              sideMenuItem = (
                <Link
                  className={classnames('side-menu__icon', { selected })}
                  to={to}
                  onClick={handleDirectLinkSelection}
                >
                  {icon ? <SideMenuIcon icon={icon} filled={selected} /> : customIcon}
                </Link>
              )
            }

            return (
              sideMenuItem && (
                <Tooltip
                  key={id}
                  content={title}
                  direction="right"
                  className={isBottomNav && 'bottom-nav'}
                  disabled={selected}
                >
                  {sideMenuItem}
                </Tooltip>
              )
            )
          },
        )}
      </div>

      <div className={classnames('side-menu__section', { visible: expanded && currentSection })}>
        {currentSection && (
          <VerticalTabs title={currentSection.title} tabs={currentSection.sections} largeTitle large />
        )}
      </div>

      <div
        ref={ref}
        className={classnames('side-menu__floating-content', { visible: currentFloatingContent })}
        style={{ bottom: currentFloatingContent?.bottomOffset }}
      >
        {currentFloatingContent && currentFloatingContent.floatingContent(hideFloatingContent, handleSectionSelection)}
      </div>
    </div>
  )
}

export default SideMenu
