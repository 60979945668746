import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import qs from 'qs'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { USER_KIND, USER_TYPE } from '@fleex/shared'

import { useAuth } from '../../components/contexts/auth/AuthProvider'
import AuthenticationLayout from '../../components/templates/AuthenticationLayout'
import { getCurrentAuth } from '../../constants/utils'
import Errors from '../errors'
import { ActivateCompanyClientPage } from './activate-company-client'
import { GoogleSignup } from './google-signup'
import ResetPassword from './reset-password'
import ResetPasswordCallback from './reset-password-callback'
import { ResetPasswordConfirm } from './reset-password/Confirm'
import SigninPage from './signin'
import SignupCompanyAdminPage from './signup-company-admin'
import SignupCompanyClientPage from './signup-company-client'
import { SSOAuthentication } from './sso-authentication'

const AuthenticationV2 = () => {
  const location = useLocation()

  const { user } = useAuth()

  if (user) {
    const userType = user.userType
    const { login, to: redirectTo } = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (userType === USER_TYPE.STAFF && !['merchant', 'market'].includes(login)) {
      return <Navigate replace to={redirectTo || '/staff'} />
    }
    if (userType === USER_TYPE.MERCHANT && !['staff', 'market'].includes(login)) {
      return <Navigate replace to={redirectTo || '/merchant'} />
    }
    if (!['staff', 'merchant'].includes(login)) {
      const { isJustActivated } = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (user.userKind === USER_KIND.ADMIN && isJustActivated) {
        return <Navigate replace to="/admin/signup-success" />
      }
      if (getCurrentAuth() === 'impersonate') {
        return <Navigate replace to={'/market/dashboard'} />
      }
      if (getCurrentAuth() === 'client' && user.userKind === USER_KIND.ADMIN) {
        return <Navigate replace to={'/admin/dashboard'} />
      }
      if (getCurrentAuth() === 'client' && !redirectTo?.includes('/merchant') && !redirectTo?.includes('/staff')) {
        return <Navigate replace to={redirectTo || '/market/dashboard'} />
      }
    }
  }

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <AuthenticationLayout>
      <GoogleOAuthProvider clientId="356662729001-0bm3lakqcbebsi21ugfrt7l5f8so94a4.apps.googleusercontent.com">
        <SentryRoutes>
          <Route path="/" element={<SigninPage />} />
          <Route path="/google-signup" element={<GoogleSignup />} />
          <Route path="/sso-authentication" element={<SSOAuthentication />} />
          <Route path="/signup/admin" element={<SignupCompanyAdminPage />} />
          <Route path="/signup/collaborator" element={<SignupCompanyClientPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/confirm" element={<ResetPasswordConfirm />} />
          <Route path="/reset-password-callback/:token" element={<ResetPasswordCallback />} />
          <Route path="/activate-user/:token" element={<ActivateCompanyClientPage />} />
          <Route path="*" element={<Errors code="404" />} />
        </SentryRoutes>
      </GoogleOAuthProvider>
    </AuthenticationLayout>
  )
}

export default AuthenticationV2
