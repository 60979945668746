import React from 'react'

import { ReactComponent as Google } from '../../../assets/icons_V2/Google__G__Logo 1.svg'
import { ReactComponent as AddCircleFilled } from '../../../assets/icons_V2/add-circle-filled.svg'
import { ReactComponent as AddCircle } from '../../../assets/icons_V2/add-circle.svg'
import { ReactComponent as AddUser } from '../../../assets/icons_V2/add-user.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons_V2/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../../../assets/icons_V2/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../assets/icons_V2/arrow-right.svg'
import { ReactComponent as ArrowUp } from '../../../assets/icons_V2/arrow-up.svg'
import { ReactComponent as BasketFilled } from '../../../assets/icons_V2/basket-filled.svg'
import { ReactComponent as Basket } from '../../../assets/icons_V2/basket.svg'
import { ReactComponent as BellFilled } from '../../../assets/icons_V2/bell-filled.svg'
import { ReactComponent as Bell } from '../../../assets/icons_V2/bell.svg'
import { ReactComponent as Box } from '../../../assets/icons_V2/box.svg'
import { ReactComponent as BubbleQuestionFilled } from '../../../assets/icons_V2/bubble-question-filled.svg'
import { ReactComponent as BubbleQuestion } from '../../../assets/icons_V2/bubble-question.svg'
import { ReactComponent as BuildingFilled } from '../../../assets/icons_V2/building-filled.svg'
import { ReactComponent as Building } from '../../../assets/icons_V2/building.svg'
import { ReactComponent as Burger } from '../../../assets/icons_V2/burger-icon.svg'
import { ReactComponent as CalendarEuro } from '../../../assets/icons_V2/calendar-euro.svg'
import { ReactComponent as Calendar } from '../../../assets/icons_V2/calendar.svg'
import { ReactComponent as CheckMarkCircle } from '../../../assets/icons_V2/check-mark-circle.svg'
import { ReactComponent as Check } from '../../../assets/icons_V2/check.svg'
import { ReactComponent as Checked } from '../../../assets/icons_V2/checked.svg'
import { ReactComponent as ChevronDown } from '../../../assets/icons_V2/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '../../../assets/icons_V2/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../../assets/icons_V2/chevron-right.svg'
import { ReactComponent as ChevronUp } from '../../../assets/icons_V2/chevron-up.svg'
import { ReactComponent as CircleUserCheck } from '../../../assets/icons_V2/circle-user-check.svg'
import { ReactComponent as CircleUserCross } from '../../../assets/icons_V2/circle-user-cross.svg'
import { ReactComponent as CircleUserGroup } from '../../../assets/icons_V2/circle-user-group.svg'
import { ReactComponent as Circle } from '../../../assets/icons_V2/circle.svg'
import { ReactComponent as Cross } from '../../../assets/icons_V2/cross.svg'
import { ReactComponent as DashboardFilled } from '../../../assets/icons_V2/dashboard-filled.svg'
import { ReactComponent as Dashboard } from '../../../assets/icons_V2/dashboard.svg'
import { ReactComponent as DragIndicator } from '../../../assets/icons_V2/drag-indicator.svg'
import { ReactComponent as EditPencil } from '../../../assets/icons_V2/edit-pencil.svg'
import { ReactComponent as EmptyBox } from '../../../assets/icons_V2/empty-box.svg'
import { ReactComponent as EyeOff } from '../../../assets/icons_V2/eye-off.svg'
import { ReactComponent as Eye } from '../../../assets/icons_V2/eye.svg'
import { ReactComponent as Filter } from '../../../assets/icons_V2/filter.svg'
import { ReactComponent as Invoice } from '../../../assets/icons_V2/invoice.svg'
import { ReactComponent as Letter } from '../../../assets/icons_V2/letter.svg'
import { ReactComponent as Lock } from '../../../assets/icons_V2/lock.svg'
import { ReactComponent as LogOut } from '../../../assets/icons_V2/log-out.svg'
import { ReactComponent as Minus } from '../../../assets/icons_V2/minus.svg'
import { ReactComponent as Plus } from '../../../assets/icons_V2/plus.svg'
import { ReactComponent as QuestionCircleBlue } from '../../../assets/icons_V2/question-circle-blue.svg'
import { ReactComponent as QuestionCircleRed } from '../../../assets/icons_V2/question-circle-red.svg'
import { ReactComponent as QuestionCircleYellow } from '../../../assets/icons_V2/question-circle-yellow.svg'
import { ReactComponent as Recycle } from '../../../assets/icons_V2/recycle.svg'
import { ReactComponent as Refresh } from '../../../assets/icons_V2/refresh.svg'
import { ReactComponent as RemoveUser } from '../../../assets/icons_V2/remove-user.svg'
import { ReactComponent as Return } from '../../../assets/icons_V2/return.svg'
import { ReactComponent as Search } from '../../../assets/icons_V2/search.svg'
import { ReactComponent as SettingsFilled } from '../../../assets/icons_V2/settings-filled.svg'
import { ReactComponent as Settings } from '../../../assets/icons_V2/settings.svg'
import { ReactComponent as Share } from '../../../assets/icons_V2/share.svg'
import { ReactComponent as ShipmentBoxFilled } from '../../../assets/icons_V2/shipment-box-filled.svg'
import { ReactComponent as ShipmentBoxReturnFilled } from '../../../assets/icons_V2/shipment-box-return-filled.svg'
import { ReactComponent as ShipmentBoxReturn } from '../../../assets/icons_V2/shipment-box-return.svg'
import { ReactComponent as ShipmentBox } from '../../../assets/icons_V2/shipment-box.svg'
import { ReactComponent as SortAsc } from '../../../assets/icons_V2/sort-asc.svg'
import { ReactComponent as SortDesc } from '../../../assets/icons_V2/sort-desc.svg'
import { ReactComponent as Sort } from '../../../assets/icons_V2/sort.svg'
import { ReactComponent as SquareEllipsis } from '../../../assets/icons_V2/square-ellipsis.svg'
import { ReactComponent as StarCoin } from '../../../assets/icons_V2/star-coin.svg'
import { ReactComponent as StoreFilled } from '../../../assets/icons_V2/store-filled.svg'
import { ReactComponent as Store } from '../../../assets/icons_V2/store.svg'
import { ReactComponent as Sustainability } from '../../../assets/icons_V2/sustainability.svg'
import { ReactComponent as ThreeDots } from '../../../assets/icons_V2/three-dots.svg'
import { ReactComponent as Trash } from '../../../assets/icons_V2/trash.svg'
import { ReactComponent as UserAdmin } from '../../../assets/icons_V2/user-admin.svg'
import { ReactComponent as UserCircleAlt } from '../../../assets/icons_V2/user-circle-alt.svg'
import { ReactComponent as UserFee } from '../../../assets/icons_V2/user-fee.svg'
import { ReactComponent as User } from '../../../assets/icons_V2/user.svg'
import { ReactComponent as UsersGroupFilled } from '../../../assets/icons_V2/users-group-filled.svg'
import { ReactComponent as UsersGroup } from '../../../assets/icons_V2/users-group.svg'
import { ReactComponent as WareHouseFilled } from '../../../assets/icons_V2/warehouse-filled.svg'
import { ReactComponent as WareHouse } from '../../../assets/icons_V2/warehouse.svg'

export type IconSize = { height: string; width: string }

export const ICON_SIZES = {
  SM: { height: '16px', width: '16px' },
  MD: { height: '20px', width: '20px' },
  LG: { height: '24px', width: '24px' },
  XL: { height: '32px', width: '32px' },
}

export enum ICONS {
  AddCircle = 'AddCircle',
  AddCircleFilled = 'AddCircleFilled',
  AddUser = 'AddUser',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  BasketFilled = 'BasketFilled',
  Basket = 'Basket',
  BellFilled = 'BellFilled',
  Bell = 'Bell',
  Box = 'Box',
  BubbleQuestionFilled = 'BubbleQuestionFilled',
  BubbleQuestion = 'BubbleQuestion',
  BuildingFilled = 'BuildingFilled',
  Building = 'Building',
  Burger = 'Burger',
  Calendar = 'Calendar',
  CalendarEuro = 'CalendarEuro',
  Check = 'Check',
  Checked = 'Checked',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  ChevronUp = 'ChevronUp',
  Circle = 'Circle',
  CircleUserCheck = 'CircleUserCheck',
  CircleUserCross = 'CircleUserCross',
  CircleUserGroup = 'CircleUserGroup',
  Cross = 'Cross',
  DashboardFilled = 'DashboardFilled',
  Dashboard = 'Dashboard',
  DragIndicator = 'DragIndicator',
  EditPencil = 'EditPencil',
  EmptyBox = 'EmptyBox',
  EyeOff = 'EyeOff',
  Eye = 'Eye',
  Filter = 'Filter',
  Google = 'Google',
  Invoice = 'Invoice',
  Letter = 'Letter',
  Lock = 'Lock',
  LogOut = 'LogOut',
  Minus = 'Minus',
  Plus = 'Plus',
  CheckMarkCircle = 'CheckMarkCircle',
  QuestionCircleBlue = 'QuestionCircleBlue',
  QuestionCircleRed = 'QuestionCircleRed',
  QuestionCircleYellow = 'QuestionCircleYellow',
  Recycle = 'Recycle',
  Refresh = 'Refresh',
  RemoveUser = 'RemoveUser',
  Return = 'Return',
  Search = 'Search',
  SettingsFilled = 'SettingsFilled',
  Settings = 'Settings',
  Share = 'Share',
  ShipmentBoxFilled = 'ShipmentBoxFilled',
  ShipmentBox = 'ShipmentBox',
  ShipmentBoxReturn = 'ShipmentBoxReturn',
  ShipmentBoxReturnFilled = 'ShipmentBoxReturnFilled',
  Sort = 'Sort',
  SortAsc = 'SortAsc',
  SortDesc = 'SortDesc',
  SquareEllipsis = 'SquareEllipsis',
  StarCoin = 'StarCoin',
  StoreFilled = 'StoreFilled',
  Store = 'Store',
  Sustainability = 'Sustainability',
  ThreeDots = 'ThreeDots',
  Trash = 'Trash',
  UserAdmin = 'UserAdmin',
  UserCircleAlt = 'UserCircleAlt',
  User = 'User',
  UserFee = 'UserFee',
  UsersGroupFilled = 'UsersGroupFilled',
  UsersGroup = 'UsersGroup',
  WareHouseFilled = 'WareHouseFilled',
  WareHouse = 'WareHouse',
}

const icons = {
  AddCircle,
  AddCircleFilled,
  AddUser,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BasketFilled,
  Basket,
  BellFilled,
  Bell,
  Box,
  BubbleQuestionFilled,
  BubbleQuestion,
  BuildingFilled,
  Building,
  Burger,
  Calendar,
  CalendarEuro,
  Check,
  Checked,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  CircleUserCheck,
  CircleUserCross,
  CircleUserGroup,
  Cross,
  DashboardFilled,
  Dashboard,
  DragIndicator,
  EditPencil,
  EmptyBox,
  EyeOff,
  Eye,
  Filter,
  Google,
  Invoice,
  Letter,
  Lock,
  LogOut,
  Minus,
  Plus,
  CheckMarkCircle,
  QuestionCircleBlue,
  QuestionCircleRed,
  QuestionCircleYellow,
  Recycle,
  Refresh,
  RemoveUser,
  Return,
  Search,
  SettingsFilled,
  Settings,
  Share,
  ShipmentBoxFilled,
  ShipmentBox,
  ShipmentBoxReturn,
  ShipmentBoxReturnFilled,
  Sort,
  SortAsc,
  SortDesc,
  SquareEllipsis,
  StoreFilled,
  StarCoin,
  Store,
  Sustainability,
  ThreeDots,
  Trash,
  UserAdmin,
  UserCircleAlt,
  User,
  UserFee,
  UsersGroupFilled,
  UsersGroup,
  WareHouseFilled,
  WareHouse,
}

type IconProps = {
  icon: ICONS
  size?: IconSize
  className?: string
}

export const Icon = ({ icon, size, ...props }: IconProps) => {
  if (!icon) {
    return null
  }
  const Icon = icons[icon]

  return <Icon style={size} {...props} />
}
