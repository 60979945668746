import { ApolloError } from '@apollo/client'
import Axios from 'axios'
import { NavigateFunction } from 'react-router-dom'

import { apiBaseUrl } from './vars'

type Auth = {
  accessToken: string
  refreshToken: string
  zendeskToken: string
  role: { authorizationKind: string; main: string }
}

const getAuth = () => {
  const authToParse = localStorage.getItem('auth')
  if (authToParse) {
    return JSON.parse(authToParse)
  }
  return null
}

export const getAuthFromLocalStorage = (key: string) => {
  const path = window.location.pathname.split('/')
  const auth = getAuth()

  if (!auth || !auth.currentAuth) {
    return null
  }

  // Get param after first backslash
  const currentApp = path.length > 1 && path[1]

  // On root path '/'
  if (!currentApp || currentApp[0] === '&' || currentApp === 'error') {
    const currentAuth = auth[auth.currentAuth]
    return currentAuth[key]
  }

  let currentUserKind
  if (auth.currentAuth === 'impersonate') {
    currentUserKind = 'impersonate'
  } else {
    switch (currentApp) {
      case 'market':
      case 'admin':
        currentUserKind = 'client'
        break
      case 'merchant':
      case 'staff':
        currentUserKind = currentApp
        break
      default:
        return null
    }
  }

  if (!auth[currentUserKind]) {
    return null
  }
  const currentAuth = auth[currentUserKind]

  return currentAuth[key]
}

export const getSpecificUser = (user: string) => {
  const auth = getAuth()
  if (!auth || !user) {
    return null
  }
  return auth[user]
}

export const getCurrentAuth = () => {
  const auth = getAuth()
  if (!auth) {
    return null
  }
  return auth.currentAuth
}

export const setValueFromLocalStorage = <T>(key: string, value: T): null | void => {
  const auth = getAuth()
  if (!auth || !auth.currentAuth) {
    return null
  }
  const currentUser = auth[auth.currentAuth]
  currentUser[key] = value
  auth[auth.currentAuth] = currentUser
  localStorage.setItem('auth', JSON.stringify(auth))
}

export const setCurrentUser = (newUser: unknown) => {
  const auth = getAuth()
  auth.currentAuth = newUser
  localStorage.setItem('auth', JSON.stringify(auth))
}

export const saveTokens = ({ accessToken, refreshToken, role, zendeskToken }: Auth) => {
  let auth = getAuth() || null

  if (!auth) {
    auth = {
      currentAuth: role.main,
      [role.main]: {
        zendeskToken,
        accessToken,
        refreshToken,
        authorizationKind: role.authorizationKind,
      },
    }
  } else {
    auth.currentAuth = role.main
    if (!auth[role.main]) {
      auth[role.main] = {}
    }
    auth[role.main].zendeskToken = zendeskToken
    auth[role.main].accessToken = accessToken
    auth[role.main].refreshToken = refreshToken
    auth[role.main].authorizationKind = role.authorizationKind
  }
  localStorage.setItem('auth', JSON.stringify(auth))
}

export const unsetTokenSpecificAuth = (specificAuth: string, forceLogout: boolean) => {
  const auth = getAuth()
  if (auth && specificAuth && auth[specificAuth]) {
    if (forceLogout) {
      auth[specificAuth].refreshToken = ''
    }
    auth[specificAuth].zendeskToken = ''
    auth[specificAuth].accessToken = ''
    auth[specificAuth].authorizationKind = ''
  }
  if (auth && auth.currentAuth && forceLogout) {
    auth.currentAuth = ''
  }
  localStorage.setItem('auth', JSON.stringify(auth))
}

export const uploadFile = (token: string, file: string | Blob) => {
  if (token && file) {
    const fileData = new FormData()
    fileData.append('file', file)
    Axios.post(apiBaseUrl + '/upload', fileData, {
      headers: { Authorization: token },
    })
  }
}

export const getByUuid = <T extends { uuid: string }>(uuid: string, elements: T[]) => {
  return elements.find((_) => _.uuid === uuid)
}

export const removeUrlParam = (
  search: string | string[][] | Record<string, string> | URLSearchParams | undefined,
  navigate: NavigateFunction,
  param: string,
) => {
  const queryParams = new URLSearchParams(search)
  queryParams.delete(param)
  navigate(
    {
      search: queryParams.toString(),
    },
    { replace: true },
  )
}

export const clearUrlParams = (navigate: NavigateFunction) =>
  navigate(
    {
      search: '',
    },
    { replace: true },
  )

export const checkIsCurrentPath = (destination: string, path: string, matchExactPath: boolean) =>
  matchExactPath ? destination === path : path.includes(destination)

export const extractGraphqlErrorData = (error: ApolloError) => error?.graphQLErrors?.[0]?.extensions

export const getLocalStorageValue = (key: string) => {
  const item = localStorage.getItem(key)
  let parsedItem
  try {
    parsedItem = item && JSON.parse(item)
  } catch {}
  return parsedItem
}

export const setLocalStorageValue = <T>(key: string, value: T) => {
  const item = JSON.stringify(value)
  try {
    localStorage.setItem(key, item)
  } catch {
    localStorage.removeItem(key)
  }
}
