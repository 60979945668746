// This must be the first line
import 'react-app-polyfill/stable'

import './wdyr'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import { isIE } from 'react-device-detect'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { App } from './apps/App'
import IncompatibleBrowser from './components/organisms/IncompatibleBrowser'
import * as serviceWorker from './serviceWorker'

import './i18n'
import './index.css'
import './assets/styles/override/main.scss'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

if (isIE) {
  root.render(<IncompatibleBrowser />)
} else {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== 'development',
    tracesSampleRate: 1.0,
  })

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
