import classNames from 'classnames'
import React from 'react'

import { Button } from '../ButtonV2/Button'
import Checkbox from '../Checkbox_V2'
import { GroupLabel } from '../GroupLabel/GroupLabel'
import { Icon, ICONS, ICON_SIZES } from '../Icon/Icon'

import './dropdown.scss'

const Dropdown = ({
  options,
  onAddItem,
  currentValues,
  onItemClick,
  cancelText = 'Annuler',
  cancelAction,
  confirmText = 'Confirmer',
  confirmAction,
  multi = true,
  color,
  label,
  kind,
}) => {
  return multi ? (
    <div className="select-dropdown">
      <ul
        className={classNames('select-dropdown__options', {
          'select-dropdown--actions': confirmAction && cancelAction,
        })}
      >
        {onAddItem && (
          <li onClick={() => onAddItem && onAddItem()} className="select-dropdown__element">
            <span className="select-dropdown__element__add">
              <Icon icon={ICONS.Plus} size={ICON_SIZES.SM} />
            </span>
            {label}
          </li>
        )}
        {options.map((option) => (
          <li
            onClick={() => onItemClick(option)}
            className="select-dropdown__element"
            key={option.value}
            value={option.value}
          >
            <span className="select-dropdown__element__checkbox">
              <Checkbox
                checked={currentValues.some(({ uuid, value }) => uuid === option.value || value === option.value)}
              />
            </span>

            {kind === 'group' ? <GroupLabel label={option.label} color={option.color} /> : option.label || option.value}
          </li>
        ))}
      </ul>
      {cancelAction && confirmAction && (
        <div className="select-dropdown__actions">
          <Button kind="secondary" onClick={cancelAction}>
            {cancelText}
          </Button>
          <Button kind="primary" onClick={confirmAction}>
            {confirmText}
          </Button>
        </div>
      )}
    </div>
  ) : color ? (
    <ul className="select-dropdown select-dropdown__options">
      {options.map((option) => (
        <li
          onClick={() => onItemClick(option)}
          className="select-dropdown__element color-dropdown__element"
          key={option.value}
          value={option.value}
        >
          <div className="color-dropdown__element-indicator" style={{ backgroundColor: option.value }} />
          {option.label || option.value}
        </li>
      ))}
    </ul>
  ) : (
    <ul className="select-dropdown">
      {options.map((option) => (
        <li
          onClick={() => onItemClick(option)}
          className="select-dropdown__element"
          key={option.value}
          value={option.value}
        >
          {kind === 'group' ? <GroupLabel label={option.label} color={option.color} /> : option.label || option.value}
        </li>
      ))}
    </ul>
  )
}

export default Dropdown
