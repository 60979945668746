import { lazy, object, string } from 'yup'

import { COUNTRY_OPTIONS } from '@fleex/shared'

import { Button } from '../../../components/Atoms/ButtonV2/Button'
import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import useScreenSize from '../../../components/hooks/useScreenSize'
import WizardFormStepV2 from '../../../components/organisms/WizardFormStepV2'
import { phoneRegex } from '../../../constants/yup'

import './SignupForm.scss'

const formSection = () => ({
  submitText: 'Découvrir mon espace',
  formInputs: [
    {
      name: 'country',
      label: 'Pays',
      type: FORM_ELEMENT_TYPE.SELECT,
      className: 'sign-input',
      options: COUNTRY_OPTIONS,
    },
    {
      name: 'address',
      label: 'Adresse personnelle de livraison',
      type: FORM_ELEMENT_TYPE.TEXT,
      className: 'sign-input',
    },
    [
      {
        name: 'zip',
        label: 'Code postal',
        type: FORM_ELEMENT_TYPE.TEXT,
        className: 'signup-form-V2__zip sign-input',
      },
      {
        name: 'city',
        label: 'Ville',
        type: FORM_ELEMENT_TYPE.TEXT,
        className: 'signup-form-V2__city sign-input',
      },
    ],
    {
      name: 'comment',
      label: 'Instructions de livraison',
      type: FORM_ELEMENT_TYPE.TEXT,
      className: 'sign-input',
    },
    {
      name: 'phone',
      label: 'Numéro de téléphone',
      type: FORM_ELEMENT_TYPE.TEXT,
      className: 'sign-input',
    },
  ],
  validationSchema: object().shape({
    phone: lazy((value) => {
      if (value !== '') {
        return string().matches(phoneRegex, "Le numéro de téléphone n'est pas valide").label('Téléphone')
      }
      return string().required().label('Numéro de téléphone')
    }),
    address: string().label('Adresse'),
    comment: string().label('Instructions').max(32),
    zip: string().label('Code postal'),
  }),
})

const SignupFormStep2 = ({ onSubmit, formData, invited, stepProps }) => {
  const formSectionData = {
    ...formData,
    ...(invited ? { email: invited } : {}),
  }

  const { desktop } = useScreenSize()

  return (
    <div className="signup-form-V2__container">
      <div className="signup-form-V2__titles">
        <h1 className="authentication-layout__title">Dites-nous en plus sur vous</h1>
        <h2 className="authentication-layout__subtitle">
          Renseignez l'adresse à laquelle vous souhaitez recevoir vos équipements Fleex.
        </h2>
      </div>

      <div className="signup-form-V2__content">
        <WizardFormStepV2
          formSection={formSection(!desktop)}
          formData={formSectionData}
          onSubmit={onSubmit}
          hasPrevious={false}
          showStepper={false}
          currentStep={0}
          totalSteps={1}
          {...stepProps}
        />
      </div>

      <div className="signup-form-V2__bottom-actions">
        <Button kind="empty" className="signup-form-V2__bottom-actions__sign-in" onClick={() => onSubmit('next')}>
          Passer cette étape
        </Button>
      </div>
    </div>
  )
}

export default SignupFormStep2
