/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "http://127.0.0.1:4000/graphql"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	JSON?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Query"]: AliasType<{
	ping?:boolean | `@${string}`,
	families?:ValueTypes["Family"],
typology?: [{	typologyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Typology"]],
typologies?: [{	familyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Typology"]],
	brands?:ValueTypes["Brand"],
	logisticians?:ValueTypes["Logistician"],
	suppliers?:ValueTypes["Supplier"],
	items?:ValueTypes["Item"],
itemManagement?: [{	itemFilter?: ValueTypes["ItemFilter"] | undefined | null | Variable<any, string>},ValueTypes["ItemManagement"]],
	itemOptions?:ValueTypes["ItemOptionsResponse"],
item?: [{	itemUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Item"]],
	categories?:ValueTypes["Category"],
	productOptions?:ValueTypes["OptionElement"],
productManagement?: [{	productFilter?: ValueTypes["ProductFilter"] | undefined | null | Variable<any, string>},ValueTypes["ProductManagement"]],
product?: [{	productUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
productMerchant?: [{	productUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
offers?: [{	offerFilter?: ValueTypes["OfferFilter"] | undefined | null | Variable<any, string>},ValueTypes["Offer"]],
offer?: [{	offerUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Offer"]],
merchantCatalog?: [{	companyUuid: string | Variable<any, string>},ValueTypes["MerchantCatalogElement"]],
	companyList?:ValueTypes["ListItem"],
categoryMerchant?: [{	categoryUuid: string | Variable<any, string>},ValueTypes["Category"]],
archivedItems?: [{	filters?: ValueTypes["ArchivedItemsFilters"] | undefined | null | Variable<any, string>},ValueTypes["ArchivedItems"]],
archivedProducts?: [{	filters?: ValueTypes["ArchivedProductsFilters"] | undefined | null | Variable<any, string>},ValueTypes["ArchivedProducts"]],
family?: [{	familyUuid: string | Variable<any, string>},ValueTypes["Family"]],
productAdmin?: [{	productUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
storeProduct?: [{	productUuid: string | Variable<any, string>},ValueTypes["Product"]],
	companystore?:ValueTypes["Product"],
	companyStoreRentalPlatform?:ValueTypes["ProductCompanyStoreRentalPlatform"],
	fleexstore?:ValueTypes["Family"],
productMarket?: [{	productUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
catalogProduct?: [{	productUuid: string | Variable<any, string>},ValueTypes["Product"]],
	catalog?:ValueTypes["Family"],
	productAttributes?:ValueTypes["ProductAttribute"],
productAttribute?: [{	productAttributeUuid: string | Variable<any, string>},ValueTypes["ProductAttribute"]],
productsStaff?: [{	typologyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["ProductStaff"]],
productStaff?: [{	productUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
offerStaff?: [{	offerUuid: string | Variable<any, string>},ValueTypes["Offer"]],
offersStaff?: [{	merchantCompanyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["OfferStaffResponse"]],
	offerStaffCreationData?:ValueTypes["OfferCreationResponse"],
staffCatalog?: [{	companyUuid: string | Variable<any, string>},ValueTypes["StaffCatalogElement"]],
	basket?:ValueTypes["Basket"],
	subsMarket_V2?:ValueTypes["Sub"],
	subsAdmin?:ValueTypes["Sub"],
	subsAdminGlobal?:ValueTypes["SubAdminGlobal"],
paginatedSubs?: [{	subFilter?: ValueTypes["SubFilter"] | undefined | null | Variable<any, string>,	pagination: ValueTypes["Pagination"] | Variable<any, string>},ValueTypes["PaginatedSubs"]],
sub?: [{	subUuid: string | Variable<any, string>},ValueTypes["Sub"]],
paginatedFlows?: [{	flowFilter?: ValueTypes["FlowFilter"] | undefined | null | Variable<any, string>,	pagination: ValueTypes["Pagination"] | Variable<any, string>},ValueTypes["PaginatedFlows"]],
flows?: [{	flowFilter?: ValueTypes["FlowFilter"] | undefined | null | Variable<any, string>},ValueTypes["Flux"]],
sendSupportSatisfactionSurveyEmail?: [{	client: ValueTypes["SubClientInput"] | Variable<any, string>,	subscriptionId: string | Variable<any, string>},boolean | `@${string}`],
	subCreationData?:ValueTypes["SubCreationData"],
clientOptions?: [{	companyUuid: string | Variable<any, string>},ValueTypes["OptionElement"]],
totalEffectiveLocationPriceExclTaxes?: [{	companyUuid?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
subsClient?: [{	clientUuid: string | Variable<any, string>},ValueTypes["Sub"]],
usage?: [{	companyUuid: string | Variable<any, string>},ValueTypes["Usage"]],
	sellsyInvoices?:ValueTypes["SellsyInvoiceResponse"],
adminDashboardDataV2?: [{	companyGroupUuid?: string | undefined | null | Variable<any, string>,	familyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["AdminDashboardDataV2"]],
authenticate?: [{	email?: string | undefined | null | Variable<any, string>,	password?: string | undefined | null | Variable<any, string>,	refreshToken?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>},ValueTypes["AuthResponse"]],
authenticateAs?: [{	clientUuid: string | Variable<any, string>},ValueTypes["AuthAsResponse"]],
checkResetToken?: [{	token: string | Variable<any, string>},ValueTypes["Client"]],
client?: [{	userUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Client"]],
clients?: [{	filterSearch?: ValueTypes["ClientsFilterSearch"] | undefined | null | Variable<any, string>,	sortSearch?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	companyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Client"]],
clientsStaff?: [{	companyUuid: string | Variable<any, string>},ValueTypes["ClientsStaffData"]],
staff?: [{	userUuid?: string | undefined | null | Variable<any, string>},ValueTypes["StaffUser"]],
merchant?: [{	userUuid?: string | undefined | null | Variable<any, string>},ValueTypes["MerchantUser"]],
	companySettings?:ValueTypes["CompanySettings"],
companyDetails?: [{	companyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Company"]],
companies?: [{	filterName?: string | undefined | null | Variable<any, string>,	filters?: ValueTypes["CompaniesFilter"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
	companiesStaff?:ValueTypes["Company"],
	companyOnboardingV2?:ValueTypes["Onboarding"],
logos?: [{	logoKind?: string | undefined | null | Variable<any, string>},ValueTypes["LogoResponse"]],
companyGroup?: [{	companyGroupUuid?: string | undefined | null | Variable<any, string>},ValueTypes["CompanyGroup"]],
companyGroupV2?: [{	companyGroupUuid?: string | undefined | null | Variable<any, string>},ValueTypes["CompanyGroup"]],
companyGroups?: [{	companyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["CompanyGroup"]],
	companyGroupsV2?:ValueTypes["CompanyGroupsData"],
	companyGroupOptions?:ValueTypes["GroupOptionElement"],
	budgetsAdmin?:ValueTypes["CompanyBudget"],
googleConnectionUrl?: [{	type: ValueTypes["GoogleUrlType"] | Variable<any, string>},boolean | `@${string}`],
getSSOConnectionUrl?: [{	domain: string | Variable<any, string>},boolean | `@${string}`],
staffDashboard?: [{	financialModel?: ValueTypes["FinancialModel"] | undefined | null | Variable<any, string>},ValueTypes["StaffDashboardData"]],
deliverySatisfactionSurvey?: [{	surveyUuid: string | Variable<any, string>},ValueTypes["DeliverySatisfactionSurvey"]],
	deliverySatisfactionSurveys?:ValueTypes["DeliverySatisfactionSurveyRow"],
productSatisfactionSurvey?: [{	surveyUuid: string | Variable<any, string>},ValueTypes["ProductSatisfactionSurvey"]],
	productSatisfactionSurveys?:ValueTypes["ProductSatisfactionSurveyRow"],
	usersAdmin?:ValueTypes["UsersAdminData"],
userAdmin?: [{	userUuid: string | Variable<any, string>},ValueTypes["Client"]],
usersStaff?: [{	userFilter?: ValueTypes["UsersStaffFilter"] | undefined | null | Variable<any, string>,	pagination: ValueTypes["Pagination"] | Variable<any, string>},ValueTypes["UsersStaffResponse"]],
usersStaffOffboarding?: [{	userFilter?: ValueTypes["UsersStaffFilter"] | undefined | null | Variable<any, string>,	pagination: ValueTypes["Pagination"] | Variable<any, string>},ValueTypes["UsersStaffResponse"]],
	marketDashboard?:ValueTypes["Client"],
unitItem?: [{	unitItemUuid: string | Variable<any, string>},ValueTypes["UnitItem"]],
stockDetailed?: [{	stockFilter?: ValueTypes["StockFilter"] | undefined | null | Variable<any, string>,	stockSelect?: ValueTypes["StockSelect"] | undefined | null | Variable<any, string>},ValueTypes["StockDetailed"]],
stockManagement?: [{	stockFilter?: ValueTypes["StockFilter"] | undefined | null | Variable<any, string>,	stockOrder?: ValueTypes["StockOrder"] | undefined | null | Variable<any, string>,	stockSelect?: ValueTypes["StockSelect"] | undefined | null | Variable<any, string>},ValueTypes["StockManagement"]],
	quantityUnitItems?:ValueTypes["QuantityUnitItems"],
requestOrders?: [{	requestOrderFilter?: ValueTypes["RequestOrderFilter"] | undefined | null | Variable<any, string>},ValueTypes["RequestOrders"]],
requestOrder?: [{	requestOrderUuid: string | Variable<any, string>},ValueTypes["RequestOrderResponse"]],
deliveryReceipts?: [{	deliveryReceiptFilter?: ValueTypes["DeliveryReceiptFilter"] | undefined | null | Variable<any, string>},ValueTypes["DeliveryReceiptResponse"]],
productReturnRequest?: [{	productReturnRequestId: string | Variable<any, string>},ValueTypes["ProductReturnRequest"]],
	productReturnRequests?:ValueTypes["ProductReturnRequest"],
productReturnRequestsStaff?: [{	filter?: ValueTypes["ProductReturnRequestsStaffFilterInput"] | undefined | null | Variable<any, string>},ValueTypes["ProductReturnRequest"]],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
	exampleMutation?:boolean | `@${string}`,
createFamily?: [{	name: string | Variable<any, string>},ValueTypes["Family"]],
createLogistician?: [{	name: string | Variable<any, string>},ValueTypes["Logistician"]],
upsertSupplier?: [{	supplierUuid?: string | undefined | null | Variable<any, string>,	name: string | Variable<any, string>},ValueTypes["Supplier"]],
upsertTypology?: [{	typologyUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["TypologyForm"] | Variable<any, string>},ValueTypes["Typology"]],
upsertBrand?: [{	brandUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["BrandForm"] | Variable<any, string>},ValueTypes["Brand"]],
upsertCategory?: [{	categoryUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["CategoryForm"] | Variable<any, string>},ValueTypes["Category"]],
upsertItem?: [{	itemUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["UpsertItemForm"] | Variable<any, string>},ValueTypes["Item"]],
upsertProduct?: [{	productUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["UpsertProductForm"] | Variable<any, string>},ValueTypes["Product"]],
upsertOffer?: [{	offerUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["UpsertOfferForm"] | Variable<any, string>},ValueTypes["Offer"]],
updateOfferEndAt?: [{	offerUuid: string | Variable<any, string>,	endAt?: string | undefined | null | Variable<any, string>},ValueTypes["Offer"]],
setProductDisplayMode?: [{	productUuid: string | Variable<any, string>,	displayMode: ValueTypes["DisplayMode"] | Variable<any, string>},ValueTypes["Product"]],
setProductDisabled?: [{	productUuid: string | Variable<any, string>,	disabled: boolean | Variable<any, string>},ValueTypes["ProductDisabledResult"]],
setProductDisabledBulk?: [{	productUuids: Array<string> | Variable<any, string>,	disabled: boolean | Variable<any, string>},ValueTypes["ProductDisabledResult"]],
setProductDisplayModeBulk?: [{	productUuids: Array<string> | Variable<any, string>,	displayMode: ValueTypes["DisplayMode"] | Variable<any, string>},ValueTypes["ProductListElement"]],
setCompanyWhitelistProductsBulk?: [{	productUuids: Array<string> | Variable<any, string>,	companyUuid: string | Variable<any, string>,	actionType: ValueTypes["WlBlAction"] | Variable<any, string>},ValueTypes["MerchantCatalogElement"]],
setCompanyBlacklistProductsBulk?: [{	productUuids: Array<string> | Variable<any, string>,	companyUuid: string | Variable<any, string>,	actionType: ValueTypes["WlBlAction"] | Variable<any, string>},ValueTypes["MerchantCatalogElement"]],
updateProductDisplayPriority?: [{	categoryUuid: string | Variable<any, string>,	sourceUuid: string | Variable<any, string>,	targetUuid: string | Variable<any, string>},ValueTypes["Category"]],
setItemDisabled?: [{	itemUuid: string | Variable<any, string>,	disabled: boolean | Variable<any, string>},ValueTypes["Item"]],
archiveItem?: [{	itemUuid: string | Variable<any, string>,	archived: boolean | Variable<any, string>,	archivingReason?: string | undefined | null | Variable<any, string>},ValueTypes["Item"]],
replaceItem?: [{	itemUuid: string | Variable<any, string>,	formData: ValueTypes["UpsertItemForm"] | Variable<any, string>},ValueTypes["Item"]],
archiveProduct?: [{	productUuid: string | Variable<any, string>,	archived: boolean | Variable<any, string>},ValueTypes["Product"]],
toggleAddToCompanyStore?: [{	productUuid: string | Variable<any, string>,	addToStore: boolean | Variable<any, string>},ValueTypes["Product"]],
toggleBulkAddToCompanyStore?: [{	productUuids: Array<string> | Variable<any, string>,	addToStore: boolean | Variable<any, string>},ValueTypes["Product"]],
toggleAllowedGroup?: [{	productUuid: string | Variable<any, string>,	groupUuid: string | Variable<any, string>},ValueTypes["ToggleAllowedGroupResult"]],
bulkAddAllowedGroups?: [{	productUuids: Array<string> | Variable<any, string>,	groupUuids: Array<string> | Variable<any, string>},ValueTypes["Product"]],
bulkRemoveAllowedGroups?: [{	productUuids: Array<string> | Variable<any, string>,	groupUuids: Array<string> | Variable<any, string>},ValueTypes["Product"]],
	processUnseenProducts?:ValueTypes["Product"],
upsertProductAttribute?: [{	productAttributeUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["ProductAttributeForm"] | Variable<any, string>},ValueTypes["ProductAttribute"]],
upsertProductStaff?: [{	productUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["ProductStaffForm"] | Variable<any, string>},ValueTypes["Product"]],
archiveProductStaff?: [{	productUuid: string | Variable<any, string>,	archived: boolean | Variable<any, string>,	archivingReason?: string | undefined | null | Variable<any, string>},ValueTypes["Product"]],
createOfferStaff?: [{	formData: ValueTypes["CreateOfferStaffForm"] | Variable<any, string>},ValueTypes["Offer"]],
replaceOfferStaff?: [{	offerUuid: string | Variable<any, string>,	formData: ValueTypes["CreateOfferStaffForm"] | Variable<any, string>},ValueTypes["ReplaceOfferStaffResponse"]],
updateFamiliesOrder?: [{	sourceUuid: string | Variable<any, string>,	targetUuid: string | Variable<any, string>},ValueTypes["Family"]],
updateTypologiesOrder?: [{	familyUuid: string | Variable<any, string>,	sourceUuid: string | Variable<any, string>,	targetUuid: string | Variable<any, string>},ValueTypes["Typology"]],
updateProductsStaffOrder?: [{	typologyUuid: string | Variable<any, string>,	sourceUuid: string | Variable<any, string>,	targetUuid: string | Variable<any, string>},ValueTypes["ProductStaff"]],
upsertProductBasket?: [{	productUuid: string | Variable<any, string>,	quantity: number | Variable<any, string>},ValueTypes["BasketItem"]],
	confirmBasket_V2?:ValueTypes["Basket"],
cancelFlux?: [{	fluxUuid: string | Variable<any, string>},ValueTypes["Sub"]],
addFlux?: [{	subUuid: string | Variable<any, string>,	fluxKind: ValueTypes["FluxKind"] | Variable<any, string>},ValueTypes["Sub"]],
addFluxBulk?: [{	subUuids: Array<string> | Variable<any, string>,	fluxKind: ValueTypes["FluxKind"] | Variable<any, string>},ValueTypes["Sub"]],
addFluxEvent?: [{	fluxUuid: string | Variable<any, string>,	eventKind: ValueTypes["FluxEventKind"] | Variable<any, string>},ValueTypes["Sub"]],
addFluxEventBulk?: [{	fluxUuids: Array<string> | Variable<any, string>,	eventKind: ValueTypes["FluxEventKind"] | Variable<any, string>},ValueTypes["Sub"]],
cancelSub?: [{	subUuid: string | Variable<any, string>,	onCancelingDelivery?: boolean | undefined | null | Variable<any, string>},ValueTypes["Sub"]],
cancelSubBulk?: [{	subUuids: Array<string> | Variable<any, string>},ValueTypes["Sub"]],
setFlag?: [{	subUuid: string | Variable<any, string>,	flagKind: ValueTypes["SubFlagKind"] | Variable<any, string>,	flagState: boolean | Variable<any, string>},ValueTypes["Sub"]],
setFlagBulk?: [{	subUuids: Array<string> | Variable<any, string>,	flagKind: ValueTypes["SubFlagKind"] | Variable<any, string>,	flagState: boolean | Variable<any, string>},ValueTypes["Sub"]],
setExpeditionIdBulk?: [{	subUuids: Array<string> | Variable<any, string>},ValueTypes["Sub"]],
addSubComment?: [{	subUuid: string | Variable<any, string>,	formData: ValueTypes["SubCommentForm"] | Variable<any, string>},ValueTypes["SubComment"]],
addFlowEventBulk?: [{	fluxUuids: Array<string> | Variable<any, string>,	eventKind: ValueTypes["FluxEventKind"] | Variable<any, string>},ValueTypes["Flux"]],
createSubMerchant?: [{	formData: ValueTypes["CreateSubMerchantForm"] | Variable<any, string>},ValueTypes["Sub"]],
signupCompanyAdmin?: [{	formData?: ValueTypes["CompanyAdminCreationForm"] | undefined | null | Variable<any, string>},ValueTypes["SignupResponse"]],
signupCompanyClient?: [{	formData: ValueTypes["CompanyClientCreationForm"] | Variable<any, string>},ValueTypes["SignupResponse"]],
signupCompanyClientAddress?: [{	clientUuid: string | Variable<any, string>,	formData: ValueTypes["CompanyClientAddressCreationForm"] | Variable<any, string>},boolean | `@${string}`],
activateCompanyClient?: [{	token: string | Variable<any, string>},boolean | `@${string}`],
upsertCompanyClient_V2?: [{	clientUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["UpsertCompanyClientForm_V2"] | undefined | null | Variable<any, string>},ValueTypes["Client"]],
setCompanyClientKind?: [{	clientUuid: string | Variable<any, string>,	kind: string | Variable<any, string>,	companyUuid?: string | undefined | null | Variable<any, string>},ValueTypes["Client"]],
setCompanyClientAccountState?: [{	clientUuid: string | Variable<any, string>,	isDisable: boolean | Variable<any, string>},ValueTypes["Client"]],
updateCompanyClientPassword?: [{	clientUuid: string | Variable<any, string>,	formData?: ValueTypes["UpdateCompanyClientPasswordForm"] | undefined | null | Variable<any, string>},ValueTypes["Client"]],
updateClientPassword?: [{	currentPassword: string | Variable<any, string>,	newPassword: string | Variable<any, string>},ValueTypes["Client"]],
updateCompanyClientShippingAddress?: [{	clientUuid: string | Variable<any, string>,	formData?: ValueTypes["updateCompanyClientShippingAddressForm"] | undefined | null | Variable<any, string>},ValueTypes["Client"]],
requestCompanyClientPasswordReset?: [{	email: string | Variable<any, string>},boolean | `@${string}`],
resetPassword?: [{	token: string | Variable<any, string>,	password: string | Variable<any, string>},boolean | `@${string}`],
updateCompanyDetails?: [{	companyUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["CompanyDetailsForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
updateCompanyDetailsV2?: [{	companyUuid: string | Variable<any, string>,	formData?: ValueTypes["CompanyDetailsForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBillingContact?: [{	companyUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["CompanyBillingContactForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBillingAddress?: [{	companyUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["UpdateCompanyBillingAddressForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBilling?: [{	companyUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["UpdateCompanyBillingAddressForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBranding?: [{	companyUuid?: string | undefined | null | Variable<any, string>,	formData?: ValueTypes["UpdateCompanyBranding"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
setCompanySelfSignup?: [{	allowSelfSignup?: boolean | undefined | null | Variable<any, string>},ValueTypes["Company"]],
setServiceSubscription?: [{	companyUuid: string | Variable<any, string>,	formData?: ValueTypes["SetServiceSubscriptionForm"] | undefined | null | Variable<any, string>},ValueTypes["Company"]],
setMinimumMRREngaged?: [{	companyUuid: string | Variable<any, string>,	mrrEngaged: number | Variable<any, string>},ValueTypes["Company"]],
createUploadToken?: [{	uploadKind?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
inviteUsers?: [{	emails: Array<string> | Variable<any, string>,	companyGroupUuid?: string | undefined | null | Variable<any, string>,	isAdmin?: boolean | undefined | null | Variable<any, string>},ValueTypes["InvitedUser"]],
resendInvitations?: [{	emails: Array<string> | Variable<any, string>},ValueTypes["InvitedUser"]],
deletePendingInvites?: [{	invitedUsersUuid: Array<string> | Variable<any, string>},ValueTypes["InvitedUser"]],
deletePendingInvitesV2?: [{	invitedUsersUuid: Array<string> | Variable<any, string>},ValueTypes["InvitedUser"]],
setUserOffboarding?: [{	userUuid: string | Variable<any, string>,	accountOffboardedAt: string | Variable<any, string>,	personalEmail: string | Variable<any, string>},ValueTypes["SetUserOffboardingResponse"]],
cancelUserOffboarding?: [{	userUuid: string | Variable<any, string>},ValueTypes["Client"]],
upsertCompanyGroup?: [{	companyGroupUuid?: string | undefined | null | Variable<any, string>,	formData: ValueTypes["CompanyGroupForm"] | Variable<any, string>},ValueTypes["CompanyGroup"]],
deleteCompanyGroup?: [{	companyGroupUuid: string | Variable<any, string>},ValueTypes["CompanyGroup"]],
deleteCompanyGroupV2?: [{	companyGroupUuid: string | Variable<any, string>},ValueTypes["Product"]],
createCompanyBudget?: [{	companyUuid: string | Variable<any, string>,	formData?: ValueTypes["CompanyBudgetForm"] | undefined | null | Variable<any, string>},ValueTypes["CompanyBudget"]],
createCompanyGroup?: [{	companyUuid: string | Variable<any, string>,	formData?: ValueTypes["CompanyGroupForm"] | undefined | null | Variable<any, string>},ValueTypes["CompanyGroup"]],
updateCompanyGroup?: [{	groupUuid: string | Variable<any, string>,	formData?: ValueTypes["CompanyGroupForm"] | undefined | null | Variable<any, string>},ValueTypes["CompanyGroup"]],
updateCompanyBudget?: [{	budgetUuid: string | Variable<any, string>,	formData?: ValueTypes["CompanyBudgetForm"] | undefined | null | Variable<any, string>},ValueTypes["CompanyBudget"]],
removeCompanyBudget?: [{	budgetUuid: string | Variable<any, string>},ValueTypes["CompanyBudget"]],
setCompanyDefaultGroup?: [{	companyGroupUuid: string | Variable<any, string>},ValueTypes["CompanyGroup"]],
addUsersToGroup?: [{	users: Array<string> | Variable<any, string>,	groupUuid: string | Variable<any, string>},ValueTypes["AddUsersToGroupResult"]],
addPendingUsersToGroup?: [{	users: Array<string> | Variable<any, string>,	groupUuid: string | Variable<any, string>},ValueTypes["Client"]],
associateGoogleAccount?: [{	code: string | Variable<any, string>},ValueTypes["Client"]],
associateGoogleAccount_V2?: [{	code: string | Variable<any, string>},ValueTypes["Client"]],
	dissociateGoogleAccount?:ValueTypes["Client"],
googleAuthentication?: [{	code: string | Variable<any, string>},ValueTypes["AuthResponse"]],
authenticateWithSSO?: [{	code: string | Variable<any, string>},ValueTypes["AuthResponse"]],
setSSOConfiguration?: [{	companyUuid: string | Variable<any, string>,	ssoConfiguration: ValueTypes["SSOConfigurationForm"] | Variable<any, string>},ValueTypes["Company"]],
setSignUpInConfiguration?: [{	companyUuid: string | Variable<any, string>,	signUpInConfiguration: ValueTypes["SignUpInConfigurationForm"] | Variable<any, string>},ValueTypes["Company"]],
sendDeliverySatisfactionSurvey?: [{	surveyUuid: string | Variable<any, string>,	answers: ValueTypes["DeliverySatisfactionSurveyAnswer"] | Variable<any, string>},boolean | `@${string}`],
sendProductSatisfactionSurvey?: [{	surveyUuid: string | Variable<any, string>,	answers: Array<ValueTypes["ProductSatisfactionSurveyAnswer"]> | Variable<any, string>},boolean | `@${string}`],
sendActivationEmail?: [{	clientUuid: string | Variable<any, string>},boolean | `@${string}`],
updateCompanyDeliveryCountries?: [{	companyUuid: string | Variable<any, string>,	countries: Array<string> | Variable<any, string>},ValueTypes["Company"]],
updateCompanyFinancialModel?: [{	companyUuid: string | Variable<any, string>,	financialModel: ValueTypes["FinancialModel"] | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBillingContactV2?: [{	companyUuid: string | Variable<any, string>,	formData: ValueTypes["CompanyBillingContactForm"] | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBillingAddressV2?: [{	companyUuid: string | Variable<any, string>,	formData: ValueTypes["CompanyBillingAddressForm"] | Variable<any, string>},ValueTypes["Company"]],
updateCompanyBillingSepa?: [{	companyUuid: string | Variable<any, string>,	sepa: boolean | Variable<any, string>},ValueTypes["Company"]],
createRequestOrder?: [{	formData: ValueTypes["RequestOrderForm"] | Variable<any, string>},ValueTypes["RequestOrder"]],
deleteRequestOrder?: [{	requestOrderUuid: string | Variable<any, string>},ValueTypes["RequestOrder"]],
closeRequestOrder?: [{	requestOrderUuid: string | Variable<any, string>,	comment?: string | undefined | null | Variable<any, string>},ValueTypes["RequestOrder"]],
updateUnitItemsStatus?: [{	itemsReceived: Array<ValueTypes["ItemReceived"]> | Variable<any, string>,	requestOrderUuid: string | Variable<any, string>},ValueTypes["RequestOrder"]],
addUnitItemEvent?: [{	unitItemUuid: string | Variable<any, string>,	eventKind: ValueTypes["UnitItemEventKind"] | Variable<any, string>},ValueTypes["UnitItem"]],
updateUnitItemSupplier?: [{	unitItemUuid: string | Variable<any, string>,	supplierUuid: string | Variable<any, string>},ValueTypes["UnitItem"]],
addUnitItemComment?: [{	unitItemUuid: string | Variable<any, string>,	textComment: string | Variable<any, string>},ValueTypes["UnitItemComment"]],
addRequestOrderComment?: [{	requestOrderUuid: string | Variable<any, string>,	textComment: string | Variable<any, string>},ValueTypes["RequestOrderComment"]],
createDeliveryReceipt?: [{	itemsReceived: Array<ValueTypes["ItemReceived"]> | Variable<any, string>,	invoiceNumber?: string | undefined | null | Variable<any, string>,	requestOrderUuid: string | Variable<any, string>},ValueTypes["RequestOrder"]],
updateDeliveryReceiptInvoiceNumber?: [{	invoiceNumber: string | Variable<any, string>,	deliveryReceiptUuid: string | Variable<any, string>},ValueTypes["DeliveryReceipt"]],
updateUnitItemsPrice?: [{	requestOrderUuid: string | Variable<any, string>,	itemUuid: string | Variable<any, string>,	newPrice: number | Variable<any, string>},ValueTypes["RequestOrder"]],
trackProductClick?: [{	productUuid: string | Variable<any, string>},boolean | `@${string}`],
createProductReturnRequest?: [{	formData: ValueTypes["CreateProductReturnRequestForm"] | Variable<any, string>},ValueTypes["ProductReturnRequest"]],
updateProductReturnStatus?: [{	status: ValueTypes["ProductReturnStatus"] | Variable<any, string>,	productReturnUuid: string | Variable<any, string>},ValueTypes["ProductReturn"]],
updateProductReturnRequestStatuses?: [{	status: ValueTypes["ProductReturnStatus"] | Variable<any, string>,	productReturnRequestUuids: Array<string> | Variable<any, string>},ValueTypes["ProductReturnRequest"]],
updateProductReturnUserInformations?: [{	formData: ValueTypes["UpdateProductReturnRequestForm"] | Variable<any, string>,	productReturnRequestUuid: string | Variable<any, string>},ValueTypes["UpdateProductReturnRequestResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyValue"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["KeyValueInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>
};
	["DisplayMode"]:DisplayMode;
	["WlBlAction"]:WlBlAction;
	["DisabledProductReason"]:DisabledProductReason;
	["SupplierMode"]:SupplierMode;
	["ItemStatus"]:ItemStatus;
	["ProductAttributeType"]:ProductAttributeType;
	["JSON"]:unknown;
	["ProductCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	brand?:ValueTypes["Brand"],
	offer?:ValueTypes["OfferCompanyStoreRentalPlatform"],
	displayPriority?:boolean | `@${string}`,
	allowedGroups?:ValueTypes["GroupCompanyStoreRentalPlatform"],
	items?:ValueTypes["ItemCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["ItemCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	typology?:ValueTypes["TypologyCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["TypologyCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	family?:ValueTypes["FamilyCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["OfferCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FamilyCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductDisabledResult"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	isDisabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Family"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	typologies?:ValueTypes["Typology"],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Brand"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Logistician"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Supplier"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Typology"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameEN?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	transportationCost?:boolean | `@${string}`,
	family?:ValueTypes["Family"],
	products?:ValueTypes["Product"],
	storeCardOrientation?:boolean | `@${string}`,
	numberOfAssociatedAttributes?:boolean | `@${string}`,
	productAttributes?:ValueTypes["ProductAttribute"],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductAttributeValue"]: AliasType<{
	uuid?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductAttribute"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	numberOfAssociatedProducts?:boolean | `@${string}`,
	values?:ValueTypes["ProductAttributeValue"],
		__typename?: boolean | `@${string}`
}>;
	["Item"]: AliasType<{
	uuid?:boolean | `@${string}`,
	model?:boolean | `@${string}`,
	ean?:boolean | `@${string}`,
	variant?:boolean | `@${string}`,
	sku?:boolean | `@${string}`,
	heightCm?:boolean | `@${string}`,
	lengthCm?:boolean | `@${string}`,
	depthCm?:boolean | `@${string}`,
	weightG?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typology?:ValueTypes["Typology"],
	brand?:ValueTypes["Brand"],
	defaultOffer?:ValueTypes["Offer"],
	offers?:ValueTypes["Offer"],
	archivedAt?:boolean | `@${string}`,
	archivingReason?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	logistician?:ValueTypes["Logistician"],
		__typename?: boolean | `@${string}`
}>;
	["ItemFilter"]: {
	typologies?: Array<string> | undefined | null | Variable<any, string>,
	families?: Array<string> | undefined | null | Variable<any, string>,
	logisticians?: Array<string> | undefined | null | Variable<any, string>,
	brands?: Array<string> | undefined | null | Variable<any, string>,
	models?: Array<string> | undefined | null | Variable<any, string>,
	names?: Array<string> | undefined | null | Variable<any, string>,
	skus?: Array<string> | undefined | null | Variable<any, string>,
	prices?: Array<number> | undefined | null | Variable<any, string>,
	status?: ValueTypes["ItemStatus"] | undefined | null | Variable<any, string>
};
	["ItemManagementFilter"]: AliasType<{
	typologies?:ValueTypes["OptionElement"],
	logisticians?:ValueTypes["OptionElement"],
	families?:ValueTypes["OptionElement"],
	brands?:ValueTypes["OptionElement"],
	models?:ValueTypes["OptionElement"],
	names?:ValueTypes["OptionElement"],
	skus?:ValueTypes["OptionElement"],
	prices?:ValueTypes["OptionElement"],
	status?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemManagement"]: AliasType<{
	items?:ValueTypes["Item"],
	filterOptions?:ValueTypes["ItemManagementFilter"],
		__typename?: boolean | `@${string}`
}>;
	["UpsertItemForm"]: {
	typologyUuid?: string | undefined | null | Variable<any, string>,
	brandUuid?: string | undefined | null | Variable<any, string>,
	model?: string | undefined | null | Variable<any, string>,
	variant?: string | undefined | null | Variable<any, string>,
	sku?: string | undefined | null | Variable<any, string>,
	heightCm?: number | undefined | null | Variable<any, string>,
	lengthCm?: number | undefined | null | Variable<any, string>,
	depthCm?: number | undefined | null | Variable<any, string>,
	weightG?: number | undefined | null | Variable<any, string>,
	pictureUrl: string | Variable<any, string>,
	deliveryDelay?: number | undefined | null | Variable<any, string>,
	engagement?: number | undefined | null | Variable<any, string>,
	price?: number | undefined | null | Variable<any, string>,
	priorNotice?: number | undefined | null | Variable<any, string>,
	archivingReason?: string | undefined | null | Variable<any, string>,
	logisticianUuid?: string | undefined | null | Variable<any, string>
};
	["TypologyAttributeForm"]: {
	uuid: string | Variable<any, string>,
	order: number | Variable<any, string>
};
	["TypologyForm"]: {
	familyUuid?: string | undefined | null | Variable<any, string>,
	nameEN?: string | undefined | null | Variable<any, string>,
	nameFR?: string | undefined | null | Variable<any, string>,
	transportationCost: number | Variable<any, string>,
	attributes?: Array<ValueTypes["TypologyAttributeForm"]> | undefined | null | Variable<any, string>
};
	["ProductAttributeValueForm"]: {
	uuid: string | Variable<any, string>,
	value: string | Variable<any, string>
};
	["ProductAttributeForm"]: {
	productAttributeUuid?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["ProductAttributeType"] | Variable<any, string>,
	values?: Array<ValueTypes["ProductAttributeValueForm"]> | undefined | null | Variable<any, string>,
	newValues?: Array<string> | undefined | null | Variable<any, string>
};
	["BrandForm"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Category"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameEN?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureMiniUrl?:boolean | `@${string}`,
	pictureWideUrl?:boolean | `@${string}`,
	whiteTheme?:boolean | `@${string}`,
	products?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryForm"]: {
	nameEN: string | Variable<any, string>,
	nameFR: string | Variable<any, string>,
	pictureMiniUrl: string | Variable<any, string>,
	pictureWideUrl: string | Variable<any, string>,
	whiteTheme?: boolean | undefined | null | Variable<any, string>
};
	["ProductPicture"]: AliasType<{
	uuid?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	rank?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisabledGroup"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabledReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	uuid?:boolean | `@${string}`,
	categories?:ValueTypes["Category"],
	items?:ValueTypes["Item"],
	item?:ValueTypes["Item"],
	name?:boolean | `@${string}`,
	brand?:ValueTypes["Brand"],
	description?:boolean | `@${string}`,
	information?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	pictures?:ValueTypes["ProductPicture"],
	heightCm?:boolean | `@${string}`,
	lengthCm?:boolean | `@${string}`,
	depthCm?:boolean | `@${string}`,
	monitor?:boolean | `@${string}`,
	connection?:boolean | `@${string}`,
	displayMode?:boolean | `@${string}`,
	isDisabled?:boolean | `@${string}`,
	disabledGroups?:ValueTypes["DisabledGroup"],
	offer?:ValueTypes["Offer"],
	displayPriority?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	isANewProduct?:boolean | `@${string}`,
	seenByAdmin?:boolean | `@${string}`,
	isInCompanyStore?:boolean | `@${string}`,
	allowedGroups?:ValueTypes["CompanyGroup"],
	merchantCompany?:ValueTypes["MerchantCompany"],
	attributes?:ValueTypes["ProductProductAttribute"],
		__typename?: boolean | `@${string}`
}>;
	["ProductProductAttribute"]: AliasType<{
	uuid?:boolean | `@${string}`,
	stringValue?:boolean | `@${string}`,
	booleanValue?:boolean | `@${string}`,
	productAttribute?:ValueTypes["ProductAttribute"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStaff"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	productPictureUrl?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	familyName?:boolean | `@${string}`,
	typologyNameEN?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	offersCount?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpsertProductForm"]: {
	categories: Array<string> | Variable<any, string>,
	items: Array<string> | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	information?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	pictureUrls: Array<string | undefined | null> | Variable<any, string>,
	heightCm?: number | undefined | null | Variable<any, string>,
	lengthCm?: number | undefined | null | Variable<any, string>,
	depthCm?: number | undefined | null | Variable<any, string>,
	monitor?: string | undefined | null | Variable<any, string>,
	connection?: string | undefined | null | Variable<any, string>,
	displayMode?: ValueTypes["DisplayMode"] | undefined | null | Variable<any, string>
};
	["ProductStaffForm"]: {
	familyUuid?: string | undefined | null | Variable<any, string>,
	typologyUuid?: string | undefined | null | Variable<any, string>,
	brandUuid?: string | undefined | null | Variable<any, string>,
	model?: string | undefined | null | Variable<any, string>,
	ean?: string | undefined | null | Variable<any, string>,
	pictureUrls: Array<string | undefined | null> | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	heightCm?: string | undefined | null | Variable<any, string>,
	lengthCm?: string | undefined | null | Variable<any, string>,
	depthCm?: string | undefined | null | Variable<any, string>,
	weightG?: string | undefined | null | Variable<any, string>,
	specs?: Array<ValueTypes["SpecsForm"]> | undefined | null | Variable<any, string>
};
	["SpecsForm"]: {
	key: string | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	booleanValue?: boolean | undefined | null | Variable<any, string>,
	type: ValueTypes["ProductAttributeType"] | Variable<any, string>
};
	["ProductFilter"]: {
	categories?: Array<string> | undefined | null | Variable<any, string>,
	names?: Array<string> | undefined | null | Variable<any, string>,
	numberOfItems?: Array<number> | undefined | null | Variable<any, string>,
	defaultDisplay?: string | undefined | null | Variable<any, string>,
	defaultPrices?: Array<number> | undefined | null | Variable<any, string>,
	status?: boolean | undefined | null | Variable<any, string>
};
	["ProductManagementFilter"]: AliasType<{
	categories?:ValueTypes["OptionElement"],
	names?:ValueTypes["OptionElement"],
	numberOfItems?:ValueTypes["OptionElement"],
	defaultPrices?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ProductManagement"]: AliasType<{
	products?:ValueTypes["ProductListElement"],
	filterOptions?:ValueTypes["ProductManagementFilter"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListElement"]: AliasType<{
	uuid?:boolean | `@${string}`,
	categories?:ValueTypes["Category"],
	name?:boolean | `@${string}`,
	numberOfItems?:boolean | `@${string}`,
	defaultDisplay?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffCountry"]: AliasType<{
	uuid?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	additionalShippingFees?:boolean | `@${string}`,
	deliveryDelay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Offer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	deliveryDelay?:boolean | `@${string}`,
	engagement?:boolean | `@${string}`,
	priorNotice?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	startAt?:boolean | `@${string}`,
	endAt?:boolean | `@${string}`,
	company?:ValueTypes["Company"],
	item?:ValueTypes["Item"],
	merchantCompany?:ValueTypes["MerchantCompany"],
	countries?:ValueTypes["OfferStaffCountry"],
		__typename?: boolean | `@${string}`
}>;
	["UpsertOfferForm"]: {
	companyUuid?: string | undefined | null | Variable<any, string>,
	itemUuid?: string | undefined | null | Variable<any, string>,
	startAt?: string | undefined | null | Variable<any, string>,
	endAt?: string | undefined | null | Variable<any, string>,
	deliveryDelay?: number | undefined | null | Variable<any, string>,
	engagement?: number | undefined | null | Variable<any, string>,
	priorNotice?: number | undefined | null | Variable<any, string>,
	price?: number | undefined | null | Variable<any, string>
};
	["OfferFilter"]: {
	companies?: Array<string> | undefined | null | Variable<any, string>,
	items?: Array<string> | undefined | null | Variable<any, string>,
	prices?: Array<number> | undefined | null | Variable<any, string>,
	startAts?: Array<string> | undefined | null | Variable<any, string>,
	endAts?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	status?: boolean | undefined | null | Variable<any, string>
};
	["ItemOptionsResponse"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	defaultOffer?:ValueTypes["Offer"],
		__typename?: boolean | `@${string}`
}>;
	["MerchantCatalogElement"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	categories?:ValueTypes["Category"],
	productName?:boolean | `@${string}`,
	defaultDisplay?:boolean | `@${string}`,
	companyDisplay?:boolean | `@${string}`,
	companyEnabled?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	companyPrice?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffCatalogElement"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	typologyName?:boolean | `@${string}`,
	hasGroupAffected?:boolean | `@${string}`,
	hasSpecificOffer?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ListItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupDisabledProductInput"]: {
	uuid: string | Variable<any, string>,
	disabled: boolean | Variable<any, string>
};
	["ArchivedItemsFilters"]: {
	names?: Array<string> | undefined | null | Variable<any, string>,
	skus?: Array<string> | undefined | null | Variable<any, string>,
	archivingDates?: Array<string> | undefined | null | Variable<any, string>
};
	["ArchivedItemsFilterOptions"]: AliasType<{
	names?:ValueTypes["OptionElement"],
	skus?:ValueTypes["OptionElement"],
	archivingDates?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedItems"]: AliasType<{
	items?:ValueTypes["Item"],
	filterOptions?:ValueTypes["ArchivedItemsFilterOptions"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedProductsFilters"]: {
	names?: Array<string> | undefined | null | Variable<any, string>,
	archivingDates?: Array<string> | undefined | null | Variable<any, string>
};
	["ArchivedProductsFilterOptions"]: AliasType<{
	names?:ValueTypes["OptionElement"],
	archivingDates?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedProducts"]: AliasType<{
	products?:ValueTypes["Product"],
	filterOptions?:ValueTypes["ArchivedProductsFilterOptions"],
		__typename?: boolean | `@${string}`
}>;
	["ToggleAllowedGroupResult"]: AliasType<{
	product?:ValueTypes["Product"],
	groupName?:boolean | `@${string}`,
	allowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffFilters"]: AliasType<{
	companies?:ValueTypes["OptionElement"],
	merchants?:ValueTypes["OptionElement"],
	typologies?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffResponse"]: AliasType<{
	offers?:ValueTypes["Offer"],
	filterOptions?:ValueTypes["OfferStaffFilters"],
		__typename?: boolean | `@${string}`
}>;
	["OfferCreationResponse"]: AliasType<{
	items?:ValueTypes["OptionElement"],
	companies?:ValueTypes["OptionElement"],
	merchants?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["CreateOfferStaffCountryForm"]: {
	country: string | Variable<any, string>,
	additionalShippingFees?: number | undefined | null | Variable<any, string>,
	deliveryDelay: number | Variable<any, string>
};
	["CreateOfferStaffForm"]: {
	itemUuid: string | Variable<any, string>,
	companyUuid?: string | undefined | null | Variable<any, string>,
	merchantUuid: string | Variable<any, string>,
	price: number | Variable<any, string>,
	startAt: string | Variable<any, string>,
	endAt?: string | undefined | null | Variable<any, string>,
	countries: Array<ValueTypes["CreateOfferStaffCountryForm"]> | Variable<any, string>
};
	["ReplaceOfferStaffResponse"]: AliasType<{
	oldOffer?:ValueTypes["Offer"],
	newOffer?:ValueTypes["Offer"],
		__typename?: boolean | `@${string}`
}>;
	["SubFlagKind"]:SubFlagKind;
	["FluxKind"]:FluxKind;
	["FluxEventKind"]:FluxEventKind;
	["BasketItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	product?:ValueTypes["Product"],
	isAvailable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BasketSummary"]: AliasType<{
	uuid?:boolean | `@${string}`,
	items?:ValueTypes["BasketItem"],
		__typename?: boolean | `@${string}`
}>;
	["BudgetSummary"]: AliasType<{
	uuid?:boolean | `@${string}`,
	basketCount?:boolean | `@${string}`,
	totalBasket?:boolean | `@${string}`,
	availableBudget?:boolean | `@${string}`,
	group?:ValueTypes["CompanyGroup"],
		__typename?: boolean | `@${string}`
}>;
	["Basket"]: AliasType<{
	basket?:ValueTypes["BasketSummary"],
	budgetSummary?:ValueTypes["BudgetSummary"],
		__typename?: boolean | `@${string}`
}>;
	["Flux"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	events?:ValueTypes["FluxEvent"],
	lastEvent?:ValueTypes["FluxEvent"],
	sub?:ValueTypes["Sub"],
	unitItem?:ValueTypes["UnitItem"],
		__typename?: boolean | `@${string}`
}>;
	["FluxEvent"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubState"]: AliasType<{
	lastFlux?:ValueTypes["Flux"],
	lastEvent?:ValueTypes["FluxEvent"],
	shortStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Sub"]: AliasType<{
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	startedAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	firstDeliveredAt?:boolean | `@${string}`,
	lastDeliveredAt?:boolean | `@${string}`,
	terminatedAt?:boolean | `@${string}`,
	commandId?:boolean | `@${string}`,
	subscriptionId?:boolean | `@${string}`,
	expeditionId?:boolean | `@${string}`,
	client?:ValueTypes["Client"],
	item?:ValueTypes["Item"],
	product?:ValueTypes["Product"],
	address?:ValueTypes["Address"],
	offer?:ValueTypes["Offer"],
	comments?:ValueTypes["SubComment"],
	fluxes?:ValueTypes["Flux"],
	deliveryFlux?:ValueTypes["Flux"],
	retrievalFlux?:ValueTypes["Flux"],
	lastFlux?:ValueTypes["Flux"],
	state?:ValueTypes["SubState"],
	supportFlagEnabledAt?:boolean | `@${string}`,
	standbyFlagEnabledAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubFilter"]: {
	triggerHistory?: Array<string> | undefined | null | Variable<any, string>,
	commandIds?: Array<string> | undefined | null | Variable<any, string>,
	expeditionIds?: Array<string> | undefined | null | Variable<any, string>,
	subIds?: Array<string> | undefined | null | Variable<any, string>,
	companies?: Array<string> | undefined | null | Variable<any, string>,
	customers?: Array<string> | undefined | null | Variable<any, string>,
	status?: Array<string> | undefined | null | Variable<any, string>,
	items?: Array<string> | undefined | null | Variable<any, string>,
	logisticians?: Array<string> | undefined | null | Variable<any, string>,
	orderDates?: ValueTypes["OrderDates"] | undefined | null | Variable<any, string>,
	deliveryDates?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	supportFlag?: Array<string> | undefined | null | Variable<any, string>,
	standbyFlag?: Array<string> | undefined | null | Variable<any, string>
};
	["OrderDates"]: {
	from: string | Variable<any, string>,
	to: string | Variable<any, string>
};
	["Pagination"]: {
	offset: number | Variable<any, string>,
	limit?: number | undefined | null | Variable<any, string>,
	cursor?: string | undefined | null | Variable<any, string>,
	total?: number | undefined | null | Variable<any, string>
};
	["PaginationInfos"]: AliasType<{
	count?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	cursor?:boolean | `@${string}`,
	hasMore?:boolean | `@${string}`,
	initial?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupedOptionElement"]: AliasType<{
	options?:ValueTypes["OptionElement"],
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedSubsFilters"]: AliasType<{
	commandIds?:ValueTypes["OptionElement"],
	expeditionIds?:ValueTypes["OptionElement"],
	subIds?:ValueTypes["OptionElement"],
	status?:ValueTypes["GroupedOptionElement"],
	companies?:ValueTypes["OptionElement"],
	logisticians?:ValueTypes["OptionElement"],
	customers?:ValueTypes["OptionElement"],
	items?:ValueTypes["OptionElement"],
	deliveryDates?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["PaginatedSubs"]: AliasType<{
	pagination?:ValueTypes["PaginationInfos"],
	subs?:ValueTypes["Sub"],
	filterOptions?:ValueTypes["PaginatedSubsFilters"],
		__typename?: boolean | `@${string}`
}>;
	["SubAdminGlobal"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	typologyName?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	productAllocated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	shownToClient?:boolean | `@${string}`,
	author?:ValueTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubCommentForm"]: {
	textContent: string | Variable<any, string>,
	shownToClient?: boolean | undefined | null | Variable<any, string>
};
	["FlowFilter"]: {
	triggerHistory?: Array<string> | undefined | null | Variable<any, string>,
	commandIds?: Array<string> | undefined | null | Variable<any, string>,
	subIds?: Array<string> | undefined | null | Variable<any, string>,
	fluxKind?: Array<ValueTypes["FluxKind"] | undefined | null> | undefined | null | Variable<any, string>,
	fluxEventKind?: Array<ValueTypes["FluxEventKind"] | undefined | null> | undefined | null | Variable<any, string>,
	customers?: Array<string> | undefined | null | Variable<any, string>,
	items?: Array<string> | undefined | null | Variable<any, string>
};
	["PaginatedFlowsFilters"]: AliasType<{
	commandIds?:ValueTypes["OptionElement"],
	subIds?:ValueTypes["OptionElement"],
	fluxKind?:ValueTypes["FluxKindOptionElement"],
	fluxEventKind?:ValueTypes["OptionElement"],
	customers?:ValueTypes["OptionElement"],
	items?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["FluxKindOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FluxEventKindOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedFlows"]: AliasType<{
	pagination?:ValueTypes["PaginationInfos"],
	flows?:ValueTypes["Flux"],
	filterOptions?:ValueTypes["PaginatedFlowsFilters"],
		__typename?: boolean | `@${string}`
}>;
	["Usage"]: AliasType<{
	totalSubs?:boolean | `@${string}`,
	activeClients?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubClientInput"]: {
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	email: string | Variable<any, string>
};
	["SubCreationData"]: AliasType<{
	companyOptions?:ValueTypes["OptionElement"],
	productOptions?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["CreateSubMerchantForm"]: {
	companyUuid: string | Variable<any, string>,
	clientUuid: string | Variable<any, string>,
	productUuid: string | Variable<any, string>,
	shouldNotImpactBudget?: boolean | undefined | null | Variable<any, string>
};
	["SellsyInvoiceResponse"]: AliasType<{
	invoices?:ValueTypes["SellsyInvoice"],
		__typename?: boolean | `@${string}`
}>;
	["SellsyInvoice"]: AliasType<{
	link?:boolean | `@${string}`,
	totalIncludingVAT?:boolean | `@${string}`,
	totalExcludingVAT?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClientStatusV2"]:ClientStatusV2;
	["ClientStatus"]:ClientStatus;
	["OffboardingStatus"]:OffboardingStatus;
	["GoogleUrlType"]:GoogleUrlType;
	["FinancialModel"]:FinancialModel;
	["NamedValue"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminDashboardItem"]: AliasType<{
	name?:boolean | `@${string}`,
	brand?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	categoryUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminDashboardDataV2"]: AliasType<{
	pendingInvites?:boolean | `@${string}`,
	signedUpUsers?:boolean | `@${string}`,
	activeUsers?:boolean | `@${string}`,
	offboardingUsers?:boolean | `@${string}`,
	disabledUsers?:boolean | `@${string}`,
	subsStatus?:ValueTypes["NamedValue"],
	families?:ValueTypes["NamedValue"],
	familiesOptions?:ValueTypes["OptionElement"],
	topItems?:ValueTypes["AdminDashboardItem"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroupsData"]: AliasType<{
	groups?:ValueTypes["CompanyGroup"],
	financialModel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersAdminFiltersOptions"]: AliasType<{
	groups?:ValueTypes["OptionElement"],
	countries?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ClientsStaffFiltersOptions"]: AliasType<{
	countries?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ClientsStaffData"]: AliasType<{
	clients?:ValueTypes["Client"],
	filterOptions?:ValueTypes["ClientsStaffFiltersOptions"],
		__typename?: boolean | `@${string}`
}>;
	["UsersAdminData"]: AliasType<{
	clients?:ValueTypes["Client"],
	filterOptions?:ValueTypes["UsersAdminFiltersOptions"],
	financialModel?:boolean | `@${string}`,
	companyUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompaniesFilter"]: {
	companies?: Array<string> | undefined | null | Variable<any, string>,
	creationDates?: Array<string> | undefined | null | Variable<any, string>,
	clientsCounts?: Array<string> | undefined | null | Variable<any, string>,
	financialModel?: ValueTypes["FinancialModel"] | undefined | null | Variable<any, string>,
	company?: string | undefined | null | Variable<any, string>,
	creationDate?: string | undefined | null | Variable<any, string>,
	clientsCount?: string | undefined | null | Variable<any, string>
};
	["ClientsFilterSearch"]: {
	nameOrEmail?: string | undefined | null | Variable<any, string>,
	accountDisabledAt?: string | undefined | null | Variable<any, string>,
	companies?: Array<string> | undefined | null | Variable<any, string>,
	firstnames?: Array<string> | undefined | null | Variable<any, string>,
	lastnames?: Array<string> | undefined | null | Variable<any, string>,
	emails?: Array<string> | undefined | null | Variable<any, string>,
	phones?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	firstname?: string | undefined | null | Variable<any, string>,
	lastname?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	active?: string | undefined | null | Variable<any, string>,
	accountState?: string | undefined | null | Variable<any, string>,
	role?: string | undefined | null | Variable<any, string>,
	offboarding?: ValueTypes["OffboardingStatus"] | undefined | null | Variable<any, string>,
	creationDates?: Array<string> | undefined | null | Variable<any, string>,
	groups?: Array<string> | undefined | null | Variable<any, string>
};
	["SetServiceSubscriptionForm"]: {
	newLocationCostCapEuro?: number | undefined | null | Variable<any, string>,
	newServiceSubscriptionCost?: number | undefined | null | Variable<any, string>
};
	["SignupResponse"]: AliasType<{
	accountActivationToken?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	logoToken?:boolean | `@${string}`,
	linkedToGoogle?:boolean | `@${string}`,
	authResponse?:ValueTypes["AuthResponse"],
	clientUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuthAsResponse"]: AliasType<{
	token?:boolean | `@${string}`,
	zendeskToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuthResponse"]: AliasType<{
	isNew?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	accessToken?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	clientUuid?:boolean | `@${string}`,
	zendeskToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Onboarding"]: AliasType<{
	isDone?:boolean | `@${string}`,
	onboardingSteps?:ValueTypes["OnboardingStep"],
	companyName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OnboardingStep"]: AliasType<{
	value?:boolean | `@${string}`,
	isDone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationCostCapResponse"]: AliasType<{
	company?:ValueTypes["Company"],
	budgetSummary?:ValueTypes["BudgetSummary"],
	basketSummary?:ValueTypes["BasketSummary"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyAdminCreationForm"]: {
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	email: string | Variable<any, string>,
	personalEmail: string | Variable<any, string>,
	password: string | Variable<any, string>,
	tosSignedVersion: number | Variable<any, string>,
	companyName: string | Variable<any, string>,
	registrationNumber: string | Variable<any, string>,
	logoUrl?: string | undefined | null | Variable<any, string>,
	primaryColor?: string | undefined | null | Variable<any, string>,
	estimatedWorkersCount?: string | undefined | null | Variable<any, string>,
	cgu?: boolean | undefined | null | Variable<any, string>
};
	["CompanyClientCreationForm"]: {
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	email: string | Variable<any, string>,
	personalEmail: string | Variable<any, string>,
	password: string | Variable<any, string>,
	tosSignedVersion: number | Variable<any, string>,
	companyGroupUuid?: string | undefined | null | Variable<any, string>,
	cgu?: boolean | undefined | null | Variable<any, string>
};
	["CompanyClientAddressCreationForm"]: {
	phone?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	zip?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["CompanyDetailsForm"]: {
	name?: string | undefined | null | Variable<any, string>,
	registrationNumber?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	logoUrl?: string | undefined | null | Variable<any, string>,
	primaryColor?: string | undefined | null | Variable<any, string>,
	estimatedWorkersCount?: string | undefined | null | Variable<any, string>,
	legalCompanyName?: string | undefined | null | Variable<any, string>,
	allowSelfSignup?: boolean | undefined | null | Variable<any, string>,
	domainName?: string | undefined | null | Variable<any, string>
};
	["CompanyBillingContactForm"]: {
	billingContactFirstName?: string | undefined | null | Variable<any, string>,
	billingContactLastName?: string | undefined | null | Variable<any, string>,
	billingContactEmail?: string | undefined | null | Variable<any, string>,
	billingContactPhone?: string | undefined | null | Variable<any, string>
};
	["UpdateCompanyBranding"]: {
	logoUrl?: string | undefined | null | Variable<any, string>,
	primaryColor?: string | undefined | null | Variable<any, string>
};
	["UpdateCompanyBillingAddressForm"]: {
	billingContactFirstName?: string | undefined | null | Variable<any, string>,
	billingContactLastName?: string | undefined | null | Variable<any, string>,
	billingContactEmail?: string | undefined | null | Variable<any, string>,
	billingContactPhone?: string | undefined | null | Variable<any, string>,
	recipientName?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	zip?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	sepa?: boolean | undefined | null | Variable<any, string>,
	rentalAYG?: boolean | undefined | null | Variable<any, string>
};
	["CompanyBillingAddressForm"]: {
	address: string | Variable<any, string>,
	zip: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>
};
	["UpsertCompanyClientForm_V2"]: {
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	personalEmail?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	recipientPhone?: string | undefined | null | Variable<any, string>,
	recipientName?: string | undefined | null | Variable<any, string>,
	job?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	zip?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["UpdateCompanyClientPasswordForm"]: {
	email?: string | undefined | null | Variable<any, string>,
	currentPassword?: string | undefined | null | Variable<any, string>,
	newPassword?: string | undefined | null | Variable<any, string>,
	newPasswordConfirmation?: string | undefined | null | Variable<any, string>
};
	["updateCompanyClientShippingAddressForm"]: {
	recipientName: string | Variable<any, string>,
	recipientPhone: string | Variable<any, string>,
	address: string | Variable<any, string>,
	zip: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["CompanyDetailsStaffForm"]: {
	name?: string | undefined | null | Variable<any, string>,
	registrationNumber?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	logoUrl?: string | undefined | null | Variable<any, string>,
	recipientName?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	zip?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	billingContactFirstName?: string | undefined | null | Variable<any, string>,
	billingContactLastName?: string | undefined | null | Variable<any, string>,
	billingContactEmail?: string | undefined | null | Variable<any, string>,
	billingContactPhone?: string | undefined | null | Variable<any, string>
};
	["Address"]: AliasType<{
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	recipientName?:boolean | `@${string}`,
	recipientPhone?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	zip?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LogoResponse"]: AliasType<{
	userLogo?:boolean | `@${string}`,
	companyLogo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyBudgetForm"]: {
	locationCostCap?: number | undefined | null | Variable<any, string>,
	serviceSubscriptionCost?: number | undefined | null | Variable<any, string>
};
	["CompanyBudget"]: AliasType<{
	uuid?:boolean | `@${string}`,
	locationCostCap?:boolean | `@${string}`,
	serviceSubscriptionCost?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Company"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	legalCompanyName?:boolean | `@${string}`,
	registrationNumber?:boolean | `@${string}`,
	estimatedWorkerCount?:boolean | `@${string}`,
	sepa?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	logoUrl?:boolean | `@${string}`,
	billingContactFirstName?:boolean | `@${string}`,
	billingContactLastName?:boolean | `@${string}`,
	billingContactEmail?:boolean | `@${string}`,
	billingContactPhone?:boolean | `@${string}`,
	creator?:ValueTypes["Client"],
	clients?:ValueTypes["Client"],
	billingAddress?:ValueTypes["Address"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	disabledProducts?:ValueTypes["CompanyDisabledProduct"],
	companySettings?:ValueTypes["CompanySettings"],
	domainNames?:ValueTypes["CompanyDomainName"],
	budgets?:ValueTypes["CompanyBudget"],
	groups?:ValueTypes["CompanyGroup"],
	clientsCount?:boolean | `@${string}`,
	pendingInvitesCount?:boolean | `@${string}`,
	inactiveUsersCount?:boolean | `@${string}`,
	activeUsersCount?:boolean | `@${string}`,
	formerActiveUsersCount?:boolean | `@${string}`,
	deactivatedUsersCount?:boolean | `@${string}`,
	estimatedWorkersCount?:boolean | `@${string}`,
	deliveryCountries?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanySettings"]: AliasType<{
	financialModel?:boolean | `@${string}`,
	shouldHidePrices?:boolean | `@${string}`,
	allowSelfSignup?:boolean | `@${string}`,
	primaryColor?:boolean | `@${string}`,
	mrrEngaged?:boolean | `@${string}`,
	workosOrganizationId?:boolean | `@${string}`,
	workosDirectoryId?:boolean | `@${string}`,
	ssoLoginOnly?:boolean | `@${string}`,
	disableSSOInvitation?:boolean | `@${string}`,
	lockAllowSelfSignup?:boolean | `@${string}`,
	gConnectOnly?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SSOConfigurationForm"]: {
	workosOrganizationId?: string | undefined | null | Variable<any, string>,
	workosDirectoryId?: string | undefined | null | Variable<any, string>,
	ssoLoginOnly: boolean | Variable<any, string>,
	disableSSOInvitation: boolean | Variable<any, string>,
	lockAllowSelfSignup: boolean | Variable<any, string>
};
	["SignUpInConfigurationForm"]: {
	workosOrganizationId?: string | undefined | null | Variable<any, string>,
	workosDirectoryId?: string | undefined | null | Variable<any, string>,
	ssoLoginOnly?: boolean | undefined | null | Variable<any, string>,
	disableSSOInvitation?: boolean | undefined | null | Variable<any, string>,
	lockAllowSelfSignup?: boolean | undefined | null | Variable<any, string>,
	gConnectOnly?: boolean | undefined | null | Variable<any, string>
};
	["CompanyDomainName"]: AliasType<{
	uuid?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Client"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdBy?:ValueTypes["Client"],
	lastLoggedIn?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	company?:ValueTypes["Company"],
	shippingAddress?:ValueTypes["Address"],
	companyRole?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	accountOffboardedAt?:boolean | `@${string}`,
	offboardedRequestedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	statusV2?:boolean | `@${string}`,
	offboardingStatus?:boolean | `@${string}`,
	activeSubsCount?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	googleEmail?:boolean | `@${string}`,
	personalEmail?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	job?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	subs?:ValueTypes["Sub"],
	companyGroup?:ValueTypes["CompanyGroup"],
	offboardedBy?:ValueTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyDisabledProduct"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountActivationToken?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	lastLoggedIn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MerchantUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountActivationToken?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	job?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	lastLoggedIn?:boolean | `@${string}`,
	company?:ValueTypes["MerchantCompany"],
		__typename?: boolean | `@${string}`
}>;
	["MerchantCompany"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	logoUrl?:boolean | `@${string}`,
	registrationNumber?:boolean | `@${string}`,
	intraCommunityVatNumber?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	contactFirstName?:boolean | `@${string}`,
	contactLastName?:boolean | `@${string}`,
	contactEmail?:boolean | `@${string}`,
	contactPhone?:boolean | `@${string}`,
	address?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["InvitedUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	budget?:ValueTypes["CompanyBudget"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroup"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	budget?:ValueTypes["CompanyBudget"],
	usersCount?:boolean | `@${string}`,
	activeUsersCount?:boolean | `@${string}`,
	inactiveUsersCount?:boolean | `@${string}`,
	productsCount?:boolean | `@${string}`,
	allowedProductsCount?:boolean | `@${string}`,
	averageConsumption?:boolean | `@${string}`,
	users?:ValueTypes["Client"],
	products?:ValueTypes["Product"],
	countries?:boolean | `@${string}`,
	creator?:ValueTypes["Client"],
	isDefault?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	monthly?:boolean | `@${string}`,
	workosId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroupForm"]: {
	name: string | Variable<any, string>,
	description: string | Variable<any, string>,
	budget: string | Variable<any, string>,
	color?: string | undefined | null | Variable<any, string>
};
	["AddUsersToGroupResult"]: AliasType<{
	clientsInBudget?:ValueTypes["Client"],
	clientsOutOfBudget?:ValueTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["StaffDashboardCompany"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	activeUsers?:boolean | `@${string}`,
	budget?:boolean | `@${string}`,
	totalPrices?:boolean | `@${string}`,
	activeSubs?:boolean | `@${string}`,
	mrrEngaged?:boolean | `@${string}`,
	realMRR?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffDashboardData"]: AliasType<{
	pendingInvites?:boolean | `@${string}`,
	inactiveUsers?:boolean | `@${string}`,
	formerActiveUsers?:boolean | `@${string}`,
	deactivatedUsers?:boolean | `@${string}`,
	companies?:ValueTypes["StaffDashboardCompany"],
		__typename?: boolean | `@${string}`
}>;
	["DeliverySatisfactionSurvey"]: AliasType<{
	firstName?:boolean | `@${string}`,
	answered?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliverySatisfactionSurveyAnswer"]: {
	rating: number | Variable<any, string>,
	commentRating?: string | undefined | null | Variable<any, string>,
	support?: boolean | undefined | null | Variable<any, string>,
	commentSupport?: string | undefined | null | Variable<any, string>
};
	["DeliverySatisfactionSurveyRow"]: AliasType<{
	lastName?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	company?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	commentRating?:boolean | `@${string}`,
	support?:boolean | `@${string}`,
	commentSupport?:boolean | `@${string}`,
	answeredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurveyItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurvey"]: AliasType<{
	firstName?:boolean | `@${string}`,
	items?:ValueTypes["ProductSatisfactionSurveyItem"],
	answered?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurveyAnswer"]: {
	uuid: string | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["ProductSatisfactionSurveyRow"]: AliasType<{
	lastName?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	company?:boolean | `@${string}`,
	item?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	answeredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersStaffFilter"]: {
	search?: string | undefined | null | Variable<any, string>,
	companies?: Array<string> | undefined | null | Variable<any, string>,
	countries?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	status?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	offboarding?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	accountState?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	role?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	sort?: ValueTypes["SortFilter"] | undefined | null | Variable<any, string>
};
	["SortFilter"]: {
	column?: string | undefined | null | Variable<any, string>,
	order?: string | undefined | null | Variable<any, string>
};
	["UsersStaffResponse"]: AliasType<{
	pagination?:ValueTypes["PaginationInfos"],
	users?:ValueTypes["Client"],
	filterOptions?:ValueTypes["UsersFiltersV2"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFiltersV2"]: AliasType<{
	countries?:ValueTypes["OptionElement"],
	companies?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["SetUserOffboardingResponse"]: AliasType<{
	uuid?:boolean | `@${string}`,
	accountOffboardedAt?:boolean | `@${string}`,
	personalEmail?:boolean | `@${string}`,
	statusV2?:boolean | `@${string}`,
	offboardingStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItemEventKind"]:UnitItemEventKind;
	["SortKind"]:SortKind;
	["RequestOrderKind"]:RequestOrderKind;
	["RequestOrderForm"]: {
	supplierUuid: string | Variable<any, string>,
	supplierMode: ValueTypes["SupplierMode"] | Variable<any, string>,
	purchaseOrderNumber: string | Variable<any, string>,
	logisticianUuid: string | Variable<any, string>,
	items: Array<ValueTypes["UnitItemForm"]> | Variable<any, string>
};
	["ItemReceived"]: {
	itemUuid: string | Variable<any, string>,
	qtyReceived: number | Variable<any, string>
};
	["UnitItemForm"]: {
	itemUuid: string | Variable<any, string>,
	qtyOrder: number | Variable<any, string>,
	buyingPrice?: number | undefined | null | Variable<any, string>
};
	["StockUnitItem"]: AliasType<{
	item?:ValueTypes["Item"],
	requested?:boolean | `@${string}`,
	inStock?:boolean | `@${string}`,
	quantityNeeded?:boolean | `@${string}`,
	allocated?:boolean | `@${string}`,
	toBeDelivered?:boolean | `@${string}`,
	rented?:boolean | `@${string}`,
	returnRequested?:boolean | `@${string}`,
	deliveryBack?:boolean | `@${string}`,
	quarantined?:boolean | `@${string}`,
	exit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	unitItemId?:boolean | `@${string}`,
	item?:ValueTypes["Item"],
	mode?:boolean | `@${string}`,
	entryAt?:boolean | `@${string}`,
	exitAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	buyingPrice?:boolean | `@${string}`,
	supplier?:ValueTypes["Supplier"],
	logistician?:ValueTypes["Logistician"],
	requestOrder?:ValueTypes["RequestOrder"],
	events?:ValueTypes["UnitItemEvent"],
	lastEvent?:ValueTypes["UnitItemEvent"],
	flux?:ValueTypes["Flux"],
	comments?:ValueTypes["UnitItemComment"],
		__typename?: boolean | `@${string}`
}>;
	["UnitItemEvent"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItemComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	author?:ValueTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestOrders"]: AliasType<{
	requestOrders?:ValueTypes["RequestOrder"],
	filterOptions?:ValueTypes["RequestOrderOptions"],
		__typename?: boolean | `@${string}`
}>;
	["RequestOrder"]: AliasType<{
	uuid?:boolean | `@${string}`,
	purchaseOrderNumber?:boolean | `@${string}`,
	requestOrderNumber?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	supplier?:ValueTypes["Supplier"],
	logistician?:ValueTypes["Logistician"],
	unitItems?:ValueTypes["UnitItem"],
	totalQuantity?:boolean | `@${string}`,
	totalReceived?:boolean | `@${string}`,
	totalBuyingPrice?:boolean | `@${string}`,
	comments?:ValueTypes["RequestOrderComment"],
	deliveryReceipts?:ValueTypes["DeliveryReceipt"],
		__typename?: boolean | `@${string}`
}>;
	["RequestOrderComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	author?:ValueTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceipt"]: AliasType<{
	uuid?:boolean | `@${string}`,
	deliveryReceiptNumber?:boolean | `@${string}`,
	invoiceNumber?:boolean | `@${string}`,
	deliveryItems?:ValueTypes["DeliveryItem"],
	createdAt?:boolean | `@${string}`,
	requestOrder?:ValueTypes["RequestOrder"],
	totalBuyingPrice?:boolean | `@${string}`,
	stockValue?:boolean | `@${string}`,
	totalReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryItem"]: AliasType<{
	itemName?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptFilter"]: {
	deliveryReceiptNumbers?: Array<string> | undefined | null | Variable<any, string>,
	invoiceNumbers?: Array<string> | undefined | null | Variable<any, string>,
	purchaseOrderNumbers?: Array<string> | undefined | null | Variable<any, string>
};
	["RequestOrderFilter"]: {
	purchaseOrderNumbers?: Array<string> | undefined | null | Variable<any, string>,
	requestOrderNumbers?: Array<string> | undefined | null | Variable<any, string>,
	suppliers?: Array<string> | undefined | null | Variable<any, string>,
	logisticians?: Array<string> | undefined | null | Variable<any, string>,
	buyingPrices?: Array<string> | undefined | null | Variable<any, string>,
	status?: Array<ValueTypes["RequestOrderKind"]> | undefined | null | Variable<any, string>,
	createdAt?: Array<string> | undefined | null | Variable<any, string>
};
	["RequestOrderOptions"]: AliasType<{
	purchaseOrderNumbers?:ValueTypes["OptionElement"],
	requestOrderNumbers?:ValueTypes["OptionElement"],
	suppliers?:ValueTypes["OptionElement"],
	logisticians?:ValueTypes["OptionElement"],
	realPrices?:ValueTypes["OptionElement"],
	buyingPrices?:ValueTypes["OptionElement"],
	status?:ValueTypes["OptionElement"],
	createdAt?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemResponseForm"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	qtyOrdered?:boolean | `@${string}`,
	buyingPrice?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestOrderResponse"]: AliasType<{
	requestOrder?:ValueTypes["RequestOrder"],
	itemsResponseForm?:ValueTypes["ItemResponseForm"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptResponse"]: AliasType<{
	deliveryReceipts?:ValueTypes["DeliveryReceipt"],
	filterOptions?:ValueTypes["DeliveryReceiptOptions"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptOptions"]: AliasType<{
	deliveryReceiptNumbers?:ValueTypes["OptionElement"],
	invoiceNumbers?:ValueTypes["OptionElement"],
	purchaseOrderNumbers?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemOrdered"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	qtyOrdered?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StockFilter"]: {
	brands?: Array<string> | undefined | null | Variable<any, string>,
	names?: Array<string> | undefined | null | Variable<any, string>,
	families?: Array<string> | undefined | null | Variable<any, string>,
	itemsNames?: Array<string> | undefined | null | Variable<any, string>,
	status?: Array<ValueTypes["UnitItemEventKind"]> | undefined | null | Variable<any, string>
};
	["StockOrder"]: {
	requested?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	inStock?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	allocated?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	toBeDelivered?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	rented?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	returnRequested?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	deliveryBack?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	quarantined?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>,
	exit?: ValueTypes["SortKind"] | undefined | null | Variable<any, string>
};
	["StockSelect"]: {
	supplierMode?: ValueTypes["SupplierMode"] | undefined | null | Variable<any, string>,
	suppliers?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	logisticians?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["StockOptions"]: AliasType<{
	brands?:ValueTypes["OptionElement"],
	names?:ValueTypes["OptionElement"],
	families?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["StockManagement"]: AliasType<{
	items?:ValueTypes["StockUnitItem"],
	filterOptions?:ValueTypes["StockOptions"],
	stockDashboard?:ValueTypes["StockDashboard"],
		__typename?: boolean | `@${string}`
}>;
	["StockDashboard"]: AliasType<{
	labels?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
	totalItems?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StockDetailedOptions"]: AliasType<{
	itemsNames?:ValueTypes["OptionElement"],
	status?:ValueTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["StockDetailed"]: AliasType<{
	unitItems?:ValueTypes["UnitItem"],
	filterOptions?:ValueTypes["StockDetailedOptions"],
		__typename?: boolean | `@${string}`
}>;
	["QuantityUnitItems"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	inStock?:boolean | `@${string}`,
	quantityNeeded?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateProductReturnRequestResponse"]: AliasType<{
	googleCloudStorageSignedUrls?:boolean | `@${string}`,
	productReturnRequest?:ValueTypes["ProductReturnRequest"],
		__typename?: boolean | `@${string}`
}>;
	["ProductReturn"]: AliasType<{
	uuid?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	subReason?:boolean | `@${string}`,
	sub?:ValueTypes["Sub"],
	productState?:boolean | `@${string}`,
	userComment?:boolean | `@${string}`,
	productPicture?:boolean | `@${string}`,
	productReturnRequest?:ValueTypes["ProductReturnRequest"],
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductReturnReason"]:ProductReturnReason;
	["ProductReturnSubReason"]:ProductReturnSubReason;
	["ProductReturnStatus"]:ProductReturnStatus;
	["ProductReturnState"]:ProductReturnState;
	["ProductReturnRequest"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	isKit?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	productReturns?:ValueTypes["ProductReturn"],
	client?:ValueTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["CreateProductReturnRequestForm"]: {
	productReturnsInput: Array<ValueTypes["ProductReturnInput"]> | Variable<any, string>,
	clientUuid: string | Variable<any, string>
};
	["ProductReturnInput"]: {
	reason: ValueTypes["ProductReturnReason"] | Variable<any, string>,
	subReason?: ValueTypes["ProductReturnSubReason"] | undefined | null | Variable<any, string>,
	subUuid: string | Variable<any, string>
};
	["ProductReturnRequestsStaffFilterInput"]: {
	clientUuid?: string | undefined | null | Variable<any, string>
};
	["UpdateProductReturnRequestForm"]: {
	isKit?: boolean | undefined | null | Variable<any, string>,
	productReturns: Array<ValueTypes["UpdateProductReturnRequestFormProductReturn"]> | Variable<any, string>
};
	["UpdateProductReturnRequestFormProductReturn"]: {
	uuid: string | Variable<any, string>,
	productState: ValueTypes["ProductReturnState"] | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	picture: boolean | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
	ping?:boolean | `@${string}`,
	families?:ResolverInputTypes["Family"],
typology?: [{	typologyUuid?: string | undefined | null},ResolverInputTypes["Typology"]],
typologies?: [{	familyUuid?: string | undefined | null},ResolverInputTypes["Typology"]],
	brands?:ResolverInputTypes["Brand"],
	logisticians?:ResolverInputTypes["Logistician"],
	suppliers?:ResolverInputTypes["Supplier"],
	items?:ResolverInputTypes["Item"],
itemManagement?: [{	itemFilter?: ResolverInputTypes["ItemFilter"] | undefined | null},ResolverInputTypes["ItemManagement"]],
	itemOptions?:ResolverInputTypes["ItemOptionsResponse"],
item?: [{	itemUuid?: string | undefined | null},ResolverInputTypes["Item"]],
	categories?:ResolverInputTypes["Category"],
	productOptions?:ResolverInputTypes["OptionElement"],
productManagement?: [{	productFilter?: ResolverInputTypes["ProductFilter"] | undefined | null},ResolverInputTypes["ProductManagement"]],
product?: [{	productUuid?: string | undefined | null},ResolverInputTypes["Product"]],
productMerchant?: [{	productUuid?: string | undefined | null},ResolverInputTypes["Product"]],
offers?: [{	offerFilter?: ResolverInputTypes["OfferFilter"] | undefined | null},ResolverInputTypes["Offer"]],
offer?: [{	offerUuid?: string | undefined | null},ResolverInputTypes["Offer"]],
merchantCatalog?: [{	companyUuid: string},ResolverInputTypes["MerchantCatalogElement"]],
	companyList?:ResolverInputTypes["ListItem"],
categoryMerchant?: [{	categoryUuid: string},ResolverInputTypes["Category"]],
archivedItems?: [{	filters?: ResolverInputTypes["ArchivedItemsFilters"] | undefined | null},ResolverInputTypes["ArchivedItems"]],
archivedProducts?: [{	filters?: ResolverInputTypes["ArchivedProductsFilters"] | undefined | null},ResolverInputTypes["ArchivedProducts"]],
family?: [{	familyUuid: string},ResolverInputTypes["Family"]],
productAdmin?: [{	productUuid?: string | undefined | null},ResolverInputTypes["Product"]],
storeProduct?: [{	productUuid: string},ResolverInputTypes["Product"]],
	companystore?:ResolverInputTypes["Product"],
	companyStoreRentalPlatform?:ResolverInputTypes["ProductCompanyStoreRentalPlatform"],
	fleexstore?:ResolverInputTypes["Family"],
productMarket?: [{	productUuid?: string | undefined | null},ResolverInputTypes["Product"]],
catalogProduct?: [{	productUuid: string},ResolverInputTypes["Product"]],
	catalog?:ResolverInputTypes["Family"],
	productAttributes?:ResolverInputTypes["ProductAttribute"],
productAttribute?: [{	productAttributeUuid: string},ResolverInputTypes["ProductAttribute"]],
productsStaff?: [{	typologyUuid?: string | undefined | null},ResolverInputTypes["ProductStaff"]],
productStaff?: [{	productUuid?: string | undefined | null},ResolverInputTypes["Product"]],
offerStaff?: [{	offerUuid: string},ResolverInputTypes["Offer"]],
offersStaff?: [{	merchantCompanyUuid?: string | undefined | null},ResolverInputTypes["OfferStaffResponse"]],
	offerStaffCreationData?:ResolverInputTypes["OfferCreationResponse"],
staffCatalog?: [{	companyUuid: string},ResolverInputTypes["StaffCatalogElement"]],
	basket?:ResolverInputTypes["Basket"],
	subsMarket_V2?:ResolverInputTypes["Sub"],
	subsAdmin?:ResolverInputTypes["Sub"],
	subsAdminGlobal?:ResolverInputTypes["SubAdminGlobal"],
paginatedSubs?: [{	subFilter?: ResolverInputTypes["SubFilter"] | undefined | null,	pagination: ResolverInputTypes["Pagination"]},ResolverInputTypes["PaginatedSubs"]],
sub?: [{	subUuid: string},ResolverInputTypes["Sub"]],
paginatedFlows?: [{	flowFilter?: ResolverInputTypes["FlowFilter"] | undefined | null,	pagination: ResolverInputTypes["Pagination"]},ResolverInputTypes["PaginatedFlows"]],
flows?: [{	flowFilter?: ResolverInputTypes["FlowFilter"] | undefined | null},ResolverInputTypes["Flux"]],
sendSupportSatisfactionSurveyEmail?: [{	client: ResolverInputTypes["SubClientInput"],	subscriptionId: string},boolean | `@${string}`],
	subCreationData?:ResolverInputTypes["SubCreationData"],
clientOptions?: [{	companyUuid: string},ResolverInputTypes["OptionElement"]],
totalEffectiveLocationPriceExclTaxes?: [{	companyUuid?: string | undefined | null},boolean | `@${string}`],
subsClient?: [{	clientUuid: string},ResolverInputTypes["Sub"]],
usage?: [{	companyUuid: string},ResolverInputTypes["Usage"]],
	sellsyInvoices?:ResolverInputTypes["SellsyInvoiceResponse"],
adminDashboardDataV2?: [{	companyGroupUuid?: string | undefined | null,	familyUuid?: string | undefined | null},ResolverInputTypes["AdminDashboardDataV2"]],
authenticate?: [{	email?: string | undefined | null,	password?: string | undefined | null,	refreshToken?: string | undefined | null,	type?: string | undefined | null},ResolverInputTypes["AuthResponse"]],
authenticateAs?: [{	clientUuid: string},ResolverInputTypes["AuthAsResponse"]],
checkResetToken?: [{	token: string},ResolverInputTypes["Client"]],
client?: [{	userUuid?: string | undefined | null},ResolverInputTypes["Client"]],
clients?: [{	filterSearch?: ResolverInputTypes["ClientsFilterSearch"] | undefined | null,	sortSearch?: Array<string | undefined | null> | undefined | null,	companyUuid?: string | undefined | null},ResolverInputTypes["Client"]],
clientsStaff?: [{	companyUuid: string},ResolverInputTypes["ClientsStaffData"]],
staff?: [{	userUuid?: string | undefined | null},ResolverInputTypes["StaffUser"]],
merchant?: [{	userUuid?: string | undefined | null},ResolverInputTypes["MerchantUser"]],
	companySettings?:ResolverInputTypes["CompanySettings"],
companyDetails?: [{	companyUuid?: string | undefined | null},ResolverInputTypes["Company"]],
companies?: [{	filterName?: string | undefined | null,	filters?: ResolverInputTypes["CompaniesFilter"] | undefined | null},ResolverInputTypes["Company"]],
	companiesStaff?:ResolverInputTypes["Company"],
	companyOnboardingV2?:ResolverInputTypes["Onboarding"],
logos?: [{	logoKind?: string | undefined | null},ResolverInputTypes["LogoResponse"]],
companyGroup?: [{	companyGroupUuid?: string | undefined | null},ResolverInputTypes["CompanyGroup"]],
companyGroupV2?: [{	companyGroupUuid?: string | undefined | null},ResolverInputTypes["CompanyGroup"]],
companyGroups?: [{	companyUuid?: string | undefined | null},ResolverInputTypes["CompanyGroup"]],
	companyGroupsV2?:ResolverInputTypes["CompanyGroupsData"],
	companyGroupOptions?:ResolverInputTypes["GroupOptionElement"],
	budgetsAdmin?:ResolverInputTypes["CompanyBudget"],
googleConnectionUrl?: [{	type: ResolverInputTypes["GoogleUrlType"]},boolean | `@${string}`],
getSSOConnectionUrl?: [{	domain: string},boolean | `@${string}`],
staffDashboard?: [{	financialModel?: ResolverInputTypes["FinancialModel"] | undefined | null},ResolverInputTypes["StaffDashboardData"]],
deliverySatisfactionSurvey?: [{	surveyUuid: string},ResolverInputTypes["DeliverySatisfactionSurvey"]],
	deliverySatisfactionSurveys?:ResolverInputTypes["DeliverySatisfactionSurveyRow"],
productSatisfactionSurvey?: [{	surveyUuid: string},ResolverInputTypes["ProductSatisfactionSurvey"]],
	productSatisfactionSurveys?:ResolverInputTypes["ProductSatisfactionSurveyRow"],
	usersAdmin?:ResolverInputTypes["UsersAdminData"],
userAdmin?: [{	userUuid: string},ResolverInputTypes["Client"]],
usersStaff?: [{	userFilter?: ResolverInputTypes["UsersStaffFilter"] | undefined | null,	pagination: ResolverInputTypes["Pagination"]},ResolverInputTypes["UsersStaffResponse"]],
usersStaffOffboarding?: [{	userFilter?: ResolverInputTypes["UsersStaffFilter"] | undefined | null,	pagination: ResolverInputTypes["Pagination"]},ResolverInputTypes["UsersStaffResponse"]],
	marketDashboard?:ResolverInputTypes["Client"],
unitItem?: [{	unitItemUuid: string},ResolverInputTypes["UnitItem"]],
stockDetailed?: [{	stockFilter?: ResolverInputTypes["StockFilter"] | undefined | null,	stockSelect?: ResolverInputTypes["StockSelect"] | undefined | null},ResolverInputTypes["StockDetailed"]],
stockManagement?: [{	stockFilter?: ResolverInputTypes["StockFilter"] | undefined | null,	stockOrder?: ResolverInputTypes["StockOrder"] | undefined | null,	stockSelect?: ResolverInputTypes["StockSelect"] | undefined | null},ResolverInputTypes["StockManagement"]],
	quantityUnitItems?:ResolverInputTypes["QuantityUnitItems"],
requestOrders?: [{	requestOrderFilter?: ResolverInputTypes["RequestOrderFilter"] | undefined | null},ResolverInputTypes["RequestOrders"]],
requestOrder?: [{	requestOrderUuid: string},ResolverInputTypes["RequestOrderResponse"]],
deliveryReceipts?: [{	deliveryReceiptFilter?: ResolverInputTypes["DeliveryReceiptFilter"] | undefined | null},ResolverInputTypes["DeliveryReceiptResponse"]],
productReturnRequest?: [{	productReturnRequestId: string},ResolverInputTypes["ProductReturnRequest"]],
	productReturnRequests?:ResolverInputTypes["ProductReturnRequest"],
productReturnRequestsStaff?: [{	filter?: ResolverInputTypes["ProductReturnRequestsStaffFilterInput"] | undefined | null},ResolverInputTypes["ProductReturnRequest"]],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
	exampleMutation?:boolean | `@${string}`,
createFamily?: [{	name: string},ResolverInputTypes["Family"]],
createLogistician?: [{	name: string},ResolverInputTypes["Logistician"]],
upsertSupplier?: [{	supplierUuid?: string | undefined | null,	name: string},ResolverInputTypes["Supplier"]],
upsertTypology?: [{	typologyUuid?: string | undefined | null,	formData: ResolverInputTypes["TypologyForm"]},ResolverInputTypes["Typology"]],
upsertBrand?: [{	brandUuid?: string | undefined | null,	formData: ResolverInputTypes["BrandForm"]},ResolverInputTypes["Brand"]],
upsertCategory?: [{	categoryUuid?: string | undefined | null,	formData: ResolverInputTypes["CategoryForm"]},ResolverInputTypes["Category"]],
upsertItem?: [{	itemUuid?: string | undefined | null,	formData: ResolverInputTypes["UpsertItemForm"]},ResolverInputTypes["Item"]],
upsertProduct?: [{	productUuid?: string | undefined | null,	formData: ResolverInputTypes["UpsertProductForm"]},ResolverInputTypes["Product"]],
upsertOffer?: [{	offerUuid?: string | undefined | null,	formData: ResolverInputTypes["UpsertOfferForm"]},ResolverInputTypes["Offer"]],
updateOfferEndAt?: [{	offerUuid: string,	endAt?: string | undefined | null},ResolverInputTypes["Offer"]],
setProductDisplayMode?: [{	productUuid: string,	displayMode: ResolverInputTypes["DisplayMode"]},ResolverInputTypes["Product"]],
setProductDisabled?: [{	productUuid: string,	disabled: boolean},ResolverInputTypes["ProductDisabledResult"]],
setProductDisabledBulk?: [{	productUuids: Array<string>,	disabled: boolean},ResolverInputTypes["ProductDisabledResult"]],
setProductDisplayModeBulk?: [{	productUuids: Array<string>,	displayMode: ResolverInputTypes["DisplayMode"]},ResolverInputTypes["ProductListElement"]],
setCompanyWhitelistProductsBulk?: [{	productUuids: Array<string>,	companyUuid: string,	actionType: ResolverInputTypes["WlBlAction"]},ResolverInputTypes["MerchantCatalogElement"]],
setCompanyBlacklistProductsBulk?: [{	productUuids: Array<string>,	companyUuid: string,	actionType: ResolverInputTypes["WlBlAction"]},ResolverInputTypes["MerchantCatalogElement"]],
updateProductDisplayPriority?: [{	categoryUuid: string,	sourceUuid: string,	targetUuid: string},ResolverInputTypes["Category"]],
setItemDisabled?: [{	itemUuid: string,	disabled: boolean},ResolverInputTypes["Item"]],
archiveItem?: [{	itemUuid: string,	archived: boolean,	archivingReason?: string | undefined | null},ResolverInputTypes["Item"]],
replaceItem?: [{	itemUuid: string,	formData: ResolverInputTypes["UpsertItemForm"]},ResolverInputTypes["Item"]],
archiveProduct?: [{	productUuid: string,	archived: boolean},ResolverInputTypes["Product"]],
toggleAddToCompanyStore?: [{	productUuid: string,	addToStore: boolean},ResolverInputTypes["Product"]],
toggleBulkAddToCompanyStore?: [{	productUuids: Array<string>,	addToStore: boolean},ResolverInputTypes["Product"]],
toggleAllowedGroup?: [{	productUuid: string,	groupUuid: string},ResolverInputTypes["ToggleAllowedGroupResult"]],
bulkAddAllowedGroups?: [{	productUuids: Array<string>,	groupUuids: Array<string>},ResolverInputTypes["Product"]],
bulkRemoveAllowedGroups?: [{	productUuids: Array<string>,	groupUuids: Array<string>},ResolverInputTypes["Product"]],
	processUnseenProducts?:ResolverInputTypes["Product"],
upsertProductAttribute?: [{	productAttributeUuid?: string | undefined | null,	formData: ResolverInputTypes["ProductAttributeForm"]},ResolverInputTypes["ProductAttribute"]],
upsertProductStaff?: [{	productUuid?: string | undefined | null,	formData: ResolverInputTypes["ProductStaffForm"]},ResolverInputTypes["Product"]],
archiveProductStaff?: [{	productUuid: string,	archived: boolean,	archivingReason?: string | undefined | null},ResolverInputTypes["Product"]],
createOfferStaff?: [{	formData: ResolverInputTypes["CreateOfferStaffForm"]},ResolverInputTypes["Offer"]],
replaceOfferStaff?: [{	offerUuid: string,	formData: ResolverInputTypes["CreateOfferStaffForm"]},ResolverInputTypes["ReplaceOfferStaffResponse"]],
updateFamiliesOrder?: [{	sourceUuid: string,	targetUuid: string},ResolverInputTypes["Family"]],
updateTypologiesOrder?: [{	familyUuid: string,	sourceUuid: string,	targetUuid: string},ResolverInputTypes["Typology"]],
updateProductsStaffOrder?: [{	typologyUuid: string,	sourceUuid: string,	targetUuid: string},ResolverInputTypes["ProductStaff"]],
upsertProductBasket?: [{	productUuid: string,	quantity: number},ResolverInputTypes["BasketItem"]],
	confirmBasket_V2?:ResolverInputTypes["Basket"],
cancelFlux?: [{	fluxUuid: string},ResolverInputTypes["Sub"]],
addFlux?: [{	subUuid: string,	fluxKind: ResolverInputTypes["FluxKind"]},ResolverInputTypes["Sub"]],
addFluxBulk?: [{	subUuids: Array<string>,	fluxKind: ResolverInputTypes["FluxKind"]},ResolverInputTypes["Sub"]],
addFluxEvent?: [{	fluxUuid: string,	eventKind: ResolverInputTypes["FluxEventKind"]},ResolverInputTypes["Sub"]],
addFluxEventBulk?: [{	fluxUuids: Array<string>,	eventKind: ResolverInputTypes["FluxEventKind"]},ResolverInputTypes["Sub"]],
cancelSub?: [{	subUuid: string,	onCancelingDelivery?: boolean | undefined | null},ResolverInputTypes["Sub"]],
cancelSubBulk?: [{	subUuids: Array<string>},ResolverInputTypes["Sub"]],
setFlag?: [{	subUuid: string,	flagKind: ResolverInputTypes["SubFlagKind"],	flagState: boolean},ResolverInputTypes["Sub"]],
setFlagBulk?: [{	subUuids: Array<string>,	flagKind: ResolverInputTypes["SubFlagKind"],	flagState: boolean},ResolverInputTypes["Sub"]],
setExpeditionIdBulk?: [{	subUuids: Array<string>},ResolverInputTypes["Sub"]],
addSubComment?: [{	subUuid: string,	formData: ResolverInputTypes["SubCommentForm"]},ResolverInputTypes["SubComment"]],
addFlowEventBulk?: [{	fluxUuids: Array<string>,	eventKind: ResolverInputTypes["FluxEventKind"]},ResolverInputTypes["Flux"]],
createSubMerchant?: [{	formData: ResolverInputTypes["CreateSubMerchantForm"]},ResolverInputTypes["Sub"]],
signupCompanyAdmin?: [{	formData?: ResolverInputTypes["CompanyAdminCreationForm"] | undefined | null},ResolverInputTypes["SignupResponse"]],
signupCompanyClient?: [{	formData: ResolverInputTypes["CompanyClientCreationForm"]},ResolverInputTypes["SignupResponse"]],
signupCompanyClientAddress?: [{	clientUuid: string,	formData: ResolverInputTypes["CompanyClientAddressCreationForm"]},boolean | `@${string}`],
activateCompanyClient?: [{	token: string},boolean | `@${string}`],
upsertCompanyClient_V2?: [{	clientUuid?: string | undefined | null,	formData?: ResolverInputTypes["UpsertCompanyClientForm_V2"] | undefined | null},ResolverInputTypes["Client"]],
setCompanyClientKind?: [{	clientUuid: string,	kind: string,	companyUuid?: string | undefined | null},ResolverInputTypes["Client"]],
setCompanyClientAccountState?: [{	clientUuid: string,	isDisable: boolean},ResolverInputTypes["Client"]],
updateCompanyClientPassword?: [{	clientUuid: string,	formData?: ResolverInputTypes["UpdateCompanyClientPasswordForm"] | undefined | null},ResolverInputTypes["Client"]],
updateClientPassword?: [{	currentPassword: string,	newPassword: string},ResolverInputTypes["Client"]],
updateCompanyClientShippingAddress?: [{	clientUuid: string,	formData?: ResolverInputTypes["updateCompanyClientShippingAddressForm"] | undefined | null},ResolverInputTypes["Client"]],
requestCompanyClientPasswordReset?: [{	email: string},boolean | `@${string}`],
resetPassword?: [{	token: string,	password: string},boolean | `@${string}`],
updateCompanyDetails?: [{	companyUuid?: string | undefined | null,	formData?: ResolverInputTypes["CompanyDetailsForm"] | undefined | null},ResolverInputTypes["Company"]],
updateCompanyDetailsV2?: [{	companyUuid: string,	formData?: ResolverInputTypes["CompanyDetailsForm"] | undefined | null},ResolverInputTypes["Company"]],
updateCompanyBillingContact?: [{	companyUuid?: string | undefined | null,	formData?: ResolverInputTypes["CompanyBillingContactForm"] | undefined | null},ResolverInputTypes["Company"]],
updateCompanyBillingAddress?: [{	companyUuid?: string | undefined | null,	formData?: ResolverInputTypes["UpdateCompanyBillingAddressForm"] | undefined | null},ResolverInputTypes["Company"]],
updateCompanyBilling?: [{	companyUuid?: string | undefined | null,	formData?: ResolverInputTypes["UpdateCompanyBillingAddressForm"] | undefined | null},ResolverInputTypes["Company"]],
updateCompanyBranding?: [{	companyUuid?: string | undefined | null,	formData?: ResolverInputTypes["UpdateCompanyBranding"] | undefined | null},ResolverInputTypes["Company"]],
setCompanySelfSignup?: [{	allowSelfSignup?: boolean | undefined | null},ResolverInputTypes["Company"]],
setServiceSubscription?: [{	companyUuid: string,	formData?: ResolverInputTypes["SetServiceSubscriptionForm"] | undefined | null},ResolverInputTypes["Company"]],
setMinimumMRREngaged?: [{	companyUuid: string,	mrrEngaged: number},ResolverInputTypes["Company"]],
createUploadToken?: [{	uploadKind?: string | undefined | null},boolean | `@${string}`],
inviteUsers?: [{	emails: Array<string>,	companyGroupUuid?: string | undefined | null,	isAdmin?: boolean | undefined | null},ResolverInputTypes["InvitedUser"]],
resendInvitations?: [{	emails: Array<string>},ResolverInputTypes["InvitedUser"]],
deletePendingInvites?: [{	invitedUsersUuid: Array<string>},ResolverInputTypes["InvitedUser"]],
deletePendingInvitesV2?: [{	invitedUsersUuid: Array<string>},ResolverInputTypes["InvitedUser"]],
setUserOffboarding?: [{	userUuid: string,	accountOffboardedAt: string,	personalEmail: string},ResolverInputTypes["SetUserOffboardingResponse"]],
cancelUserOffboarding?: [{	userUuid: string},ResolverInputTypes["Client"]],
upsertCompanyGroup?: [{	companyGroupUuid?: string | undefined | null,	formData: ResolverInputTypes["CompanyGroupForm"]},ResolverInputTypes["CompanyGroup"]],
deleteCompanyGroup?: [{	companyGroupUuid: string},ResolverInputTypes["CompanyGroup"]],
deleteCompanyGroupV2?: [{	companyGroupUuid: string},ResolverInputTypes["Product"]],
createCompanyBudget?: [{	companyUuid: string,	formData?: ResolverInputTypes["CompanyBudgetForm"] | undefined | null},ResolverInputTypes["CompanyBudget"]],
createCompanyGroup?: [{	companyUuid: string,	formData?: ResolverInputTypes["CompanyGroupForm"] | undefined | null},ResolverInputTypes["CompanyGroup"]],
updateCompanyGroup?: [{	groupUuid: string,	formData?: ResolverInputTypes["CompanyGroupForm"] | undefined | null},ResolverInputTypes["CompanyGroup"]],
updateCompanyBudget?: [{	budgetUuid: string,	formData?: ResolverInputTypes["CompanyBudgetForm"] | undefined | null},ResolverInputTypes["CompanyBudget"]],
removeCompanyBudget?: [{	budgetUuid: string},ResolverInputTypes["CompanyBudget"]],
setCompanyDefaultGroup?: [{	companyGroupUuid: string},ResolverInputTypes["CompanyGroup"]],
addUsersToGroup?: [{	users: Array<string>,	groupUuid: string},ResolverInputTypes["AddUsersToGroupResult"]],
addPendingUsersToGroup?: [{	users: Array<string>,	groupUuid: string},ResolverInputTypes["Client"]],
associateGoogleAccount?: [{	code: string},ResolverInputTypes["Client"]],
associateGoogleAccount_V2?: [{	code: string},ResolverInputTypes["Client"]],
	dissociateGoogleAccount?:ResolverInputTypes["Client"],
googleAuthentication?: [{	code: string},ResolverInputTypes["AuthResponse"]],
authenticateWithSSO?: [{	code: string},ResolverInputTypes["AuthResponse"]],
setSSOConfiguration?: [{	companyUuid: string,	ssoConfiguration: ResolverInputTypes["SSOConfigurationForm"]},ResolverInputTypes["Company"]],
setSignUpInConfiguration?: [{	companyUuid: string,	signUpInConfiguration: ResolverInputTypes["SignUpInConfigurationForm"]},ResolverInputTypes["Company"]],
sendDeliverySatisfactionSurvey?: [{	surveyUuid: string,	answers: ResolverInputTypes["DeliverySatisfactionSurveyAnswer"]},boolean | `@${string}`],
sendProductSatisfactionSurvey?: [{	surveyUuid: string,	answers: Array<ResolverInputTypes["ProductSatisfactionSurveyAnswer"]>},boolean | `@${string}`],
sendActivationEmail?: [{	clientUuid: string},boolean | `@${string}`],
updateCompanyDeliveryCountries?: [{	companyUuid: string,	countries: Array<string>},ResolverInputTypes["Company"]],
updateCompanyFinancialModel?: [{	companyUuid: string,	financialModel: ResolverInputTypes["FinancialModel"]},ResolverInputTypes["Company"]],
updateCompanyBillingContactV2?: [{	companyUuid: string,	formData: ResolverInputTypes["CompanyBillingContactForm"]},ResolverInputTypes["Company"]],
updateCompanyBillingAddressV2?: [{	companyUuid: string,	formData: ResolverInputTypes["CompanyBillingAddressForm"]},ResolverInputTypes["Company"]],
updateCompanyBillingSepa?: [{	companyUuid: string,	sepa: boolean},ResolverInputTypes["Company"]],
createRequestOrder?: [{	formData: ResolverInputTypes["RequestOrderForm"]},ResolverInputTypes["RequestOrder"]],
deleteRequestOrder?: [{	requestOrderUuid: string},ResolverInputTypes["RequestOrder"]],
closeRequestOrder?: [{	requestOrderUuid: string,	comment?: string | undefined | null},ResolverInputTypes["RequestOrder"]],
updateUnitItemsStatus?: [{	itemsReceived: Array<ResolverInputTypes["ItemReceived"]>,	requestOrderUuid: string},ResolverInputTypes["RequestOrder"]],
addUnitItemEvent?: [{	unitItemUuid: string,	eventKind: ResolverInputTypes["UnitItemEventKind"]},ResolverInputTypes["UnitItem"]],
updateUnitItemSupplier?: [{	unitItemUuid: string,	supplierUuid: string},ResolverInputTypes["UnitItem"]],
addUnitItemComment?: [{	unitItemUuid: string,	textComment: string},ResolverInputTypes["UnitItemComment"]],
addRequestOrderComment?: [{	requestOrderUuid: string,	textComment: string},ResolverInputTypes["RequestOrderComment"]],
createDeliveryReceipt?: [{	itemsReceived: Array<ResolverInputTypes["ItemReceived"]>,	invoiceNumber?: string | undefined | null,	requestOrderUuid: string},ResolverInputTypes["RequestOrder"]],
updateDeliveryReceiptInvoiceNumber?: [{	invoiceNumber: string,	deliveryReceiptUuid: string},ResolverInputTypes["DeliveryReceipt"]],
updateUnitItemsPrice?: [{	requestOrderUuid: string,	itemUuid: string,	newPrice: number},ResolverInputTypes["RequestOrder"]],
trackProductClick?: [{	productUuid: string},boolean | `@${string}`],
createProductReturnRequest?: [{	formData: ResolverInputTypes["CreateProductReturnRequestForm"]},ResolverInputTypes["ProductReturnRequest"]],
updateProductReturnStatus?: [{	status: ResolverInputTypes["ProductReturnStatus"],	productReturnUuid: string},ResolverInputTypes["ProductReturn"]],
updateProductReturnRequestStatuses?: [{	status: ResolverInputTypes["ProductReturnStatus"],	productReturnRequestUuids: Array<string>},ResolverInputTypes["ProductReturnRequest"]],
updateProductReturnUserInformations?: [{	formData: ResolverInputTypes["UpdateProductReturnRequestForm"],	productReturnRequestUuid: string},ResolverInputTypes["UpdateProductReturnRequestResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyValue"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["KeyValueInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null
};
	["DisplayMode"]:DisplayMode;
	["WlBlAction"]:WlBlAction;
	["DisabledProductReason"]:DisabledProductReason;
	["SupplierMode"]:SupplierMode;
	["ItemStatus"]:ItemStatus;
	["ProductAttributeType"]:ProductAttributeType;
	["JSON"]:unknown;
	["ProductCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	brand?:ResolverInputTypes["Brand"],
	offer?:ResolverInputTypes["OfferCompanyStoreRentalPlatform"],
	displayPriority?:boolean | `@${string}`,
	allowedGroups?:ResolverInputTypes["GroupCompanyStoreRentalPlatform"],
	items?:ResolverInputTypes["ItemCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["ItemCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	typology?:ResolverInputTypes["TypologyCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["TypologyCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	family?:ResolverInputTypes["FamilyCompanyStoreRentalPlatform"],
		__typename?: boolean | `@${string}`
}>;
	["OfferCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FamilyCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupCompanyStoreRentalPlatform"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductDisabledResult"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	isDisabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Family"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	typologies?:ResolverInputTypes["Typology"],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Brand"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Logistician"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Supplier"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Typology"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameEN?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	transportationCost?:boolean | `@${string}`,
	family?:ResolverInputTypes["Family"],
	products?:ResolverInputTypes["Product"],
	storeCardOrientation?:boolean | `@${string}`,
	numberOfAssociatedAttributes?:boolean | `@${string}`,
	productAttributes?:ResolverInputTypes["ProductAttribute"],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductAttributeValue"]: AliasType<{
	uuid?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductAttribute"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	numberOfAssociatedProducts?:boolean | `@${string}`,
	values?:ResolverInputTypes["ProductAttributeValue"],
		__typename?: boolean | `@${string}`
}>;
	["Item"]: AliasType<{
	uuid?:boolean | `@${string}`,
	model?:boolean | `@${string}`,
	ean?:boolean | `@${string}`,
	variant?:boolean | `@${string}`,
	sku?:boolean | `@${string}`,
	heightCm?:boolean | `@${string}`,
	lengthCm?:boolean | `@${string}`,
	depthCm?:boolean | `@${string}`,
	weightG?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typology?:ResolverInputTypes["Typology"],
	brand?:ResolverInputTypes["Brand"],
	defaultOffer?:ResolverInputTypes["Offer"],
	offers?:ResolverInputTypes["Offer"],
	archivedAt?:boolean | `@${string}`,
	archivingReason?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	logistician?:ResolverInputTypes["Logistician"],
		__typename?: boolean | `@${string}`
}>;
	["ItemFilter"]: {
	typologies?: Array<string> | undefined | null,
	families?: Array<string> | undefined | null,
	logisticians?: Array<string> | undefined | null,
	brands?: Array<string> | undefined | null,
	models?: Array<string> | undefined | null,
	names?: Array<string> | undefined | null,
	skus?: Array<string> | undefined | null,
	prices?: Array<number> | undefined | null,
	status?: ResolverInputTypes["ItemStatus"] | undefined | null
};
	["ItemManagementFilter"]: AliasType<{
	typologies?:ResolverInputTypes["OptionElement"],
	logisticians?:ResolverInputTypes["OptionElement"],
	families?:ResolverInputTypes["OptionElement"],
	brands?:ResolverInputTypes["OptionElement"],
	models?:ResolverInputTypes["OptionElement"],
	names?:ResolverInputTypes["OptionElement"],
	skus?:ResolverInputTypes["OptionElement"],
	prices?:ResolverInputTypes["OptionElement"],
	status?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemManagement"]: AliasType<{
	items?:ResolverInputTypes["Item"],
	filterOptions?:ResolverInputTypes["ItemManagementFilter"],
		__typename?: boolean | `@${string}`
}>;
	["UpsertItemForm"]: {
	typologyUuid?: string | undefined | null,
	brandUuid?: string | undefined | null,
	model?: string | undefined | null,
	variant?: string | undefined | null,
	sku?: string | undefined | null,
	heightCm?: number | undefined | null,
	lengthCm?: number | undefined | null,
	depthCm?: number | undefined | null,
	weightG?: number | undefined | null,
	pictureUrl: string,
	deliveryDelay?: number | undefined | null,
	engagement?: number | undefined | null,
	price?: number | undefined | null,
	priorNotice?: number | undefined | null,
	archivingReason?: string | undefined | null,
	logisticianUuid?: string | undefined | null
};
	["TypologyAttributeForm"]: {
	uuid: string,
	order: number
};
	["TypologyForm"]: {
	familyUuid?: string | undefined | null,
	nameEN?: string | undefined | null,
	nameFR?: string | undefined | null,
	transportationCost: number,
	attributes?: Array<ResolverInputTypes["TypologyAttributeForm"]> | undefined | null
};
	["ProductAttributeValueForm"]: {
	uuid: string,
	value: string
};
	["ProductAttributeForm"]: {
	productAttributeUuid?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	type: ResolverInputTypes["ProductAttributeType"],
	values?: Array<ResolverInputTypes["ProductAttributeValueForm"]> | undefined | null,
	newValues?: Array<string> | undefined | null
};
	["BrandForm"]: {
	name?: string | undefined | null,
	description?: ResolverInputTypes["JSON"] | undefined | null
};
	["Category"]: AliasType<{
	uuid?:boolean | `@${string}`,
	nameEN?:boolean | `@${string}`,
	nameFR?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureMiniUrl?:boolean | `@${string}`,
	pictureWideUrl?:boolean | `@${string}`,
	whiteTheme?:boolean | `@${string}`,
	products?:ResolverInputTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryForm"]: {
	nameEN: string,
	nameFR: string,
	pictureMiniUrl: string,
	pictureWideUrl: string,
	whiteTheme?: boolean | undefined | null
};
	["ProductPicture"]: AliasType<{
	uuid?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	rank?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisabledGroup"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabledReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	uuid?:boolean | `@${string}`,
	categories?:ResolverInputTypes["Category"],
	items?:ResolverInputTypes["Item"],
	item?:ResolverInputTypes["Item"],
	name?:boolean | `@${string}`,
	brand?:ResolverInputTypes["Brand"],
	description?:boolean | `@${string}`,
	information?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	pictures?:ResolverInputTypes["ProductPicture"],
	heightCm?:boolean | `@${string}`,
	lengthCm?:boolean | `@${string}`,
	depthCm?:boolean | `@${string}`,
	monitor?:boolean | `@${string}`,
	connection?:boolean | `@${string}`,
	displayMode?:boolean | `@${string}`,
	isDisabled?:boolean | `@${string}`,
	disabledGroups?:ResolverInputTypes["DisabledGroup"],
	offer?:ResolverInputTypes["Offer"],
	displayPriority?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	isANewProduct?:boolean | `@${string}`,
	seenByAdmin?:boolean | `@${string}`,
	isInCompanyStore?:boolean | `@${string}`,
	allowedGroups?:ResolverInputTypes["CompanyGroup"],
	merchantCompany?:ResolverInputTypes["MerchantCompany"],
	attributes?:ResolverInputTypes["ProductProductAttribute"],
		__typename?: boolean | `@${string}`
}>;
	["ProductProductAttribute"]: AliasType<{
	uuid?:boolean | `@${string}`,
	stringValue?:boolean | `@${string}`,
	booleanValue?:boolean | `@${string}`,
	productAttribute?:ResolverInputTypes["ProductAttribute"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStaff"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	productPictureUrl?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	familyName?:boolean | `@${string}`,
	typologyNameEN?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	offersCount?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpsertProductForm"]: {
	categories: Array<string>,
	items: Array<string>,
	name: string,
	description?: ResolverInputTypes["JSON"] | undefined | null,
	information?: ResolverInputTypes["JSON"] | undefined | null,
	pictureUrls: Array<string | undefined | null>,
	heightCm?: number | undefined | null,
	lengthCm?: number | undefined | null,
	depthCm?: number | undefined | null,
	monitor?: string | undefined | null,
	connection?: string | undefined | null,
	displayMode?: ResolverInputTypes["DisplayMode"] | undefined | null
};
	["ProductStaffForm"]: {
	familyUuid?: string | undefined | null,
	typologyUuid?: string | undefined | null,
	brandUuid?: string | undefined | null,
	model?: string | undefined | null,
	ean?: string | undefined | null,
	pictureUrls: Array<string | undefined | null>,
	name: string,
	description?: ResolverInputTypes["JSON"] | undefined | null,
	heightCm?: string | undefined | null,
	lengthCm?: string | undefined | null,
	depthCm?: string | undefined | null,
	weightG?: string | undefined | null,
	specs?: Array<ResolverInputTypes["SpecsForm"]> | undefined | null
};
	["SpecsForm"]: {
	key: string,
	value?: string | undefined | null,
	booleanValue?: boolean | undefined | null,
	type: ResolverInputTypes["ProductAttributeType"]
};
	["ProductFilter"]: {
	categories?: Array<string> | undefined | null,
	names?: Array<string> | undefined | null,
	numberOfItems?: Array<number> | undefined | null,
	defaultDisplay?: string | undefined | null,
	defaultPrices?: Array<number> | undefined | null,
	status?: boolean | undefined | null
};
	["ProductManagementFilter"]: AliasType<{
	categories?:ResolverInputTypes["OptionElement"],
	names?:ResolverInputTypes["OptionElement"],
	numberOfItems?:ResolverInputTypes["OptionElement"],
	defaultPrices?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ProductManagement"]: AliasType<{
	products?:ResolverInputTypes["ProductListElement"],
	filterOptions?:ResolverInputTypes["ProductManagementFilter"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListElement"]: AliasType<{
	uuid?:boolean | `@${string}`,
	categories?:ResolverInputTypes["Category"],
	name?:boolean | `@${string}`,
	numberOfItems?:boolean | `@${string}`,
	defaultDisplay?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffCountry"]: AliasType<{
	uuid?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	additionalShippingFees?:boolean | `@${string}`,
	deliveryDelay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Offer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	deliveryDelay?:boolean | `@${string}`,
	engagement?:boolean | `@${string}`,
	priorNotice?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	startAt?:boolean | `@${string}`,
	endAt?:boolean | `@${string}`,
	company?:ResolverInputTypes["Company"],
	item?:ResolverInputTypes["Item"],
	merchantCompany?:ResolverInputTypes["MerchantCompany"],
	countries?:ResolverInputTypes["OfferStaffCountry"],
		__typename?: boolean | `@${string}`
}>;
	["UpsertOfferForm"]: {
	companyUuid?: string | undefined | null,
	itemUuid?: string | undefined | null,
	startAt?: string | undefined | null,
	endAt?: string | undefined | null,
	deliveryDelay?: number | undefined | null,
	engagement?: number | undefined | null,
	priorNotice?: number | undefined | null,
	price?: number | undefined | null
};
	["OfferFilter"]: {
	companies?: Array<string> | undefined | null,
	items?: Array<string> | undefined | null,
	prices?: Array<number> | undefined | null,
	startAts?: Array<string> | undefined | null,
	endAts?: Array<string | undefined | null> | undefined | null,
	status?: boolean | undefined | null
};
	["ItemOptionsResponse"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	defaultOffer?:ResolverInputTypes["Offer"],
		__typename?: boolean | `@${string}`
}>;
	["MerchantCatalogElement"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	categories?:ResolverInputTypes["Category"],
	productName?:boolean | `@${string}`,
	defaultDisplay?:boolean | `@${string}`,
	companyDisplay?:boolean | `@${string}`,
	companyEnabled?:boolean | `@${string}`,
	defaultPrice?:boolean | `@${string}`,
	companyPrice?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffCatalogElement"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	typologyName?:boolean | `@${string}`,
	hasGroupAffected?:boolean | `@${string}`,
	hasSpecificOffer?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ListItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupDisabledProductInput"]: {
	uuid: string,
	disabled: boolean
};
	["ArchivedItemsFilters"]: {
	names?: Array<string> | undefined | null,
	skus?: Array<string> | undefined | null,
	archivingDates?: Array<string> | undefined | null
};
	["ArchivedItemsFilterOptions"]: AliasType<{
	names?:ResolverInputTypes["OptionElement"],
	skus?:ResolverInputTypes["OptionElement"],
	archivingDates?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedItems"]: AliasType<{
	items?:ResolverInputTypes["Item"],
	filterOptions?:ResolverInputTypes["ArchivedItemsFilterOptions"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedProductsFilters"]: {
	names?: Array<string> | undefined | null,
	archivingDates?: Array<string> | undefined | null
};
	["ArchivedProductsFilterOptions"]: AliasType<{
	names?:ResolverInputTypes["OptionElement"],
	archivingDates?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ArchivedProducts"]: AliasType<{
	products?:ResolverInputTypes["Product"],
	filterOptions?:ResolverInputTypes["ArchivedProductsFilterOptions"],
		__typename?: boolean | `@${string}`
}>;
	["ToggleAllowedGroupResult"]: AliasType<{
	product?:ResolverInputTypes["Product"],
	groupName?:boolean | `@${string}`,
	allowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffFilters"]: AliasType<{
	companies?:ResolverInputTypes["OptionElement"],
	merchants?:ResolverInputTypes["OptionElement"],
	typologies?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["OfferStaffResponse"]: AliasType<{
	offers?:ResolverInputTypes["Offer"],
	filterOptions?:ResolverInputTypes["OfferStaffFilters"],
		__typename?: boolean | `@${string}`
}>;
	["OfferCreationResponse"]: AliasType<{
	items?:ResolverInputTypes["OptionElement"],
	companies?:ResolverInputTypes["OptionElement"],
	merchants?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["CreateOfferStaffCountryForm"]: {
	country: string,
	additionalShippingFees?: number | undefined | null,
	deliveryDelay: number
};
	["CreateOfferStaffForm"]: {
	itemUuid: string,
	companyUuid?: string | undefined | null,
	merchantUuid: string,
	price: number,
	startAt: string,
	endAt?: string | undefined | null,
	countries: Array<ResolverInputTypes["CreateOfferStaffCountryForm"]>
};
	["ReplaceOfferStaffResponse"]: AliasType<{
	oldOffer?:ResolverInputTypes["Offer"],
	newOffer?:ResolverInputTypes["Offer"],
		__typename?: boolean | `@${string}`
}>;
	["SubFlagKind"]:SubFlagKind;
	["FluxKind"]:FluxKind;
	["FluxEventKind"]:FluxEventKind;
	["BasketItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	product?:ResolverInputTypes["Product"],
	isAvailable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BasketSummary"]: AliasType<{
	uuid?:boolean | `@${string}`,
	items?:ResolverInputTypes["BasketItem"],
		__typename?: boolean | `@${string}`
}>;
	["BudgetSummary"]: AliasType<{
	uuid?:boolean | `@${string}`,
	basketCount?:boolean | `@${string}`,
	totalBasket?:boolean | `@${string}`,
	availableBudget?:boolean | `@${string}`,
	group?:ResolverInputTypes["CompanyGroup"],
		__typename?: boolean | `@${string}`
}>;
	["Basket"]: AliasType<{
	basket?:ResolverInputTypes["BasketSummary"],
	budgetSummary?:ResolverInputTypes["BudgetSummary"],
		__typename?: boolean | `@${string}`
}>;
	["Flux"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	events?:ResolverInputTypes["FluxEvent"],
	lastEvent?:ResolverInputTypes["FluxEvent"],
	sub?:ResolverInputTypes["Sub"],
	unitItem?:ResolverInputTypes["UnitItem"],
		__typename?: boolean | `@${string}`
}>;
	["FluxEvent"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubState"]: AliasType<{
	lastFlux?:ResolverInputTypes["Flux"],
	lastEvent?:ResolverInputTypes["FluxEvent"],
	shortStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Sub"]: AliasType<{
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	startedAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	firstDeliveredAt?:boolean | `@${string}`,
	lastDeliveredAt?:boolean | `@${string}`,
	terminatedAt?:boolean | `@${string}`,
	commandId?:boolean | `@${string}`,
	subscriptionId?:boolean | `@${string}`,
	expeditionId?:boolean | `@${string}`,
	client?:ResolverInputTypes["Client"],
	item?:ResolverInputTypes["Item"],
	product?:ResolverInputTypes["Product"],
	address?:ResolverInputTypes["Address"],
	offer?:ResolverInputTypes["Offer"],
	comments?:ResolverInputTypes["SubComment"],
	fluxes?:ResolverInputTypes["Flux"],
	deliveryFlux?:ResolverInputTypes["Flux"],
	retrievalFlux?:ResolverInputTypes["Flux"],
	lastFlux?:ResolverInputTypes["Flux"],
	state?:ResolverInputTypes["SubState"],
	supportFlagEnabledAt?:boolean | `@${string}`,
	standbyFlagEnabledAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubFilter"]: {
	triggerHistory?: Array<string> | undefined | null,
	commandIds?: Array<string> | undefined | null,
	expeditionIds?: Array<string> | undefined | null,
	subIds?: Array<string> | undefined | null,
	companies?: Array<string> | undefined | null,
	customers?: Array<string> | undefined | null,
	status?: Array<string> | undefined | null,
	items?: Array<string> | undefined | null,
	logisticians?: Array<string> | undefined | null,
	orderDates?: ResolverInputTypes["OrderDates"] | undefined | null,
	deliveryDates?: Array<string | undefined | null> | undefined | null,
	supportFlag?: Array<string> | undefined | null,
	standbyFlag?: Array<string> | undefined | null
};
	["OrderDates"]: {
	from: string,
	to: string
};
	["Pagination"]: {
	offset: number,
	limit?: number | undefined | null,
	cursor?: string | undefined | null,
	total?: number | undefined | null
};
	["PaginationInfos"]: AliasType<{
	count?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	cursor?:boolean | `@${string}`,
	hasMore?:boolean | `@${string}`,
	initial?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupedOptionElement"]: AliasType<{
	options?:ResolverInputTypes["OptionElement"],
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedSubsFilters"]: AliasType<{
	commandIds?:ResolverInputTypes["OptionElement"],
	expeditionIds?:ResolverInputTypes["OptionElement"],
	subIds?:ResolverInputTypes["OptionElement"],
	status?:ResolverInputTypes["GroupedOptionElement"],
	companies?:ResolverInputTypes["OptionElement"],
	logisticians?:ResolverInputTypes["OptionElement"],
	customers?:ResolverInputTypes["OptionElement"],
	items?:ResolverInputTypes["OptionElement"],
	deliveryDates?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["PaginatedSubs"]: AliasType<{
	pagination?:ResolverInputTypes["PaginationInfos"],
	subs?:ResolverInputTypes["Sub"],
	filterOptions?:ResolverInputTypes["PaginatedSubsFilters"],
		__typename?: boolean | `@${string}`
}>;
	["SubAdminGlobal"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	typologyName?:boolean | `@${string}`,
	brandName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	productAllocated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	shownToClient?:boolean | `@${string}`,
	author?:ResolverInputTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubCommentForm"]: {
	textContent: string,
	shownToClient?: boolean | undefined | null
};
	["FlowFilter"]: {
	triggerHistory?: Array<string> | undefined | null,
	commandIds?: Array<string> | undefined | null,
	subIds?: Array<string> | undefined | null,
	fluxKind?: Array<ResolverInputTypes["FluxKind"] | undefined | null> | undefined | null,
	fluxEventKind?: Array<ResolverInputTypes["FluxEventKind"] | undefined | null> | undefined | null,
	customers?: Array<string> | undefined | null,
	items?: Array<string> | undefined | null
};
	["PaginatedFlowsFilters"]: AliasType<{
	commandIds?:ResolverInputTypes["OptionElement"],
	subIds?:ResolverInputTypes["OptionElement"],
	fluxKind?:ResolverInputTypes["FluxKindOptionElement"],
	fluxEventKind?:ResolverInputTypes["OptionElement"],
	customers?:ResolverInputTypes["OptionElement"],
	items?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["FluxKindOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FluxEventKindOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedFlows"]: AliasType<{
	pagination?:ResolverInputTypes["PaginationInfos"],
	flows?:ResolverInputTypes["Flux"],
	filterOptions?:ResolverInputTypes["PaginatedFlowsFilters"],
		__typename?: boolean | `@${string}`
}>;
	["Usage"]: AliasType<{
	totalSubs?:boolean | `@${string}`,
	activeClients?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubClientInput"]: {
	firstName: string,
	lastName: string,
	email: string
};
	["SubCreationData"]: AliasType<{
	companyOptions?:ResolverInputTypes["OptionElement"],
	productOptions?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["CreateSubMerchantForm"]: {
	companyUuid: string,
	clientUuid: string,
	productUuid: string,
	shouldNotImpactBudget?: boolean | undefined | null
};
	["SellsyInvoiceResponse"]: AliasType<{
	invoices?:ResolverInputTypes["SellsyInvoice"],
		__typename?: boolean | `@${string}`
}>;
	["SellsyInvoice"]: AliasType<{
	link?:boolean | `@${string}`,
	totalIncludingVAT?:boolean | `@${string}`,
	totalExcludingVAT?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClientStatusV2"]:ClientStatusV2;
	["ClientStatus"]:ClientStatus;
	["OffboardingStatus"]:OffboardingStatus;
	["GoogleUrlType"]:GoogleUrlType;
	["FinancialModel"]:FinancialModel;
	["NamedValue"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminDashboardItem"]: AliasType<{
	name?:boolean | `@${string}`,
	brand?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	productUuid?:boolean | `@${string}`,
	categoryUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminDashboardDataV2"]: AliasType<{
	pendingInvites?:boolean | `@${string}`,
	signedUpUsers?:boolean | `@${string}`,
	activeUsers?:boolean | `@${string}`,
	offboardingUsers?:boolean | `@${string}`,
	disabledUsers?:boolean | `@${string}`,
	subsStatus?:ResolverInputTypes["NamedValue"],
	families?:ResolverInputTypes["NamedValue"],
	familiesOptions?:ResolverInputTypes["OptionElement"],
	topItems?:ResolverInputTypes["AdminDashboardItem"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroupsData"]: AliasType<{
	groups?:ResolverInputTypes["CompanyGroup"],
	financialModel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersAdminFiltersOptions"]: AliasType<{
	groups?:ResolverInputTypes["OptionElement"],
	countries?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ClientsStaffFiltersOptions"]: AliasType<{
	countries?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ClientsStaffData"]: AliasType<{
	clients?:ResolverInputTypes["Client"],
	filterOptions?:ResolverInputTypes["ClientsStaffFiltersOptions"],
		__typename?: boolean | `@${string}`
}>;
	["UsersAdminData"]: AliasType<{
	clients?:ResolverInputTypes["Client"],
	filterOptions?:ResolverInputTypes["UsersAdminFiltersOptions"],
	financialModel?:boolean | `@${string}`,
	companyUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompaniesFilter"]: {
	companies?: Array<string> | undefined | null,
	creationDates?: Array<string> | undefined | null,
	clientsCounts?: Array<string> | undefined | null,
	financialModel?: ResolverInputTypes["FinancialModel"] | undefined | null,
	company?: string | undefined | null,
	creationDate?: string | undefined | null,
	clientsCount?: string | undefined | null
};
	["ClientsFilterSearch"]: {
	nameOrEmail?: string | undefined | null,
	accountDisabledAt?: string | undefined | null,
	companies?: Array<string> | undefined | null,
	firstnames?: Array<string> | undefined | null,
	lastnames?: Array<string> | undefined | null,
	emails?: Array<string> | undefined | null,
	phones?: Array<string | undefined | null> | undefined | null,
	roles?: Array<string | undefined | null> | undefined | null,
	firstname?: string | undefined | null,
	lastname?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	active?: string | undefined | null,
	accountState?: string | undefined | null,
	role?: string | undefined | null,
	offboarding?: ResolverInputTypes["OffboardingStatus"] | undefined | null,
	creationDates?: Array<string> | undefined | null,
	groups?: Array<string> | undefined | null
};
	["SetServiceSubscriptionForm"]: {
	newLocationCostCapEuro?: number | undefined | null,
	newServiceSubscriptionCost?: number | undefined | null
};
	["SignupResponse"]: AliasType<{
	accountActivationToken?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	logoToken?:boolean | `@${string}`,
	linkedToGoogle?:boolean | `@${string}`,
	authResponse?:ResolverInputTypes["AuthResponse"],
	clientUuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuthAsResponse"]: AliasType<{
	token?:boolean | `@${string}`,
	zendeskToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuthResponse"]: AliasType<{
	isNew?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	accessToken?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	clientUuid?:boolean | `@${string}`,
	zendeskToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Onboarding"]: AliasType<{
	isDone?:boolean | `@${string}`,
	onboardingSteps?:ResolverInputTypes["OnboardingStep"],
	companyName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OnboardingStep"]: AliasType<{
	value?:boolean | `@${string}`,
	isDone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationCostCapResponse"]: AliasType<{
	company?:ResolverInputTypes["Company"],
	budgetSummary?:ResolverInputTypes["BudgetSummary"],
	basketSummary?:ResolverInputTypes["BasketSummary"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyAdminCreationForm"]: {
	firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyName: string,
	registrationNumber: string,
	logoUrl?: string | undefined | null,
	primaryColor?: string | undefined | null,
	estimatedWorkersCount?: string | undefined | null,
	cgu?: boolean | undefined | null
};
	["CompanyClientCreationForm"]: {
	firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyGroupUuid?: string | undefined | null,
	cgu?: boolean | undefined | null
};
	["CompanyClientAddressCreationForm"]: {
	phone?: string | undefined | null,
	address?: string | undefined | null,
	zip?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	comment?: string | undefined | null
};
	["CompanyDetailsForm"]: {
	name?: string | undefined | null,
	registrationNumber?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	logoUrl?: string | undefined | null,
	primaryColor?: string | undefined | null,
	estimatedWorkersCount?: string | undefined | null,
	legalCompanyName?: string | undefined | null,
	allowSelfSignup?: boolean | undefined | null,
	domainName?: string | undefined | null
};
	["CompanyBillingContactForm"]: {
	billingContactFirstName?: string | undefined | null,
	billingContactLastName?: string | undefined | null,
	billingContactEmail?: string | undefined | null,
	billingContactPhone?: string | undefined | null
};
	["UpdateCompanyBranding"]: {
	logoUrl?: string | undefined | null,
	primaryColor?: string | undefined | null
};
	["UpdateCompanyBillingAddressForm"]: {
	billingContactFirstName?: string | undefined | null,
	billingContactLastName?: string | undefined | null,
	billingContactEmail?: string | undefined | null,
	billingContactPhone?: string | undefined | null,
	recipientName?: string | undefined | null,
	address?: string | undefined | null,
	zip?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	sepa?: boolean | undefined | null,
	rentalAYG?: boolean | undefined | null
};
	["CompanyBillingAddressForm"]: {
	address: string,
	zip: string,
	city: string,
	country: string
};
	["UpsertCompanyClientForm_V2"]: {
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	email?: string | undefined | null,
	personalEmail?: string | undefined | null,
	phone?: string | undefined | null,
	recipientPhone?: string | undefined | null,
	recipientName?: string | undefined | null,
	job?: string | undefined | null,
	address?: string | undefined | null,
	zip?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	comment?: string | undefined | null
};
	["UpdateCompanyClientPasswordForm"]: {
	email?: string | undefined | null,
	currentPassword?: string | undefined | null,
	newPassword?: string | undefined | null,
	newPasswordConfirmation?: string | undefined | null
};
	["updateCompanyClientShippingAddressForm"]: {
	recipientName: string,
	recipientPhone: string,
	address: string,
	zip: string,
	city: string,
	country: string,
	comment?: string | undefined | null
};
	["CompanyDetailsStaffForm"]: {
	name?: string | undefined | null,
	registrationNumber?: string | undefined | null,
	createdAt?: string | undefined | null,
	creator?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	logoUrl?: string | undefined | null,
	recipientName?: string | undefined | null,
	address?: string | undefined | null,
	zip?: string | undefined | null,
	city?: string | undefined | null,
	billingContactFirstName?: string | undefined | null,
	billingContactLastName?: string | undefined | null,
	billingContactEmail?: string | undefined | null,
	billingContactPhone?: string | undefined | null
};
	["Address"]: AliasType<{
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	recipientName?:boolean | `@${string}`,
	recipientPhone?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	zip?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LogoResponse"]: AliasType<{
	userLogo?:boolean | `@${string}`,
	companyLogo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyBudgetForm"]: {
	locationCostCap?: number | undefined | null,
	serviceSubscriptionCost?: number | undefined | null
};
	["CompanyBudget"]: AliasType<{
	uuid?:boolean | `@${string}`,
	locationCostCap?:boolean | `@${string}`,
	serviceSubscriptionCost?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Company"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	legalCompanyName?:boolean | `@${string}`,
	registrationNumber?:boolean | `@${string}`,
	estimatedWorkerCount?:boolean | `@${string}`,
	sepa?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	logoUrl?:boolean | `@${string}`,
	billingContactFirstName?:boolean | `@${string}`,
	billingContactLastName?:boolean | `@${string}`,
	billingContactEmail?:boolean | `@${string}`,
	billingContactPhone?:boolean | `@${string}`,
	creator?:ResolverInputTypes["Client"],
	clients?:ResolverInputTypes["Client"],
	billingAddress?:ResolverInputTypes["Address"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	disabledProducts?:ResolverInputTypes["CompanyDisabledProduct"],
	companySettings?:ResolverInputTypes["CompanySettings"],
	domainNames?:ResolverInputTypes["CompanyDomainName"],
	budgets?:ResolverInputTypes["CompanyBudget"],
	groups?:ResolverInputTypes["CompanyGroup"],
	clientsCount?:boolean | `@${string}`,
	pendingInvitesCount?:boolean | `@${string}`,
	inactiveUsersCount?:boolean | `@${string}`,
	activeUsersCount?:boolean | `@${string}`,
	formerActiveUsersCount?:boolean | `@${string}`,
	deactivatedUsersCount?:boolean | `@${string}`,
	estimatedWorkersCount?:boolean | `@${string}`,
	deliveryCountries?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanySettings"]: AliasType<{
	financialModel?:boolean | `@${string}`,
	shouldHidePrices?:boolean | `@${string}`,
	allowSelfSignup?:boolean | `@${string}`,
	primaryColor?:boolean | `@${string}`,
	mrrEngaged?:boolean | `@${string}`,
	workosOrganizationId?:boolean | `@${string}`,
	workosDirectoryId?:boolean | `@${string}`,
	ssoLoginOnly?:boolean | `@${string}`,
	disableSSOInvitation?:boolean | `@${string}`,
	lockAllowSelfSignup?:boolean | `@${string}`,
	gConnectOnly?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SSOConfigurationForm"]: {
	workosOrganizationId?: string | undefined | null,
	workosDirectoryId?: string | undefined | null,
	ssoLoginOnly: boolean,
	disableSSOInvitation: boolean,
	lockAllowSelfSignup: boolean
};
	["SignUpInConfigurationForm"]: {
	workosOrganizationId?: string | undefined | null,
	workosDirectoryId?: string | undefined | null,
	ssoLoginOnly?: boolean | undefined | null,
	disableSSOInvitation?: boolean | undefined | null,
	lockAllowSelfSignup?: boolean | undefined | null,
	gConnectOnly?: boolean | undefined | null
};
	["CompanyDomainName"]: AliasType<{
	uuid?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Client"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdBy?:ResolverInputTypes["Client"],
	lastLoggedIn?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	company?:ResolverInputTypes["Company"],
	shippingAddress?:ResolverInputTypes["Address"],
	companyRole?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	accountOffboardedAt?:boolean | `@${string}`,
	offboardedRequestedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	statusV2?:boolean | `@${string}`,
	offboardingStatus?:boolean | `@${string}`,
	activeSubsCount?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	googleEmail?:boolean | `@${string}`,
	personalEmail?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	job?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	subs?:ResolverInputTypes["Sub"],
	companyGroup?:ResolverInputTypes["CompanyGroup"],
	offboardedBy?:ResolverInputTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyDisabledProduct"]: AliasType<{
	uuid?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountActivationToken?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	lastLoggedIn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MerchantUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	passwordUpdatedAt?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	accountActivatedAt?:boolean | `@${string}`,
	accountActivationToken?:boolean | `@${string}`,
	accountDisabledAt?:boolean | `@${string}`,
	job?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	lastLoggedIn?:boolean | `@${string}`,
	company?:ResolverInputTypes["MerchantCompany"],
		__typename?: boolean | `@${string}`
}>;
	["MerchantCompany"]: AliasType<{
	uuid?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	logoUrl?:boolean | `@${string}`,
	registrationNumber?:boolean | `@${string}`,
	intraCommunityVatNumber?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	contactFirstName?:boolean | `@${string}`,
	contactLastName?:boolean | `@${string}`,
	contactEmail?:boolean | `@${string}`,
	contactPhone?:boolean | `@${string}`,
	address?:ResolverInputTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["InvitedUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GroupOptionElement"]: AliasType<{
	value?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	budget?:ResolverInputTypes["CompanyBudget"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroup"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	budget?:ResolverInputTypes["CompanyBudget"],
	usersCount?:boolean | `@${string}`,
	activeUsersCount?:boolean | `@${string}`,
	inactiveUsersCount?:boolean | `@${string}`,
	productsCount?:boolean | `@${string}`,
	allowedProductsCount?:boolean | `@${string}`,
	averageConsumption?:boolean | `@${string}`,
	users?:ResolverInputTypes["Client"],
	products?:ResolverInputTypes["Product"],
	countries?:boolean | `@${string}`,
	creator?:ResolverInputTypes["Client"],
	isDefault?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	monthly?:boolean | `@${string}`,
	workosId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyGroupForm"]: {
	name: string,
	description: string,
	budget: string,
	color?: string | undefined | null
};
	["AddUsersToGroupResult"]: AliasType<{
	clientsInBudget?:ResolverInputTypes["Client"],
	clientsOutOfBudget?:ResolverInputTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["StaffDashboardCompany"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	activeUsers?:boolean | `@${string}`,
	budget?:boolean | `@${string}`,
	totalPrices?:boolean | `@${string}`,
	activeSubs?:boolean | `@${string}`,
	mrrEngaged?:boolean | `@${string}`,
	realMRR?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffDashboardData"]: AliasType<{
	pendingInvites?:boolean | `@${string}`,
	inactiveUsers?:boolean | `@${string}`,
	formerActiveUsers?:boolean | `@${string}`,
	deactivatedUsers?:boolean | `@${string}`,
	companies?:ResolverInputTypes["StaffDashboardCompany"],
		__typename?: boolean | `@${string}`
}>;
	["DeliverySatisfactionSurvey"]: AliasType<{
	firstName?:boolean | `@${string}`,
	answered?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliverySatisfactionSurveyAnswer"]: {
	rating: number,
	commentRating?: string | undefined | null,
	support?: boolean | undefined | null,
	commentSupport?: string | undefined | null
};
	["DeliverySatisfactionSurveyRow"]: AliasType<{
	lastName?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	company?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	commentRating?:boolean | `@${string}`,
	support?:boolean | `@${string}`,
	commentSupport?:boolean | `@${string}`,
	answeredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurveyItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	pictureUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurvey"]: AliasType<{
	firstName?:boolean | `@${string}`,
	items?:ResolverInputTypes["ProductSatisfactionSurveyItem"],
	answered?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductSatisfactionSurveyAnswer"]: {
	uuid: string,
	rating: number,
	comment?: string | undefined | null
};
	["ProductSatisfactionSurveyRow"]: AliasType<{
	lastName?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	company?:boolean | `@${string}`,
	item?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	answeredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersStaffFilter"]: {
	search?: string | undefined | null,
	companies?: Array<string> | undefined | null,
	countries?: Array<string | undefined | null> | undefined | null,
	status?: Array<string | undefined | null> | undefined | null,
	offboarding?: Array<string | undefined | null> | undefined | null,
	accountState?: Array<string | undefined | null> | undefined | null,
	role?: Array<string | undefined | null> | undefined | null,
	sort?: ResolverInputTypes["SortFilter"] | undefined | null
};
	["SortFilter"]: {
	column?: string | undefined | null,
	order?: string | undefined | null
};
	["UsersStaffResponse"]: AliasType<{
	pagination?:ResolverInputTypes["PaginationInfos"],
	users?:ResolverInputTypes["Client"],
	filterOptions?:ResolverInputTypes["UsersFiltersV2"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFiltersV2"]: AliasType<{
	countries?:ResolverInputTypes["OptionElement"],
	companies?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["SetUserOffboardingResponse"]: AliasType<{
	uuid?:boolean | `@${string}`,
	accountOffboardedAt?:boolean | `@${string}`,
	personalEmail?:boolean | `@${string}`,
	statusV2?:boolean | `@${string}`,
	offboardingStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItemEventKind"]:UnitItemEventKind;
	["SortKind"]:SortKind;
	["RequestOrderKind"]:RequestOrderKind;
	["RequestOrderForm"]: {
	supplierUuid: string,
	supplierMode: ResolverInputTypes["SupplierMode"],
	purchaseOrderNumber: string,
	logisticianUuid: string,
	items: Array<ResolverInputTypes["UnitItemForm"]>
};
	["ItemReceived"]: {
	itemUuid: string,
	qtyReceived: number
};
	["UnitItemForm"]: {
	itemUuid: string,
	qtyOrder: number,
	buyingPrice?: number | undefined | null
};
	["StockUnitItem"]: AliasType<{
	item?:ResolverInputTypes["Item"],
	requested?:boolean | `@${string}`,
	inStock?:boolean | `@${string}`,
	quantityNeeded?:boolean | `@${string}`,
	allocated?:boolean | `@${string}`,
	toBeDelivered?:boolean | `@${string}`,
	rented?:boolean | `@${string}`,
	returnRequested?:boolean | `@${string}`,
	deliveryBack?:boolean | `@${string}`,
	quarantined?:boolean | `@${string}`,
	exit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItem"]: AliasType<{
	uuid?:boolean | `@${string}`,
	unitItemId?:boolean | `@${string}`,
	item?:ResolverInputTypes["Item"],
	mode?:boolean | `@${string}`,
	entryAt?:boolean | `@${string}`,
	exitAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	buyingPrice?:boolean | `@${string}`,
	supplier?:ResolverInputTypes["Supplier"],
	logistician?:ResolverInputTypes["Logistician"],
	requestOrder?:ResolverInputTypes["RequestOrder"],
	events?:ResolverInputTypes["UnitItemEvent"],
	lastEvent?:ResolverInputTypes["UnitItemEvent"],
	flux?:ResolverInputTypes["Flux"],
	comments?:ResolverInputTypes["UnitItemComment"],
		__typename?: boolean | `@${string}`
}>;
	["UnitItemEvent"]: AliasType<{
	uuid?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitItemComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	author?:ResolverInputTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestOrders"]: AliasType<{
	requestOrders?:ResolverInputTypes["RequestOrder"],
	filterOptions?:ResolverInputTypes["RequestOrderOptions"],
		__typename?: boolean | `@${string}`
}>;
	["RequestOrder"]: AliasType<{
	uuid?:boolean | `@${string}`,
	purchaseOrderNumber?:boolean | `@${string}`,
	requestOrderNumber?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	supplier?:ResolverInputTypes["Supplier"],
	logistician?:ResolverInputTypes["Logistician"],
	unitItems?:ResolverInputTypes["UnitItem"],
	totalQuantity?:boolean | `@${string}`,
	totalReceived?:boolean | `@${string}`,
	totalBuyingPrice?:boolean | `@${string}`,
	comments?:ResolverInputTypes["RequestOrderComment"],
	deliveryReceipts?:ResolverInputTypes["DeliveryReceipt"],
		__typename?: boolean | `@${string}`
}>;
	["RequestOrderComment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	textContent?:boolean | `@${string}`,
	author?:ResolverInputTypes["MerchantUser"],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceipt"]: AliasType<{
	uuid?:boolean | `@${string}`,
	deliveryReceiptNumber?:boolean | `@${string}`,
	invoiceNumber?:boolean | `@${string}`,
	deliveryItems?:ResolverInputTypes["DeliveryItem"],
	createdAt?:boolean | `@${string}`,
	requestOrder?:ResolverInputTypes["RequestOrder"],
	totalBuyingPrice?:boolean | `@${string}`,
	stockValue?:boolean | `@${string}`,
	totalReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryItem"]: AliasType<{
	itemName?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptFilter"]: {
	deliveryReceiptNumbers?: Array<string> | undefined | null,
	invoiceNumbers?: Array<string> | undefined | null,
	purchaseOrderNumbers?: Array<string> | undefined | null
};
	["RequestOrderFilter"]: {
	purchaseOrderNumbers?: Array<string> | undefined | null,
	requestOrderNumbers?: Array<string> | undefined | null,
	suppliers?: Array<string> | undefined | null,
	logisticians?: Array<string> | undefined | null,
	buyingPrices?: Array<string> | undefined | null,
	status?: Array<ResolverInputTypes["RequestOrderKind"]> | undefined | null,
	createdAt?: Array<string> | undefined | null
};
	["RequestOrderOptions"]: AliasType<{
	purchaseOrderNumbers?:ResolverInputTypes["OptionElement"],
	requestOrderNumbers?:ResolverInputTypes["OptionElement"],
	suppliers?:ResolverInputTypes["OptionElement"],
	logisticians?:ResolverInputTypes["OptionElement"],
	realPrices?:ResolverInputTypes["OptionElement"],
	buyingPrices?:ResolverInputTypes["OptionElement"],
	status?:ResolverInputTypes["OptionElement"],
	createdAt?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemResponseForm"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	qtyOrdered?:boolean | `@${string}`,
	buyingPrice?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestOrderResponse"]: AliasType<{
	requestOrder?:ResolverInputTypes["RequestOrder"],
	itemsResponseForm?:ResolverInputTypes["ItemResponseForm"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptResponse"]: AliasType<{
	deliveryReceipts?:ResolverInputTypes["DeliveryReceipt"],
	filterOptions?:ResolverInputTypes["DeliveryReceiptOptions"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryReceiptOptions"]: AliasType<{
	deliveryReceiptNumbers?:ResolverInputTypes["OptionElement"],
	invoiceNumbers?:ResolverInputTypes["OptionElement"],
	purchaseOrderNumbers?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["ItemOrdered"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	qtyOrdered?:boolean | `@${string}`,
	qtyReceived?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StockFilter"]: {
	brands?: Array<string> | undefined | null,
	names?: Array<string> | undefined | null,
	families?: Array<string> | undefined | null,
	itemsNames?: Array<string> | undefined | null,
	status?: Array<ResolverInputTypes["UnitItemEventKind"]> | undefined | null
};
	["StockOrder"]: {
	requested?: ResolverInputTypes["SortKind"] | undefined | null,
	inStock?: ResolverInputTypes["SortKind"] | undefined | null,
	allocated?: ResolverInputTypes["SortKind"] | undefined | null,
	toBeDelivered?: ResolverInputTypes["SortKind"] | undefined | null,
	rented?: ResolverInputTypes["SortKind"] | undefined | null,
	returnRequested?: ResolverInputTypes["SortKind"] | undefined | null,
	deliveryBack?: ResolverInputTypes["SortKind"] | undefined | null,
	quarantined?: ResolverInputTypes["SortKind"] | undefined | null,
	exit?: ResolverInputTypes["SortKind"] | undefined | null
};
	["StockSelect"]: {
	supplierMode?: ResolverInputTypes["SupplierMode"] | undefined | null,
	suppliers?: Array<string | undefined | null> | undefined | null,
	logisticians?: Array<string | undefined | null> | undefined | null
};
	["StockOptions"]: AliasType<{
	brands?:ResolverInputTypes["OptionElement"],
	names?:ResolverInputTypes["OptionElement"],
	families?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["StockManagement"]: AliasType<{
	items?:ResolverInputTypes["StockUnitItem"],
	filterOptions?:ResolverInputTypes["StockOptions"],
	stockDashboard?:ResolverInputTypes["StockDashboard"],
		__typename?: boolean | `@${string}`
}>;
	["StockDashboard"]: AliasType<{
	labels?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
	totalItems?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StockDetailedOptions"]: AliasType<{
	itemsNames?:ResolverInputTypes["OptionElement"],
	status?:ResolverInputTypes["OptionElement"],
		__typename?: boolean | `@${string}`
}>;
	["StockDetailed"]: AliasType<{
	unitItems?:ResolverInputTypes["UnitItem"],
	filterOptions?:ResolverInputTypes["StockDetailedOptions"],
		__typename?: boolean | `@${string}`
}>;
	["QuantityUnitItems"]: AliasType<{
	itemUuid?:boolean | `@${string}`,
	inStock?:boolean | `@${string}`,
	quantityNeeded?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateProductReturnRequestResponse"]: AliasType<{
	googleCloudStorageSignedUrls?:boolean | `@${string}`,
	productReturnRequest?:ResolverInputTypes["ProductReturnRequest"],
		__typename?: boolean | `@${string}`
}>;
	["ProductReturn"]: AliasType<{
	uuid?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	subReason?:boolean | `@${string}`,
	sub?:ResolverInputTypes["Sub"],
	productState?:boolean | `@${string}`,
	userComment?:boolean | `@${string}`,
	productPicture?:boolean | `@${string}`,
	productReturnRequest?:ResolverInputTypes["ProductReturnRequest"],
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductReturnReason"]:ProductReturnReason;
	["ProductReturnSubReason"]:ProductReturnSubReason;
	["ProductReturnStatus"]:ProductReturnStatus;
	["ProductReturnState"]:ProductReturnState;
	["ProductReturnRequest"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	isKit?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	productReturns?:ResolverInputTypes["ProductReturn"],
	client?:ResolverInputTypes["Client"],
		__typename?: boolean | `@${string}`
}>;
	["CreateProductReturnRequestForm"]: {
	productReturnsInput: Array<ResolverInputTypes["ProductReturnInput"]>,
	clientUuid: string
};
	["ProductReturnInput"]: {
	reason: ResolverInputTypes["ProductReturnReason"],
	subReason?: ResolverInputTypes["ProductReturnSubReason"] | undefined | null,
	subUuid: string
};
	["ProductReturnRequestsStaffFilterInput"]: {
	clientUuid?: string | undefined | null
};
	["UpdateProductReturnRequestForm"]: {
	isKit?: boolean | undefined | null,
	productReturns: Array<ResolverInputTypes["UpdateProductReturnRequestFormProductReturn"]>
};
	["UpdateProductReturnRequestFormProductReturn"]: {
	uuid: string,
	productState: ResolverInputTypes["ProductReturnState"],
	comment?: string | undefined | null,
	picture: boolean
}
  }

export type ModelTypes = {
    ["Query"]: {
		ping?: string | undefined,
	families: Array<ModelTypes["Family"]>,
	typology: ModelTypes["Typology"],
	typologies: Array<ModelTypes["Typology"]>,
	brands: Array<ModelTypes["Brand"]>,
	logisticians: Array<ModelTypes["Logistician"]>,
	suppliers: Array<ModelTypes["Supplier"]>,
	items: Array<ModelTypes["Item"]>,
	itemManagement: ModelTypes["ItemManagement"],
	itemOptions: Array<ModelTypes["ItemOptionsResponse"]>,
	item?: ModelTypes["Item"] | undefined,
	categories: Array<ModelTypes["Category"]>,
	productOptions: Array<ModelTypes["OptionElement"]>,
	productManagement: ModelTypes["ProductManagement"],
	product: ModelTypes["Product"],
	productMerchant: ModelTypes["Product"],
	offers: Array<ModelTypes["Offer"]>,
	offer: ModelTypes["Offer"],
	merchantCatalog: Array<ModelTypes["MerchantCatalogElement"]>,
	companyList: Array<ModelTypes["ListItem"]>,
	categoryMerchant?: ModelTypes["Category"] | undefined,
	archivedItems?: ModelTypes["ArchivedItems"] | undefined,
	archivedProducts?: ModelTypes["ArchivedProducts"] | undefined,
	family: ModelTypes["Family"],
	productAdmin: ModelTypes["Product"],
	storeProduct: ModelTypes["Product"],
	companystore: Array<ModelTypes["Product"]>,
	companyStoreRentalPlatform: Array<ModelTypes["ProductCompanyStoreRentalPlatform"] | undefined>,
	fleexstore: Array<ModelTypes["Family"]>,
	productMarket: ModelTypes["Product"],
	catalogProduct: ModelTypes["Product"],
	catalog: Array<ModelTypes["Family"]>,
	productAttributes: Array<ModelTypes["ProductAttribute"]>,
	productAttribute: ModelTypes["ProductAttribute"],
	productsStaff: Array<ModelTypes["ProductStaff"]>,
	productStaff: ModelTypes["Product"],
	offerStaff: ModelTypes["Offer"],
	offersStaff: ModelTypes["OfferStaffResponse"],
	offerStaffCreationData: ModelTypes["OfferCreationResponse"],
	staffCatalog: Array<ModelTypes["StaffCatalogElement"]>,
	basket?: ModelTypes["Basket"] | undefined,
	subsMarket_V2: Array<ModelTypes["Sub"]>,
	subsAdmin: Array<ModelTypes["Sub"]>,
	subsAdminGlobal: Array<ModelTypes["SubAdminGlobal"]>,
	paginatedSubs?: ModelTypes["PaginatedSubs"] | undefined,
	sub?: ModelTypes["Sub"] | undefined,
	paginatedFlows?: ModelTypes["PaginatedFlows"] | undefined,
	flows: Array<ModelTypes["Flux"]>,
	sendSupportSatisfactionSurveyEmail?: boolean | undefined,
	subCreationData: ModelTypes["SubCreationData"],
	clientOptions: Array<ModelTypes["OptionElement"]>,
	totalEffectiveLocationPriceExclTaxes?: number | undefined,
	subsClient: Array<ModelTypes["Sub"]>,
	usage: ModelTypes["Usage"],
	sellsyInvoices: ModelTypes["SellsyInvoiceResponse"],
	adminDashboardDataV2?: ModelTypes["AdminDashboardDataV2"] | undefined,
	authenticate?: ModelTypes["AuthResponse"] | undefined,
	authenticateAs?: ModelTypes["AuthAsResponse"] | undefined,
	checkResetToken?: ModelTypes["Client"] | undefined,
	client?: ModelTypes["Client"] | undefined,
	clients?: Array<ModelTypes["Client"] | undefined> | undefined,
	clientsStaff?: ModelTypes["ClientsStaffData"] | undefined,
	staff?: ModelTypes["StaffUser"] | undefined,
	merchant?: ModelTypes["MerchantUser"] | undefined,
	companySettings?: ModelTypes["CompanySettings"] | undefined,
	companyDetails?: ModelTypes["Company"] | undefined,
	companies?: Array<ModelTypes["Company"] | undefined> | undefined,
	companiesStaff?: Array<ModelTypes["Company"] | undefined> | undefined,
	companyOnboardingV2?: ModelTypes["Onboarding"] | undefined,
	logos?: ModelTypes["LogoResponse"] | undefined,
	companyGroup: ModelTypes["CompanyGroup"],
	companyGroupV2: ModelTypes["CompanyGroup"],
	companyGroups: Array<ModelTypes["CompanyGroup"]>,
	companyGroupsV2?: ModelTypes["CompanyGroupsData"] | undefined,
	companyGroupOptions: Array<ModelTypes["GroupOptionElement"]>,
	budgetsAdmin: Array<ModelTypes["CompanyBudget"]>,
	googleConnectionUrl: string,
	getSSOConnectionUrl: string,
	staffDashboard: ModelTypes["StaffDashboardData"],
	deliverySatisfactionSurvey?: ModelTypes["DeliverySatisfactionSurvey"] | undefined,
	deliverySatisfactionSurveys: Array<ModelTypes["DeliverySatisfactionSurveyRow"]>,
	productSatisfactionSurvey?: ModelTypes["ProductSatisfactionSurvey"] | undefined,
	productSatisfactionSurveys: Array<ModelTypes["ProductSatisfactionSurveyRow"]>,
	usersAdmin?: ModelTypes["UsersAdminData"] | undefined,
	userAdmin: ModelTypes["Client"],
	usersStaff?: ModelTypes["UsersStaffResponse"] | undefined,
	usersStaffOffboarding?: ModelTypes["UsersStaffResponse"] | undefined,
	marketDashboard: ModelTypes["Client"],
	unitItem: ModelTypes["UnitItem"],
	stockDetailed: ModelTypes["StockDetailed"],
	stockManagement: ModelTypes["StockManagement"],
	quantityUnitItems: Array<ModelTypes["QuantityUnitItems"] | undefined>,
	requestOrders?: ModelTypes["RequestOrders"] | undefined,
	requestOrder?: ModelTypes["RequestOrderResponse"] | undefined,
	deliveryReceipts?: ModelTypes["DeliveryReceiptResponse"] | undefined,
	productReturnRequest?: ModelTypes["ProductReturnRequest"] | undefined,
	productReturnRequests: Array<ModelTypes["ProductReturnRequest"]>,
	productReturnRequestsStaff: Array<ModelTypes["ProductReturnRequest"]>
};
	["Mutation"]: {
		exampleMutation?: string | undefined,
	createFamily?: ModelTypes["Family"] | undefined,
	createLogistician?: ModelTypes["Logistician"] | undefined,
	upsertSupplier: ModelTypes["Supplier"],
	upsertTypology?: ModelTypes["Typology"] | undefined,
	upsertBrand?: ModelTypes["Brand"] | undefined,
	upsertCategory?: ModelTypes["Category"] | undefined,
	upsertItem?: ModelTypes["Item"] | undefined,
	upsertProduct?: ModelTypes["Product"] | undefined,
	upsertOffer?: Array<ModelTypes["Offer"] | undefined> | undefined,
	updateOfferEndAt: ModelTypes["Offer"],
	setProductDisplayMode?: ModelTypes["Product"] | undefined,
	setProductDisabled: ModelTypes["ProductDisabledResult"],
	setProductDisabledBulk: Array<ModelTypes["ProductDisabledResult"]>,
	setProductDisplayModeBulk: Array<ModelTypes["ProductListElement"]>,
	setCompanyWhitelistProductsBulk: Array<ModelTypes["MerchantCatalogElement"]>,
	setCompanyBlacklistProductsBulk: Array<ModelTypes["MerchantCatalogElement"]>,
	updateProductDisplayPriority?: ModelTypes["Category"] | undefined,
	setItemDisabled?: ModelTypes["Item"] | undefined,
	archiveItem: ModelTypes["Item"],
	replaceItem: Array<ModelTypes["Item"]>,
	archiveProduct: ModelTypes["Product"],
	toggleAddToCompanyStore: ModelTypes["Product"],
	toggleBulkAddToCompanyStore: Array<ModelTypes["Product"]>,
	toggleAllowedGroup: ModelTypes["ToggleAllowedGroupResult"],
	bulkAddAllowedGroups: Array<ModelTypes["Product"]>,
	bulkRemoveAllowedGroups: Array<ModelTypes["Product"]>,
	processUnseenProducts: Array<ModelTypes["Product"]>,
	upsertProductAttribute?: ModelTypes["ProductAttribute"] | undefined,
	upsertProductStaff?: ModelTypes["Product"] | undefined,
	archiveProductStaff?: ModelTypes["Product"] | undefined,
	createOfferStaff: ModelTypes["Offer"],
	replaceOfferStaff: ModelTypes["ReplaceOfferStaffResponse"],
	updateFamiliesOrder?: Array<ModelTypes["Family"] | undefined> | undefined,
	updateTypologiesOrder?: Array<ModelTypes["Typology"] | undefined> | undefined,
	updateProductsStaffOrder?: Array<ModelTypes["ProductStaff"] | undefined> | undefined,
	upsertProductBasket: ModelTypes["BasketItem"],
	confirmBasket_V2: ModelTypes["Basket"],
	cancelFlux: ModelTypes["Sub"],
	addFlux: ModelTypes["Sub"],
	addFluxBulk: Array<ModelTypes["Sub"]>,
	addFluxEvent: ModelTypes["Sub"],
	addFluxEventBulk: Array<ModelTypes["Sub"]>,
	cancelSub: ModelTypes["Sub"],
	cancelSubBulk: Array<ModelTypes["Sub"]>,
	setFlag: ModelTypes["Sub"],
	setFlagBulk: Array<ModelTypes["Sub"]>,
	setExpeditionIdBulk: Array<ModelTypes["Sub"]>,
	addSubComment: ModelTypes["SubComment"],
	addFlowEventBulk: Array<ModelTypes["Flux"]>,
	createSubMerchant: ModelTypes["Sub"],
	signupCompanyAdmin?: ModelTypes["SignupResponse"] | undefined,
	signupCompanyClient?: ModelTypes["SignupResponse"] | undefined,
	signupCompanyClientAddress?: boolean | undefined,
	activateCompanyClient?: boolean | undefined,
	upsertCompanyClient_V2?: ModelTypes["Client"] | undefined,
	setCompanyClientKind?: ModelTypes["Client"] | undefined,
	setCompanyClientAccountState?: ModelTypes["Client"] | undefined,
	updateCompanyClientPassword?: ModelTypes["Client"] | undefined,
	updateClientPassword?: ModelTypes["Client"] | undefined,
	updateCompanyClientShippingAddress?: ModelTypes["Client"] | undefined,
	requestCompanyClientPasswordReset?: boolean | undefined,
	resetPassword?: boolean | undefined,
	updateCompanyDetails?: ModelTypes["Company"] | undefined,
	updateCompanyDetailsV2?: ModelTypes["Company"] | undefined,
	updateCompanyBillingContact?: ModelTypes["Company"] | undefined,
	updateCompanyBillingAddress?: ModelTypes["Company"] | undefined,
	updateCompanyBilling?: ModelTypes["Company"] | undefined,
	updateCompanyBranding?: ModelTypes["Company"] | undefined,
	setCompanySelfSignup?: ModelTypes["Company"] | undefined,
	setServiceSubscription?: ModelTypes["Company"] | undefined,
	setMinimumMRREngaged?: ModelTypes["Company"] | undefined,
	createUploadToken?: string | undefined,
	inviteUsers?: Array<ModelTypes["InvitedUser"] | undefined> | undefined,
	resendInvitations?: Array<ModelTypes["InvitedUser"] | undefined> | undefined,
	deletePendingInvites?: Array<ModelTypes["InvitedUser"] | undefined> | undefined,
	deletePendingInvitesV2?: Array<ModelTypes["InvitedUser"]> | undefined,
	setUserOffboarding?: ModelTypes["SetUserOffboardingResponse"] | undefined,
	cancelUserOffboarding?: ModelTypes["Client"] | undefined,
	upsertCompanyGroup: ModelTypes["CompanyGroup"],
	deleteCompanyGroup: Array<ModelTypes["CompanyGroup"] | undefined>,
	deleteCompanyGroupV2: ModelTypes["Product"],
	createCompanyBudget: ModelTypes["CompanyBudget"],
	createCompanyGroup: ModelTypes["CompanyGroup"],
	updateCompanyGroup: ModelTypes["CompanyGroup"],
	updateCompanyBudget: ModelTypes["CompanyBudget"],
	removeCompanyBudget: ModelTypes["CompanyBudget"],
	setCompanyDefaultGroup: Array<ModelTypes["CompanyGroup"]>,
	addUsersToGroup?: ModelTypes["AddUsersToGroupResult"] | undefined,
	addPendingUsersToGroup: Array<ModelTypes["Client"]>,
	associateGoogleAccount: ModelTypes["Client"],
	associateGoogleAccount_V2: ModelTypes["Client"],
	dissociateGoogleAccount: ModelTypes["Client"],
	googleAuthentication: ModelTypes["AuthResponse"],
	authenticateWithSSO: ModelTypes["AuthResponse"],
	setSSOConfiguration: ModelTypes["Company"],
	setSignUpInConfiguration: ModelTypes["Company"],
	sendDeliverySatisfactionSurvey?: boolean | undefined,
	sendProductSatisfactionSurvey?: boolean | undefined,
	sendActivationEmail?: boolean | undefined,
	updateCompanyDeliveryCountries: ModelTypes["Company"],
	updateCompanyFinancialModel: ModelTypes["Company"],
	updateCompanyBillingContactV2: ModelTypes["Company"],
	updateCompanyBillingAddressV2: ModelTypes["Company"],
	updateCompanyBillingSepa: ModelTypes["Company"],
	createRequestOrder: ModelTypes["RequestOrder"],
	deleteRequestOrder: ModelTypes["RequestOrder"],
	closeRequestOrder: ModelTypes["RequestOrder"],
	updateUnitItemsStatus: ModelTypes["RequestOrder"],
	addUnitItemEvent: ModelTypes["UnitItem"],
	updateUnitItemSupplier: ModelTypes["UnitItem"],
	addUnitItemComment: ModelTypes["UnitItemComment"],
	addRequestOrderComment: ModelTypes["RequestOrderComment"],
	createDeliveryReceipt: ModelTypes["RequestOrder"],
	updateDeliveryReceiptInvoiceNumber: ModelTypes["DeliveryReceipt"],
	updateUnitItemsPrice: ModelTypes["RequestOrder"],
	trackProductClick?: boolean | undefined,
	createProductReturnRequest?: ModelTypes["ProductReturnRequest"] | undefined,
	updateProductReturnStatus: ModelTypes["ProductReturn"],
	updateProductReturnRequestStatuses: Array<ModelTypes["ProductReturnRequest"]>,
	updateProductReturnUserInformations: ModelTypes["UpdateProductReturnRequestResponse"]
};
	["KeyValue"]: {
		name?: string | undefined,
	value?: string | undefined
};
	["KeyValueInput"]: {
	name?: string | undefined,
	value?: string | undefined
};
	["DisplayMode"]:DisplayMode;
	["WlBlAction"]:WlBlAction;
	["DisabledProductReason"]:DisabledProductReason;
	["SupplierMode"]:SupplierMode;
	["ItemStatus"]:ItemStatus;
	["ProductAttributeType"]:ProductAttributeType;
	["JSON"]:any;
	["ProductCompanyStoreRentalPlatform"]: {
		uuid: string,
	name: string,
	pictureUrl: string,
	brand: ModelTypes["Brand"],
	offer?: ModelTypes["OfferCompanyStoreRentalPlatform"] | undefined,
	displayPriority?: number | undefined,
	allowedGroups?: Array<ModelTypes["GroupCompanyStoreRentalPlatform"] | undefined> | undefined,
	items: Array<ModelTypes["ItemCompanyStoreRentalPlatform"]>
};
	["ItemCompanyStoreRentalPlatform"]: {
		uuid: string,
	typology: ModelTypes["TypologyCompanyStoreRentalPlatform"]
};
	["TypologyCompanyStoreRentalPlatform"]: {
		uuid: string,
	nameFR: string,
	family: ModelTypes["FamilyCompanyStoreRentalPlatform"]
};
	["OfferCompanyStoreRentalPlatform"]: {
		uuid?: string | undefined,
	price?: number | undefined
};
	["FamilyCompanyStoreRentalPlatform"]: {
		uuid: string,
	name: string
};
	["GroupCompanyStoreRentalPlatform"]: {
		uuid: string,
	name: string,
	color?: string | undefined
};
	["ProductDisabledResult"]: {
		uuid: string,
	productUuid: string,
	isDisabled: boolean
};
	["Family"]: {
		uuid: string,
	name: string,
	typologies?: Array<ModelTypes["Typology"]> | undefined,
	order: number
};
	["Brand"]: {
		uuid: string,
	name: string,
	description?: string | undefined
};
	["Logistician"]: {
		uuid: string,
	name: string
};
	["Supplier"]: {
		uuid: string,
	name: string
};
	["Typology"]: {
		uuid: string,
	nameEN: string,
	nameFR: string,
	transportationCost: number,
	family?: ModelTypes["Family"] | undefined,
	products?: Array<ModelTypes["Product"]> | undefined,
	storeCardOrientation?: string | undefined,
	numberOfAssociatedAttributes?: number | undefined,
	productAttributes?: Array<ModelTypes["ProductAttribute"]> | undefined,
	order?: number | undefined
};
	["ProductAttributeValue"]: {
		uuid: string,
	value: string
};
	["ProductAttribute"]: {
		uuid: string,
	name: string,
	description?: string | undefined,
	type: ModelTypes["ProductAttributeType"],
	numberOfAssociatedProducts?: number | undefined,
	values?: Array<ModelTypes["ProductAttributeValue"]> | undefined
};
	["Item"]: {
		uuid: string,
	model?: string | undefined,
	ean?: string | undefined,
	variant?: string | undefined,
	sku?: string | undefined,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	weightG?: string | undefined,
	disabledAt?: string | undefined,
	typology?: ModelTypes["Typology"] | undefined,
	brand?: ModelTypes["Brand"] | undefined,
	defaultOffer?: ModelTypes["Offer"] | undefined,
	offers: Array<ModelTypes["Offer"]>,
	archivedAt?: string | undefined,
	archivingReason?: string | undefined,
	pictureUrl?: string | undefined,
	name?: string | undefined,
	logistician?: ModelTypes["Logistician"] | undefined
};
	["ItemFilter"]: {
	typologies?: Array<string> | undefined,
	families?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	brands?: Array<string> | undefined,
	models?: Array<string> | undefined,
	names?: Array<string> | undefined,
	skus?: Array<string> | undefined,
	prices?: Array<number> | undefined,
	status?: ModelTypes["ItemStatus"] | undefined
};
	["ItemManagementFilter"]: {
		typologies?: Array<ModelTypes["OptionElement"]> | undefined,
	logisticians?: Array<ModelTypes["OptionElement"]> | undefined,
	families?: Array<ModelTypes["OptionElement"]> | undefined,
	brands?: Array<ModelTypes["OptionElement"]> | undefined,
	models?: Array<ModelTypes["OptionElement"]> | undefined,
	names?: Array<ModelTypes["OptionElement"]> | undefined,
	skus?: Array<ModelTypes["OptionElement"]> | undefined,
	prices?: Array<ModelTypes["OptionElement"]> | undefined,
	status?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ItemManagement"]: {
		items: Array<ModelTypes["Item"]>,
	filterOptions?: ModelTypes["ItemManagementFilter"] | undefined
};
	["UpsertItemForm"]: {
	typologyUuid?: string | undefined,
	brandUuid?: string | undefined,
	model?: string | undefined,
	variant?: string | undefined,
	sku?: string | undefined,
	heightCm?: number | undefined,
	lengthCm?: number | undefined,
	depthCm?: number | undefined,
	weightG?: number | undefined,
	pictureUrl: string,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	price?: number | undefined,
	priorNotice?: number | undefined,
	archivingReason?: string | undefined,
	logisticianUuid?: string | undefined
};
	["TypologyAttributeForm"]: {
	uuid: string,
	order: number
};
	["TypologyForm"]: {
	familyUuid?: string | undefined,
	nameEN?: string | undefined,
	nameFR?: string | undefined,
	transportationCost: number,
	attributes?: Array<ModelTypes["TypologyAttributeForm"]> | undefined
};
	["ProductAttributeValueForm"]: {
	uuid: string,
	value: string
};
	["ProductAttributeForm"]: {
	productAttributeUuid?: string | undefined,
	name: string,
	description?: string | undefined,
	type: ModelTypes["ProductAttributeType"],
	values?: Array<ModelTypes["ProductAttributeValueForm"]> | undefined,
	newValues?: Array<string> | undefined
};
	["BrandForm"]: {
	name?: string | undefined,
	description?: ModelTypes["JSON"] | undefined
};
	["Category"]: {
		uuid: string,
	nameEN: string,
	nameFR: string,
	name?: string | undefined,
	pictureMiniUrl?: string | undefined,
	pictureWideUrl?: string | undefined,
	whiteTheme?: boolean | undefined,
	products: Array<ModelTypes["Product"]>
};
	["CategoryForm"]: {
	nameEN: string,
	nameFR: string,
	pictureMiniUrl: string,
	pictureWideUrl: string,
	whiteTheme?: boolean | undefined
};
	["ProductPicture"]: {
		uuid: string,
	pictureUrl: string,
	rank: number
};
	["DisabledGroup"]: {
		uuid: string,
	name: string,
	disabledReason?: ModelTypes["DisabledProductReason"] | undefined
};
	["Product"]: {
		uuid: string,
	categories: Array<ModelTypes["Category"]>,
	items: Array<ModelTypes["Item"]>,
	item: ModelTypes["Item"],
	name: string,
	brand?: ModelTypes["Brand"] | undefined,
	description?: string | undefined,
	information?: string | undefined,
	pictureUrl: string,
	pictures: Array<ModelTypes["ProductPicture"]>,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	monitor?: string | undefined,
	connection?: string | undefined,
	displayMode?: ModelTypes["DisplayMode"] | undefined,
	isDisabled?: boolean | undefined,
	disabledGroups?: Array<ModelTypes["DisabledGroup"]> | undefined,
	offer?: ModelTypes["Offer"] | undefined,
	displayPriority?: number | undefined,
	archivedAt?: string | undefined,
	isANewProduct?: boolean | undefined,
	seenByAdmin?: boolean | undefined,
	isInCompanyStore?: boolean | undefined,
	allowedGroups?: Array<ModelTypes["CompanyGroup"]> | undefined,
	merchantCompany?: ModelTypes["MerchantCompany"] | undefined,
	attributes?: Array<ModelTypes["ProductProductAttribute"] | undefined> | undefined
};
	["ProductProductAttribute"]: {
		uuid: string,
	stringValue?: string | undefined,
	booleanValue?: boolean | undefined,
	productAttribute?: ModelTypes["ProductAttribute"] | undefined
};
	["ProductStaff"]: {
		uuid: string,
	productName: string,
	productPictureUrl: string,
	brandName: string,
	familyName: string,
	typologyNameEN: string,
	defaultPrice?: number | undefined,
	offersCount?: number | undefined,
	status: boolean,
	order?: number | undefined
};
	["UpsertProductForm"]: {
	categories: Array<string>,
	items: Array<string>,
	name: string,
	description?: ModelTypes["JSON"] | undefined,
	information?: ModelTypes["JSON"] | undefined,
	pictureUrls: Array<string | undefined>,
	heightCm?: number | undefined,
	lengthCm?: number | undefined,
	depthCm?: number | undefined,
	monitor?: string | undefined,
	connection?: string | undefined,
	displayMode?: ModelTypes["DisplayMode"] | undefined
};
	["ProductStaffForm"]: {
	familyUuid?: string | undefined,
	typologyUuid?: string | undefined,
	brandUuid?: string | undefined,
	model?: string | undefined,
	ean?: string | undefined,
	pictureUrls: Array<string | undefined>,
	name: string,
	description?: ModelTypes["JSON"] | undefined,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	weightG?: string | undefined,
	specs?: Array<ModelTypes["SpecsForm"]> | undefined
};
	["SpecsForm"]: {
	key: string,
	value?: string | undefined,
	booleanValue?: boolean | undefined,
	type: ModelTypes["ProductAttributeType"]
};
	["ProductFilter"]: {
	categories?: Array<string> | undefined,
	names?: Array<string> | undefined,
	numberOfItems?: Array<number> | undefined,
	defaultDisplay?: string | undefined,
	defaultPrices?: Array<number> | undefined,
	status?: boolean | undefined
};
	["ProductManagementFilter"]: {
		categories?: Array<ModelTypes["OptionElement"]> | undefined,
	names?: Array<ModelTypes["OptionElement"]> | undefined,
	numberOfItems?: Array<ModelTypes["OptionElement"]> | undefined,
	defaultPrices?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ProductManagement"]: {
		products: Array<ModelTypes["ProductListElement"]>,
	filterOptions?: ModelTypes["ProductManagementFilter"] | undefined
};
	["ProductListElement"]: {
		uuid: string,
	categories: Array<ModelTypes["Category"]>,
	name: string,
	numberOfItems: number,
	defaultDisplay: ModelTypes["DisplayMode"],
	defaultPrice: number,
	status: boolean
};
	["OfferStaffCountry"]: {
		uuid?: string | undefined,
	country?: string | undefined,
	additionalShippingFees?: number | undefined,
	deliveryDelay?: number | undefined
};
	["Offer"]: {
		uuid: string,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	priorNotice?: number | undefined,
	price?: number | undefined,
	startAt?: string | undefined,
	endAt?: string | undefined,
	company?: ModelTypes["Company"] | undefined,
	item?: ModelTypes["Item"] | undefined,
	merchantCompany?: ModelTypes["MerchantCompany"] | undefined,
	countries?: Array<ModelTypes["OfferStaffCountry"]> | undefined
};
	["UpsertOfferForm"]: {
	companyUuid?: string | undefined,
	itemUuid?: string | undefined,
	startAt?: string | undefined,
	endAt?: string | undefined,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	priorNotice?: number | undefined,
	price?: number | undefined
};
	["OfferFilter"]: {
	companies?: Array<string> | undefined,
	items?: Array<string> | undefined,
	prices?: Array<number> | undefined,
	startAts?: Array<string> | undefined,
	endAts?: Array<string | undefined> | undefined,
	status?: boolean | undefined
};
	["ItemOptionsResponse"]: {
		value: string,
	label: string,
	defaultOffer: ModelTypes["Offer"]
};
	["MerchantCatalogElement"]: {
		itemUuid: string,
	productUuid: string,
	categories: Array<ModelTypes["Category"]>,
	productName: string,
	defaultDisplay: ModelTypes["DisplayMode"],
	companyDisplay: boolean,
	companyEnabled: boolean,
	defaultPrice: number,
	companyPrice?: number | undefined,
	status: boolean
};
	["StaffCatalogElement"]: {
		itemUuid: string,
	productUuid: string,
	productName: string,
	brandName: string,
	pictureUrl: string,
	typologyName: string,
	hasGroupAffected: boolean,
	hasSpecificOffer: boolean,
	price: number
};
	["ListItem"]: {
		uuid: string,
	name: string
};
	["GroupDisabledProductInput"]: {
	uuid: string,
	disabled: boolean
};
	["ArchivedItemsFilters"]: {
	names?: Array<string> | undefined,
	skus?: Array<string> | undefined,
	archivingDates?: Array<string> | undefined
};
	["ArchivedItemsFilterOptions"]: {
		names?: Array<ModelTypes["OptionElement"]> | undefined,
	skus?: Array<ModelTypes["OptionElement"]> | undefined,
	archivingDates?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ArchivedItems"]: {
		items: Array<ModelTypes["Item"]>,
	filterOptions?: ModelTypes["ArchivedItemsFilterOptions"] | undefined
};
	["ArchivedProductsFilters"]: {
	names?: Array<string> | undefined,
	archivingDates?: Array<string> | undefined
};
	["ArchivedProductsFilterOptions"]: {
		names?: Array<ModelTypes["OptionElement"]> | undefined,
	archivingDates?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ArchivedProducts"]: {
		products: Array<ModelTypes["Product"]>,
	filterOptions?: ModelTypes["ArchivedProductsFilterOptions"] | undefined
};
	["ToggleAllowedGroupResult"]: {
		product: ModelTypes["Product"],
	groupName: string,
	allowed: boolean
};
	["OfferStaffFilters"]: {
		companies: Array<ModelTypes["OptionElement"]>,
	merchants: Array<ModelTypes["OptionElement"]>,
	typologies: Array<ModelTypes["OptionElement"]>
};
	["OfferStaffResponse"]: {
		offers: Array<ModelTypes["Offer"]>,
	filterOptions?: ModelTypes["OfferStaffFilters"] | undefined
};
	["OfferCreationResponse"]: {
		items: Array<ModelTypes["OptionElement"]>,
	companies: Array<ModelTypes["OptionElement"]>,
	merchants: Array<ModelTypes["OptionElement"]>
};
	["CreateOfferStaffCountryForm"]: {
	country: string,
	additionalShippingFees?: number | undefined,
	deliveryDelay: number
};
	["CreateOfferStaffForm"]: {
	itemUuid: string,
	companyUuid?: string | undefined,
	merchantUuid: string,
	price: number,
	startAt: string,
	endAt?: string | undefined,
	countries: Array<ModelTypes["CreateOfferStaffCountryForm"]>
};
	["ReplaceOfferStaffResponse"]: {
		oldOffer: ModelTypes["Offer"],
	newOffer: ModelTypes["Offer"]
};
	["SubFlagKind"]:SubFlagKind;
	["FluxKind"]:FluxKind;
	["FluxEventKind"]:FluxEventKind;
	["BasketItem"]: {
		uuid: string,
	quantity: number,
	product: ModelTypes["Product"],
	isAvailable?: boolean | undefined
};
	["BasketSummary"]: {
		uuid: string,
	items?: Array<ModelTypes["BasketItem"]> | undefined
};
	["BudgetSummary"]: {
		uuid: string,
	basketCount?: number | undefined,
	totalBasket?: number | undefined,
	availableBudget?: number | undefined,
	group?: ModelTypes["CompanyGroup"] | undefined
};
	["Basket"]: {
		basket?: ModelTypes["BasketSummary"] | undefined,
	budgetSummary?: ModelTypes["BudgetSummary"] | undefined
};
	["Flux"]: {
		uuid: string,
	kind: ModelTypes["FluxKind"],
	canceledAt?: string | undefined,
	createdAt: string,
	updatedAt: string,
	events?: Array<ModelTypes["FluxEvent"] | undefined> | undefined,
	lastEvent?: ModelTypes["FluxEvent"] | undefined,
	sub?: ModelTypes["Sub"] | undefined,
	unitItem?: ModelTypes["UnitItem"] | undefined
};
	["FluxEvent"]: {
		uuid: string,
	kind: ModelTypes["FluxEventKind"],
	createdAt: string,
	updatedAt: string
};
	["SubState"]: {
		lastFlux?: ModelTypes["Flux"] | undefined,
	lastEvent?: ModelTypes["FluxEvent"] | undefined,
	shortStatus?: string | undefined
};
	["Sub"]: {
		uuid: string,
	createdAt?: string | undefined,
	updatedAt?: string | undefined,
	startedAt?: string | undefined,
	canceledAt?: string | undefined,
	firstDeliveredAt?: string | undefined,
	lastDeliveredAt?: string | undefined,
	terminatedAt?: string | undefined,
	commandId?: string | undefined,
	subscriptionId?: string | undefined,
	expeditionId?: string | undefined,
	client?: ModelTypes["Client"] | undefined,
	item?: ModelTypes["Item"] | undefined,
	product?: ModelTypes["Product"] | undefined,
	address?: ModelTypes["Address"] | undefined,
	offer?: ModelTypes["Offer"] | undefined,
	comments?: Array<ModelTypes["SubComment"] | undefined> | undefined,
	fluxes?: Array<ModelTypes["Flux"] | undefined> | undefined,
	deliveryFlux?: ModelTypes["Flux"] | undefined,
	retrievalFlux?: ModelTypes["Flux"] | undefined,
	lastFlux?: ModelTypes["Flux"] | undefined,
	state?: ModelTypes["SubState"] | undefined,
	supportFlagEnabledAt?: string | undefined,
	standbyFlagEnabledAt?: string | undefined
};
	["SubFilter"]: {
	triggerHistory?: Array<string> | undefined,
	commandIds?: Array<string> | undefined,
	expeditionIds?: Array<string> | undefined,
	subIds?: Array<string> | undefined,
	companies?: Array<string> | undefined,
	customers?: Array<string> | undefined,
	status?: Array<string> | undefined,
	items?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	orderDates?: ModelTypes["OrderDates"] | undefined,
	deliveryDates?: Array<string | undefined> | undefined,
	supportFlag?: Array<string> | undefined,
	standbyFlag?: Array<string> | undefined
};
	["OrderDates"]: {
	from: string,
	to: string
};
	["Pagination"]: {
	offset: number,
	limit?: number | undefined,
	cursor?: string | undefined,
	total?: number | undefined
};
	["PaginationInfos"]: {
		count: number,
	offset: number,
	cursor?: string | undefined,
	hasMore: boolean,
	initial?: boolean | undefined
};
	["OptionElement"]: {
		value?: string | undefined,
	label: string
};
	["GroupedOptionElement"]: {
		options: Array<ModelTypes["OptionElement"]>,
	label: string
};
	["PaginatedSubsFilters"]: {
		commandIds?: Array<ModelTypes["OptionElement"]> | undefined,
	expeditionIds?: Array<ModelTypes["OptionElement"]> | undefined,
	subIds?: Array<ModelTypes["OptionElement"]> | undefined,
	status?: Array<ModelTypes["GroupedOptionElement"]> | undefined,
	companies?: Array<ModelTypes["OptionElement"]> | undefined,
	logisticians?: Array<ModelTypes["OptionElement"]> | undefined,
	customers?: Array<ModelTypes["OptionElement"]> | undefined,
	items?: Array<ModelTypes["OptionElement"]> | undefined,
	deliveryDates?: Array<ModelTypes["OptionElement"]> | undefined
};
	["PaginatedSubs"]: {
		pagination: ModelTypes["PaginationInfos"],
	subs: Array<ModelTypes["Sub"] | undefined>,
	filterOptions?: ModelTypes["PaginatedSubsFilters"] | undefined
};
	["SubAdminGlobal"]: {
		uuid?: string | undefined,
	productName?: string | undefined,
	typologyName?: string | undefined,
	brandName?: string | undefined,
	pictureUrl?: string | undefined,
	price?: number | undefined,
	productAllocated?: number | undefined
};
	["SubComment"]: {
		uuid: string,
	textContent: string,
	shownToClient?: boolean | undefined,
	author?: ModelTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["SubCommentForm"]: {
	textContent: string,
	shownToClient?: boolean | undefined
};
	["FlowFilter"]: {
	triggerHistory?: Array<string> | undefined,
	commandIds?: Array<string> | undefined,
	subIds?: Array<string> | undefined,
	fluxKind?: Array<ModelTypes["FluxKind"] | undefined> | undefined,
	fluxEventKind?: Array<ModelTypes["FluxEventKind"] | undefined> | undefined,
	customers?: Array<string> | undefined,
	items?: Array<string> | undefined
};
	["PaginatedFlowsFilters"]: {
		commandIds?: Array<ModelTypes["OptionElement"]> | undefined,
	subIds?: Array<ModelTypes["OptionElement"]> | undefined,
	fluxKind?: Array<ModelTypes["FluxKindOptionElement"]> | undefined,
	fluxEventKind?: Array<ModelTypes["OptionElement"]> | undefined,
	customers?: Array<ModelTypes["OptionElement"]> | undefined,
	items?: Array<ModelTypes["OptionElement"]> | undefined
};
	["FluxKindOptionElement"]: {
		value: ModelTypes["FluxKind"],
	label: string
};
	["FluxEventKindOptionElement"]: {
		value: ModelTypes["FluxEventKind"],
	label: string
};
	["PaginatedFlows"]: {
		pagination: ModelTypes["PaginationInfos"],
	flows: Array<ModelTypes["Flux"]>,
	filterOptions?: ModelTypes["PaginatedFlowsFilters"] | undefined
};
	["Usage"]: {
		totalSubs: number,
	activeClients: number
};
	["SubClientInput"]: {
	firstName: string,
	lastName: string,
	email: string
};
	["SubCreationData"]: {
		companyOptions: Array<ModelTypes["OptionElement"]>,
	productOptions: Array<ModelTypes["OptionElement"]>
};
	["CreateSubMerchantForm"]: {
	companyUuid: string,
	clientUuid: string,
	productUuid: string,
	shouldNotImpactBudget?: boolean | undefined
};
	["SellsyInvoiceResponse"]: {
		invoices?: Array<ModelTypes["SellsyInvoice"] | undefined> | undefined
};
	["SellsyInvoice"]: {
		link?: string | undefined,
	totalIncludingVAT?: number | undefined,
	totalExcludingVAT?: number | undefined,
	status?: string | undefined,
	number?: string | undefined,
	date?: string | undefined
};
	["ClientStatusV2"]:ClientStatusV2;
	["ClientStatus"]:ClientStatus;
	["OffboardingStatus"]:OffboardingStatus;
	["GoogleUrlType"]:GoogleUrlType;
	["FinancialModel"]:FinancialModel;
	["NamedValue"]: {
		name: string,
	value: number
};
	["AdminDashboardItem"]: {
		name: string,
	brand: string,
	pictureUrl: string,
	quantity: number,
	productUuid: string,
	categoryUuid: string
};
	["AdminDashboardDataV2"]: {
		pendingInvites: number,
	signedUpUsers: number,
	activeUsers: number,
	offboardingUsers: number,
	disabledUsers: number,
	subsStatus: Array<ModelTypes["NamedValue"]>,
	families: Array<ModelTypes["NamedValue"]>,
	familiesOptions: Array<ModelTypes["OptionElement"]>,
	topItems: Array<ModelTypes["AdminDashboardItem"]>
};
	["CompanyGroupsData"]: {
		groups: Array<ModelTypes["CompanyGroup"]>,
	financialModel: ModelTypes["FinancialModel"]
};
	["UsersAdminFiltersOptions"]: {
		groups?: Array<ModelTypes["OptionElement"]> | undefined,
	countries?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ClientsStaffFiltersOptions"]: {
		countries?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ClientsStaffData"]: {
		clients?: Array<ModelTypes["Client"]> | undefined,
	filterOptions?: ModelTypes["ClientsStaffFiltersOptions"] | undefined
};
	["UsersAdminData"]: {
		clients?: Array<ModelTypes["Client"]> | undefined,
	filterOptions?: ModelTypes["UsersAdminFiltersOptions"] | undefined,
	financialModel: ModelTypes["FinancialModel"],
	companyUuid: string
};
	["CompaniesFilter"]: {
	companies?: Array<string> | undefined,
	creationDates?: Array<string> | undefined,
	clientsCounts?: Array<string> | undefined,
	financialModel?: ModelTypes["FinancialModel"] | undefined,
	company?: string | undefined,
	creationDate?: string | undefined,
	clientsCount?: string | undefined
};
	["ClientsFilterSearch"]: {
	nameOrEmail?: string | undefined,
	accountDisabledAt?: string | undefined,
	companies?: Array<string> | undefined,
	firstnames?: Array<string> | undefined,
	lastnames?: Array<string> | undefined,
	emails?: Array<string> | undefined,
	phones?: Array<string | undefined> | undefined,
	roles?: Array<string | undefined> | undefined,
	firstname?: string | undefined,
	lastname?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	active?: string | undefined,
	accountState?: string | undefined,
	role?: string | undefined,
	offboarding?: ModelTypes["OffboardingStatus"] | undefined,
	creationDates?: Array<string> | undefined,
	groups?: Array<string> | undefined
};
	["SetServiceSubscriptionForm"]: {
	newLocationCostCapEuro?: number | undefined,
	newServiceSubscriptionCost?: number | undefined
};
	["SignupResponse"]: {
		accountActivationToken?: string | undefined,
	email?: string | undefined,
	logoToken?: string | undefined,
	linkedToGoogle?: boolean | undefined,
	authResponse?: ModelTypes["AuthResponse"] | undefined,
	clientUuid?: string | undefined
};
	["AuthAsResponse"]: {
		token?: string | undefined,
	zendeskToken?: string | undefined
};
	["AuthResponse"]: {
		isNew?: boolean | undefined,
	refreshToken?: string | undefined,
	accessToken?: string | undefined,
	error?: string | undefined,
	type?: string | undefined,
	clientUuid?: string | undefined,
	zendeskToken?: string | undefined
};
	["Onboarding"]: {
		isDone: boolean,
	onboardingSteps: Array<ModelTypes["OnboardingStep"]>,
	companyName?: string | undefined
};
	["OnboardingStep"]: {
		value: string,
	isDone: boolean
};
	["LocationCostCapResponse"]: {
		company?: ModelTypes["Company"] | undefined,
	budgetSummary?: ModelTypes["BudgetSummary"] | undefined,
	basketSummary?: ModelTypes["BasketSummary"] | undefined
};
	["CompanyAdminCreationForm"]: {
	firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyName: string,
	registrationNumber: string,
	logoUrl?: string | undefined,
	primaryColor?: string | undefined,
	estimatedWorkersCount?: string | undefined,
	cgu?: boolean | undefined
};
	["CompanyClientCreationForm"]: {
	firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyGroupUuid?: string | undefined,
	cgu?: boolean | undefined
};
	["CompanyClientAddressCreationForm"]: {
	phone?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	comment?: string | undefined
};
	["CompanyDetailsForm"]: {
	name?: string | undefined,
	registrationNumber?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	primaryColor?: string | undefined,
	estimatedWorkersCount?: string | undefined,
	legalCompanyName?: string | undefined,
	allowSelfSignup?: boolean | undefined,
	domainName?: string | undefined
};
	["CompanyBillingContactForm"]: {
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined
};
	["UpdateCompanyBranding"]: {
	logoUrl?: string | undefined,
	primaryColor?: string | undefined
};
	["UpdateCompanyBillingAddressForm"]: {
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined,
	recipientName?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	sepa?: boolean | undefined,
	rentalAYG?: boolean | undefined
};
	["CompanyBillingAddressForm"]: {
	address: string,
	zip: string,
	city: string,
	country: string
};
	["UpsertCompanyClientForm_V2"]: {
	firstName?: string | undefined,
	lastName?: string | undefined,
	email?: string | undefined,
	personalEmail?: string | undefined,
	phone?: string | undefined,
	recipientPhone?: string | undefined,
	recipientName?: string | undefined,
	job?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	comment?: string | undefined
};
	["UpdateCompanyClientPasswordForm"]: {
	email?: string | undefined,
	currentPassword?: string | undefined,
	newPassword?: string | undefined,
	newPasswordConfirmation?: string | undefined
};
	["updateCompanyClientShippingAddressForm"]: {
	recipientName: string,
	recipientPhone: string,
	address: string,
	zip: string,
	city: string,
	country: string,
	comment?: string | undefined
};
	["CompanyDetailsStaffForm"]: {
	name?: string | undefined,
	registrationNumber?: string | undefined,
	createdAt?: string | undefined,
	creator?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	recipientName?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined
};
	["Address"]: {
		uuid: string,
	createdAt: string,
	updatedAt: string,
	recipientName: string,
	recipientPhone: string,
	address: string,
	zip: string,
	city: string,
	country: string,
	comment?: string | undefined
};
	["LogoResponse"]: {
		userLogo?: string | undefined,
	companyLogo?: string | undefined
};
	["CompanyBudgetForm"]: {
	locationCostCap?: number | undefined,
	serviceSubscriptionCost?: number | undefined
};
	["CompanyBudget"]: {
		uuid: string,
	locationCostCap?: number | undefined,
	serviceSubscriptionCost?: number | undefined
};
	["Company"]: {
		uuid: string,
	name: string,
	legalCompanyName?: string | undefined,
	registrationNumber: string,
	estimatedWorkerCount: string,
	sepa?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined,
	creator?: ModelTypes["Client"] | undefined,
	clients?: Array<ModelTypes["Client"] | undefined> | undefined,
	billingAddress?: ModelTypes["Address"] | undefined,
	updatedAt: string,
	createdAt: string,
	disabledProducts?: Array<ModelTypes["CompanyDisabledProduct"]> | undefined,
	companySettings?: ModelTypes["CompanySettings"] | undefined,
	domainNames?: Array<ModelTypes["CompanyDomainName"] | undefined> | undefined,
	budgets?: Array<ModelTypes["CompanyBudget"]> | undefined,
	groups?: Array<ModelTypes["CompanyGroup"] | undefined> | undefined,
	clientsCount?: number | undefined,
	pendingInvitesCount?: number | undefined,
	inactiveUsersCount?: number | undefined,
	activeUsersCount?: number | undefined,
	formerActiveUsersCount?: number | undefined,
	deactivatedUsersCount?: number | undefined,
	estimatedWorkersCount?: string | undefined,
	deliveryCountries?: Array<string> | undefined
};
	["CompanySettings"]: {
		financialModel: ModelTypes["FinancialModel"],
	shouldHidePrices: boolean,
	allowSelfSignup?: boolean | undefined,
	primaryColor?: string | undefined,
	mrrEngaged?: number | undefined,
	workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly?: boolean | undefined,
	disableSSOInvitation?: boolean | undefined,
	lockAllowSelfSignup?: boolean | undefined,
	gConnectOnly?: boolean | undefined
};
	["SSOConfigurationForm"]: {
	workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly: boolean,
	disableSSOInvitation: boolean,
	lockAllowSelfSignup: boolean
};
	["SignUpInConfigurationForm"]: {
	workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly?: boolean | undefined,
	disableSSOInvitation?: boolean | undefined,
	lockAllowSelfSignup?: boolean | undefined,
	gConnectOnly?: boolean | undefined
};
	["CompanyDomainName"]: {
		uuid: string,
	label: string
};
	["Client"]: {
		uuid: string,
	updatedAt: string,
	createdAt: string,
	createdBy?: ModelTypes["Client"] | undefined,
	lastLoggedIn?: string | undefined,
	passwordUpdatedAt?: string | undefined,
	company?: ModelTypes["Company"] | undefined,
	shippingAddress?: ModelTypes["Address"] | undefined,
	companyRole: string,
	accountActivatedAt?: string | undefined,
	accountDisabledAt?: string | undefined,
	accountOffboardedAt?: string | undefined,
	offboardedRequestedAt?: string | undefined,
	status?: ModelTypes["ClientStatus"] | undefined,
	statusV2?: ModelTypes["ClientStatusV2"] | undefined,
	offboardingStatus?: ModelTypes["OffboardingStatus"] | undefined,
	activeSubsCount?: number | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	email: string,
	googleEmail?: string | undefined,
	personalEmail?: string | undefined,
	phone?: string | undefined,
	job?: string | undefined,
	gender?: string | undefined,
	pictureUrl?: string | undefined,
	kind?: string | undefined,
	subs?: Array<ModelTypes["Sub"] | undefined> | undefined,
	companyGroup?: ModelTypes["CompanyGroup"] | undefined,
	offboardedBy?: ModelTypes["Client"] | undefined
};
	["CompanyDisabledProduct"]: {
		uuid: string,
	productId: string
};
	["StaffUser"]: {
		uuid: string,
	updatedAt: string,
	createdAt: string,
	passwordUpdatedAt?: string | undefined,
	firstName: string,
	lastName: string,
	pictureUrl?: string | undefined,
	phone?: string | undefined,
	email: string,
	accountActivatedAt?: string | undefined,
	accountActivationToken?: string | undefined,
	accountDisabledAt?: string | undefined,
	gender?: string | undefined,
	kind?: string | undefined,
	lastLoggedIn?: string | undefined
};
	["MerchantUser"]: {
		uuid: string,
	updatedAt: string,
	createdAt: string,
	passwordUpdatedAt?: string | undefined,
	firstName: string,
	lastName: string,
	pictureUrl?: string | undefined,
	phone?: string | undefined,
	email: string,
	accountActivatedAt?: string | undefined,
	accountActivationToken?: string | undefined,
	accountDisabledAt?: string | undefined,
	job?: string | undefined,
	gender?: string | undefined,
	kind?: string | undefined,
	lastLoggedIn?: string | undefined,
	company?: ModelTypes["MerchantCompany"] | undefined
};
	["MerchantCompany"]: {
		uuid: string,
	updatedAt: string,
	createdAt: string,
	name: string,
	logoUrl?: string | undefined,
	registrationNumber: string,
	intraCommunityVatNumber?: string | undefined,
	email: string,
	phone?: string | undefined,
	description?: string | undefined,
	contactFirstName?: string | undefined,
	contactLastName?: string | undefined,
	contactEmail?: string | undefined,
	contactPhone?: string | undefined,
	address?: ModelTypes["Address"] | undefined
};
	["InvitedUser"]: {
		uuid: string
};
	["GroupOptionElement"]: {
		value: string,
	label: string,
	color?: string | undefined,
	budget?: ModelTypes["CompanyBudget"] | undefined
};
	["CompanyGroup"]: {
		uuid: string,
	name: string,
	description: string,
	budget: ModelTypes["CompanyBudget"],
	usersCount: number,
	activeUsersCount: number,
	inactiveUsersCount: number,
	productsCount: number,
	allowedProductsCount: number,
	averageConsumption?: number | undefined,
	users?: Array<ModelTypes["Client"]> | undefined,
	products?: Array<ModelTypes["Product"]> | undefined,
	countries?: Array<string> | undefined,
	creator?: ModelTypes["Client"] | undefined,
	isDefault?: boolean | undefined,
	color?: string | undefined,
	monthly?: number | undefined,
	workosId?: string | undefined
};
	["CompanyGroupForm"]: {
	name: string,
	description: string,
	budget: string,
	color?: string | undefined
};
	["AddUsersToGroupResult"]: {
		clientsInBudget: Array<ModelTypes["Client"]>,
	clientsOutOfBudget: Array<ModelTypes["Client"]>
};
	["StaffDashboardCompany"]: {
		uuid: string,
	name: string,
	activeUsers: number,
	budget: number,
	totalPrices: number,
	activeSubs: number,
	mrrEngaged?: number | undefined,
	realMRR?: number | undefined
};
	["StaffDashboardData"]: {
		pendingInvites: number,
	inactiveUsers: number,
	formerActiveUsers: number,
	deactivatedUsers: number,
	companies: Array<ModelTypes["StaffDashboardCompany"]>
};
	["DeliverySatisfactionSurvey"]: {
		firstName: string,
	answered?: boolean | undefined
};
	["DeliverySatisfactionSurveyAnswer"]: {
	rating: number,
	commentRating?: string | undefined,
	support?: boolean | undefined,
	commentSupport?: string | undefined
};
	["DeliverySatisfactionSurveyRow"]: {
		lastName: string,
	firstName: string,
	email: string,
	company: string,
	rating: number,
	commentRating?: string | undefined,
	support?: boolean | undefined,
	commentSupport?: string | undefined,
	answeredAt: string
};
	["ProductSatisfactionSurveyItem"]: {
		uuid: string,
	name: string,
	pictureUrl: string
};
	["ProductSatisfactionSurvey"]: {
		firstName: string,
	items: Array<ModelTypes["ProductSatisfactionSurveyItem"]>,
	answered?: boolean | undefined
};
	["ProductSatisfactionSurveyAnswer"]: {
	uuid: string,
	rating: number,
	comment?: string | undefined
};
	["ProductSatisfactionSurveyRow"]: {
		lastName: string,
	firstName: string,
	email: string,
	company: string,
	item: string,
	rating: number,
	comment?: string | undefined,
	answeredAt: string
};
	["UsersStaffFilter"]: {
	search?: string | undefined,
	companies?: Array<string> | undefined,
	countries?: Array<string | undefined> | undefined,
	status?: Array<string | undefined> | undefined,
	offboarding?: Array<string | undefined> | undefined,
	accountState?: Array<string | undefined> | undefined,
	role?: Array<string | undefined> | undefined,
	sort?: ModelTypes["SortFilter"] | undefined
};
	["SortFilter"]: {
	column?: string | undefined,
	order?: string | undefined
};
	["UsersStaffResponse"]: {
		pagination: ModelTypes["PaginationInfos"],
	users: Array<ModelTypes["Client"] | undefined>,
	filterOptions?: ModelTypes["UsersFiltersV2"] | undefined
};
	["UsersFiltersV2"]: {
		countries?: Array<ModelTypes["OptionElement"]> | undefined,
	companies?: Array<ModelTypes["OptionElement"]> | undefined
};
	["SetUserOffboardingResponse"]: {
		uuid: string,
	accountOffboardedAt: string,
	personalEmail: string,
	statusV2: ModelTypes["ClientStatusV2"],
	offboardingStatus: ModelTypes["OffboardingStatus"]
};
	["UnitItemEventKind"]:UnitItemEventKind;
	["SortKind"]:SortKind;
	["RequestOrderKind"]:RequestOrderKind;
	["RequestOrderForm"]: {
	supplierUuid: string,
	supplierMode: ModelTypes["SupplierMode"],
	purchaseOrderNumber: string,
	logisticianUuid: string,
	items: Array<ModelTypes["UnitItemForm"]>
};
	["ItemReceived"]: {
	itemUuid: string,
	qtyReceived: number
};
	["UnitItemForm"]: {
	itemUuid: string,
	qtyOrder: number,
	buyingPrice?: number | undefined
};
	["StockUnitItem"]: {
		item?: ModelTypes["Item"] | undefined,
	requested?: number | undefined,
	inStock?: number | undefined,
	quantityNeeded?: number | undefined,
	allocated?: number | undefined,
	toBeDelivered?: number | undefined,
	rented?: number | undefined,
	returnRequested?: number | undefined,
	deliveryBack?: number | undefined,
	quarantined?: number | undefined,
	exit?: number | undefined
};
	["UnitItem"]: {
		uuid: string,
	unitItemId: string,
	item: ModelTypes["Item"],
	mode?: ModelTypes["SupplierMode"] | undefined,
	entryAt?: string | undefined,
	exitAt?: string | undefined,
	canceledAt?: string | undefined,
	buyingPrice?: number | undefined,
	supplier?: ModelTypes["Supplier"] | undefined,
	logistician?: ModelTypes["Logistician"] | undefined,
	requestOrder?: ModelTypes["RequestOrder"] | undefined,
	events?: Array<ModelTypes["UnitItemEvent"] | undefined> | undefined,
	lastEvent?: ModelTypes["UnitItemEvent"] | undefined,
	flux?: ModelTypes["Flux"] | undefined,
	comments?: Array<ModelTypes["UnitItemComment"] | undefined> | undefined
};
	["UnitItemEvent"]: {
		uuid: string,
	kind?: ModelTypes["UnitItemEventKind"] | undefined,
	updatedAt?: string | undefined
};
	["UnitItemComment"]: {
		uuid: string,
	textContent: string,
	author?: ModelTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["RequestOrders"]: {
		requestOrders?: Array<ModelTypes["RequestOrder"]> | undefined,
	filterOptions?: ModelTypes["RequestOrderOptions"] | undefined
};
	["RequestOrder"]: {
		uuid: string,
	purchaseOrderNumber?: string | undefined,
	requestOrderNumber?: string | undefined,
	mode?: ModelTypes["SupplierMode"] | undefined,
	createdAt?: string | undefined,
	status?: ModelTypes["RequestOrderKind"] | undefined,
	supplier?: ModelTypes["Supplier"] | undefined,
	logistician?: ModelTypes["Logistician"] | undefined,
	unitItems?: Array<ModelTypes["UnitItem"] | undefined> | undefined,
	totalQuantity?: number | undefined,
	totalReceived?: number | undefined,
	totalBuyingPrice?: number | undefined,
	comments?: Array<ModelTypes["RequestOrderComment"] | undefined> | undefined,
	deliveryReceipts?: Array<ModelTypes["DeliveryReceipt"] | undefined> | undefined
};
	["RequestOrderComment"]: {
		uuid: string,
	textContent: string,
	author?: ModelTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["DeliveryReceipt"]: {
		uuid: string,
	deliveryReceiptNumber: string,
	invoiceNumber: string,
	deliveryItems?: Array<ModelTypes["DeliveryItem"]> | undefined,
	createdAt: string,
	requestOrder?: ModelTypes["RequestOrder"] | undefined,
	totalBuyingPrice?: number | undefined,
	stockValue?: number | undefined,
	totalReceived?: number | undefined
};
	["DeliveryItem"]: {
		itemName: string,
	qtyReceived: number
};
	["DeliveryReceiptFilter"]: {
	deliveryReceiptNumbers?: Array<string> | undefined,
	invoiceNumbers?: Array<string> | undefined,
	purchaseOrderNumbers?: Array<string> | undefined
};
	["RequestOrderFilter"]: {
	purchaseOrderNumbers?: Array<string> | undefined,
	requestOrderNumbers?: Array<string> | undefined,
	suppliers?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	buyingPrices?: Array<string> | undefined,
	status?: Array<ModelTypes["RequestOrderKind"]> | undefined,
	createdAt?: Array<string> | undefined
};
	["RequestOrderOptions"]: {
		purchaseOrderNumbers?: Array<ModelTypes["OptionElement"]> | undefined,
	requestOrderNumbers?: Array<ModelTypes["OptionElement"]> | undefined,
	suppliers?: Array<ModelTypes["OptionElement"]> | undefined,
	logisticians?: Array<ModelTypes["OptionElement"]> | undefined,
	realPrices?: Array<ModelTypes["OptionElement"]> | undefined,
	buyingPrices?: Array<ModelTypes["OptionElement"]> | undefined,
	status?: Array<ModelTypes["OptionElement"]> | undefined,
	createdAt?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ItemResponseForm"]: {
		itemUuid: string,
	qtyOrdered: number,
	buyingPrice?: number | undefined,
	name?: string | undefined,
	qtyReceived?: number | undefined
};
	["RequestOrderResponse"]: {
		requestOrder: ModelTypes["RequestOrder"],
	itemsResponseForm: Array<ModelTypes["ItemResponseForm"] | undefined>
};
	["DeliveryReceiptResponse"]: {
		deliveryReceipts: Array<ModelTypes["DeliveryReceipt"] | undefined>,
	filterOptions?: ModelTypes["DeliveryReceiptOptions"] | undefined
};
	["DeliveryReceiptOptions"]: {
		deliveryReceiptNumbers?: Array<ModelTypes["OptionElement"]> | undefined,
	invoiceNumbers?: Array<ModelTypes["OptionElement"]> | undefined,
	purchaseOrderNumbers?: Array<ModelTypes["OptionElement"]> | undefined
};
	["ItemOrdered"]: {
		itemUuid: string,
	name: string,
	qtyOrdered: number,
	qtyReceived: number
};
	["StockFilter"]: {
	brands?: Array<string> | undefined,
	names?: Array<string> | undefined,
	families?: Array<string> | undefined,
	itemsNames?: Array<string> | undefined,
	status?: Array<ModelTypes["UnitItemEventKind"]> | undefined
};
	["StockOrder"]: {
	requested?: ModelTypes["SortKind"] | undefined,
	inStock?: ModelTypes["SortKind"] | undefined,
	allocated?: ModelTypes["SortKind"] | undefined,
	toBeDelivered?: ModelTypes["SortKind"] | undefined,
	rented?: ModelTypes["SortKind"] | undefined,
	returnRequested?: ModelTypes["SortKind"] | undefined,
	deliveryBack?: ModelTypes["SortKind"] | undefined,
	quarantined?: ModelTypes["SortKind"] | undefined,
	exit?: ModelTypes["SortKind"] | undefined
};
	["StockSelect"]: {
	supplierMode?: ModelTypes["SupplierMode"] | undefined,
	suppliers?: Array<string | undefined> | undefined,
	logisticians?: Array<string | undefined> | undefined
};
	["StockOptions"]: {
		brands?: Array<ModelTypes["OptionElement"]> | undefined,
	names?: Array<ModelTypes["OptionElement"]> | undefined,
	families?: Array<ModelTypes["OptionElement"]> | undefined
};
	["StockManagement"]: {
		items: Array<ModelTypes["StockUnitItem"] | undefined>,
	filterOptions?: ModelTypes["StockOptions"] | undefined,
	stockDashboard?: ModelTypes["StockDashboard"] | undefined
};
	["StockDashboard"]: {
		labels?: Array<string> | undefined,
	values?: Array<number> | undefined,
	totalItems?: number | undefined
};
	["StockDetailedOptions"]: {
		itemsNames?: Array<ModelTypes["OptionElement"]> | undefined,
	status?: Array<ModelTypes["OptionElement"]> | undefined
};
	["StockDetailed"]: {
		unitItems: Array<ModelTypes["UnitItem"] | undefined>,
	filterOptions?: ModelTypes["StockDetailedOptions"] | undefined
};
	["QuantityUnitItems"]: {
		itemUuid?: string | undefined,
	inStock?: number | undefined,
	quantityNeeded?: number | undefined
};
	["UpdateProductReturnRequestResponse"]: {
		googleCloudStorageSignedUrls: Array<string | undefined>,
	productReturnRequest: ModelTypes["ProductReturnRequest"]
};
	["ProductReturn"]: {
		uuid: string,
	reason: ModelTypes["ProductReturnReason"],
	subReason?: ModelTypes["ProductReturnSubReason"] | undefined,
	sub?: ModelTypes["Sub"] | undefined,
	productState?: ModelTypes["ProductReturnState"] | undefined,
	userComment?: string | undefined,
	productPicture?: string | undefined,
	productReturnRequest: ModelTypes["ProductReturnRequest"],
	status: ModelTypes["ProductReturnStatus"]
};
	["ProductReturnReason"]:ProductReturnReason;
	["ProductReturnSubReason"]:ProductReturnSubReason;
	["ProductReturnStatus"]:ProductReturnStatus;
	["ProductReturnState"]:ProductReturnState;
	["ProductReturnRequest"]: {
		id?: string | undefined,
	uuid: string,
	isKit?: boolean | undefined,
	status: ModelTypes["ProductReturnStatus"],
	createdAt: string,
	updatedAt: string,
	productReturns: Array<ModelTypes["ProductReturn"]>,
	client: ModelTypes["Client"]
};
	["CreateProductReturnRequestForm"]: {
	productReturnsInput: Array<ModelTypes["ProductReturnInput"]>,
	clientUuid: string
};
	["ProductReturnInput"]: {
	reason: ModelTypes["ProductReturnReason"],
	subReason?: ModelTypes["ProductReturnSubReason"] | undefined,
	subUuid: string
};
	["ProductReturnRequestsStaffFilterInput"]: {
	clientUuid?: string | undefined
};
	["UpdateProductReturnRequestForm"]: {
	isKit?: boolean | undefined,
	productReturns: Array<ModelTypes["UpdateProductReturnRequestFormProductReturn"]>
};
	["UpdateProductReturnRequestFormProductReturn"]: {
	uuid: string,
	productState: ModelTypes["ProductReturnState"],
	comment?: string | undefined,
	picture: boolean
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	ping?: string | undefined,
	families: Array<GraphQLTypes["Family"]>,
	typology: GraphQLTypes["Typology"],
	typologies: Array<GraphQLTypes["Typology"]>,
	brands: Array<GraphQLTypes["Brand"]>,
	logisticians: Array<GraphQLTypes["Logistician"]>,
	suppliers: Array<GraphQLTypes["Supplier"]>,
	items: Array<GraphQLTypes["Item"]>,
	itemManagement: GraphQLTypes["ItemManagement"],
	itemOptions: Array<GraphQLTypes["ItemOptionsResponse"]>,
	item?: GraphQLTypes["Item"] | undefined,
	categories: Array<GraphQLTypes["Category"]>,
	productOptions: Array<GraphQLTypes["OptionElement"]>,
	productManagement: GraphQLTypes["ProductManagement"],
	product: GraphQLTypes["Product"],
	productMerchant: GraphQLTypes["Product"],
	offers: Array<GraphQLTypes["Offer"]>,
	offer: GraphQLTypes["Offer"],
	merchantCatalog: Array<GraphQLTypes["MerchantCatalogElement"]>,
	companyList: Array<GraphQLTypes["ListItem"]>,
	categoryMerchant?: GraphQLTypes["Category"] | undefined,
	archivedItems?: GraphQLTypes["ArchivedItems"] | undefined,
	archivedProducts?: GraphQLTypes["ArchivedProducts"] | undefined,
	family: GraphQLTypes["Family"],
	productAdmin: GraphQLTypes["Product"],
	storeProduct: GraphQLTypes["Product"],
	companystore: Array<GraphQLTypes["Product"]>,
	companyStoreRentalPlatform: Array<GraphQLTypes["ProductCompanyStoreRentalPlatform"] | undefined>,
	fleexstore: Array<GraphQLTypes["Family"]>,
	productMarket: GraphQLTypes["Product"],
	catalogProduct: GraphQLTypes["Product"],
	catalog: Array<GraphQLTypes["Family"]>,
	productAttributes: Array<GraphQLTypes["ProductAttribute"]>,
	productAttribute: GraphQLTypes["ProductAttribute"],
	productsStaff: Array<GraphQLTypes["ProductStaff"]>,
	productStaff: GraphQLTypes["Product"],
	offerStaff: GraphQLTypes["Offer"],
	offersStaff: GraphQLTypes["OfferStaffResponse"],
	offerStaffCreationData: GraphQLTypes["OfferCreationResponse"],
	staffCatalog: Array<GraphQLTypes["StaffCatalogElement"]>,
	basket?: GraphQLTypes["Basket"] | undefined,
	subsMarket_V2: Array<GraphQLTypes["Sub"]>,
	subsAdmin: Array<GraphQLTypes["Sub"]>,
	subsAdminGlobal: Array<GraphQLTypes["SubAdminGlobal"]>,
	paginatedSubs?: GraphQLTypes["PaginatedSubs"] | undefined,
	sub?: GraphQLTypes["Sub"] | undefined,
	paginatedFlows?: GraphQLTypes["PaginatedFlows"] | undefined,
	flows: Array<GraphQLTypes["Flux"]>,
	sendSupportSatisfactionSurveyEmail?: boolean | undefined,
	subCreationData: GraphQLTypes["SubCreationData"],
	clientOptions: Array<GraphQLTypes["OptionElement"]>,
	totalEffectiveLocationPriceExclTaxes?: number | undefined,
	subsClient: Array<GraphQLTypes["Sub"]>,
	usage: GraphQLTypes["Usage"],
	sellsyInvoices: GraphQLTypes["SellsyInvoiceResponse"],
	adminDashboardDataV2?: GraphQLTypes["AdminDashboardDataV2"] | undefined,
	authenticate?: GraphQLTypes["AuthResponse"] | undefined,
	authenticateAs?: GraphQLTypes["AuthAsResponse"] | undefined,
	checkResetToken?: GraphQLTypes["Client"] | undefined,
	client?: GraphQLTypes["Client"] | undefined,
	clients?: Array<GraphQLTypes["Client"] | undefined> | undefined,
	clientsStaff?: GraphQLTypes["ClientsStaffData"] | undefined,
	staff?: GraphQLTypes["StaffUser"] | undefined,
	merchant?: GraphQLTypes["MerchantUser"] | undefined,
	companySettings?: GraphQLTypes["CompanySettings"] | undefined,
	companyDetails?: GraphQLTypes["Company"] | undefined,
	companies?: Array<GraphQLTypes["Company"] | undefined> | undefined,
	companiesStaff?: Array<GraphQLTypes["Company"] | undefined> | undefined,
	companyOnboardingV2?: GraphQLTypes["Onboarding"] | undefined,
	logos?: GraphQLTypes["LogoResponse"] | undefined,
	companyGroup: GraphQLTypes["CompanyGroup"],
	companyGroupV2: GraphQLTypes["CompanyGroup"],
	companyGroups: Array<GraphQLTypes["CompanyGroup"]>,
	companyGroupsV2?: GraphQLTypes["CompanyGroupsData"] | undefined,
	companyGroupOptions: Array<GraphQLTypes["GroupOptionElement"]>,
	budgetsAdmin: Array<GraphQLTypes["CompanyBudget"]>,
	googleConnectionUrl: string,
	getSSOConnectionUrl: string,
	staffDashboard: GraphQLTypes["StaffDashboardData"],
	deliverySatisfactionSurvey?: GraphQLTypes["DeliverySatisfactionSurvey"] | undefined,
	deliverySatisfactionSurveys: Array<GraphQLTypes["DeliverySatisfactionSurveyRow"]>,
	productSatisfactionSurvey?: GraphQLTypes["ProductSatisfactionSurvey"] | undefined,
	productSatisfactionSurveys: Array<GraphQLTypes["ProductSatisfactionSurveyRow"]>,
	usersAdmin?: GraphQLTypes["UsersAdminData"] | undefined,
	userAdmin: GraphQLTypes["Client"],
	usersStaff?: GraphQLTypes["UsersStaffResponse"] | undefined,
	usersStaffOffboarding?: GraphQLTypes["UsersStaffResponse"] | undefined,
	marketDashboard: GraphQLTypes["Client"],
	unitItem: GraphQLTypes["UnitItem"],
	stockDetailed: GraphQLTypes["StockDetailed"],
	stockManagement: GraphQLTypes["StockManagement"],
	quantityUnitItems: Array<GraphQLTypes["QuantityUnitItems"] | undefined>,
	requestOrders?: GraphQLTypes["RequestOrders"] | undefined,
	requestOrder?: GraphQLTypes["RequestOrderResponse"] | undefined,
	deliveryReceipts?: GraphQLTypes["DeliveryReceiptResponse"] | undefined,
	productReturnRequest?: GraphQLTypes["ProductReturnRequest"] | undefined,
	productReturnRequests: Array<GraphQLTypes["ProductReturnRequest"]>,
	productReturnRequestsStaff: Array<GraphQLTypes["ProductReturnRequest"]>
};
	["Mutation"]: {
	__typename: "Mutation",
	exampleMutation?: string | undefined,
	createFamily?: GraphQLTypes["Family"] | undefined,
	createLogistician?: GraphQLTypes["Logistician"] | undefined,
	upsertSupplier: GraphQLTypes["Supplier"],
	upsertTypology?: GraphQLTypes["Typology"] | undefined,
	upsertBrand?: GraphQLTypes["Brand"] | undefined,
	upsertCategory?: GraphQLTypes["Category"] | undefined,
	upsertItem?: GraphQLTypes["Item"] | undefined,
	upsertProduct?: GraphQLTypes["Product"] | undefined,
	upsertOffer?: Array<GraphQLTypes["Offer"] | undefined> | undefined,
	updateOfferEndAt: GraphQLTypes["Offer"],
	setProductDisplayMode?: GraphQLTypes["Product"] | undefined,
	setProductDisabled: GraphQLTypes["ProductDisabledResult"],
	setProductDisabledBulk: Array<GraphQLTypes["ProductDisabledResult"]>,
	setProductDisplayModeBulk: Array<GraphQLTypes["ProductListElement"]>,
	setCompanyWhitelistProductsBulk: Array<GraphQLTypes["MerchantCatalogElement"]>,
	setCompanyBlacklistProductsBulk: Array<GraphQLTypes["MerchantCatalogElement"]>,
	updateProductDisplayPriority?: GraphQLTypes["Category"] | undefined,
	setItemDisabled?: GraphQLTypes["Item"] | undefined,
	archiveItem: GraphQLTypes["Item"],
	replaceItem: Array<GraphQLTypes["Item"]>,
	archiveProduct: GraphQLTypes["Product"],
	toggleAddToCompanyStore: GraphQLTypes["Product"],
	toggleBulkAddToCompanyStore: Array<GraphQLTypes["Product"]>,
	toggleAllowedGroup: GraphQLTypes["ToggleAllowedGroupResult"],
	bulkAddAllowedGroups: Array<GraphQLTypes["Product"]>,
	bulkRemoveAllowedGroups: Array<GraphQLTypes["Product"]>,
	processUnseenProducts: Array<GraphQLTypes["Product"]>,
	upsertProductAttribute?: GraphQLTypes["ProductAttribute"] | undefined,
	upsertProductStaff?: GraphQLTypes["Product"] | undefined,
	archiveProductStaff?: GraphQLTypes["Product"] | undefined,
	createOfferStaff: GraphQLTypes["Offer"],
	replaceOfferStaff: GraphQLTypes["ReplaceOfferStaffResponse"],
	updateFamiliesOrder?: Array<GraphQLTypes["Family"] | undefined> | undefined,
	updateTypologiesOrder?: Array<GraphQLTypes["Typology"] | undefined> | undefined,
	updateProductsStaffOrder?: Array<GraphQLTypes["ProductStaff"] | undefined> | undefined,
	upsertProductBasket: GraphQLTypes["BasketItem"],
	confirmBasket_V2: GraphQLTypes["Basket"],
	cancelFlux: GraphQLTypes["Sub"],
	addFlux: GraphQLTypes["Sub"],
	addFluxBulk: Array<GraphQLTypes["Sub"]>,
	addFluxEvent: GraphQLTypes["Sub"],
	addFluxEventBulk: Array<GraphQLTypes["Sub"]>,
	cancelSub: GraphQLTypes["Sub"],
	cancelSubBulk: Array<GraphQLTypes["Sub"]>,
	setFlag: GraphQLTypes["Sub"],
	setFlagBulk: Array<GraphQLTypes["Sub"]>,
	setExpeditionIdBulk: Array<GraphQLTypes["Sub"]>,
	addSubComment: GraphQLTypes["SubComment"],
	addFlowEventBulk: Array<GraphQLTypes["Flux"]>,
	createSubMerchant: GraphQLTypes["Sub"],
	signupCompanyAdmin?: GraphQLTypes["SignupResponse"] | undefined,
	signupCompanyClient?: GraphQLTypes["SignupResponse"] | undefined,
	signupCompanyClientAddress?: boolean | undefined,
	activateCompanyClient?: boolean | undefined,
	upsertCompanyClient_V2?: GraphQLTypes["Client"] | undefined,
	setCompanyClientKind?: GraphQLTypes["Client"] | undefined,
	setCompanyClientAccountState?: GraphQLTypes["Client"] | undefined,
	updateCompanyClientPassword?: GraphQLTypes["Client"] | undefined,
	updateClientPassword?: GraphQLTypes["Client"] | undefined,
	updateCompanyClientShippingAddress?: GraphQLTypes["Client"] | undefined,
	requestCompanyClientPasswordReset?: boolean | undefined,
	resetPassword?: boolean | undefined,
	updateCompanyDetails?: GraphQLTypes["Company"] | undefined,
	updateCompanyDetailsV2?: GraphQLTypes["Company"] | undefined,
	updateCompanyBillingContact?: GraphQLTypes["Company"] | undefined,
	updateCompanyBillingAddress?: GraphQLTypes["Company"] | undefined,
	updateCompanyBilling?: GraphQLTypes["Company"] | undefined,
	updateCompanyBranding?: GraphQLTypes["Company"] | undefined,
	setCompanySelfSignup?: GraphQLTypes["Company"] | undefined,
	setServiceSubscription?: GraphQLTypes["Company"] | undefined,
	setMinimumMRREngaged?: GraphQLTypes["Company"] | undefined,
	createUploadToken?: string | undefined,
	inviteUsers?: Array<GraphQLTypes["InvitedUser"] | undefined> | undefined,
	resendInvitations?: Array<GraphQLTypes["InvitedUser"] | undefined> | undefined,
	deletePendingInvites?: Array<GraphQLTypes["InvitedUser"] | undefined> | undefined,
	deletePendingInvitesV2?: Array<GraphQLTypes["InvitedUser"]> | undefined,
	setUserOffboarding?: GraphQLTypes["SetUserOffboardingResponse"] | undefined,
	cancelUserOffboarding?: GraphQLTypes["Client"] | undefined,
	upsertCompanyGroup: GraphQLTypes["CompanyGroup"],
	deleteCompanyGroup: Array<GraphQLTypes["CompanyGroup"] | undefined>,
	deleteCompanyGroupV2: GraphQLTypes["Product"],
	createCompanyBudget: GraphQLTypes["CompanyBudget"],
	createCompanyGroup: GraphQLTypes["CompanyGroup"],
	updateCompanyGroup: GraphQLTypes["CompanyGroup"],
	updateCompanyBudget: GraphQLTypes["CompanyBudget"],
	removeCompanyBudget: GraphQLTypes["CompanyBudget"],
	setCompanyDefaultGroup: Array<GraphQLTypes["CompanyGroup"]>,
	addUsersToGroup?: GraphQLTypes["AddUsersToGroupResult"] | undefined,
	addPendingUsersToGroup: Array<GraphQLTypes["Client"]>,
	associateGoogleAccount: GraphQLTypes["Client"],
	associateGoogleAccount_V2: GraphQLTypes["Client"],
	dissociateGoogleAccount: GraphQLTypes["Client"],
	googleAuthentication: GraphQLTypes["AuthResponse"],
	authenticateWithSSO: GraphQLTypes["AuthResponse"],
	setSSOConfiguration: GraphQLTypes["Company"],
	setSignUpInConfiguration: GraphQLTypes["Company"],
	sendDeliverySatisfactionSurvey?: boolean | undefined,
	sendProductSatisfactionSurvey?: boolean | undefined,
	sendActivationEmail?: boolean | undefined,
	updateCompanyDeliveryCountries: GraphQLTypes["Company"],
	updateCompanyFinancialModel: GraphQLTypes["Company"],
	updateCompanyBillingContactV2: GraphQLTypes["Company"],
	updateCompanyBillingAddressV2: GraphQLTypes["Company"],
	updateCompanyBillingSepa: GraphQLTypes["Company"],
	createRequestOrder: GraphQLTypes["RequestOrder"],
	deleteRequestOrder: GraphQLTypes["RequestOrder"],
	closeRequestOrder: GraphQLTypes["RequestOrder"],
	updateUnitItemsStatus: GraphQLTypes["RequestOrder"],
	addUnitItemEvent: GraphQLTypes["UnitItem"],
	updateUnitItemSupplier: GraphQLTypes["UnitItem"],
	addUnitItemComment: GraphQLTypes["UnitItemComment"],
	addRequestOrderComment: GraphQLTypes["RequestOrderComment"],
	createDeliveryReceipt: GraphQLTypes["RequestOrder"],
	updateDeliveryReceiptInvoiceNumber: GraphQLTypes["DeliveryReceipt"],
	updateUnitItemsPrice: GraphQLTypes["RequestOrder"],
	trackProductClick?: boolean | undefined,
	createProductReturnRequest?: GraphQLTypes["ProductReturnRequest"] | undefined,
	updateProductReturnStatus: GraphQLTypes["ProductReturn"],
	updateProductReturnRequestStatuses: Array<GraphQLTypes["ProductReturnRequest"]>,
	updateProductReturnUserInformations: GraphQLTypes["UpdateProductReturnRequestResponse"]
};
	["KeyValue"]: {
	__typename: "KeyValue",
	name?: string | undefined,
	value?: string | undefined
};
	["KeyValueInput"]: {
		name?: string | undefined,
	value?: string | undefined
};
	["DisplayMode"]: DisplayMode;
	["WlBlAction"]: WlBlAction;
	["DisabledProductReason"]: DisabledProductReason;
	["SupplierMode"]: SupplierMode;
	["ItemStatus"]: ItemStatus;
	["ProductAttributeType"]: ProductAttributeType;
	["JSON"]: "scalar" & { name: "JSON" };
	["ProductCompanyStoreRentalPlatform"]: {
	__typename: "ProductCompanyStoreRentalPlatform",
	uuid: string,
	name: string,
	pictureUrl: string,
	brand: GraphQLTypes["Brand"],
	offer?: GraphQLTypes["OfferCompanyStoreRentalPlatform"] | undefined,
	displayPriority?: number | undefined,
	allowedGroups?: Array<GraphQLTypes["GroupCompanyStoreRentalPlatform"] | undefined> | undefined,
	items: Array<GraphQLTypes["ItemCompanyStoreRentalPlatform"]>
};
	["ItemCompanyStoreRentalPlatform"]: {
	__typename: "ItemCompanyStoreRentalPlatform",
	uuid: string,
	typology: GraphQLTypes["TypologyCompanyStoreRentalPlatform"]
};
	["TypologyCompanyStoreRentalPlatform"]: {
	__typename: "TypologyCompanyStoreRentalPlatform",
	uuid: string,
	nameFR: string,
	family: GraphQLTypes["FamilyCompanyStoreRentalPlatform"]
};
	["OfferCompanyStoreRentalPlatform"]: {
	__typename: "OfferCompanyStoreRentalPlatform",
	uuid?: string | undefined,
	price?: number | undefined
};
	["FamilyCompanyStoreRentalPlatform"]: {
	__typename: "FamilyCompanyStoreRentalPlatform",
	uuid: string,
	name: string
};
	["GroupCompanyStoreRentalPlatform"]: {
	__typename: "GroupCompanyStoreRentalPlatform",
	uuid: string,
	name: string,
	color?: string | undefined
};
	["ProductDisabledResult"]: {
	__typename: "ProductDisabledResult",
	uuid: string,
	productUuid: string,
	isDisabled: boolean
};
	["Family"]: {
	__typename: "Family",
	uuid: string,
	name: string,
	typologies?: Array<GraphQLTypes["Typology"]> | undefined,
	order: number
};
	["Brand"]: {
	__typename: "Brand",
	uuid: string,
	name: string,
	description?: string | undefined
};
	["Logistician"]: {
	__typename: "Logistician",
	uuid: string,
	name: string
};
	["Supplier"]: {
	__typename: "Supplier",
	uuid: string,
	name: string
};
	["Typology"]: {
	__typename: "Typology",
	uuid: string,
	nameEN: string,
	nameFR: string,
	transportationCost: number,
	family?: GraphQLTypes["Family"] | undefined,
	products?: Array<GraphQLTypes["Product"]> | undefined,
	storeCardOrientation?: string | undefined,
	numberOfAssociatedAttributes?: number | undefined,
	productAttributes?: Array<GraphQLTypes["ProductAttribute"]> | undefined,
	order?: number | undefined
};
	["ProductAttributeValue"]: {
	__typename: "ProductAttributeValue",
	uuid: string,
	value: string
};
	["ProductAttribute"]: {
	__typename: "ProductAttribute",
	uuid: string,
	name: string,
	description?: string | undefined,
	type: GraphQLTypes["ProductAttributeType"],
	numberOfAssociatedProducts?: number | undefined,
	values?: Array<GraphQLTypes["ProductAttributeValue"]> | undefined
};
	["Item"]: {
	__typename: "Item",
	uuid: string,
	model?: string | undefined,
	ean?: string | undefined,
	variant?: string | undefined,
	sku?: string | undefined,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	weightG?: string | undefined,
	disabledAt?: string | undefined,
	typology?: GraphQLTypes["Typology"] | undefined,
	brand?: GraphQLTypes["Brand"] | undefined,
	defaultOffer?: GraphQLTypes["Offer"] | undefined,
	offers: Array<GraphQLTypes["Offer"]>,
	archivedAt?: string | undefined,
	archivingReason?: string | undefined,
	pictureUrl?: string | undefined,
	name?: string | undefined,
	logistician?: GraphQLTypes["Logistician"] | undefined
};
	["ItemFilter"]: {
		typologies?: Array<string> | undefined,
	families?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	brands?: Array<string> | undefined,
	models?: Array<string> | undefined,
	names?: Array<string> | undefined,
	skus?: Array<string> | undefined,
	prices?: Array<number> | undefined,
	status?: GraphQLTypes["ItemStatus"] | undefined
};
	["ItemManagementFilter"]: {
	__typename: "ItemManagementFilter",
	typologies?: Array<GraphQLTypes["OptionElement"]> | undefined,
	logisticians?: Array<GraphQLTypes["OptionElement"]> | undefined,
	families?: Array<GraphQLTypes["OptionElement"]> | undefined,
	brands?: Array<GraphQLTypes["OptionElement"]> | undefined,
	models?: Array<GraphQLTypes["OptionElement"]> | undefined,
	names?: Array<GraphQLTypes["OptionElement"]> | undefined,
	skus?: Array<GraphQLTypes["OptionElement"]> | undefined,
	prices?: Array<GraphQLTypes["OptionElement"]> | undefined,
	status?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ItemManagement"]: {
	__typename: "ItemManagement",
	items: Array<GraphQLTypes["Item"]>,
	filterOptions?: GraphQLTypes["ItemManagementFilter"] | undefined
};
	["UpsertItemForm"]: {
		typologyUuid?: string | undefined,
	brandUuid?: string | undefined,
	model?: string | undefined,
	variant?: string | undefined,
	sku?: string | undefined,
	heightCm?: number | undefined,
	lengthCm?: number | undefined,
	depthCm?: number | undefined,
	weightG?: number | undefined,
	pictureUrl: string,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	price?: number | undefined,
	priorNotice?: number | undefined,
	archivingReason?: string | undefined,
	logisticianUuid?: string | undefined
};
	["TypologyAttributeForm"]: {
		uuid: string,
	order: number
};
	["TypologyForm"]: {
		familyUuid?: string | undefined,
	nameEN?: string | undefined,
	nameFR?: string | undefined,
	transportationCost: number,
	attributes?: Array<GraphQLTypes["TypologyAttributeForm"]> | undefined
};
	["ProductAttributeValueForm"]: {
		uuid: string,
	value: string
};
	["ProductAttributeForm"]: {
		productAttributeUuid?: string | undefined,
	name: string,
	description?: string | undefined,
	type: GraphQLTypes["ProductAttributeType"],
	values?: Array<GraphQLTypes["ProductAttributeValueForm"]> | undefined,
	newValues?: Array<string> | undefined
};
	["BrandForm"]: {
		name?: string | undefined,
	description?: GraphQLTypes["JSON"] | undefined
};
	["Category"]: {
	__typename: "Category",
	uuid: string,
	nameEN: string,
	nameFR: string,
	name?: string | undefined,
	pictureMiniUrl?: string | undefined,
	pictureWideUrl?: string | undefined,
	whiteTheme?: boolean | undefined,
	products: Array<GraphQLTypes["Product"]>
};
	["CategoryForm"]: {
		nameEN: string,
	nameFR: string,
	pictureMiniUrl: string,
	pictureWideUrl: string,
	whiteTheme?: boolean | undefined
};
	["ProductPicture"]: {
	__typename: "ProductPicture",
	uuid: string,
	pictureUrl: string,
	rank: number
};
	["DisabledGroup"]: {
	__typename: "DisabledGroup",
	uuid: string,
	name: string,
	disabledReason?: GraphQLTypes["DisabledProductReason"] | undefined
};
	["Product"]: {
	__typename: "Product",
	uuid: string,
	categories: Array<GraphQLTypes["Category"]>,
	items: Array<GraphQLTypes["Item"]>,
	item: GraphQLTypes["Item"],
	name: string,
	brand?: GraphQLTypes["Brand"] | undefined,
	description?: string | undefined,
	information?: string | undefined,
	pictureUrl: string,
	pictures: Array<GraphQLTypes["ProductPicture"]>,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	monitor?: string | undefined,
	connection?: string | undefined,
	displayMode?: GraphQLTypes["DisplayMode"] | undefined,
	isDisabled?: boolean | undefined,
	disabledGroups?: Array<GraphQLTypes["DisabledGroup"]> | undefined,
	offer?: GraphQLTypes["Offer"] | undefined,
	displayPriority?: number | undefined,
	archivedAt?: string | undefined,
	isANewProduct?: boolean | undefined,
	seenByAdmin?: boolean | undefined,
	isInCompanyStore?: boolean | undefined,
	allowedGroups?: Array<GraphQLTypes["CompanyGroup"]> | undefined,
	merchantCompany?: GraphQLTypes["MerchantCompany"] | undefined,
	attributes?: Array<GraphQLTypes["ProductProductAttribute"] | undefined> | undefined
};
	["ProductProductAttribute"]: {
	__typename: "ProductProductAttribute",
	uuid: string,
	stringValue?: string | undefined,
	booleanValue?: boolean | undefined,
	productAttribute?: GraphQLTypes["ProductAttribute"] | undefined
};
	["ProductStaff"]: {
	__typename: "ProductStaff",
	uuid: string,
	productName: string,
	productPictureUrl: string,
	brandName: string,
	familyName: string,
	typologyNameEN: string,
	defaultPrice?: number | undefined,
	offersCount?: number | undefined,
	status: boolean,
	order?: number | undefined
};
	["UpsertProductForm"]: {
		categories: Array<string>,
	items: Array<string>,
	name: string,
	description?: GraphQLTypes["JSON"] | undefined,
	information?: GraphQLTypes["JSON"] | undefined,
	pictureUrls: Array<string | undefined>,
	heightCm?: number | undefined,
	lengthCm?: number | undefined,
	depthCm?: number | undefined,
	monitor?: string | undefined,
	connection?: string | undefined,
	displayMode?: GraphQLTypes["DisplayMode"] | undefined
};
	["ProductStaffForm"]: {
		familyUuid?: string | undefined,
	typologyUuid?: string | undefined,
	brandUuid?: string | undefined,
	model?: string | undefined,
	ean?: string | undefined,
	pictureUrls: Array<string | undefined>,
	name: string,
	description?: GraphQLTypes["JSON"] | undefined,
	heightCm?: string | undefined,
	lengthCm?: string | undefined,
	depthCm?: string | undefined,
	weightG?: string | undefined,
	specs?: Array<GraphQLTypes["SpecsForm"]> | undefined
};
	["SpecsForm"]: {
		key: string,
	value?: string | undefined,
	booleanValue?: boolean | undefined,
	type: GraphQLTypes["ProductAttributeType"]
};
	["ProductFilter"]: {
		categories?: Array<string> | undefined,
	names?: Array<string> | undefined,
	numberOfItems?: Array<number> | undefined,
	defaultDisplay?: string | undefined,
	defaultPrices?: Array<number> | undefined,
	status?: boolean | undefined
};
	["ProductManagementFilter"]: {
	__typename: "ProductManagementFilter",
	categories?: Array<GraphQLTypes["OptionElement"]> | undefined,
	names?: Array<GraphQLTypes["OptionElement"]> | undefined,
	numberOfItems?: Array<GraphQLTypes["OptionElement"]> | undefined,
	defaultPrices?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ProductManagement"]: {
	__typename: "ProductManagement",
	products: Array<GraphQLTypes["ProductListElement"]>,
	filterOptions?: GraphQLTypes["ProductManagementFilter"] | undefined
};
	["ProductListElement"]: {
	__typename: "ProductListElement",
	uuid: string,
	categories: Array<GraphQLTypes["Category"]>,
	name: string,
	numberOfItems: number,
	defaultDisplay: GraphQLTypes["DisplayMode"],
	defaultPrice: number,
	status: boolean
};
	["OfferStaffCountry"]: {
	__typename: "OfferStaffCountry",
	uuid?: string | undefined,
	country?: string | undefined,
	additionalShippingFees?: number | undefined,
	deliveryDelay?: number | undefined
};
	["Offer"]: {
	__typename: "Offer",
	uuid: string,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	priorNotice?: number | undefined,
	price?: number | undefined,
	startAt?: string | undefined,
	endAt?: string | undefined,
	company?: GraphQLTypes["Company"] | undefined,
	item?: GraphQLTypes["Item"] | undefined,
	merchantCompany?: GraphQLTypes["MerchantCompany"] | undefined,
	countries?: Array<GraphQLTypes["OfferStaffCountry"]> | undefined
};
	["UpsertOfferForm"]: {
		companyUuid?: string | undefined,
	itemUuid?: string | undefined,
	startAt?: string | undefined,
	endAt?: string | undefined,
	deliveryDelay?: number | undefined,
	engagement?: number | undefined,
	priorNotice?: number | undefined,
	price?: number | undefined
};
	["OfferFilter"]: {
		companies?: Array<string> | undefined,
	items?: Array<string> | undefined,
	prices?: Array<number> | undefined,
	startAts?: Array<string> | undefined,
	endAts?: Array<string | undefined> | undefined,
	status?: boolean | undefined
};
	["ItemOptionsResponse"]: {
	__typename: "ItemOptionsResponse",
	value: string,
	label: string,
	defaultOffer: GraphQLTypes["Offer"]
};
	["MerchantCatalogElement"]: {
	__typename: "MerchantCatalogElement",
	itemUuid: string,
	productUuid: string,
	categories: Array<GraphQLTypes["Category"]>,
	productName: string,
	defaultDisplay: GraphQLTypes["DisplayMode"],
	companyDisplay: boolean,
	companyEnabled: boolean,
	defaultPrice: number,
	companyPrice?: number | undefined,
	status: boolean
};
	["StaffCatalogElement"]: {
	__typename: "StaffCatalogElement",
	itemUuid: string,
	productUuid: string,
	productName: string,
	brandName: string,
	pictureUrl: string,
	typologyName: string,
	hasGroupAffected: boolean,
	hasSpecificOffer: boolean,
	price: number
};
	["ListItem"]: {
	__typename: "ListItem",
	uuid: string,
	name: string
};
	["GroupDisabledProductInput"]: {
		uuid: string,
	disabled: boolean
};
	["ArchivedItemsFilters"]: {
		names?: Array<string> | undefined,
	skus?: Array<string> | undefined,
	archivingDates?: Array<string> | undefined
};
	["ArchivedItemsFilterOptions"]: {
	__typename: "ArchivedItemsFilterOptions",
	names?: Array<GraphQLTypes["OptionElement"]> | undefined,
	skus?: Array<GraphQLTypes["OptionElement"]> | undefined,
	archivingDates?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ArchivedItems"]: {
	__typename: "ArchivedItems",
	items: Array<GraphQLTypes["Item"]>,
	filterOptions?: GraphQLTypes["ArchivedItemsFilterOptions"] | undefined
};
	["ArchivedProductsFilters"]: {
		names?: Array<string> | undefined,
	archivingDates?: Array<string> | undefined
};
	["ArchivedProductsFilterOptions"]: {
	__typename: "ArchivedProductsFilterOptions",
	names?: Array<GraphQLTypes["OptionElement"]> | undefined,
	archivingDates?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ArchivedProducts"]: {
	__typename: "ArchivedProducts",
	products: Array<GraphQLTypes["Product"]>,
	filterOptions?: GraphQLTypes["ArchivedProductsFilterOptions"] | undefined
};
	["ToggleAllowedGroupResult"]: {
	__typename: "ToggleAllowedGroupResult",
	product: GraphQLTypes["Product"],
	groupName: string,
	allowed: boolean
};
	["OfferStaffFilters"]: {
	__typename: "OfferStaffFilters",
	companies: Array<GraphQLTypes["OptionElement"]>,
	merchants: Array<GraphQLTypes["OptionElement"]>,
	typologies: Array<GraphQLTypes["OptionElement"]>
};
	["OfferStaffResponse"]: {
	__typename: "OfferStaffResponse",
	offers: Array<GraphQLTypes["Offer"]>,
	filterOptions?: GraphQLTypes["OfferStaffFilters"] | undefined
};
	["OfferCreationResponse"]: {
	__typename: "OfferCreationResponse",
	items: Array<GraphQLTypes["OptionElement"]>,
	companies: Array<GraphQLTypes["OptionElement"]>,
	merchants: Array<GraphQLTypes["OptionElement"]>
};
	["CreateOfferStaffCountryForm"]: {
		country: string,
	additionalShippingFees?: number | undefined,
	deliveryDelay: number
};
	["CreateOfferStaffForm"]: {
		itemUuid: string,
	companyUuid?: string | undefined,
	merchantUuid: string,
	price: number,
	startAt: string,
	endAt?: string | undefined,
	countries: Array<GraphQLTypes["CreateOfferStaffCountryForm"]>
};
	["ReplaceOfferStaffResponse"]: {
	__typename: "ReplaceOfferStaffResponse",
	oldOffer: GraphQLTypes["Offer"],
	newOffer: GraphQLTypes["Offer"]
};
	["SubFlagKind"]: SubFlagKind;
	["FluxKind"]: FluxKind;
	["FluxEventKind"]: FluxEventKind;
	["BasketItem"]: {
	__typename: "BasketItem",
	uuid: string,
	quantity: number,
	product: GraphQLTypes["Product"],
	isAvailable?: boolean | undefined
};
	["BasketSummary"]: {
	__typename: "BasketSummary",
	uuid: string,
	items?: Array<GraphQLTypes["BasketItem"]> | undefined
};
	["BudgetSummary"]: {
	__typename: "BudgetSummary",
	uuid: string,
	basketCount?: number | undefined,
	totalBasket?: number | undefined,
	availableBudget?: number | undefined,
	group?: GraphQLTypes["CompanyGroup"] | undefined
};
	["Basket"]: {
	__typename: "Basket",
	basket?: GraphQLTypes["BasketSummary"] | undefined,
	budgetSummary?: GraphQLTypes["BudgetSummary"] | undefined
};
	["Flux"]: {
	__typename: "Flux",
	uuid: string,
	kind: GraphQLTypes["FluxKind"],
	canceledAt?: string | undefined,
	createdAt: string,
	updatedAt: string,
	events?: Array<GraphQLTypes["FluxEvent"] | undefined> | undefined,
	lastEvent?: GraphQLTypes["FluxEvent"] | undefined,
	sub?: GraphQLTypes["Sub"] | undefined,
	unitItem?: GraphQLTypes["UnitItem"] | undefined
};
	["FluxEvent"]: {
	__typename: "FluxEvent",
	uuid: string,
	kind: GraphQLTypes["FluxEventKind"],
	createdAt: string,
	updatedAt: string
};
	["SubState"]: {
	__typename: "SubState",
	lastFlux?: GraphQLTypes["Flux"] | undefined,
	lastEvent?: GraphQLTypes["FluxEvent"] | undefined,
	shortStatus?: string | undefined
};
	["Sub"]: {
	__typename: "Sub",
	uuid: string,
	createdAt?: string | undefined,
	updatedAt?: string | undefined,
	startedAt?: string | undefined,
	canceledAt?: string | undefined,
	firstDeliveredAt?: string | undefined,
	lastDeliveredAt?: string | undefined,
	terminatedAt?: string | undefined,
	commandId?: string | undefined,
	subscriptionId?: string | undefined,
	expeditionId?: string | undefined,
	client?: GraphQLTypes["Client"] | undefined,
	item?: GraphQLTypes["Item"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	offer?: GraphQLTypes["Offer"] | undefined,
	comments?: Array<GraphQLTypes["SubComment"] | undefined> | undefined,
	fluxes?: Array<GraphQLTypes["Flux"] | undefined> | undefined,
	deliveryFlux?: GraphQLTypes["Flux"] | undefined,
	retrievalFlux?: GraphQLTypes["Flux"] | undefined,
	lastFlux?: GraphQLTypes["Flux"] | undefined,
	state?: GraphQLTypes["SubState"] | undefined,
	supportFlagEnabledAt?: string | undefined,
	standbyFlagEnabledAt?: string | undefined
};
	["SubFilter"]: {
		triggerHistory?: Array<string> | undefined,
	commandIds?: Array<string> | undefined,
	expeditionIds?: Array<string> | undefined,
	subIds?: Array<string> | undefined,
	companies?: Array<string> | undefined,
	customers?: Array<string> | undefined,
	status?: Array<string> | undefined,
	items?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	orderDates?: GraphQLTypes["OrderDates"] | undefined,
	deliveryDates?: Array<string | undefined> | undefined,
	supportFlag?: Array<string> | undefined,
	standbyFlag?: Array<string> | undefined
};
	["OrderDates"]: {
		from: string,
	to: string
};
	["Pagination"]: {
		offset: number,
	limit?: number | undefined,
	cursor?: string | undefined,
	total?: number | undefined
};
	["PaginationInfos"]: {
	__typename: "PaginationInfos",
	count: number,
	offset: number,
	cursor?: string | undefined,
	hasMore: boolean,
	initial?: boolean | undefined
};
	["OptionElement"]: {
	__typename: "OptionElement",
	value?: string | undefined,
	label: string
};
	["GroupedOptionElement"]: {
	__typename: "GroupedOptionElement",
	options: Array<GraphQLTypes["OptionElement"]>,
	label: string
};
	["PaginatedSubsFilters"]: {
	__typename: "PaginatedSubsFilters",
	commandIds?: Array<GraphQLTypes["OptionElement"]> | undefined,
	expeditionIds?: Array<GraphQLTypes["OptionElement"]> | undefined,
	subIds?: Array<GraphQLTypes["OptionElement"]> | undefined,
	status?: Array<GraphQLTypes["GroupedOptionElement"]> | undefined,
	companies?: Array<GraphQLTypes["OptionElement"]> | undefined,
	logisticians?: Array<GraphQLTypes["OptionElement"]> | undefined,
	customers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	items?: Array<GraphQLTypes["OptionElement"]> | undefined,
	deliveryDates?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["PaginatedSubs"]: {
	__typename: "PaginatedSubs",
	pagination: GraphQLTypes["PaginationInfos"],
	subs: Array<GraphQLTypes["Sub"] | undefined>,
	filterOptions?: GraphQLTypes["PaginatedSubsFilters"] | undefined
};
	["SubAdminGlobal"]: {
	__typename: "SubAdminGlobal",
	uuid?: string | undefined,
	productName?: string | undefined,
	typologyName?: string | undefined,
	brandName?: string | undefined,
	pictureUrl?: string | undefined,
	price?: number | undefined,
	productAllocated?: number | undefined
};
	["SubComment"]: {
	__typename: "SubComment",
	uuid: string,
	textContent: string,
	shownToClient?: boolean | undefined,
	author?: GraphQLTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["SubCommentForm"]: {
		textContent: string,
	shownToClient?: boolean | undefined
};
	["FlowFilter"]: {
		triggerHistory?: Array<string> | undefined,
	commandIds?: Array<string> | undefined,
	subIds?: Array<string> | undefined,
	fluxKind?: Array<GraphQLTypes["FluxKind"] | undefined> | undefined,
	fluxEventKind?: Array<GraphQLTypes["FluxEventKind"] | undefined> | undefined,
	customers?: Array<string> | undefined,
	items?: Array<string> | undefined
};
	["PaginatedFlowsFilters"]: {
	__typename: "PaginatedFlowsFilters",
	commandIds?: Array<GraphQLTypes["OptionElement"]> | undefined,
	subIds?: Array<GraphQLTypes["OptionElement"]> | undefined,
	fluxKind?: Array<GraphQLTypes["FluxKindOptionElement"]> | undefined,
	fluxEventKind?: Array<GraphQLTypes["OptionElement"]> | undefined,
	customers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	items?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["FluxKindOptionElement"]: {
	__typename: "FluxKindOptionElement",
	value: GraphQLTypes["FluxKind"],
	label: string
};
	["FluxEventKindOptionElement"]: {
	__typename: "FluxEventKindOptionElement",
	value: GraphQLTypes["FluxEventKind"],
	label: string
};
	["PaginatedFlows"]: {
	__typename: "PaginatedFlows",
	pagination: GraphQLTypes["PaginationInfos"],
	flows: Array<GraphQLTypes["Flux"]>,
	filterOptions?: GraphQLTypes["PaginatedFlowsFilters"] | undefined
};
	["Usage"]: {
	__typename: "Usage",
	totalSubs: number,
	activeClients: number
};
	["SubClientInput"]: {
		firstName: string,
	lastName: string,
	email: string
};
	["SubCreationData"]: {
	__typename: "SubCreationData",
	companyOptions: Array<GraphQLTypes["OptionElement"]>,
	productOptions: Array<GraphQLTypes["OptionElement"]>
};
	["CreateSubMerchantForm"]: {
		companyUuid: string,
	clientUuid: string,
	productUuid: string,
	shouldNotImpactBudget?: boolean | undefined
};
	["SellsyInvoiceResponse"]: {
	__typename: "SellsyInvoiceResponse",
	invoices?: Array<GraphQLTypes["SellsyInvoice"] | undefined> | undefined
};
	["SellsyInvoice"]: {
	__typename: "SellsyInvoice",
	link?: string | undefined,
	totalIncludingVAT?: number | undefined,
	totalExcludingVAT?: number | undefined,
	status?: string | undefined,
	number?: string | undefined,
	date?: string | undefined
};
	["ClientStatusV2"]: ClientStatusV2;
	["ClientStatus"]: ClientStatus;
	["OffboardingStatus"]: OffboardingStatus;
	["GoogleUrlType"]: GoogleUrlType;
	["FinancialModel"]: FinancialModel;
	["NamedValue"]: {
	__typename: "NamedValue",
	name: string,
	value: number
};
	["AdminDashboardItem"]: {
	__typename: "AdminDashboardItem",
	name: string,
	brand: string,
	pictureUrl: string,
	quantity: number,
	productUuid: string,
	categoryUuid: string
};
	["AdminDashboardDataV2"]: {
	__typename: "AdminDashboardDataV2",
	pendingInvites: number,
	signedUpUsers: number,
	activeUsers: number,
	offboardingUsers: number,
	disabledUsers: number,
	subsStatus: Array<GraphQLTypes["NamedValue"]>,
	families: Array<GraphQLTypes["NamedValue"]>,
	familiesOptions: Array<GraphQLTypes["OptionElement"]>,
	topItems: Array<GraphQLTypes["AdminDashboardItem"]>
};
	["CompanyGroupsData"]: {
	__typename: "CompanyGroupsData",
	groups: Array<GraphQLTypes["CompanyGroup"]>,
	financialModel: GraphQLTypes["FinancialModel"]
};
	["UsersAdminFiltersOptions"]: {
	__typename: "UsersAdminFiltersOptions",
	groups?: Array<GraphQLTypes["OptionElement"]> | undefined,
	countries?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ClientsStaffFiltersOptions"]: {
	__typename: "ClientsStaffFiltersOptions",
	countries?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ClientsStaffData"]: {
	__typename: "ClientsStaffData",
	clients?: Array<GraphQLTypes["Client"]> | undefined,
	filterOptions?: GraphQLTypes["ClientsStaffFiltersOptions"] | undefined
};
	["UsersAdminData"]: {
	__typename: "UsersAdminData",
	clients?: Array<GraphQLTypes["Client"]> | undefined,
	filterOptions?: GraphQLTypes["UsersAdminFiltersOptions"] | undefined,
	financialModel: GraphQLTypes["FinancialModel"],
	companyUuid: string
};
	["CompaniesFilter"]: {
		companies?: Array<string> | undefined,
	creationDates?: Array<string> | undefined,
	clientsCounts?: Array<string> | undefined,
	financialModel?: GraphQLTypes["FinancialModel"] | undefined,
	company?: string | undefined,
	creationDate?: string | undefined,
	clientsCount?: string | undefined
};
	["ClientsFilterSearch"]: {
		nameOrEmail?: string | undefined,
	accountDisabledAt?: string | undefined,
	companies?: Array<string> | undefined,
	firstnames?: Array<string> | undefined,
	lastnames?: Array<string> | undefined,
	emails?: Array<string> | undefined,
	phones?: Array<string | undefined> | undefined,
	roles?: Array<string | undefined> | undefined,
	firstname?: string | undefined,
	lastname?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	active?: string | undefined,
	accountState?: string | undefined,
	role?: string | undefined,
	offboarding?: GraphQLTypes["OffboardingStatus"] | undefined,
	creationDates?: Array<string> | undefined,
	groups?: Array<string> | undefined
};
	["SetServiceSubscriptionForm"]: {
		newLocationCostCapEuro?: number | undefined,
	newServiceSubscriptionCost?: number | undefined
};
	["SignupResponse"]: {
	__typename: "SignupResponse",
	accountActivationToken?: string | undefined,
	email?: string | undefined,
	logoToken?: string | undefined,
	linkedToGoogle?: boolean | undefined,
	authResponse?: GraphQLTypes["AuthResponse"] | undefined,
	clientUuid?: string | undefined
};
	["AuthAsResponse"]: {
	__typename: "AuthAsResponse",
	token?: string | undefined,
	zendeskToken?: string | undefined
};
	["AuthResponse"]: {
	__typename: "AuthResponse",
	isNew?: boolean | undefined,
	refreshToken?: string | undefined,
	accessToken?: string | undefined,
	error?: string | undefined,
	type?: string | undefined,
	clientUuid?: string | undefined,
	zendeskToken?: string | undefined
};
	["Onboarding"]: {
	__typename: "Onboarding",
	isDone: boolean,
	onboardingSteps: Array<GraphQLTypes["OnboardingStep"]>,
	companyName?: string | undefined
};
	["OnboardingStep"]: {
	__typename: "OnboardingStep",
	value: string,
	isDone: boolean
};
	["LocationCostCapResponse"]: {
	__typename: "LocationCostCapResponse",
	company?: GraphQLTypes["Company"] | undefined,
	budgetSummary?: GraphQLTypes["BudgetSummary"] | undefined,
	basketSummary?: GraphQLTypes["BasketSummary"] | undefined
};
	["CompanyAdminCreationForm"]: {
		firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyName: string,
	registrationNumber: string,
	logoUrl?: string | undefined,
	primaryColor?: string | undefined,
	estimatedWorkersCount?: string | undefined,
	cgu?: boolean | undefined
};
	["CompanyClientCreationForm"]: {
		firstName: string,
	lastName: string,
	email: string,
	personalEmail: string,
	password: string,
	tosSignedVersion: number,
	companyGroupUuid?: string | undefined,
	cgu?: boolean | undefined
};
	["CompanyClientAddressCreationForm"]: {
		phone?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	comment?: string | undefined
};
	["CompanyDetailsForm"]: {
		name?: string | undefined,
	registrationNumber?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	primaryColor?: string | undefined,
	estimatedWorkersCount?: string | undefined,
	legalCompanyName?: string | undefined,
	allowSelfSignup?: boolean | undefined,
	domainName?: string | undefined
};
	["CompanyBillingContactForm"]: {
		billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined
};
	["UpdateCompanyBranding"]: {
		logoUrl?: string | undefined,
	primaryColor?: string | undefined
};
	["UpdateCompanyBillingAddressForm"]: {
		billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined,
	recipientName?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	sepa?: boolean | undefined,
	rentalAYG?: boolean | undefined
};
	["CompanyBillingAddressForm"]: {
		address: string,
	zip: string,
	city: string,
	country: string
};
	["UpsertCompanyClientForm_V2"]: {
		firstName?: string | undefined,
	lastName?: string | undefined,
	email?: string | undefined,
	personalEmail?: string | undefined,
	phone?: string | undefined,
	recipientPhone?: string | undefined,
	recipientName?: string | undefined,
	job?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	comment?: string | undefined
};
	["UpdateCompanyClientPasswordForm"]: {
		email?: string | undefined,
	currentPassword?: string | undefined,
	newPassword?: string | undefined,
	newPasswordConfirmation?: string | undefined
};
	["updateCompanyClientShippingAddressForm"]: {
		recipientName: string,
	recipientPhone: string,
	address: string,
	zip: string,
	city: string,
	country: string,
	comment?: string | undefined
};
	["CompanyDetailsStaffForm"]: {
		name?: string | undefined,
	registrationNumber?: string | undefined,
	createdAt?: string | undefined,
	creator?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	recipientName?: string | undefined,
	address?: string | undefined,
	zip?: string | undefined,
	city?: string | undefined,
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined
};
	["Address"]: {
	__typename: "Address",
	uuid: string,
	createdAt: string,
	updatedAt: string,
	recipientName: string,
	recipientPhone: string,
	address: string,
	zip: string,
	city: string,
	country: string,
	comment?: string | undefined
};
	["LogoResponse"]: {
	__typename: "LogoResponse",
	userLogo?: string | undefined,
	companyLogo?: string | undefined
};
	["CompanyBudgetForm"]: {
		locationCostCap?: number | undefined,
	serviceSubscriptionCost?: number | undefined
};
	["CompanyBudget"]: {
	__typename: "CompanyBudget",
	uuid: string,
	locationCostCap?: number | undefined,
	serviceSubscriptionCost?: number | undefined
};
	["Company"]: {
	__typename: "Company",
	uuid: string,
	name: string,
	legalCompanyName?: string | undefined,
	registrationNumber: string,
	estimatedWorkerCount: string,
	sepa?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	logoUrl?: string | undefined,
	billingContactFirstName?: string | undefined,
	billingContactLastName?: string | undefined,
	billingContactEmail?: string | undefined,
	billingContactPhone?: string | undefined,
	creator?: GraphQLTypes["Client"] | undefined,
	clients?: Array<GraphQLTypes["Client"] | undefined> | undefined,
	billingAddress?: GraphQLTypes["Address"] | undefined,
	updatedAt: string,
	createdAt: string,
	disabledProducts?: Array<GraphQLTypes["CompanyDisabledProduct"]> | undefined,
	companySettings?: GraphQLTypes["CompanySettings"] | undefined,
	domainNames?: Array<GraphQLTypes["CompanyDomainName"] | undefined> | undefined,
	budgets?: Array<GraphQLTypes["CompanyBudget"]> | undefined,
	groups?: Array<GraphQLTypes["CompanyGroup"] | undefined> | undefined,
	clientsCount?: number | undefined,
	pendingInvitesCount?: number | undefined,
	inactiveUsersCount?: number | undefined,
	activeUsersCount?: number | undefined,
	formerActiveUsersCount?: number | undefined,
	deactivatedUsersCount?: number | undefined,
	estimatedWorkersCount?: string | undefined,
	deliveryCountries?: Array<string> | undefined
};
	["CompanySettings"]: {
	__typename: "CompanySettings",
	financialModel: GraphQLTypes["FinancialModel"],
	shouldHidePrices: boolean,
	allowSelfSignup?: boolean | undefined,
	primaryColor?: string | undefined,
	mrrEngaged?: number | undefined,
	workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly?: boolean | undefined,
	disableSSOInvitation?: boolean | undefined,
	lockAllowSelfSignup?: boolean | undefined,
	gConnectOnly?: boolean | undefined
};
	["SSOConfigurationForm"]: {
		workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly: boolean,
	disableSSOInvitation: boolean,
	lockAllowSelfSignup: boolean
};
	["SignUpInConfigurationForm"]: {
		workosOrganizationId?: string | undefined,
	workosDirectoryId?: string | undefined,
	ssoLoginOnly?: boolean | undefined,
	disableSSOInvitation?: boolean | undefined,
	lockAllowSelfSignup?: boolean | undefined,
	gConnectOnly?: boolean | undefined
};
	["CompanyDomainName"]: {
	__typename: "CompanyDomainName",
	uuid: string,
	label: string
};
	["Client"]: {
	__typename: "Client",
	uuid: string,
	updatedAt: string,
	createdAt: string,
	createdBy?: GraphQLTypes["Client"] | undefined,
	lastLoggedIn?: string | undefined,
	passwordUpdatedAt?: string | undefined,
	company?: GraphQLTypes["Company"] | undefined,
	shippingAddress?: GraphQLTypes["Address"] | undefined,
	companyRole: string,
	accountActivatedAt?: string | undefined,
	accountDisabledAt?: string | undefined,
	accountOffboardedAt?: string | undefined,
	offboardedRequestedAt?: string | undefined,
	status?: GraphQLTypes["ClientStatus"] | undefined,
	statusV2?: GraphQLTypes["ClientStatusV2"] | undefined,
	offboardingStatus?: GraphQLTypes["OffboardingStatus"] | undefined,
	activeSubsCount?: number | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	email: string,
	googleEmail?: string | undefined,
	personalEmail?: string | undefined,
	phone?: string | undefined,
	job?: string | undefined,
	gender?: string | undefined,
	pictureUrl?: string | undefined,
	kind?: string | undefined,
	subs?: Array<GraphQLTypes["Sub"] | undefined> | undefined,
	companyGroup?: GraphQLTypes["CompanyGroup"] | undefined,
	offboardedBy?: GraphQLTypes["Client"] | undefined
};
	["CompanyDisabledProduct"]: {
	__typename: "CompanyDisabledProduct",
	uuid: string,
	productId: string
};
	["StaffUser"]: {
	__typename: "StaffUser",
	uuid: string,
	updatedAt: string,
	createdAt: string,
	passwordUpdatedAt?: string | undefined,
	firstName: string,
	lastName: string,
	pictureUrl?: string | undefined,
	phone?: string | undefined,
	email: string,
	accountActivatedAt?: string | undefined,
	accountActivationToken?: string | undefined,
	accountDisabledAt?: string | undefined,
	gender?: string | undefined,
	kind?: string | undefined,
	lastLoggedIn?: string | undefined
};
	["MerchantUser"]: {
	__typename: "MerchantUser",
	uuid: string,
	updatedAt: string,
	createdAt: string,
	passwordUpdatedAt?: string | undefined,
	firstName: string,
	lastName: string,
	pictureUrl?: string | undefined,
	phone?: string | undefined,
	email: string,
	accountActivatedAt?: string | undefined,
	accountActivationToken?: string | undefined,
	accountDisabledAt?: string | undefined,
	job?: string | undefined,
	gender?: string | undefined,
	kind?: string | undefined,
	lastLoggedIn?: string | undefined,
	company?: GraphQLTypes["MerchantCompany"] | undefined
};
	["MerchantCompany"]: {
	__typename: "MerchantCompany",
	uuid: string,
	updatedAt: string,
	createdAt: string,
	name: string,
	logoUrl?: string | undefined,
	registrationNumber: string,
	intraCommunityVatNumber?: string | undefined,
	email: string,
	phone?: string | undefined,
	description?: string | undefined,
	contactFirstName?: string | undefined,
	contactLastName?: string | undefined,
	contactEmail?: string | undefined,
	contactPhone?: string | undefined,
	address?: GraphQLTypes["Address"] | undefined
};
	["InvitedUser"]: {
	__typename: "InvitedUser",
	uuid: string
};
	["GroupOptionElement"]: {
	__typename: "GroupOptionElement",
	value: string,
	label: string,
	color?: string | undefined,
	budget?: GraphQLTypes["CompanyBudget"] | undefined
};
	["CompanyGroup"]: {
	__typename: "CompanyGroup",
	uuid: string,
	name: string,
	description: string,
	budget: GraphQLTypes["CompanyBudget"],
	usersCount: number,
	activeUsersCount: number,
	inactiveUsersCount: number,
	productsCount: number,
	allowedProductsCount: number,
	averageConsumption?: number | undefined,
	users?: Array<GraphQLTypes["Client"]> | undefined,
	products?: Array<GraphQLTypes["Product"]> | undefined,
	countries?: Array<string> | undefined,
	creator?: GraphQLTypes["Client"] | undefined,
	isDefault?: boolean | undefined,
	color?: string | undefined,
	monthly?: number | undefined,
	workosId?: string | undefined
};
	["CompanyGroupForm"]: {
		name: string,
	description: string,
	budget: string,
	color?: string | undefined
};
	["AddUsersToGroupResult"]: {
	__typename: "AddUsersToGroupResult",
	clientsInBudget: Array<GraphQLTypes["Client"]>,
	clientsOutOfBudget: Array<GraphQLTypes["Client"]>
};
	["StaffDashboardCompany"]: {
	__typename: "StaffDashboardCompany",
	uuid: string,
	name: string,
	activeUsers: number,
	budget: number,
	totalPrices: number,
	activeSubs: number,
	mrrEngaged?: number | undefined,
	realMRR?: number | undefined
};
	["StaffDashboardData"]: {
	__typename: "StaffDashboardData",
	pendingInvites: number,
	inactiveUsers: number,
	formerActiveUsers: number,
	deactivatedUsers: number,
	companies: Array<GraphQLTypes["StaffDashboardCompany"]>
};
	["DeliverySatisfactionSurvey"]: {
	__typename: "DeliverySatisfactionSurvey",
	firstName: string,
	answered?: boolean | undefined
};
	["DeliverySatisfactionSurveyAnswer"]: {
		rating: number,
	commentRating?: string | undefined,
	support?: boolean | undefined,
	commentSupport?: string | undefined
};
	["DeliverySatisfactionSurveyRow"]: {
	__typename: "DeliverySatisfactionSurveyRow",
	lastName: string,
	firstName: string,
	email: string,
	company: string,
	rating: number,
	commentRating?: string | undefined,
	support?: boolean | undefined,
	commentSupport?: string | undefined,
	answeredAt: string
};
	["ProductSatisfactionSurveyItem"]: {
	__typename: "ProductSatisfactionSurveyItem",
	uuid: string,
	name: string,
	pictureUrl: string
};
	["ProductSatisfactionSurvey"]: {
	__typename: "ProductSatisfactionSurvey",
	firstName: string,
	items: Array<GraphQLTypes["ProductSatisfactionSurveyItem"]>,
	answered?: boolean | undefined
};
	["ProductSatisfactionSurveyAnswer"]: {
		uuid: string,
	rating: number,
	comment?: string | undefined
};
	["ProductSatisfactionSurveyRow"]: {
	__typename: "ProductSatisfactionSurveyRow",
	lastName: string,
	firstName: string,
	email: string,
	company: string,
	item: string,
	rating: number,
	comment?: string | undefined,
	answeredAt: string
};
	["UsersStaffFilter"]: {
		search?: string | undefined,
	companies?: Array<string> | undefined,
	countries?: Array<string | undefined> | undefined,
	status?: Array<string | undefined> | undefined,
	offboarding?: Array<string | undefined> | undefined,
	accountState?: Array<string | undefined> | undefined,
	role?: Array<string | undefined> | undefined,
	sort?: GraphQLTypes["SortFilter"] | undefined
};
	["SortFilter"]: {
		column?: string | undefined,
	order?: string | undefined
};
	["UsersStaffResponse"]: {
	__typename: "UsersStaffResponse",
	pagination: GraphQLTypes["PaginationInfos"],
	users: Array<GraphQLTypes["Client"] | undefined>,
	filterOptions?: GraphQLTypes["UsersFiltersV2"] | undefined
};
	["UsersFiltersV2"]: {
	__typename: "UsersFiltersV2",
	countries?: Array<GraphQLTypes["OptionElement"]> | undefined,
	companies?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["SetUserOffboardingResponse"]: {
	__typename: "SetUserOffboardingResponse",
	uuid: string,
	accountOffboardedAt: string,
	personalEmail: string,
	statusV2: GraphQLTypes["ClientStatusV2"],
	offboardingStatus: GraphQLTypes["OffboardingStatus"]
};
	["UnitItemEventKind"]: UnitItemEventKind;
	["SortKind"]: SortKind;
	["RequestOrderKind"]: RequestOrderKind;
	["RequestOrderForm"]: {
		supplierUuid: string,
	supplierMode: GraphQLTypes["SupplierMode"],
	purchaseOrderNumber: string,
	logisticianUuid: string,
	items: Array<GraphQLTypes["UnitItemForm"]>
};
	["ItemReceived"]: {
		itemUuid: string,
	qtyReceived: number
};
	["UnitItemForm"]: {
		itemUuid: string,
	qtyOrder: number,
	buyingPrice?: number | undefined
};
	["StockUnitItem"]: {
	__typename: "StockUnitItem",
	item?: GraphQLTypes["Item"] | undefined,
	requested?: number | undefined,
	inStock?: number | undefined,
	quantityNeeded?: number | undefined,
	allocated?: number | undefined,
	toBeDelivered?: number | undefined,
	rented?: number | undefined,
	returnRequested?: number | undefined,
	deliveryBack?: number | undefined,
	quarantined?: number | undefined,
	exit?: number | undefined
};
	["UnitItem"]: {
	__typename: "UnitItem",
	uuid: string,
	unitItemId: string,
	item: GraphQLTypes["Item"],
	mode?: GraphQLTypes["SupplierMode"] | undefined,
	entryAt?: string | undefined,
	exitAt?: string | undefined,
	canceledAt?: string | undefined,
	buyingPrice?: number | undefined,
	supplier?: GraphQLTypes["Supplier"] | undefined,
	logistician?: GraphQLTypes["Logistician"] | undefined,
	requestOrder?: GraphQLTypes["RequestOrder"] | undefined,
	events?: Array<GraphQLTypes["UnitItemEvent"] | undefined> | undefined,
	lastEvent?: GraphQLTypes["UnitItemEvent"] | undefined,
	flux?: GraphQLTypes["Flux"] | undefined,
	comments?: Array<GraphQLTypes["UnitItemComment"] | undefined> | undefined
};
	["UnitItemEvent"]: {
	__typename: "UnitItemEvent",
	uuid: string,
	kind?: GraphQLTypes["UnitItemEventKind"] | undefined,
	updatedAt?: string | undefined
};
	["UnitItemComment"]: {
	__typename: "UnitItemComment",
	uuid: string,
	textContent: string,
	author?: GraphQLTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["RequestOrders"]: {
	__typename: "RequestOrders",
	requestOrders?: Array<GraphQLTypes["RequestOrder"]> | undefined,
	filterOptions?: GraphQLTypes["RequestOrderOptions"] | undefined
};
	["RequestOrder"]: {
	__typename: "RequestOrder",
	uuid: string,
	purchaseOrderNumber?: string | undefined,
	requestOrderNumber?: string | undefined,
	mode?: GraphQLTypes["SupplierMode"] | undefined,
	createdAt?: string | undefined,
	status?: GraphQLTypes["RequestOrderKind"] | undefined,
	supplier?: GraphQLTypes["Supplier"] | undefined,
	logistician?: GraphQLTypes["Logistician"] | undefined,
	unitItems?: Array<GraphQLTypes["UnitItem"] | undefined> | undefined,
	totalQuantity?: number | undefined,
	totalReceived?: number | undefined,
	totalBuyingPrice?: number | undefined,
	comments?: Array<GraphQLTypes["RequestOrderComment"] | undefined> | undefined,
	deliveryReceipts?: Array<GraphQLTypes["DeliveryReceipt"] | undefined> | undefined
};
	["RequestOrderComment"]: {
	__typename: "RequestOrderComment",
	uuid: string,
	textContent: string,
	author?: GraphQLTypes["MerchantUser"] | undefined,
	createdAt: string,
	updatedAt: string
};
	["DeliveryReceipt"]: {
	__typename: "DeliveryReceipt",
	uuid: string,
	deliveryReceiptNumber: string,
	invoiceNumber: string,
	deliveryItems?: Array<GraphQLTypes["DeliveryItem"]> | undefined,
	createdAt: string,
	requestOrder?: GraphQLTypes["RequestOrder"] | undefined,
	totalBuyingPrice?: number | undefined,
	stockValue?: number | undefined,
	totalReceived?: number | undefined
};
	["DeliveryItem"]: {
	__typename: "DeliveryItem",
	itemName: string,
	qtyReceived: number
};
	["DeliveryReceiptFilter"]: {
		deliveryReceiptNumbers?: Array<string> | undefined,
	invoiceNumbers?: Array<string> | undefined,
	purchaseOrderNumbers?: Array<string> | undefined
};
	["RequestOrderFilter"]: {
		purchaseOrderNumbers?: Array<string> | undefined,
	requestOrderNumbers?: Array<string> | undefined,
	suppliers?: Array<string> | undefined,
	logisticians?: Array<string> | undefined,
	buyingPrices?: Array<string> | undefined,
	status?: Array<GraphQLTypes["RequestOrderKind"]> | undefined,
	createdAt?: Array<string> | undefined
};
	["RequestOrderOptions"]: {
	__typename: "RequestOrderOptions",
	purchaseOrderNumbers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	requestOrderNumbers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	suppliers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	logisticians?: Array<GraphQLTypes["OptionElement"]> | undefined,
	realPrices?: Array<GraphQLTypes["OptionElement"]> | undefined,
	buyingPrices?: Array<GraphQLTypes["OptionElement"]> | undefined,
	status?: Array<GraphQLTypes["OptionElement"]> | undefined,
	createdAt?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ItemResponseForm"]: {
	__typename: "ItemResponseForm",
	itemUuid: string,
	qtyOrdered: number,
	buyingPrice?: number | undefined,
	name?: string | undefined,
	qtyReceived?: number | undefined
};
	["RequestOrderResponse"]: {
	__typename: "RequestOrderResponse",
	requestOrder: GraphQLTypes["RequestOrder"],
	itemsResponseForm: Array<GraphQLTypes["ItemResponseForm"] | undefined>
};
	["DeliveryReceiptResponse"]: {
	__typename: "DeliveryReceiptResponse",
	deliveryReceipts: Array<GraphQLTypes["DeliveryReceipt"] | undefined>,
	filterOptions?: GraphQLTypes["DeliveryReceiptOptions"] | undefined
};
	["DeliveryReceiptOptions"]: {
	__typename: "DeliveryReceiptOptions",
	deliveryReceiptNumbers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	invoiceNumbers?: Array<GraphQLTypes["OptionElement"]> | undefined,
	purchaseOrderNumbers?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["ItemOrdered"]: {
	__typename: "ItemOrdered",
	itemUuid: string,
	name: string,
	qtyOrdered: number,
	qtyReceived: number
};
	["StockFilter"]: {
		brands?: Array<string> | undefined,
	names?: Array<string> | undefined,
	families?: Array<string> | undefined,
	itemsNames?: Array<string> | undefined,
	status?: Array<GraphQLTypes["UnitItemEventKind"]> | undefined
};
	["StockOrder"]: {
		requested?: GraphQLTypes["SortKind"] | undefined,
	inStock?: GraphQLTypes["SortKind"] | undefined,
	allocated?: GraphQLTypes["SortKind"] | undefined,
	toBeDelivered?: GraphQLTypes["SortKind"] | undefined,
	rented?: GraphQLTypes["SortKind"] | undefined,
	returnRequested?: GraphQLTypes["SortKind"] | undefined,
	deliveryBack?: GraphQLTypes["SortKind"] | undefined,
	quarantined?: GraphQLTypes["SortKind"] | undefined,
	exit?: GraphQLTypes["SortKind"] | undefined
};
	["StockSelect"]: {
		supplierMode?: GraphQLTypes["SupplierMode"] | undefined,
	suppliers?: Array<string | undefined> | undefined,
	logisticians?: Array<string | undefined> | undefined
};
	["StockOptions"]: {
	__typename: "StockOptions",
	brands?: Array<GraphQLTypes["OptionElement"]> | undefined,
	names?: Array<GraphQLTypes["OptionElement"]> | undefined,
	families?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["StockManagement"]: {
	__typename: "StockManagement",
	items: Array<GraphQLTypes["StockUnitItem"] | undefined>,
	filterOptions?: GraphQLTypes["StockOptions"] | undefined,
	stockDashboard?: GraphQLTypes["StockDashboard"] | undefined
};
	["StockDashboard"]: {
	__typename: "StockDashboard",
	labels?: Array<string> | undefined,
	values?: Array<number> | undefined,
	totalItems?: number | undefined
};
	["StockDetailedOptions"]: {
	__typename: "StockDetailedOptions",
	itemsNames?: Array<GraphQLTypes["OptionElement"]> | undefined,
	status?: Array<GraphQLTypes["OptionElement"]> | undefined
};
	["StockDetailed"]: {
	__typename: "StockDetailed",
	unitItems: Array<GraphQLTypes["UnitItem"] | undefined>,
	filterOptions?: GraphQLTypes["StockDetailedOptions"] | undefined
};
	["QuantityUnitItems"]: {
	__typename: "QuantityUnitItems",
	itemUuid?: string | undefined,
	inStock?: number | undefined,
	quantityNeeded?: number | undefined
};
	["UpdateProductReturnRequestResponse"]: {
	__typename: "UpdateProductReturnRequestResponse",
	googleCloudStorageSignedUrls: Array<string | undefined>,
	productReturnRequest: GraphQLTypes["ProductReturnRequest"]
};
	["ProductReturn"]: {
	__typename: "ProductReturn",
	uuid: string,
	reason: GraphQLTypes["ProductReturnReason"],
	subReason?: GraphQLTypes["ProductReturnSubReason"] | undefined,
	sub?: GraphQLTypes["Sub"] | undefined,
	productState?: GraphQLTypes["ProductReturnState"] | undefined,
	userComment?: string | undefined,
	productPicture?: string | undefined,
	productReturnRequest: GraphQLTypes["ProductReturnRequest"],
	status: GraphQLTypes["ProductReturnStatus"]
};
	["ProductReturnReason"]: ProductReturnReason;
	["ProductReturnSubReason"]: ProductReturnSubReason;
	["ProductReturnStatus"]: ProductReturnStatus;
	["ProductReturnState"]: ProductReturnState;
	["ProductReturnRequest"]: {
	__typename: "ProductReturnRequest",
	id?: string | undefined,
	uuid: string,
	isKit?: boolean | undefined,
	status: GraphQLTypes["ProductReturnStatus"],
	createdAt: string,
	updatedAt: string,
	productReturns: Array<GraphQLTypes["ProductReturn"]>,
	client: GraphQLTypes["Client"]
};
	["CreateProductReturnRequestForm"]: {
		productReturnsInput: Array<GraphQLTypes["ProductReturnInput"]>,
	clientUuid: string
};
	["ProductReturnInput"]: {
		reason: GraphQLTypes["ProductReturnReason"],
	subReason?: GraphQLTypes["ProductReturnSubReason"] | undefined,
	subUuid: string
};
	["ProductReturnRequestsStaffFilterInput"]: {
		clientUuid?: string | undefined
};
	["UpdateProductReturnRequestForm"]: {
		isKit?: boolean | undefined,
	productReturns: Array<GraphQLTypes["UpdateProductReturnRequestFormProductReturn"]>
};
	["UpdateProductReturnRequestFormProductReturn"]: {
		uuid: string,
	productState: GraphQLTypes["ProductReturnState"],
	comment?: string | undefined,
	picture: boolean
}
    }
export const enum DisplayMode {
	blacklist = "blacklist",
	whitelist = "whitelist"
}
export const enum WlBlAction {
	insert = "insert",
	delete = "delete"
}
export const enum DisabledProductReason {
	USER_REQUEST = "USER_REQUEST",
	NEW_PRODUCT = "NEW_PRODUCT"
}
export const enum SupplierMode {
	rented = "rented",
	purchased = "purchased"
}
export const enum ItemStatus {
	active = "active",
	inactive = "inactive"
}
export const enum ProductAttributeType {
	text = "text",
	select = "select",
	boolean = "boolean"
}
export const enum SubFlagKind {
	STANDBY = "STANDBY",
	SUPPORT = "SUPPORT"
}
export const enum FluxKind {
	delivery = "delivery",
	retrieval = "retrieval",
	buy_back = "buy_back",
	customer_service_delivery = "customer_service_delivery",
	customer_service_retrieval = "customer_service_retrieval",
	delivery_consumable = "delivery_consumable"
}
export const enum FluxEventKind {
	standby = "standby",
	product_allocated = "product_allocated",
	in_progress = "in_progress",
	done = "done"
}
export const enum ClientStatusV2 {
	pending = "pending",
	inactive = "inactive",
	active = "active",
	offboardingPending = "offboardingPending",
	offboardingOngoing = "offboardingOngoing",
	deactivated = "deactivated"
}
export const enum ClientStatus {
	pending = "pending",
	inactive = "inactive",
	active = "active",
	formerActive = "formerActive",
	deactivated = "deactivated"
}
export const enum OffboardingStatus {
	none = "none",
	pending = "pending",
	ongoing = "ongoing",
	done = "done"
}
export const enum GoogleUrlType {
	ASSOCIATION = "ASSOCIATION",
	ASSOCIATION_V2 = "ASSOCIATION_V2",
	AUTHENTICATION = "AUTHENTICATION"
}
export const enum FinancialModel {
	rentalAYG = "rentalAYG",
	rentalPackage = "rentalPackage",
	rentalPlatform = "rentalPlatform"
}
export const enum UnitItemEventKind {
	requested = "requested",
	in_stock = "in_stock",
	quantity_needed = "quantity_needed",
	allocated = "allocated",
	to_be_delivered = "to_be_delivered",
	rented = "rented",
	return_requested = "return_requested",
	delivery_back = "delivery_back",
	quarantined = "quarantined",
	exit = "exit"
}
export const enum SortKind {
	up = "up",
	down = "down",
	none = "none"
}
export const enum RequestOrderKind {
	received = "received",
	partlyReceived = "partlyReceived",
	ongoing = "ongoing"
}
export const enum ProductReturnReason {
	leaving = "leaving",
	less72h = "less72h",
	more72h = "more72h"
}
export const enum ProductReturnSubReason {
	brokenReplace = "brokenReplace",
	brokenNoReplace = "brokenNoReplace",
	notSuitable = "notSuitable"
}
export const enum ProductReturnStatus {
	requested = "requested",
	activated = "activated",
	ongoing = "ongoing",
	ready = "ready",
	done = "done",
	cancelled = "cancelled",
	refused = "refused"
}
export const enum ProductReturnState {
	asNew = "asNew",
	partiallyBroken = "partiallyBroken",
	fullyBroken = "fullyBroken"
}

type ZEUS_VARIABLES = {
	["KeyValueInput"]: ValueTypes["KeyValueInput"];
	["DisplayMode"]: ValueTypes["DisplayMode"];
	["WlBlAction"]: ValueTypes["WlBlAction"];
	["DisabledProductReason"]: ValueTypes["DisabledProductReason"];
	["SupplierMode"]: ValueTypes["SupplierMode"];
	["ItemStatus"]: ValueTypes["ItemStatus"];
	["ProductAttributeType"]: ValueTypes["ProductAttributeType"];
	["JSON"]: ValueTypes["JSON"];
	["ItemFilter"]: ValueTypes["ItemFilter"];
	["UpsertItemForm"]: ValueTypes["UpsertItemForm"];
	["TypologyAttributeForm"]: ValueTypes["TypologyAttributeForm"];
	["TypologyForm"]: ValueTypes["TypologyForm"];
	["ProductAttributeValueForm"]: ValueTypes["ProductAttributeValueForm"];
	["ProductAttributeForm"]: ValueTypes["ProductAttributeForm"];
	["BrandForm"]: ValueTypes["BrandForm"];
	["CategoryForm"]: ValueTypes["CategoryForm"];
	["UpsertProductForm"]: ValueTypes["UpsertProductForm"];
	["ProductStaffForm"]: ValueTypes["ProductStaffForm"];
	["SpecsForm"]: ValueTypes["SpecsForm"];
	["ProductFilter"]: ValueTypes["ProductFilter"];
	["UpsertOfferForm"]: ValueTypes["UpsertOfferForm"];
	["OfferFilter"]: ValueTypes["OfferFilter"];
	["GroupDisabledProductInput"]: ValueTypes["GroupDisabledProductInput"];
	["ArchivedItemsFilters"]: ValueTypes["ArchivedItemsFilters"];
	["ArchivedProductsFilters"]: ValueTypes["ArchivedProductsFilters"];
	["CreateOfferStaffCountryForm"]: ValueTypes["CreateOfferStaffCountryForm"];
	["CreateOfferStaffForm"]: ValueTypes["CreateOfferStaffForm"];
	["SubFlagKind"]: ValueTypes["SubFlagKind"];
	["FluxKind"]: ValueTypes["FluxKind"];
	["FluxEventKind"]: ValueTypes["FluxEventKind"];
	["SubFilter"]: ValueTypes["SubFilter"];
	["OrderDates"]: ValueTypes["OrderDates"];
	["Pagination"]: ValueTypes["Pagination"];
	["SubCommentForm"]: ValueTypes["SubCommentForm"];
	["FlowFilter"]: ValueTypes["FlowFilter"];
	["SubClientInput"]: ValueTypes["SubClientInput"];
	["CreateSubMerchantForm"]: ValueTypes["CreateSubMerchantForm"];
	["ClientStatusV2"]: ValueTypes["ClientStatusV2"];
	["ClientStatus"]: ValueTypes["ClientStatus"];
	["OffboardingStatus"]: ValueTypes["OffboardingStatus"];
	["GoogleUrlType"]: ValueTypes["GoogleUrlType"];
	["FinancialModel"]: ValueTypes["FinancialModel"];
	["CompaniesFilter"]: ValueTypes["CompaniesFilter"];
	["ClientsFilterSearch"]: ValueTypes["ClientsFilterSearch"];
	["SetServiceSubscriptionForm"]: ValueTypes["SetServiceSubscriptionForm"];
	["CompanyAdminCreationForm"]: ValueTypes["CompanyAdminCreationForm"];
	["CompanyClientCreationForm"]: ValueTypes["CompanyClientCreationForm"];
	["CompanyClientAddressCreationForm"]: ValueTypes["CompanyClientAddressCreationForm"];
	["CompanyDetailsForm"]: ValueTypes["CompanyDetailsForm"];
	["CompanyBillingContactForm"]: ValueTypes["CompanyBillingContactForm"];
	["UpdateCompanyBranding"]: ValueTypes["UpdateCompanyBranding"];
	["UpdateCompanyBillingAddressForm"]: ValueTypes["UpdateCompanyBillingAddressForm"];
	["CompanyBillingAddressForm"]: ValueTypes["CompanyBillingAddressForm"];
	["UpsertCompanyClientForm_V2"]: ValueTypes["UpsertCompanyClientForm_V2"];
	["UpdateCompanyClientPasswordForm"]: ValueTypes["UpdateCompanyClientPasswordForm"];
	["updateCompanyClientShippingAddressForm"]: ValueTypes["updateCompanyClientShippingAddressForm"];
	["CompanyDetailsStaffForm"]: ValueTypes["CompanyDetailsStaffForm"];
	["CompanyBudgetForm"]: ValueTypes["CompanyBudgetForm"];
	["SSOConfigurationForm"]: ValueTypes["SSOConfigurationForm"];
	["SignUpInConfigurationForm"]: ValueTypes["SignUpInConfigurationForm"];
	["CompanyGroupForm"]: ValueTypes["CompanyGroupForm"];
	["DeliverySatisfactionSurveyAnswer"]: ValueTypes["DeliverySatisfactionSurveyAnswer"];
	["ProductSatisfactionSurveyAnswer"]: ValueTypes["ProductSatisfactionSurveyAnswer"];
	["UsersStaffFilter"]: ValueTypes["UsersStaffFilter"];
	["SortFilter"]: ValueTypes["SortFilter"];
	["UnitItemEventKind"]: ValueTypes["UnitItemEventKind"];
	["SortKind"]: ValueTypes["SortKind"];
	["RequestOrderKind"]: ValueTypes["RequestOrderKind"];
	["RequestOrderForm"]: ValueTypes["RequestOrderForm"];
	["ItemReceived"]: ValueTypes["ItemReceived"];
	["UnitItemForm"]: ValueTypes["UnitItemForm"];
	["DeliveryReceiptFilter"]: ValueTypes["DeliveryReceiptFilter"];
	["RequestOrderFilter"]: ValueTypes["RequestOrderFilter"];
	["StockFilter"]: ValueTypes["StockFilter"];
	["StockOrder"]: ValueTypes["StockOrder"];
	["StockSelect"]: ValueTypes["StockSelect"];
	["ProductReturnReason"]: ValueTypes["ProductReturnReason"];
	["ProductReturnSubReason"]: ValueTypes["ProductReturnSubReason"];
	["ProductReturnStatus"]: ValueTypes["ProductReturnStatus"];
	["ProductReturnState"]: ValueTypes["ProductReturnState"];
	["CreateProductReturnRequestForm"]: ValueTypes["CreateProductReturnRequestForm"];
	["ProductReturnInput"]: ValueTypes["ProductReturnInput"];
	["ProductReturnRequestsStaffFilterInput"]: ValueTypes["ProductReturnRequestsStaffFilterInput"];
	["UpdateProductReturnRequestForm"]: ValueTypes["UpdateProductReturnRequestForm"];
	["UpdateProductReturnRequestFormProductReturn"]: ValueTypes["UpdateProductReturnRequestFormProductReturn"];
}