import React from 'react'
import { object, string } from 'yup'

import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import WizardFormStepV2 from '../../../components/organisms/WizardFormStepV2'

const formSection = {
  submitText: 'Paramétrer mon compte',
  formInputs: [
    {
      name: 'companyName',
      label: 'Nom de votre entreprise',
      type: 'text',
      required: true,
      className: 'sign-input',
    },
    {
      name: 'registrationNumber',
      label: 'SIREN',
      type: FORM_ELEMENT_TYPE.TEXT,
      hint: 'Vous pouvez trouver ce numéro à 9 chiffres sur societe.com',
      required: true,
      className: 'sign-input',
    },
    {
      name: 'estimatedWorkersCount',
      label: 'Nombre de collaborateurs dans votre entreprise',
      type: FORM_ELEMENT_TYPE.SELECT,
      hint: "Autrement-dit, le nombre de collaborateurs succeptibles d'utiliser Fleex",
      options: [
        { value: 'unknown', label: 'Je ne sais pas' },
        { value: '1-20', label: '1-20' },
        { value: '21-80', label: '21-80' },
        { value: '81-200', label: '81-200' },
        { value: '201-500', label: '201-500' },
        { value: '501-1000', label: '501-1000' },
        { value: '1001-5000', label: '1001-5000' },
        { value: '>5000', label: '>5000' },
      ],
      className: 'sign-input',
    },
  ],
  validationSchema: object().shape({
    companyName: string().required('Obligatoire').label('Société'),
    registrationNumber: string().required('Obligatoire').label('SIREN'),
  }),
}

const SignupCompanyForm = ({ formData, ...stepProps }) => {
  return (
    <div className="signup-company-form-V2">
      <h1 className="signup-company-form-V2__title">Bienvenue chez Fleex</h1>
      <p className="signup-company-form-V2__subtitle">
        Nous avons juste besoin de quelques informations sur votre entreprise.
      </p>

      <div className="signup-company-form__content">
        <WizardFormStepV2 formSection={formSection} formData={formData} {...stepProps} />
      </div>
    </div>
  )
}

export default SignupCompanyForm
