import classnames from 'classnames'
import { convertFromRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller } from 'react-hook-form'

import useClickOutside from '../../hooks/useClickOutside'

const initializeEditoreState = (content) => {
  if (content) {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
  }
  return EditorState.createEmpty()
}

export const wysiwygToHTML = (content) => content && draftToHtml(JSON.parse(content))

const WysiwygEditor = ({ name, defaultValue, control, error, onlyReadMode, onFocus }) => {
  const ref = useRef()
  useClickOutside(ref, () => {
    setShowToolbar(false)
    onFocus(false)
  })
  const [editorState, setEditorState] = useState(initializeEditoreState(defaultValue))
  const [showToolbar, setShowToolbar] = useState(false)

  useEffect(() => {
    setEditorState(initializeEditoreState(defaultValue))
  }, [defaultValue])

  const editor = (field) => (
    <Editor
      {...field}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarHidden={!showToolbar}
      onFocus={() => {
        setShowToolbar(true)
        onFocus(true)
      }}
      wrapperClassName="form-element-v2__wysiwyg-editor-wrapper"
      editorClassName={classnames('form-element-v2__wysiwyg-editor-input', {
        error,
        onlyReadMode,
      })}
    />
  )

  return (
    <div className="form-element-v2__wysiwyg-editor" ref={ref}>
      <Controller render={({ ref: _, ...field }) => editor(field)} name={name} control={control} />
    </div>
  )
}

export default WysiwygEditor

export const WysiwygContent = ({ content, ...props }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} className="wysiwyg-content-v2" {...props}></div>
)
