export type CountryCode = keyof typeof countriesCode
export type Country = { country: string; countryEN: string; code: string }

export const getCountryFromCode = (countryCode: CountryCode, lang = 'fr') =>
  lang === 'fr' ? countriesCode[countryCode].country : countriesCode[countryCode].countryEN

export const lowerCountryCode = (code: string) => code.toLowerCase().replace(' ', '_')

export const countriesCode = {
  __: { country: '', countryEN: '', code: '' },
  AF: { country: 'Afghanistan', countryEN: 'Afghanistan', code: 'AF' },
  ZA: { country: 'Afrique du Sud', countryEN: 'South Africa', code: 'ZA' },
  AX: { country: 'Åland, Îles', countryEN: 'Åland', code: 'AX' },
  AL: { country: 'Albanie', countryEN: 'Albania', code: 'AL' },
  DZ: { country: 'Algérie', countryEN: 'Algeria', code: 'DZ' },
  DE: { country: 'Allemagne', countryEN: 'Germany', code: 'DE' },
  AD: { country: 'Andorre', countryEN: 'Andorra', code: 'AD' },
  AO: { country: 'Angola', countryEN: 'Angola', code: 'AO' },
  AI: { country: 'Anguilla', countryEN: 'Anguilla', code: 'AI' },
  AQ: { country: 'Antarctique', countryEN: 'Antarctica', code: 'AQ' },
  AG: { country: 'Antigua et Barbuda', countryEN: 'Antigua and Barbuda', code: 'AG' },
  SA: { country: 'Arabie Saoudite', countryEN: 'Saudi Arabia', code: 'SA' },
  AR: { country: 'Argentine', countryEN: 'Argentina', code: 'AR' },
  AM: { country: 'Arménie', countryEN: 'Armenia', code: 'AM' },
  AW: { country: 'Aruba', countryEN: 'Aruba', code: 'AW' },
  AU: { country: 'Australie', countryEN: 'Australia', code: 'AU' },
  AT: { country: 'Autriche', countryEN: 'Austria', code: 'AT' },
  AZ: { country: 'Azerbaïdjan', countryEN: 'Azerbaijan', code: 'AZ' },
  BS: { country: 'Bahamas', countryEN: 'Bahamas', code: 'BS' },
  BH: { country: 'Bahrein', countryEN: 'Bahrain', code: 'BH' },
  BD: { country: 'Bangladesh', countryEN: 'Bangladesh', code: 'BD' },
  BB: { country: 'Barbade', countryEN: 'Barbados', code: 'BB' },
  BY: { country: 'Bélarus', countryEN: 'Belarus', code: 'BY' },
  BE: { country: 'Belgique', countryEN: 'Belgium', code: 'BE' },
  BZ: { country: 'Bélize', countryEN: 'Belize', code: 'BZ' },
  BJ: { country: 'Bénin', countryEN: 'Benin', code: 'BJ' },
  BM: { country: 'Bermudes', countryEN: 'Bermuda', code: 'BM' },
  BT: { country: 'Bhoutan', countryEN: 'Bhutan', code: 'BT' },
  BO: { country: 'Bolivie (État plurinational de)', countryEN: 'Bolivia', code: 'BO' },
  BQ: { country: 'Bonaire, Saint-Eustache et Saba', countryEN: 'Caribbean Netherlands', code: 'BQ' },
  BA: { country: 'Bosnie-Herzégovine', countryEN: 'Bosnia and Herzegovina', code: 'BA' },
  BW: { country: 'Botswana', countryEN: 'Botswana', code: 'BW' },
  BV: { country: 'Bouvet, Ile', countryEN: 'Bouvet Island', code: 'BV' },
  BR: { country: 'Brésil', countryEN: 'Brazil', code: 'BR' },
  BN: { country: 'Brunéi Darussalam', countryEN: 'Brunei', code: 'BN' },
  BG: { country: 'Bulgarie', countryEN: 'Bulgaria', code: 'BG' },
  BF: { country: 'Burkina Faso', countryEN: 'Burkina Faso', code: 'BF' },
  BI: { country: 'Burundi', countryEN: 'Burundi', code: 'BI' },
  CV: { country: 'Cap-Vert', countryEN: 'Cabo Verde', code: 'CV' },
  KY: { country: 'Caïmans, Iles', countryEN: 'Cayman Islands', code: 'KY' },
  KH: { country: 'Cambodge', countryEN: 'Cambodia', code: 'KH' },
  CM: { country: 'Cameroun', countryEN: 'Cameroon', code: 'CM' },
  CA: { country: 'Canada', countryEN: 'Canada', code: 'CA' },
  CL: { country: 'Chili', countryEN: 'Chile', code: 'CL' },
  CN: { country: 'Chine', countryEN: 'China', code: 'CN' },
  CX: { country: 'Christmas, île', countryEN: 'Christmas Island', code: 'CX' },
  CY: { country: 'Chypre', countryEN: 'Cyprus', code: 'CY' },
  CC: { country: 'Cocos/Keeling (Îles)', countryEN: 'Cocos (Keeling) Islands', code: 'CC' },
  CO: { country: 'Colombie', countryEN: 'Colombia', code: 'CO' },
  KM: { country: 'Comores', countryEN: 'Comoros', code: 'KM' },
  CG: { country: 'Congo', countryEN: 'Congo (Congo-Brazzaville)', code: 'CG' },
  CD: { country: 'Congo, République démocratique du', countryEN: 'Democratic Republic of the Congo', code: 'CD' },
  CK: { country: 'Cook, Iles', countryEN: 'Cook Islands', code: 'CK' },
  KR: { country: 'Corée, République de', countryEN: 'South Korea', code: 'KR' },
  KP: { country: 'Corée, République populaire démocratique de', countryEN: 'North Korea', code: 'KP' },
  CR: { country: 'Costa Rica', countryEN: 'Costa Rica', code: 'CR' },
  CI: { country: "Côte d'Ivoire", countryEN: "Côte d'Ivoire", code: 'CI' },
  HR: { country: 'Croatie', countryEN: 'Croatia', code: 'HR' },
  CU: { country: 'Cuba', countryEN: 'Cuba', code: 'CU' },
  CW: { country: 'Curaçao', countryEN: 'Curaçao', code: 'CW' },
  DK: { country: 'Danemark', countryEN: 'Denmark', code: 'DK' },
  DJ: { country: 'Djibouti', countryEN: 'Djibouti', code: 'DJ' },
  DO: { country: 'Dominicaine, République', countryEN: 'Dominican Republic', code: 'DO' },
  DM: { country: 'Dominique', countryEN: 'Dominica', code: 'DM' },
  EG: { country: 'Egypte', countryEN: 'Egypt', code: 'EG' },
  SV: { country: 'El Salvador', countryEN: 'El Salvador', code: 'SV' },
  AE: { country: 'Emirats arabes unis', countryEN: 'United Arab Emirates', code: 'AE' },
  EC: { country: 'Equateur', countryEN: 'Ecuador', code: 'EC' },
  ER: { country: 'Erythrée', countryEN: 'Eritrea', code: 'ER' },
  ES: { country: 'Espagne', countryEN: 'Spain', code: 'ES' },
  EE: { country: 'Estonie', countryEN: 'Estonia', code: 'EE' },
  US: { country: "Etats-Unis d'Amérique", countryEN: 'United States of America', code: 'US' },
  ET: { country: 'Ethiopie', countryEN: 'Ethiopia', code: 'ET' },
  FK: { country: 'Falkland/Malouines (Îles)', countryEN: 'Falkland Islands', code: 'FK' },
  FO: { country: 'Féroé, îles', countryEN: 'Faroe Islands', code: 'FO' },
  FJ: { country: 'Fidji', countryEN: 'Fiji', code: 'FJ' },
  FI: { country: 'Finlande', countryEN: 'Finland', code: 'FI' },
  FR: { country: 'France', countryEN: 'France', code: 'FR' },
  GA: { country: 'Gabon', countryEN: 'Gabon', code: 'GA' },
  GM: { country: 'Gambie', countryEN: 'Gambia', code: 'GM' },
  GE: { country: 'Géorgie', countryEN: 'Georgia', code: 'GE' },
  GS: {
    country: 'Géorgie du sud et les îles Sandwich du sud',
    countryEN: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  GH: { country: 'Ghana', countryEN: 'Ghana', code: 'GH' },
  GI: { country: 'Gibraltar', countryEN: 'Gibraltar', code: 'GI' },
  GR: { country: 'Grèce', countryEN: 'Greece', code: 'GR' },
  GD: { country: 'Grenade', countryEN: 'Grenada', code: 'GD' },
  GL: { country: 'Groenland', countryEN: 'Greenland', code: 'GL' },
  GP: { country: 'Guadeloupe', countryEN: 'Guadeloupe', code: 'GP' },
  GU: { country: 'Guam', countryEN: 'Guam', code: 'GU' },
  GT: { country: 'Guatemala', countryEN: 'Guatemala', code: 'GT' },
  GG: { country: 'Guernesey', countryEN: 'Guernsey', code: 'GG' },
  GN: { country: 'Guinée', countryEN: 'Guinea', code: 'GN' },
  GW: { country: 'Guinée-Bissau', countryEN: 'Guinea-Bissau', code: 'GW' },
  GQ: { country: 'Guinée équatoriale', countryEN: 'Equatorial Guinea', code: 'GQ' },
  GY: { country: 'Guyana', countryEN: 'Guyana', code: 'GY' },
  GF: { country: 'Guyane française', countryEN: 'French_Guiana', code: 'GF' },
  HT: { country: 'Haïti', countryEN: 'Haiti', code: 'HT' },
  HM: { country: 'Heard, Ile et MacDonald, îles', countryEN: 'Heard Island and McDonald Islands', code: 'HM' },
  HN: { country: 'Honduras', countryEN: 'Honduras', code: 'HN' },
  HK: { country: 'Hong Kong', countryEN: 'Hong Kong', code: 'HK' },
  HU: { country: 'Hongrie', countryEN: 'Hungary', code: 'HU' },
  IM: { country: 'Île de Man', countryEN: 'Isle of Man', code: 'IM' },
  UM: {
    country: 'Îles mineures éloignées des Etats-Unis',
    countryEN: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  VG: { country: 'Îles vierges britanniques', countryEN: 'British Virgin Islands', code: 'VG' },
  VI: { country: 'Îles vierges des Etats-Unis', countryEN: 'United States Virgin Islands', code: 'VI' },
  IN: { country: 'Inde', countryEN: 'India', code: 'IN' },
  IO: {
    country: "Indien (Territoire britannique de l'océan)",
    countryEN: 'British Indian Ocean Territory',
    code: 'IO',
  },
  ID: { country: 'Indonésie', countryEN: 'Indonesia', code: 'ID' },
  IR: { country: "Iran, République islamique d'", countryEN: 'Iran', code: 'IR' },
  IQ: { country: 'Iraq', countryEN: 'Iraq', code: 'IQ' },
  IE: { country: 'Irlande', countryEN: 'Ireland', code: 'IE' },
  IS: { country: 'Islande', countryEN: 'Iceland', code: 'IS' },
  IL: { country: 'Israël', countryEN: 'Israel', code: 'IL' },
  IT: { country: 'Italie', countryEN: 'Italy', code: 'IT' },
  JM: { country: 'Jamaïque', countryEN: 'Jamaica', code: 'JM' },
  JP: { country: 'Japon', countryEN: 'Japan', code: 'JP' },
  JE: { country: 'Jersey', countryEN: 'Jersey', code: 'JE' },
  JO: { country: 'Jordanie', countryEN: 'Jordan', code: 'JO' },
  KZ: { country: 'Kazakhstan', countryEN: 'Kazakhstan', code: 'KZ' },
  KE: { country: 'Kenya', countryEN: 'Kenya', code: 'KE' },
  KG: { country: 'Kirghizistan', countryEN: 'Kyrgyzstan', code: 'KG' },
  KI: { country: 'Kiribati', countryEN: 'Kiribati', code: 'KI' },
  KW: { country: 'Koweït', countryEN: 'Kuwait', code: 'KW' },
  LA: { country: 'Lao, République démocratique populaire', countryEN: 'Laos', code: 'LA' },
  LS: { country: 'Lesotho', countryEN: 'Lesotho', code: 'LS' },
  LV: { country: 'Lettonie', countryEN: 'Latvia', code: 'LV' },
  LB: { country: 'Liban', countryEN: 'Lebanon', code: 'LB' },
  LR: { country: 'Libéria', countryEN: 'Liberia', code: 'LR' },
  LY: { country: 'Libye', countryEN: 'Libya', code: 'LY' },
  LI: { country: 'Liechtenstein', countryEN: 'Liechtenstein', code: 'LI' },
  LT: { country: 'Lituanie', countryEN: 'Lithuania', code: 'LT' },
  LU: { country: 'Luxembourg', countryEN: 'Luxembourg', code: 'LU' },
  MO: { country: 'Macao', countryEN: 'Macau', code: 'MO' },
  MK: { country: "Macédoine, l'ex-République yougoslave de", countryEN: 'North Macedonia', code: 'MK' },
  MG: { country: 'Madagascar', countryEN: 'Madagascar', code: 'MG' },
  MY: { country: 'Malaisie', countryEN: 'Malaysia', code: 'MY' },
  MW: { country: 'Malawi', countryEN: 'Malawi', code: 'MW' },
  MV: { country: 'Maldives', countryEN: 'Maldives', code: 'MV' },
  ML: { country: 'Mali', countryEN: 'Mali', code: 'ML' },
  MT: { country: 'Malte', countryEN: 'Malta', code: 'MT' },
  MP: { country: 'Mariannes du nord, Iles', countryEN: 'Northern Mariana Islands', code: 'MP' },
  MA: { country: 'Maroc', countryEN: 'Morocco', code: 'MA' },
  MH: { country: 'Marshall, Iles', countryEN: 'Marshall Islands', code: 'MH' },
  MQ: { country: 'Martinique', countryEN: 'Martinique', code: 'MQ' },
  MU: { country: 'Maurice', countryEN: 'Mauritius', code: 'MU' },
  MR: { country: 'Mauritanie', countryEN: 'Mauritania', code: 'MR' },
  YT: { country: 'Mayotte', countryEN: 'Mayotte', code: 'YT' },
  MX: { country: 'Mexique', countryEN: 'Mexico', code: 'MX' },
  FM: { country: 'Micronésie, Etats Fédérés de', countryEN: 'Micronesia', code: 'FM' },
  MD: { country: 'Moldova, République de', countryEN: 'Moldova', code: 'MD' },
  MC: { country: 'Monaco', countryEN: 'Monaco', code: 'MC' },
  MN: { country: 'Mongolie', countryEN: 'Mongolia', code: 'MN' },
  ME: { country: 'Monténégro', countryEN: 'Montenegro', code: 'ME' },
  MS: { country: 'Montserrat', countryEN: 'Montserrat', code: 'MS' },
  MZ: { country: 'Mozambique', countryEN: 'Mozambique', code: 'MZ' },
  MM: { country: 'Myanmar', countryEN: 'Myanmar', code: 'MM' },
  NA: { country: 'Namibie', countryEN: 'Namibia', code: 'NA' },
  NR: { country: 'Nauru', countryEN: 'Nauru', code: 'NR' },
  NP: { country: 'Népal', countryEN: 'Nepal', code: 'NP' },
  NI: { country: 'Nicaragua', countryEN: 'Nicaragua', code: 'NI' },
  NE: { country: 'Niger', countryEN: 'Niger', code: 'NE' },
  NG: { country: 'Nigéria', countryEN: 'Nigeria', code: 'NG' },
  NU: { country: 'Niue', countryEN: 'Niue', code: 'NU' },
  NF: { country: 'Norfolk, Ile', countryEN: 'Norfolk Island', code: 'NF' },
  NO: { country: 'Norvège', countryEN: 'Norway', code: 'NO' },
  NC: { country: 'Nouvelle-Calédonie', countryEN: 'New Caledonia', code: 'NC' },
  NZ: { country: 'Nouvelle-Zélande', countryEN: 'New Zealand', code: 'NZ' },
  OM: { country: 'Oman', countryEN: 'Oman', code: 'OM' },
  UG: { country: 'Ouganda', countryEN: 'Uganda', code: 'UG' },
  UZ: { country: 'Ouzbékistan', countryEN: 'Uzbekistan', code: 'UZ' },
  PK: { country: 'Pakistan', countryEN: 'Pakistan', code: 'PK' },
  PW: { country: 'Palaos', countryEN: 'Palau', code: 'PW' },
  PS: { country: 'Palestine, Etat de', countryEN: 'Palestine State', code: 'PS' },
  PA: { country: 'Panama', countryEN: 'Panama', code: 'PA' },
  PG: { country: 'Papouasie-Nouvelle-Guinée', countryEN: 'Papua New Guinea', code: 'PG' },
  PY: { country: 'Paraguay', countryEN: 'Paraguay', code: 'PY' },
  NL: { country: 'Pays-Bas', countryEN: 'Netherlands', code: 'NL' },
  XX: { country: 'Pays inconnu', countryEN: 'Unknown country', code: 'XX' },
  ZZ: { country: 'Pays multiples', countryEN: 'Many countries', code: 'ZZ' },
  PE: { country: 'Pérou', countryEN: 'Peru', code: 'PE' },
  PH: { country: 'Philippines', countryEN: 'Philippines', code: 'PH' },
  PN: { country: 'Pitcairn', countryEN: 'Pitcairn Islands', code: 'PN' },
  PL: { country: 'Pologne', countryEN: 'Poland', code: 'PL' },
  PF: { country: 'Polynésie française', countryEN: 'French Polynesia', code: 'PF' },
  PR: { country: 'Porto Rico', countryEN: 'Puerto Rico', code: 'PR' },
  PT: { country: 'Portugal', countryEN: 'Portugal', code: 'PT' },
  QA: { country: 'Qatar', countryEN: 'Qatar', code: 'QA' },
  SY: { country: 'République arabe syrienne', countryEN: 'Syria', code: 'SY' },
  CF: { country: 'République centrafricaine', countryEN: 'Central African Republic', code: 'CF' },
  RE: { country: 'Réunion', countryEN: 'Réunion', code: 'RE' },
  RO: { country: 'Roumanie', countryEN: 'Romania', code: 'RO' },
  GB: { country: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord", countryEN: 'United Kingdom', code: 'GB' },
  RU: { country: 'Russie, Fédération de', countryEN: 'Russia', code: 'RU' },
  RW: { country: 'Rwanda', countryEN: 'Rwanda', code: 'RW' },
  EH: { country: 'Sahara occidental', countryEN: 'Western Sahara', code: 'EH' },
  BL: { country: 'Saint-Barthélemy', countryEN: 'Saint Barthélemy', code: 'BL' },
  KN: { country: 'Saint-Kitts-et-Nevis', countryEN: 'Saint Kitts and Nevis', code: 'KN' },
  SM: { country: 'Saint-Marin', countryEN: 'San Marino', code: 'SM' },
  MF: { country: 'Saint-Martin (partie française)', countryEN: 'Collectivity of Saint Martin', code: 'MF' },
  SX: { country: 'Saint-Martin (partie néerlandaise)', countryEN: 'Sint Maarten', code: 'SX' },
  PM: { country: 'Saint-Pierre-et-Miquelon', countryEN: 'Saint Pierre and Miquelon', code: 'PM' },
  VC: { country: 'Saint-Vincent-et-les-Grenadines', countryEN: 'Saint Vincent and the Grenadines', code: 'VC' },
  SH: {
    country: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    countryEN: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
  },
  LC: { country: 'Sainte-Lucie', countryEN: 'Saint Lucia', code: 'LC' },
  SB: { country: 'Salomon, Iles', countryEN: 'Solomon Islands', code: 'SB' },
  WS: { country: 'Samoa', countryEN: 'Samoa', code: 'WS' },
  AS: { country: 'Samoa américaines', countryEN: 'American Samoa', code: 'AS' },
  ST: { country: 'Sao Tomé-et-Principe', countryEN: 'Sao Tome and Principe', code: 'ST' },
  SN: { country: 'Sénégal', countryEN: 'Senegal', code: 'SN' },
  RS: { country: 'Serbie', countryEN: 'Serbia', code: 'RS' },
  SC: { country: 'Seychelles', countryEN: 'Seychelles', code: 'SC' },
  SL: { country: 'Sierra Leone', countryEN: 'Sierra Leone', code: 'SL' },
  SG: { country: 'Singapour', countryEN: 'Singapore', code: 'SG' },
  SK: { country: 'Slovaquie', countryEN: 'Slovakia', code: 'SK' },
  SI: { country: 'Slovénie', countryEN: 'Slovenia', code: 'SI' },
  SO: { country: 'Somalie', countryEN: 'Somalia', code: 'SO' },
  SD: { country: 'Soudan', countryEN: 'Sudan', code: 'SD' },
  SS: { country: 'Soudan du Sud', countryEN: 'South Sudan', code: 'SS' },
  LK: { country: 'Sri Lanka', countryEN: 'Sri Lanka', code: 'LK' },
  SE: { country: 'Suède', countryEN: 'Sweden', code: 'SE' },
  CH: { country: 'Suisse', countryEN: 'Switzerland', code: 'CH' },
  SR: { country: 'Suriname', countryEN: 'Suriname', code: 'SR' },
  SJ: { country: 'Svalbard et île Jan Mayen', countryEN: 'Svalbard and Jan Mayen', code: 'SJ' },
  SZ: { country: 'Swaziland', countryEN: 'Swaziland', code: 'SZ' },
  TJ: { country: 'Tadjikistan', countryEN: 'Tajikistan', code: 'TJ' },
  TW: { country: 'Taïwan, Province de Chine', countryEN: 'Taiwan Province', code: 'TW' },
  TZ: { country: 'Tanzanie, République unie de', countryEN: 'Tanzania', code: 'TZ' },
  TD: { country: 'Tchad', countryEN: 'Chad', code: 'TD' },
  CZ: { country: 'Tchèque, République', countryEN: 'Czechia (Czech Republic)', code: 'CZ' },
  TF: { country: 'Terres australes françaises', countryEN: 'French Southern and Antarctic Lands', code: 'TF' },
  TH: { country: 'Thaïlande', countryEN: 'Thailand', code: 'TH' },
  TL: { country: 'Timor-Leste', countryEN: 'Timor-Leste', code: 'TL' },
  TG: { country: 'Togo', countryEN: 'Togo', code: 'TG' },
  TK: { country: 'Tokelau', countryEN: 'Tokelau', code: 'TK' },
  TO: { country: 'Tonga', countryEN: 'Tonga', code: 'TO' },
  TT: { country: 'Trinité-et-Tobago', countryEN: 'Trinidad and Tobago', code: 'TT' },
  TN: { country: 'Tunisie', countryEN: 'Tunisia', code: 'TN' },
  TM: { country: 'Turkménistan', countryEN: 'Turkmenistan', code: 'TM' },
  TC: { country: 'Turks-et-Caïcos (Îles)', countryEN: 'Turks and Caicos Islands', code: 'TC' },
  TR: { country: 'Turquie', countryEN: 'Turkey', code: 'TR' },
  TV: { country: 'Tuvalu', countryEN: 'Tuvalu', code: 'TV' },
  UA: { country: 'Ukraine', countryEN: 'Ukraine', code: 'UA' },
  UY: { country: 'Uruguay', countryEN: 'Uruguay', code: 'UY' },
  VU: { country: 'Vanuatu', countryEN: 'Vanuatu', code: 'VU' },
  VA: { country: 'Vatican', countryEN: 'Holy See', code: 'VA' },
  VE: { country: 'Venezuela (République bolivarienne du)', countryEN: 'Venezuela', code: 'VE' },
  VN: { country: 'Viet Nam', countryEN: 'Vietnam', code: 'VN' },
  WF: { country: 'Wallis et Futuna', countryEN: 'Wallis and Futuna', code: 'WF' },
  YE: { country: 'Yémen', countryEN: 'Yemen', code: 'YE' },
  ZM: { country: 'Zambie', countryEN: 'Zambia', code: 'ZM' },
  ZW: { country: 'Zimbabwe', countryEN: 'Zimbabwe', code: 'ZW' },
}

export const UE_COUNTRY_CODES = [
  'DE',
  'AT',
  'BE',
  'BG',
  'CY',
  'HR',
  'DK',
  'ES',
  'EE',
  'FI',
  'FR',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'SE',
  'CZ',
]

export const METROPOLITAN_FRANCE_LABEL = 'France, Metropolitan'

export const FRANCE_DOMTOM_ZIP_PREFIXES = ['20', '971', '973', '975', '972', '974', '976', '984', '987', '986', '988']

export const COUNTRY_CODES = Object.values(countriesCode).reduce<string[]>(
  (acc, { code }) => (code ? [...acc, code] : acc),
  [],
)

export const COUNTRY_OPTIONS = Object.values(countriesCode).reduce<{ value: string; label: string }[]>(
  (acc, { country, code }) => (code ? [...acc, { value: code, label: country }] : acc),
  [],
)

export const COUNTRY_OPTIONS_EN = Object.values(countriesCode)
  .reduce<{ value: string; label: string }[]>(
    (acc, { countryEN, code }) => (code ? [...acc, { value: code, label: countryEN }] : acc),
    [],
  )
  .sort((a, b) => a.label.localeCompare(b.label))

export const COUNTRY_OPTIONS_WITH_METROPOLITAN_FRANCE = COUNTRY_OPTIONS_EN.map((option) =>
  option.value === 'FR' ? { value: 'FR', label: METROPOLITAN_FRANCE_LABEL } : option,
)
