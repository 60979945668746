import { Controller, FieldValues } from 'react-hook-form'

import SelectComponent from '../SelectV2/SelectV2'
import { SelectProps } from './FormElementV2.model'

export const Select = <T extends FieldValues>({
  name,
  control,
  options,
  onlyReadMode,
  register,
  hideLabel,
  label,
  size,
  defaultValue,
  onChange,
  error,
}: Omit<SelectProps<T>, 'type'>) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    control={control}
    render={({ field: { ref: _, onChange: onUpdate, value, ...field } }) => {
      return (
        <SelectComponent
          className={undefined}
          color={undefined}
          kind={undefined}
          options={options}
          {...field}
          onChange={(data: string) => {
            onUpdate(data)
            onChange && onChange(data)
          }}
          onlyReadMode={onlyReadMode}
          register={register}
          hideLabel={hideLabel}
          label={label}
          size={size}
          value={value || defaultValue}
          error={error}
        />
      )
    }}
  />
)
