import React from 'react'

import { Button } from '../../../components/Atoms/ButtonV2/Button'

import './SignupForm.scss'

export const SignupFormSuccess = ({ email, sendActivationEmail }) => (
  <div className="signup-company-client-success-V2__container">
    <h1 className="signup-company-client-success-V2__title">Une dernière étape</h1>
    <div className="signup-company-client-success-V2__subtitles">
      <p className="signup-company-client-success-V2__subtitle">
        Merci pour votre inscription. Nous vous avons envoyé un mail à l'adresse{' '}
        <span className="signup-company-client-success-V2__email">{email}</span> pour confirmer votre identité.
      </p>
      <p className="signup-company-client-success-V2__subtitle">
        Veuillez cliquer sur le lien d'activation que nous vous avons envoyé afin d'activer votre compte Fleex.
      </p>
    </div>

    <div className="signup-company-client-success-V2__separator"></div>

    <div className="signup-company-client-success-V2__bottom">
      <Button kind="primary" className="full-width signup-company-client-success-V2__bottom__back" linkTo="/">
        Retourner à l’écran de connexion
      </Button>
      <p className="signup-company-client-success-V2__bottom__text">Vous n'avez pas reçu le mail ?</p>
      <Button onClick={sendActivationEmail} className="signup-company-client-success-V2__bottom__link" kind="empty">
        Renvoyer le mail d'activation
      </Button>
    </div>
  </div>
)

export default SignupFormSuccess
