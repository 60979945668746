import React from 'react'

import { Button } from '../../../components/Atoms/ButtonV2/Button'

import './style.scss'

export const SignupCompanyFormSuccess = () => (
  <div className="signup-company-admin-success-V2">
    <h1 className="signup-company-admin-success-V2__title">Votre compte a bien été créé !</h1>
    <p className="signup-company-admin-success-V2__subtitle">Activez votre compte grâce au lien envoyé par email.</p>
    <Button linkTo="/" kind="primary" className="full-width">
      Se connecter
    </Button>
  </div>
)

export default SignupCompanyFormSuccess
