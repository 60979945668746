import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import WizardFormStep from '../../../components/organisms/WizardFormStepV2'

const formSections = (t) => [
  {
    submitText: t('reset.password'),
    formInputs: [{ name: 'email', label: t('login.email'), isAuthenticationInput: true }],
    validationSchema: object().shape({
      email: string().email(t('reset.errors.email')).required(t('reset.errors.email_required')).label('Email'),
    }),
  },
]

export const ResetForm = ({ makeOnSubmit }) => {
  const { t } = useTranslation()
  return (
    <div className="reset-form-V2">
      <div className="reset-form-V2__titles">
        <h1 className="reset-form-V2__title">{t('login.forgot_password')}</h1>
        <p className="reset-form-V2__subtitle">{t('reset.subtitle')}.</p>
      </div>

      <div className="reset-form-V2__content">
        <WizardFormStep
          formSection={formSections(t)[0]}
          formData={{}}
          onSubmit={makeOnSubmit}
          hasPrevious={false}
          showStepper={false}
          currentStep={0}
          totalSteps={1}
        />
      </div>
    </div>
  )
}
