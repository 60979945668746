import jwtDecode from 'jwt-decode'
import { useState, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  getAuthFromLocalStorage,
  getSpecificUser,
  saveTokens,
  setCurrentUser,
  unsetTokenSpecificAuth,
} from '../../../constants/utils'
import { initZendeskMessengerBehaviour, ZendeskAPI } from '../../hooks/useZendeskAPI'

type UserContextProviderProps = {
  children: React.ReactNode
}

type Tokens = {
  accessToken: string
  clientUuid: string
  refreshToken: string
  type: string
  zendeskToken: string
}

type AsToken = {
  authenticateAs: {
    token: string
    zendeskToken: string
  }
}

export type Identity = {
  userUuid: string
  userType: 'client'
  userKind: 'admin' | 'user'
  companyUuid: string
  lastLoggedIn: number
  zendeskToken: string
}

type AuthContext = {
  getIdentityFromToken: (tokens: Tokens) => Identity
  login: (tokens: Tokens) => void
  loginAs: (userAccessToken: AsToken) => void
  logoutAs: () => void
  setIdentity: (identity: Identity) => void
  user: Identity
}

const decodeAndSaveTokens = (tokens: Tokens) => {
  const refreshToken = tokens.refreshToken
  const accessToken = tokens.accessToken
  const zendeskToken = tokens.zendeskToken
  const decodedToken = jwtDecode<{ identity: Identity }>(accessToken)
  const role = { main: tokens.type, authorizationKind: `jwt/${tokens.type}` }
  saveTokens({ accessToken, refreshToken, role, zendeskToken })

  return { accessToken, refreshToken, role, decodedToken, zendeskToken }
}

export const AuthContext = createContext<AuthContext>(null as unknown as AuthContext)

export const AuthProvider = ({ children }: UserContextProviderProps): JSX.Element => {
  const token = getAuthFromLocalStorage('accessToken')
  const decodedToken = token && jwtDecode(token)

  const [user, setUser] = useState<Identity>(decodedToken?.identity)

  const navigate = useNavigate()

  const login = (tokens: Tokens) => {
    const { decodedToken, zendeskToken } = decodeAndSaveTokens(tokens)
    zendeskLogin(zendeskToken)
    setUser(decodedToken.identity)
  }

  const zendeskLogin = (zendeskToken: string) => {
    ZendeskAPI('messenger', 'loginUser', (callback: (token: string) => void) => {
      callback(zendeskToken)
    })
    initZendeskMessengerBehaviour()
  }

  const loginAs = (userAccessToken: AsToken) => {
    const refreshToken = getAuthFromLocalStorage('refreshToken')
    const accessToken = userAccessToken.authenticateAs.token
    const role = { main: 'impersonate', authorizationKind: 'jwt/client' }

    zendeskLogin(userAccessToken.authenticateAs.zendeskToken)
    saveTokens({ accessToken, refreshToken, role, zendeskToken: userAccessToken.authenticateAs.zendeskToken })
  }

  const logoutAs = () => {
    unsetTokenSpecificAuth('impersonate', true)
    setCurrentUser('staff')

    const accessToken = getSpecificUser('staff')?.accessToken
    const decodedToken = jwtDecode<{ identity: Identity }>(accessToken)
    setUser(decodedToken.identity)
    navigate('/staff')
  }

  const setIdentity = (identity: Identity) => {
    setUser(identity)
  }

  const getIdentityFromToken = (tokens: Tokens) => {
    const { decodedToken } = decodeAndSaveTokens(tokens)
    return decodedToken.identity
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        loginAs,
        logoutAs,
        setIdentity,
        getIdentityFromToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
