import classnames from 'classnames'
import React from 'react'

import { Icon, ICONS } from '../Icon/Icon'

import './style.scss'

const Checkbox = ({ checked, indeterminate, onChange, text, disabled, className }) => (
  <div
    className={classnames('checkbox-V2', className, { disabled, checked: !indeterminate && checked })}
    onClick={() => !disabled && onChange?.(!checked)}
  >
    <div
      className={classnames('checkbox-V2__element', {
        disabled,
        checked,
        indeterminate,
        unchecked: !checked && !indeterminate,
      })}
    >
      <Icon icon={indeterminate ? ICONS.Minus : checked && ICONS.Check} />
    </div>
    {text && <span className="checkbox-V2__text">{text}</span>}
  </div>
)

export default Checkbox
