const normalize = (s?: string) => s?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const normalizeObject = (o: Record<string, unknown>) => {
  Object.keys(o).forEach((k) => {
    const value = o[k]
    if (typeof value === 'string') {
      o[k] = normalize(value)
    }
  })
  return o
}

export const removeAccents = <T>(data: T): T => {
  if (data === null || data === undefined) {
    return data
  }

  if (typeof data === 'string') {
    return normalize(data) as unknown as T
  }

  if (Array.isArray(data)) {
    return (data as unknown[]).map(removeAccents) as unknown as T
  }

  if (typeof data === 'object') {
    return normalizeObject(data as unknown as Record<string, unknown>) as unknown as T
  }

  return data
}
