import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as FleexIcon } from '../../../assets/icons/fleex.svg'
import { checkIsCurrentPath } from '../../../constants/utils'
import { Icon, ICONS, ICON_SIZES } from '../../Atoms/Icon/Icon'
import useClickOutside from '../../hooks/useClickOutside'
import SideMenuIcon from '../SideMenu/SideMenuIcon'
import './style.scss'

const getCurrentSections = (nav, path) => {
  for (const { title, to, sections } of nav) {
    if (to && checkIsCurrentPath(to, path, true)) {
      return []
    }
    if (sections) {
      for (const { title: label, to, sections: subsections } of sections) {
        const currentSection = { title, sections }
        if (to && checkIsCurrentPath(to, path, true)) {
          return [currentSection]
        }

        if (subsections) {
          for (const { to } of subsections) {
            if (checkIsCurrentPath(to, path)) {
              return [
                currentSection,
                {
                  title,
                  subtitle: label,
                  sections: subsections,
                },
              ]
            }
          }
        }
      }
    }
  }
  return []
}

const SideMenuMobileSection = ({
  title = '',
  subtitle = '',
  sections = [],
  currentPath,
  onGoback,
  sectionSetter,
  closeNav,
  className,
}) => {
  let hasChildCurrent = false
  const subsections = sections.map(({ id, title: label, to, sections }) => {
    let sideMenuItem
    if (to) {
      const selected = checkIsCurrentPath(to, currentPath, true)
      hasChildCurrent = hasChildCurrent || selected
      sideMenuItem = (
        <Link
          key={id}
          to={to}
          className={classnames('sidemenu-mobile__section__item', { selected })}
          onClick={closeNav}
        >
          <div className="sidemenu-mobile__section__item-title">{label}</div>
        </Link>
      )
    } else if (sections) {
      const hasChildCurrent = sections.some(({ to }) => checkIsCurrentPath(to, currentPath))
      sideMenuItem = (
        <div
          key={id}
          className={classnames('sidemenu-mobile__section__item', { selected: hasChildCurrent })}
          onClick={() =>
            sectionSetter({
              title,
              subtitle: label,
              sections,
            })
          }
        >
          <div>{label}</div>
          <Icon icon={ICONS.ChevronRight} size={ICON_SIZES.LG} className="sidemenu-mobile__section__item-chevron" />
        </div>
      )
    }

    return sideMenuItem
  })

  return (
    <div className={classnames('sidemenu-mobile__section', className)}>
      <div className="sidemenu-mobile__section__header">
        <Icon icon={ICONS.ArrowLeft} size={ICON_SIZES.LG} onClick={onGoback} />
        <span className="sidemenu-mobile__section__header-title">
          {title}
          {subtitle && <span>/</span>}
          {subtitle}
        </span>
        <Icon icon={ICONS.Cross} size={ICON_SIZES.LG} onClick={closeNav} />
      </div>
      <div className="sidemenu-mobile__section__content">{subsections}</div>
    </div>
  )
}

const SideMenuMobile = ({ bar, nav, customLogo }) => {
  const location = useLocation()
  const [openedNav, setOpenedNav] = useState(false)
  const [currentSection, setCurrentSection] = useState()
  const [currentSubsection, setCurrentSubsection] = useState()

  useEffect(() => {
    document.body.style = openedNav ? 'overflow: hidden;' : 'overflow: auto;'
    return () => (document.body.style = 'overflow: auto;')
  }, [openedNav])

  useEffect(() => {
    const [defaultSection, defaultSubsection] = getCurrentSections(nav, location.pathname)
    setCurrentSection(defaultSection)
    setCurrentSubsection(defaultSubsection)
  }, [location.pathname, nav])

  const ref = useRef()
  const [currentFloatingContent, setCurrentFloatingContent] = useState()
  const hideFloatingContent = () => setCurrentFloatingContent()
  useClickOutside(ref, hideFloatingContent)

  return (
    <div className="sidemenu-mobile">
      <div className="sidemenu-mobile__bar">
        <Link to="/" className="sidemenu-mobile__link-logo">
          {customLogo || <FleexIcon />}
        </Link>
        {bar}
        <Icon icon={ICONS.Burger} size={ICON_SIZES.LG} onClick={() => setOpenedNav(true)} />
      </div>
      <div
        className={classnames('sidemenu-mobile__container', {
          visible: openedNav,
          shifted: !currentSubsection && currentSection,
          'shifted-2': currentSubsection,
        })}
      >
        <div className="sidemenu-mobile__nav section-0">
          <div className="sidemenu-mobile__nav__header">
            <div className="sidemenu-mobile__nav__header-filler"></div>
            <Icon icon={ICONS.Cross} size={ICON_SIZES.LG} onClick={() => setOpenedNav(false)} />
          </div>
          <div className="sidemenu-mobile__nav__content">
            {nav.map(({ id, title, icon, customIcon, to, sections, isBottomNav, floatingContent, bottomOffset }) => {
              let selected, sideMenuItem
              if (to) {
                selected = checkIsCurrentPath(to, location.pathname)
                sideMenuItem = (
                  <Link
                    key={id}
                    to={to}
                    className={classnames('sidemenu-mobile__nav__item', { selected, 'bottom-nav': isBottomNav })}
                    onClick={() => setOpenedNav(false)}
                  >
                    <div className="sidemenu-mobile__nav__item-icon">
                      {icon ? <SideMenuIcon icon={icon} filled={selected} /> : customIcon}
                    </div>
                    <div className="sidemenu-mobile__nav__item-title">{title}</div>
                  </Link>
                )
              } else if (sections) {
                selected = sections.some(({ to }) => checkIsCurrentPath(to, location.pathname))
                sideMenuItem = (
                  <div
                    key={id}
                    className={classnames('sidemenu-mobile__nav__item', { selected, 'bottom-nav': isBottomNav })}
                    onClick={() => setCurrentSection({ title, sections })}
                  >
                    <div className={classnames('sidemenu-mobile__nav__item-icon', { selected })}>
                      {icon ? <SideMenuIcon icon={icon} filled={selected} /> : customIcon}
                    </div>
                    <div className="sidemenu-mobile__nav__item-title">{title}</div>
                    <Icon
                      icon={ICONS.ChevronRight}
                      size={ICON_SIZES.LG}
                      className="sidemenu-mobile__nav__item-chevron"
                    />
                  </div>
                )
              } else if (floatingContent) {
                selected = currentFloatingContent?.id === id
                sideMenuItem = (
                  <div
                    key={id}
                    className={classnames('sidemenu-mobile__nav__item', { 'bottom-nav': isBottomNav })}
                    onClick={() => setCurrentFloatingContent({ id, floatingContent, bottomOffset })}
                  >
                    <div className={classnames('sidemenu-mobile__nav__item-icon', { selected })}>
                      {icon ? <SideMenuIcon icon={icon} /> : customIcon}
                    </div>
                    <div className="sidemenu-mobile__nav__item-title">{title}</div>
                  </div>
                )
              } else {
                sideMenuItem = (
                  <div key={id} className={classnames('sidemenu-mobile__nav__item', { 'bottom-nav': isBottomNav })}>
                    <div className={classnames('sidemenu-mobile__nav__item-icon')}>
                      {icon ? <SideMenuIcon icon={icon} /> : customIcon}
                    </div>
                    <div className="sidemenu-mobile__nav__item-title">{title}</div>
                  </div>
                )
              }

              return sideMenuItem
            })}
          </div>
        </div>

        <SideMenuMobileSection
          title={currentSection?.title}
          sections={currentSection?.sections}
          currentPath={location.pathname}
          onGoback={() => setCurrentSection()}
          sectionSetter={setCurrentSubsection}
          closeNav={() => setOpenedNav(false)}
          className="section-1"
        />

        <SideMenuMobileSection
          title={currentSubsection?.title}
          subtitle={currentSubsection?.subtitle}
          sections={currentSubsection?.sections}
          currentPath={location.pathname}
          onGoback={() => setCurrentSubsection()}
          closeNav={() => setOpenedNav(false)}
          className="section-2"
        />
      </div>

      <div
        ref={ref}
        className={classnames('sidemenu-mobile__floating-content', { visible: currentFloatingContent })}
        style={{ bottom: currentFloatingContent?.bottomOffset }}
      >
        {currentFloatingContent && currentFloatingContent.floatingContent(hideFloatingContent)}
      </div>
    </div>
  )
}

export default SideMenuMobile
