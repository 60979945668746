/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { Controller, FieldError, Merge, Path } from 'react-hook-form'
import { match } from 'ts-pattern'

import { UPLOAD_TYPE } from '@fleex/shared'

import Checkbox from '../Checkbox_V2'
import { FilePicker } from '../FilePicker/FilePicker'
import ToggleSwitch from '../ToggleSwitch'
import DateInput from './DateInput'
import {
  CheckboxProps,
  ColorProps,
  ContentProps,
  DateProps,
  DefaultProps,
  HiddenProps,
  FileProps,
  FORM_ELEMENT_SIZE,
  FORM_ELEMENT_TYPE,
  FormElementV2Props,
  ImageProps,
  NumberProps,
  PasswordProps,
  SelectInputProps,
  SelectProps,
  SwitchProps,
  TextAreaProps,
  WysiwygProps,
  RadioProps,
} from './FormElementV2.model'
import { PasswordInput } from './PasswordInput'
import { Select } from './Select'
import SelectInput from './SelectInput'
import WysiwygEditor from './WysiwygEditor'

import './style.scss'

const checkbox = (p: CheckboxProps) => (
  <Controller
    name={p.name}
    control={p.control}
    defaultValue={p.defaultValue}
    render={({ field: { ref: _, onChange, value, ...field } }) => (
      <Checkbox
        indeterminate={undefined}
        disabled={undefined}
        className={undefined}
        checked={value}
        onChange={onChange}
        text={p.label}
        {...field}
      />
    )}
  />
)

const radio = (p: RadioProps) => (
  <input
    className={classNames(p.className, 'form-element-v2__radio', { 'form-element-v2__radio--error': p.error })}
    type="radio"
    id={p.id}
    name={p.name}
    value={p.value}
    {...(p.register ? p.register(p.name) : undefined)}
  />
)

const filePicker = (p: FileProps) => (
  <Controller
    name={p.name}
    control={p.control}
    render={({ field: { ref: _, onChange, value, ...field } }) => (
      <FilePicker label={p.label} onChange={onChange} accept={p.accept ?? UPLOAD_TYPE.Img} {...field} value={value} />
    )}
  />
)

const select = (p: SelectProps) => <Select {...p} />

const color = (p: ColorProps) => <Select {...p} />

const image = (p: ImageProps) => (
  <figure className="form-element-v2__image-wrapper">
    <img className="form-element-v2__image" src={p.value} alt="pic" />
  </figure>
)

const password = (p: PasswordProps) => (
  <PasswordInput name={p.name} register={p.register} placeholder=" " error={p.error} onChange={p.onChange} />
)

const date = (p: DateProps) => (
  <DateInput
    name={p.name}
    control={p.control}
    defaultValue={p.defaultValue}
    error={p.error}
    minDate={p.minDate}
    label={p.label}
  />
)

const content = (p: ContentProps) => <p className={`form-element-v2__content ${p.className ?? ''}`}>{p.value}</p>

const selectInput = (p: SelectInputProps) => (
  <SelectInput
    control={p.control}
    options={p.options}
    defaultValue={p.defaultValue}
    onlyReadMode={p.onlyReadMode}
    register={p.register}
    name={p.name}
    label={p.label}
    hideLabel={p.hideLabel}
    size={p.size}
    onUpdate={p.onChange}
  />
)

const wysiwyg = (p: WysiwygProps, setWysiwygFocus: Dispatch<SetStateAction<boolean>>) => (
  <WysiwygEditor
    name={p.name}
    defaultValue={p.value}
    control={p.control}
    error={p.error}
    onlyReadMode={p.onlyReadMode}
    onFocus={setWysiwygFocus}
  />
)

const textArea = (p: TextAreaProps) => (
  <textarea
    className="form-element-v2__input form-element-v2__input--textarea"
    placeholder=" "
    readOnly={p.onlyReadMode || false}
    defaultValue={p.defaultValue}
    wrap="hard"
    onChange={(event) => p.onChange?.(event.target.value)}
    {...(p.register
      ? p.register(p.name, {
          onChange: (event) => {
            p.onChange?.(event.target.value)
          },
        })
      : undefined)}
  />
)

const switchC = (p: SwitchProps) => (
  <Controller
    name={p.name}
    control={p.control}
    defaultValue={p.defaultValue}
    render={({ field: { ref: _, onChange: controlledOnChange, value, ...field } }) => (
      <ToggleSwitch
        onClick={undefined}
        disabled={undefined}
        {...field}
        label={p.label}
        onChange={(e: string) => {
          controlledOnChange(e)
          p.onChange?.(e)
        }}
        checked={value}
        name={p.name}
      />
    )}
  />
)

const number = (p: NumberProps) => (
  <input
    className={classNames('form-element-v2__input form-element-v2__input--text')}
    type="number"
    step={p.typeNumberStep}
    placeholder=" "
    defaultValue={p.defaultValue}
    min={p.min}
    max={p.max}
    style={p.iconLeft ? { paddingLeft: '3.25rem' } : {}}
  />
)

const hidden = (p: HiddenProps) => <input name={p.name} type="hidden" />

const defaultComponent = (p: DefaultProps) => (
  <input
    className={classNames('form-element-v2__input form-element-v2__input--text', {
      error: p.error,
      'is-disabled': p.isDisabled,
    })}
    placeholder=" "
    readOnly={p.onlyReadMode || false}
    defaultValue={p.defaultValue}
    disabled={p.onlyReadMode}
    onChange={(event) => p.onChange?.(event.target.value)}
    style={p.iconLeft ? { paddingLeft: '3.25rem' } : {}}
    autoComplete={p.disableAutocomplete ? 'off' : ''}
    data-lpignore={p.disableAutocomplete && 'true'}
    data-form-type={p.disableAutocomplete && 'other'}
    {...(p.register ? p.register(p.name, { onChange: p.onChange }) : undefined)}
  />
)

const getComponent = (p: FormElementV2Props, setWysiwygFocus: Dispatch<SetStateAction<boolean>>) =>
  match(p)
    .with({ type: FORM_ELEMENT_TYPE.IMAGE }, image)
    .with({ type: FORM_ELEMENT_TYPE.SELECT }, select)
    .with({ type: FORM_ELEMENT_TYPE.COLOR }, color)
    .with({ type: FORM_ELEMENT_TYPE.FILE }, filePicker)
    .with({ type: FORM_ELEMENT_TYPE.CHECKBOX }, checkbox)
    .with({ type: FORM_ELEMENT_TYPE.PASSWORD }, password)
    .with({ type: FORM_ELEMENT_TYPE.DATE }, date)
    .with({ type: FORM_ELEMENT_TYPE.CONTENT }, content)
    .with({ type: FORM_ELEMENT_TYPE.SELECT_INPUT }, selectInput)
    .with({ type: FORM_ELEMENT_TYPE.WYSIWYG }, (p) => wysiwyg(p, setWysiwygFocus))
    .with({ type: FORM_ELEMENT_TYPE.TEXTAREA }, textArea)
    .with({ type: FORM_ELEMENT_TYPE.SWITCH }, switchC)
    .with({ type: FORM_ELEMENT_TYPE.NUMBER }, number)
    .with({ type: FORM_ELEMENT_TYPE.HIDDEN }, hidden)
    .with({ type: FORM_ELEMENT_TYPE.RADIO }, radio)
    .with({ type: FORM_ELEMENT_TYPE.TEXT }, { type: undefined }, defaultComponent)
    .exhaustive()

const getHint = (hint?: string | null) => hint && <span className="form-element-v2__hint">{hint}</span>
const getError = (error?: Merge<FieldError, (FieldError | undefined)[]>) =>
  error && <span className="form-element-v2__error">{error.message}</span>
const getDynamicHint = (dynamicHint?: (watchSelf: Path<any> | null) => ReactElement, watchSelf?: Path<any> | null) =>
  dynamicHint && <div className="form-element-v2__hint">{dynamicHint(watchSelf || '')}</div>

const FormElement = (props: FormElementV2Props) => {
  const [inputJSX, setInputJSX] = useState<React.ReactNode>()
  const [wysiwygFocus, setWysiwygFocus] = useState(false)

  const watchSelf = props.name && props.watch ? props.watch(props.name) : null

  useEffect(() => {
    setInputJSX(getComponent('type' in props ? props : { type: undefined, ...props }, setWysiwygFocus))
  }, [props])

  const formElementClassNames = classNames('form-element-v2', props.className, {
    'form-element-v2--read-only': ['content', 'image', 'file'].includes(props.type ?? ''),
    'form-element-v2--lg': props.size === FORM_ELEMENT_SIZE.LARGE,
    'form-element-v2--md': props.size === FORM_ELEMENT_SIZE.MEDIUM,
    'form-element-v2--sm': props.size === FORM_ELEMENT_SIZE.SMALL,
  })

  if (props.type === FORM_ELEMENT_TYPE.HIDDEN) {
    return <div className={formElementClassNames}>{inputJSX}</div>
  }

  if (props.type === FORM_ELEMENT_TYPE.CHECKBOX) {
    return (
      <div>
        {inputJSX}
        {getHint(props.hint)}
        {getError(props.error)}
      </div>
    )
  }

  if (props.type === FORM_ELEMENT_TYPE.RADIO) {
    return (
      <div className="form-element-v2__radio-container">
        {inputJSX}
        <label htmlFor={props.id} className="form-element-v2__radio__label">
          {props.label}
        </label>
      </div>
    )
  }

  if (
    props.type === FORM_ELEMENT_TYPE.SELECT ||
    props.type === FORM_ELEMENT_TYPE.COLOR ||
    props.type === FORM_ELEMENT_TYPE.FILE
  ) {
    return (
      <div className={formElementClassNames}>
        {inputJSX}
        {getHint(props.hint)}
        {getDynamicHint(props.dynamicHint, watchSelf)}
        {getError(props.error)}
      </div>
    )
  }

  return (
    <div className={formElementClassNames}>
      {inputJSX}
      {!props.hideLabel &&
        props.type !== FORM_ELEMENT_TYPE.DATE &&
        props.type !== FORM_ELEMENT_TYPE.SELECT_INPUT &&
        props.label && (
          <label
            className={classNames('form-element-v2__label', {
              'form-element-v2__label--wysiwyg': props.type === FORM_ELEMENT_TYPE.WYSIWYG && wysiwygFocus,
            })}
            style={props.iconLeft ? { paddingLeft: '3.25rem' } : {}}
          >
            {props.label}
          </label>
        )}

      {props.children && (
        <span className={`form-element-v2__icon ${props.iconLeft ? 'form-element-v2__icon--left' : ''}`}>
          {props.children}
        </span>
      )}
      {getHint(props.hint)}
      {getError(props.error)}
      {getDynamicHint(props.dynamicHint, watchSelf)}
    </div>
  )
}

FormElement.size = FORM_ELEMENT_SIZE.LARGE

export default FormElement
