import React, { useEffect } from 'react'

import { Icon, ICONS } from '../Icon/Icon'

import './style.scss'

const ruleLength = { regex: /.{12,}/, label: 'Min. 12 caractères' }
const ruleUppercase = { regex: /(?=.*[a-z])(?=.*[A-Z])/, label: 'Au moins 1 majuscule & 1 minuscule' }
const ruleDigit = { regex: /[0-9]/, label: 'Au moins 1 chiffre' }
const ruleSpecialChar = { regex: /[*.@#$%^&(){}[\]:;<>,.!?/~_+=|\\]/, label: 'Au moins 1 caractère spécial' }
const defaultRules = [ruleLength, ruleUppercase, ruleDigit, ruleSpecialChar]

export const DynamicPasswordHint = ({ value, checkPassword, rules = defaultRules }) => {
  useEffect(() => checkPassword && checkPassword(rules.every((rule) => value?.match(rule.regex))))

  const displayRules = rules.map((rule) => (
    <span key={rule.regex} className="password-hint__rule">
      {ruleIcon(value, rule.regex)}
      <span>{rule.label}</span>
    </span>
  ))

  return <div className="password-hint">{displayRules}</div>
}

const ruleIcon = (value, regex) => {
  return value?.match(regex) ? (
    <Icon icon={ICONS.Check} className="password-hint__rule-valid" />
  ) : (
    <Icon icon={ICONS.Circle} />
  )
}
