import { useMutation } from '@apollo/client'
import { useGoogleLogin } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { $, typedGql } from '@fleex/shared'

import { Button } from '../../../components/Atoms/ButtonV2/Button'
import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import { ICONS, ICON_SIZES } from '../../../components/Atoms/Icon/Icon'
import { useAuth } from '../../../components/contexts/auth/AuthProvider'
import WizardFormStepV2 from '../../../components/organisms/WizardFormStepV2'
import { extractErrorMessage } from '../../../constants/errors'

const GOOGLE_AUTHENTICATION_MUTATION = typedGql('mutation')({
  googleAuthentication: [
    { code: $('code', 'String!') },
    { accessToken: true, refreshToken: true, zendeskToken: true, clientUuid: true, isNew: true, type: true },
  ],
})

export const SigninForm = ({ onSubmit, isBackOffice, switchToSSO }) => {
  const { t } = useTranslation()
  const { setIdentity, getIdentityFromToken } = useAuth()
  const navigate = useNavigate()

  const [googleAuthentication] = useMutation(GOOGLE_AUTHENTICATION_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  const login = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'http://localhost:3000',
    onSuccess: async ({ code }) => {
      const { data } = await googleAuthentication({ variables: { code } })
      const identity = getIdentityFromToken(data.googleAuthentication)
      if (!data.googleAuthentication.isNew) {
        setIdentity(identity)
      } else {
        navigate('/google-signup', { state: identity })
      }
    },
  })

  const formSection = () => ({
    submitText: t('login.login'),
    formInputs: [
      {
        name: 'email',
        label: t('login.email'),
        className: 'sign-input',
      },
      {
        name: 'password',
        label: t('login.password'),
        type: FORM_ELEMENT_TYPE.PASSWORD,
        className: 'sign-input',
      },
      {
        name: 'forgot-password',
        label: t('login.forgot_password'),
        type: 'link',
        to: '/reset-password',
        className: 'sign-input signin-form-V2__password_forgotten',
      },
    ],
  })

  return (
    <>
      <div className="signin-form-V2__titles">
        <h1 className="authentication-layout__title">{t('common.welcome')}</h1>
        {!isBackOffice && <h2 className="authentication-layout__subtitle">{t('common.great_to_see_you_again')}</h2>}
      </div>

      <div className="signin-form-V2__content">
        <Button
          kind="secondary"
          className="full-width"
          iconLeft={ICONS.Google}
          iconSize={ICON_SIZES.SM}
          onClick={() => login()}
        >
          {t('login.signin_with_google')}
        </Button>
        <div className="separator-V2">
          <div className="separator-V2__line"></div>
          <p className="separator-V2__content">{t('common.or')}</p>
          <div className="separator-V2__line"></div>
        </div>

        <WizardFormStepV2
          formSection={formSection()}
          formData={{}}
          onSubmit={onSubmit}
          hasPrevious={false}
          showStepper={false}
          currentStep={0}
          totalSteps={1}
        />
      </div>
      <Button iconLeft={ICONS.Lock} kind="empty" className="signin-form-V2__switch" onClick={switchToSSO}>
        {t('login.signin_with_sso')}
      </Button>

      <div className="signin-form-V2__bottom-actions">
        <p className="signin-form-V2__bottom-actions__text">{t('login.no_account')}</p>
        <Button kind="empty" className="signin-form-V2__bottom-actions__sign-up" linkTo={'/signup/collaborator'}>
          {t('login.signup')}
        </Button>
      </div>
    </>
  )
}
