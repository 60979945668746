import React from 'react'

import { Icon, ICONS, ICON_SIZES } from '../../Atoms/Icon/Icon'

const SideMenuIcon = ({ icon, filled }) => {
  const iconName = `${icon}${filled ? 'Filled' : ''}`

  return <Icon icon={ICONS[iconName] ? iconName : icon} size={ICON_SIZES.LG} />
}

export default SideMenuIcon
