import { useMutation } from '@apollo/client'
import qs from 'qs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { $, typedGql } from '@fleex/shared'

import { useAuth } from '../../../components/contexts/auth/AuthProvider'
import { extractErrorMessage } from '../../../constants/errors'
import { authenticateUser } from '../signin'

import './style.scss'

const SSO_AUTHENTICATION_MUTATION = typedGql('mutation')({
  authenticateWithSSO: [
    { code: $('code', 'String!') },
    {
      accessToken: true,
      refreshToken: true,
      zendeskToken: true,
      clientUuid: true,
      type: true,
      error: true,
    },
  ],
})

export const SSOAuthentication = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { login } = useAuth()
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [authenticateWithSSO, { error, data }] = useMutation(SSO_AUTHENTICATION_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  useEffect(() => {
    if (code && typeof code === 'string') {
      authenticateWithSSO({ variables: { code } })
    }
  }, [code, authenticateWithSSO])

  if (!code || error) {
    return <Navigate replace to={`/?type=SSO&error=${error?.message || 'NO_CODE'}`} />
  }
  if (data?.authenticateWithSSO) {
    authenticateUser(data.authenticateWithSSO, login)
    return <Navigate replace to="/market/catalog" />
  }

  return <div className="sso-authentication">{t('login.in_progress')}</div>
}
