/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		typology:{

		},
		typologies:{

		},
		itemManagement:{
			itemFilter:"ItemFilter"
		},
		item:{

		},
		productManagement:{
			productFilter:"ProductFilter"
		},
		product:{

		},
		productMerchant:{

		},
		offers:{
			offerFilter:"OfferFilter"
		},
		offer:{

		},
		merchantCatalog:{

		},
		categoryMerchant:{

		},
		archivedItems:{
			filters:"ArchivedItemsFilters"
		},
		archivedProducts:{
			filters:"ArchivedProductsFilters"
		},
		family:{

		},
		productAdmin:{

		},
		storeProduct:{

		},
		productMarket:{

		},
		catalogProduct:{

		},
		productAttribute:{

		},
		productsStaff:{

		},
		productStaff:{

		},
		offerStaff:{

		},
		offersStaff:{

		},
		staffCatalog:{

		},
		paginatedSubs:{
			subFilter:"SubFilter",
			pagination:"Pagination"
		},
		sub:{

		},
		paginatedFlows:{
			flowFilter:"FlowFilter",
			pagination:"Pagination"
		},
		flows:{
			flowFilter:"FlowFilter"
		},
		sendSupportSatisfactionSurveyEmail:{
			client:"SubClientInput"
		},
		clientOptions:{

		},
		totalEffectiveLocationPriceExclTaxes:{

		},
		subsClient:{

		},
		usage:{

		},
		adminDashboardDataV2:{

		},
		authenticate:{

		},
		authenticateAs:{

		},
		checkResetToken:{

		},
		client:{

		},
		clients:{
			filterSearch:"ClientsFilterSearch"
		},
		clientsStaff:{

		},
		staff:{

		},
		merchant:{

		},
		companyDetails:{

		},
		companies:{
			filters:"CompaniesFilter"
		},
		logos:{

		},
		companyGroup:{

		},
		companyGroupV2:{

		},
		companyGroups:{

		},
		googleConnectionUrl:{
			type:"GoogleUrlType"
		},
		getSSOConnectionUrl:{

		},
		staffDashboard:{
			financialModel:"FinancialModel"
		},
		deliverySatisfactionSurvey:{

		},
		productSatisfactionSurvey:{

		},
		userAdmin:{

		},
		usersStaff:{
			userFilter:"UsersStaffFilter",
			pagination:"Pagination"
		},
		usersStaffOffboarding:{
			userFilter:"UsersStaffFilter",
			pagination:"Pagination"
		},
		unitItem:{

		},
		stockDetailed:{
			stockFilter:"StockFilter",
			stockSelect:"StockSelect"
		},
		stockManagement:{
			stockFilter:"StockFilter",
			stockOrder:"StockOrder",
			stockSelect:"StockSelect"
		},
		requestOrders:{
			requestOrderFilter:"RequestOrderFilter"
		},
		requestOrder:{

		},
		deliveryReceipts:{
			deliveryReceiptFilter:"DeliveryReceiptFilter"
		},
		productReturnRequest:{

		},
		productReturnRequestsStaff:{
			filter:"ProductReturnRequestsStaffFilterInput"
		}
	},
	Mutation:{
		createFamily:{

		},
		createLogistician:{

		},
		upsertSupplier:{

		},
		upsertTypology:{
			formData:"TypologyForm"
		},
		upsertBrand:{
			formData:"BrandForm"
		},
		upsertCategory:{
			formData:"CategoryForm"
		},
		upsertItem:{
			formData:"UpsertItemForm"
		},
		upsertProduct:{
			formData:"UpsertProductForm"
		},
		upsertOffer:{
			formData:"UpsertOfferForm"
		},
		updateOfferEndAt:{

		},
		setProductDisplayMode:{
			displayMode:"DisplayMode"
		},
		setProductDisabled:{

		},
		setProductDisabledBulk:{

		},
		setProductDisplayModeBulk:{
			displayMode:"DisplayMode"
		},
		setCompanyWhitelistProductsBulk:{
			actionType:"WlBlAction"
		},
		setCompanyBlacklistProductsBulk:{
			actionType:"WlBlAction"
		},
		updateProductDisplayPriority:{

		},
		setItemDisabled:{

		},
		archiveItem:{

		},
		replaceItem:{
			formData:"UpsertItemForm"
		},
		archiveProduct:{

		},
		toggleAddToCompanyStore:{

		},
		toggleBulkAddToCompanyStore:{

		},
		toggleAllowedGroup:{

		},
		bulkAddAllowedGroups:{

		},
		bulkRemoveAllowedGroups:{

		},
		upsertProductAttribute:{
			formData:"ProductAttributeForm"
		},
		upsertProductStaff:{
			formData:"ProductStaffForm"
		},
		archiveProductStaff:{

		},
		createOfferStaff:{
			formData:"CreateOfferStaffForm"
		},
		replaceOfferStaff:{
			formData:"CreateOfferStaffForm"
		},
		updateFamiliesOrder:{

		},
		updateTypologiesOrder:{

		},
		updateProductsStaffOrder:{

		},
		upsertProductBasket:{

		},
		cancelFlux:{

		},
		addFlux:{
			fluxKind:"FluxKind"
		},
		addFluxBulk:{
			fluxKind:"FluxKind"
		},
		addFluxEvent:{
			eventKind:"FluxEventKind"
		},
		addFluxEventBulk:{
			eventKind:"FluxEventKind"
		},
		cancelSub:{

		},
		cancelSubBulk:{

		},
		setFlag:{
			flagKind:"SubFlagKind"
		},
		setFlagBulk:{
			flagKind:"SubFlagKind"
		},
		setExpeditionIdBulk:{

		},
		addSubComment:{
			formData:"SubCommentForm"
		},
		addFlowEventBulk:{
			eventKind:"FluxEventKind"
		},
		createSubMerchant:{
			formData:"CreateSubMerchantForm"
		},
		signupCompanyAdmin:{
			formData:"CompanyAdminCreationForm"
		},
		signupCompanyClient:{
			formData:"CompanyClientCreationForm"
		},
		signupCompanyClientAddress:{
			formData:"CompanyClientAddressCreationForm"
		},
		activateCompanyClient:{

		},
		upsertCompanyClient_V2:{
			formData:"UpsertCompanyClientForm_V2"
		},
		setCompanyClientKind:{

		},
		setCompanyClientAccountState:{

		},
		updateCompanyClientPassword:{
			formData:"UpdateCompanyClientPasswordForm"
		},
		updateClientPassword:{

		},
		updateCompanyClientShippingAddress:{
			formData:"updateCompanyClientShippingAddressForm"
		},
		requestCompanyClientPasswordReset:{

		},
		resetPassword:{

		},
		updateCompanyDetails:{
			formData:"CompanyDetailsForm"
		},
		updateCompanyDetailsV2:{
			formData:"CompanyDetailsForm"
		},
		updateCompanyBillingContact:{
			formData:"CompanyBillingContactForm"
		},
		updateCompanyBillingAddress:{
			formData:"UpdateCompanyBillingAddressForm"
		},
		updateCompanyBilling:{
			formData:"UpdateCompanyBillingAddressForm"
		},
		updateCompanyBranding:{
			formData:"UpdateCompanyBranding"
		},
		setCompanySelfSignup:{

		},
		setServiceSubscription:{
			formData:"SetServiceSubscriptionForm"
		},
		setMinimumMRREngaged:{

		},
		createUploadToken:{

		},
		inviteUsers:{

		},
		resendInvitations:{

		},
		deletePendingInvites:{

		},
		deletePendingInvitesV2:{

		},
		setUserOffboarding:{

		},
		cancelUserOffboarding:{

		},
		upsertCompanyGroup:{
			formData:"CompanyGroupForm"
		},
		deleteCompanyGroup:{

		},
		deleteCompanyGroupV2:{

		},
		createCompanyBudget:{
			formData:"CompanyBudgetForm"
		},
		createCompanyGroup:{
			formData:"CompanyGroupForm"
		},
		updateCompanyGroup:{
			formData:"CompanyGroupForm"
		},
		updateCompanyBudget:{
			formData:"CompanyBudgetForm"
		},
		removeCompanyBudget:{

		},
		setCompanyDefaultGroup:{

		},
		addUsersToGroup:{

		},
		addPendingUsersToGroup:{

		},
		associateGoogleAccount:{

		},
		associateGoogleAccount_V2:{

		},
		googleAuthentication:{

		},
		authenticateWithSSO:{

		},
		setSSOConfiguration:{
			ssoConfiguration:"SSOConfigurationForm"
		},
		setSignUpInConfiguration:{
			signUpInConfiguration:"SignUpInConfigurationForm"
		},
		sendDeliverySatisfactionSurvey:{
			answers:"DeliverySatisfactionSurveyAnswer"
		},
		sendProductSatisfactionSurvey:{
			answers:"ProductSatisfactionSurveyAnswer"
		},
		sendActivationEmail:{

		},
		updateCompanyDeliveryCountries:{

		},
		updateCompanyFinancialModel:{
			financialModel:"FinancialModel"
		},
		updateCompanyBillingContactV2:{
			formData:"CompanyBillingContactForm"
		},
		updateCompanyBillingAddressV2:{
			formData:"CompanyBillingAddressForm"
		},
		updateCompanyBillingSepa:{

		},
		createRequestOrder:{
			formData:"RequestOrderForm"
		},
		deleteRequestOrder:{

		},
		closeRequestOrder:{

		},
		updateUnitItemsStatus:{
			itemsReceived:"ItemReceived"
		},
		addUnitItemEvent:{
			eventKind:"UnitItemEventKind"
		},
		updateUnitItemSupplier:{

		},
		addUnitItemComment:{

		},
		addRequestOrderComment:{

		},
		createDeliveryReceipt:{
			itemsReceived:"ItemReceived"
		},
		updateDeliveryReceiptInvoiceNumber:{

		},
		updateUnitItemsPrice:{

		},
		trackProductClick:{

		},
		createProductReturnRequest:{
			formData:"CreateProductReturnRequestForm"
		},
		updateProductReturnStatus:{
			status:"ProductReturnStatus"
		},
		updateProductReturnRequestStatuses:{
			status:"ProductReturnStatus"
		},
		updateProductReturnUserInformations:{
			formData:"UpdateProductReturnRequestForm"
		}
	},
	KeyValueInput:{

	},
	DisplayMode: "enum" as const,
	WlBlAction: "enum" as const,
	DisabledProductReason: "enum" as const,
	SupplierMode: "enum" as const,
	ItemStatus: "enum" as const,
	ProductAttributeType: "enum" as const,
	JSON: `scalar.JSON` as const,
	ItemFilter:{
		status:"ItemStatus"
	},
	UpsertItemForm:{

	},
	TypologyAttributeForm:{

	},
	TypologyForm:{
		attributes:"TypologyAttributeForm"
	},
	ProductAttributeValueForm:{

	},
	ProductAttributeForm:{
		type:"ProductAttributeType",
		values:"ProductAttributeValueForm"
	},
	BrandForm:{
		description:"JSON"
	},
	CategoryForm:{

	},
	UpsertProductForm:{
		description:"JSON",
		information:"JSON",
		displayMode:"DisplayMode"
	},
	ProductStaffForm:{
		description:"JSON",
		specs:"SpecsForm"
	},
	SpecsForm:{
		type:"ProductAttributeType"
	},
	ProductFilter:{

	},
	UpsertOfferForm:{

	},
	OfferFilter:{

	},
	GroupDisabledProductInput:{

	},
	ArchivedItemsFilters:{

	},
	ArchivedProductsFilters:{

	},
	CreateOfferStaffCountryForm:{

	},
	CreateOfferStaffForm:{
		countries:"CreateOfferStaffCountryForm"
	},
	SubFlagKind: "enum" as const,
	FluxKind: "enum" as const,
	FluxEventKind: "enum" as const,
	SubFilter:{
		orderDates:"OrderDates"
	},
	OrderDates:{

	},
	Pagination:{

	},
	SubCommentForm:{

	},
	FlowFilter:{
		fluxKind:"FluxKind",
		fluxEventKind:"FluxEventKind"
	},
	SubClientInput:{

	},
	CreateSubMerchantForm:{

	},
	ClientStatusV2: "enum" as const,
	ClientStatus: "enum" as const,
	OffboardingStatus: "enum" as const,
	GoogleUrlType: "enum" as const,
	FinancialModel: "enum" as const,
	CompaniesFilter:{
		financialModel:"FinancialModel"
	},
	ClientsFilterSearch:{
		offboarding:"OffboardingStatus"
	},
	SetServiceSubscriptionForm:{

	},
	CompanyAdminCreationForm:{

	},
	CompanyClientCreationForm:{

	},
	CompanyClientAddressCreationForm:{

	},
	CompanyDetailsForm:{

	},
	CompanyBillingContactForm:{

	},
	UpdateCompanyBranding:{

	},
	UpdateCompanyBillingAddressForm:{

	},
	CompanyBillingAddressForm:{

	},
	UpsertCompanyClientForm_V2:{

	},
	UpdateCompanyClientPasswordForm:{

	},
	updateCompanyClientShippingAddressForm:{

	},
	CompanyDetailsStaffForm:{

	},
	CompanyBudgetForm:{

	},
	SSOConfigurationForm:{

	},
	SignUpInConfigurationForm:{

	},
	CompanyGroupForm:{

	},
	DeliverySatisfactionSurveyAnswer:{

	},
	ProductSatisfactionSurveyAnswer:{

	},
	UsersStaffFilter:{
		sort:"SortFilter"
	},
	SortFilter:{

	},
	UnitItemEventKind: "enum" as const,
	SortKind: "enum" as const,
	RequestOrderKind: "enum" as const,
	RequestOrderForm:{
		supplierMode:"SupplierMode",
		items:"UnitItemForm"
	},
	ItemReceived:{

	},
	UnitItemForm:{

	},
	DeliveryReceiptFilter:{

	},
	RequestOrderFilter:{
		status:"RequestOrderKind"
	},
	StockFilter:{
		status:"UnitItemEventKind"
	},
	StockOrder:{
		requested:"SortKind",
		inStock:"SortKind",
		allocated:"SortKind",
		toBeDelivered:"SortKind",
		rented:"SortKind",
		returnRequested:"SortKind",
		deliveryBack:"SortKind",
		quarantined:"SortKind",
		exit:"SortKind"
	},
	StockSelect:{
		supplierMode:"SupplierMode"
	},
	ProductReturnReason: "enum" as const,
	ProductReturnSubReason: "enum" as const,
	ProductReturnStatus: "enum" as const,
	ProductReturnState: "enum" as const,
	CreateProductReturnRequestForm:{
		productReturnsInput:"ProductReturnInput"
	},
	ProductReturnInput:{
		reason:"ProductReturnReason",
		subReason:"ProductReturnSubReason"
	},
	ProductReturnRequestsStaffFilterInput:{

	},
	UpdateProductReturnRequestForm:{
		productReturns:"UpdateProductReturnRequestFormProductReturn"
	},
	UpdateProductReturnRequestFormProductReturn:{
		productState:"ProductReturnState"
	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		ping:"String",
		families:"Family",
		typology:"Typology",
		typologies:"Typology",
		brands:"Brand",
		logisticians:"Logistician",
		suppliers:"Supplier",
		items:"Item",
		itemManagement:"ItemManagement",
		itemOptions:"ItemOptionsResponse",
		item:"Item",
		categories:"Category",
		productOptions:"OptionElement",
		productManagement:"ProductManagement",
		product:"Product",
		productMerchant:"Product",
		offers:"Offer",
		offer:"Offer",
		merchantCatalog:"MerchantCatalogElement",
		companyList:"ListItem",
		categoryMerchant:"Category",
		archivedItems:"ArchivedItems",
		archivedProducts:"ArchivedProducts",
		family:"Family",
		productAdmin:"Product",
		storeProduct:"Product",
		companystore:"Product",
		companyStoreRentalPlatform:"ProductCompanyStoreRentalPlatform",
		fleexstore:"Family",
		productMarket:"Product",
		catalogProduct:"Product",
		catalog:"Family",
		productAttributes:"ProductAttribute",
		productAttribute:"ProductAttribute",
		productsStaff:"ProductStaff",
		productStaff:"Product",
		offerStaff:"Offer",
		offersStaff:"OfferStaffResponse",
		offerStaffCreationData:"OfferCreationResponse",
		staffCatalog:"StaffCatalogElement",
		basket:"Basket",
		subsMarket_V2:"Sub",
		subsAdmin:"Sub",
		subsAdminGlobal:"SubAdminGlobal",
		paginatedSubs:"PaginatedSubs",
		sub:"Sub",
		paginatedFlows:"PaginatedFlows",
		flows:"Flux",
		sendSupportSatisfactionSurveyEmail:"Boolean",
		subCreationData:"SubCreationData",
		clientOptions:"OptionElement",
		totalEffectiveLocationPriceExclTaxes:"Float",
		subsClient:"Sub",
		usage:"Usage",
		sellsyInvoices:"SellsyInvoiceResponse",
		adminDashboardDataV2:"AdminDashboardDataV2",
		authenticate:"AuthResponse",
		authenticateAs:"AuthAsResponse",
		checkResetToken:"Client",
		client:"Client",
		clients:"Client",
		clientsStaff:"ClientsStaffData",
		staff:"StaffUser",
		merchant:"MerchantUser",
		companySettings:"CompanySettings",
		companyDetails:"Company",
		companies:"Company",
		companiesStaff:"Company",
		companyOnboardingV2:"Onboarding",
		logos:"LogoResponse",
		companyGroup:"CompanyGroup",
		companyGroupV2:"CompanyGroup",
		companyGroups:"CompanyGroup",
		companyGroupsV2:"CompanyGroupsData",
		companyGroupOptions:"GroupOptionElement",
		budgetsAdmin:"CompanyBudget",
		googleConnectionUrl:"String",
		getSSOConnectionUrl:"String",
		staffDashboard:"StaffDashboardData",
		deliverySatisfactionSurvey:"DeliverySatisfactionSurvey",
		deliverySatisfactionSurveys:"DeliverySatisfactionSurveyRow",
		productSatisfactionSurvey:"ProductSatisfactionSurvey",
		productSatisfactionSurveys:"ProductSatisfactionSurveyRow",
		usersAdmin:"UsersAdminData",
		userAdmin:"Client",
		usersStaff:"UsersStaffResponse",
		usersStaffOffboarding:"UsersStaffResponse",
		marketDashboard:"Client",
		unitItem:"UnitItem",
		stockDetailed:"StockDetailed",
		stockManagement:"StockManagement",
		quantityUnitItems:"QuantityUnitItems",
		requestOrders:"RequestOrders",
		requestOrder:"RequestOrderResponse",
		deliveryReceipts:"DeliveryReceiptResponse",
		productReturnRequest:"ProductReturnRequest",
		productReturnRequests:"ProductReturnRequest",
		productReturnRequestsStaff:"ProductReturnRequest"
	},
	Mutation:{
		exampleMutation:"String",
		createFamily:"Family",
		createLogistician:"Logistician",
		upsertSupplier:"Supplier",
		upsertTypology:"Typology",
		upsertBrand:"Brand",
		upsertCategory:"Category",
		upsertItem:"Item",
		upsertProduct:"Product",
		upsertOffer:"Offer",
		updateOfferEndAt:"Offer",
		setProductDisplayMode:"Product",
		setProductDisabled:"ProductDisabledResult",
		setProductDisabledBulk:"ProductDisabledResult",
		setProductDisplayModeBulk:"ProductListElement",
		setCompanyWhitelistProductsBulk:"MerchantCatalogElement",
		setCompanyBlacklistProductsBulk:"MerchantCatalogElement",
		updateProductDisplayPriority:"Category",
		setItemDisabled:"Item",
		archiveItem:"Item",
		replaceItem:"Item",
		archiveProduct:"Product",
		toggleAddToCompanyStore:"Product",
		toggleBulkAddToCompanyStore:"Product",
		toggleAllowedGroup:"ToggleAllowedGroupResult",
		bulkAddAllowedGroups:"Product",
		bulkRemoveAllowedGroups:"Product",
		processUnseenProducts:"Product",
		upsertProductAttribute:"ProductAttribute",
		upsertProductStaff:"Product",
		archiveProductStaff:"Product",
		createOfferStaff:"Offer",
		replaceOfferStaff:"ReplaceOfferStaffResponse",
		updateFamiliesOrder:"Family",
		updateTypologiesOrder:"Typology",
		updateProductsStaffOrder:"ProductStaff",
		upsertProductBasket:"BasketItem",
		confirmBasket_V2:"Basket",
		cancelFlux:"Sub",
		addFlux:"Sub",
		addFluxBulk:"Sub",
		addFluxEvent:"Sub",
		addFluxEventBulk:"Sub",
		cancelSub:"Sub",
		cancelSubBulk:"Sub",
		setFlag:"Sub",
		setFlagBulk:"Sub",
		setExpeditionIdBulk:"Sub",
		addSubComment:"SubComment",
		addFlowEventBulk:"Flux",
		createSubMerchant:"Sub",
		signupCompanyAdmin:"SignupResponse",
		signupCompanyClient:"SignupResponse",
		signupCompanyClientAddress:"Boolean",
		activateCompanyClient:"Boolean",
		upsertCompanyClient_V2:"Client",
		setCompanyClientKind:"Client",
		setCompanyClientAccountState:"Client",
		updateCompanyClientPassword:"Client",
		updateClientPassword:"Client",
		updateCompanyClientShippingAddress:"Client",
		requestCompanyClientPasswordReset:"Boolean",
		resetPassword:"Boolean",
		updateCompanyDetails:"Company",
		updateCompanyDetailsV2:"Company",
		updateCompanyBillingContact:"Company",
		updateCompanyBillingAddress:"Company",
		updateCompanyBilling:"Company",
		updateCompanyBranding:"Company",
		setCompanySelfSignup:"Company",
		setServiceSubscription:"Company",
		setMinimumMRREngaged:"Company",
		createUploadToken:"String",
		inviteUsers:"InvitedUser",
		resendInvitations:"InvitedUser",
		deletePendingInvites:"InvitedUser",
		deletePendingInvitesV2:"InvitedUser",
		setUserOffboarding:"SetUserOffboardingResponse",
		cancelUserOffboarding:"Client",
		upsertCompanyGroup:"CompanyGroup",
		deleteCompanyGroup:"CompanyGroup",
		deleteCompanyGroupV2:"Product",
		createCompanyBudget:"CompanyBudget",
		createCompanyGroup:"CompanyGroup",
		updateCompanyGroup:"CompanyGroup",
		updateCompanyBudget:"CompanyBudget",
		removeCompanyBudget:"CompanyBudget",
		setCompanyDefaultGroup:"CompanyGroup",
		addUsersToGroup:"AddUsersToGroupResult",
		addPendingUsersToGroup:"Client",
		associateGoogleAccount:"Client",
		associateGoogleAccount_V2:"Client",
		dissociateGoogleAccount:"Client",
		googleAuthentication:"AuthResponse",
		authenticateWithSSO:"AuthResponse",
		setSSOConfiguration:"Company",
		setSignUpInConfiguration:"Company",
		sendDeliverySatisfactionSurvey:"Boolean",
		sendProductSatisfactionSurvey:"Boolean",
		sendActivationEmail:"Boolean",
		updateCompanyDeliveryCountries:"Company",
		updateCompanyFinancialModel:"Company",
		updateCompanyBillingContactV2:"Company",
		updateCompanyBillingAddressV2:"Company",
		updateCompanyBillingSepa:"Company",
		createRequestOrder:"RequestOrder",
		deleteRequestOrder:"RequestOrder",
		closeRequestOrder:"RequestOrder",
		updateUnitItemsStatus:"RequestOrder",
		addUnitItemEvent:"UnitItem",
		updateUnitItemSupplier:"UnitItem",
		addUnitItemComment:"UnitItemComment",
		addRequestOrderComment:"RequestOrderComment",
		createDeliveryReceipt:"RequestOrder",
		updateDeliveryReceiptInvoiceNumber:"DeliveryReceipt",
		updateUnitItemsPrice:"RequestOrder",
		trackProductClick:"Boolean",
		createProductReturnRequest:"ProductReturnRequest",
		updateProductReturnStatus:"ProductReturn",
		updateProductReturnRequestStatuses:"ProductReturnRequest",
		updateProductReturnUserInformations:"UpdateProductReturnRequestResponse"
	},
	KeyValue:{
		name:"String",
		value:"String"
	},
	JSON: `scalar.JSON` as const,
	ProductCompanyStoreRentalPlatform:{
		uuid:"ID",
		name:"String",
		pictureUrl:"String",
		brand:"Brand",
		offer:"OfferCompanyStoreRentalPlatform",
		displayPriority:"Int",
		allowedGroups:"GroupCompanyStoreRentalPlatform",
		items:"ItemCompanyStoreRentalPlatform"
	},
	ItemCompanyStoreRentalPlatform:{
		uuid:"ID",
		typology:"TypologyCompanyStoreRentalPlatform"
	},
	TypologyCompanyStoreRentalPlatform:{
		uuid:"ID",
		nameFR:"String",
		family:"FamilyCompanyStoreRentalPlatform"
	},
	OfferCompanyStoreRentalPlatform:{
		uuid:"ID",
		price:"Int"
	},
	FamilyCompanyStoreRentalPlatform:{
		uuid:"ID",
		name:"String"
	},
	GroupCompanyStoreRentalPlatform:{
		uuid:"ID",
		name:"String",
		color:"String"
	},
	ProductDisabledResult:{
		uuid:"String",
		productUuid:"ID",
		isDisabled:"Boolean"
	},
	Family:{
		uuid:"ID",
		name:"String",
		typologies:"Typology",
		order:"Int"
	},
	Brand:{
		uuid:"ID",
		name:"String",
		description:"String"
	},
	Logistician:{
		uuid:"ID",
		name:"String"
	},
	Supplier:{
		uuid:"ID",
		name:"String"
	},
	Typology:{
		uuid:"ID",
		nameEN:"String",
		nameFR:"String",
		transportationCost:"Float",
		family:"Family",
		products:"Product",
		storeCardOrientation:"String",
		numberOfAssociatedAttributes:"Int",
		productAttributes:"ProductAttribute",
		order:"Int"
	},
	ProductAttributeValue:{
		uuid:"ID",
		value:"String"
	},
	ProductAttribute:{
		uuid:"ID",
		name:"String",
		description:"String",
		type:"ProductAttributeType",
		numberOfAssociatedProducts:"Int",
		values:"ProductAttributeValue"
	},
	Item:{
		uuid:"ID",
		model:"String",
		ean:"String",
		variant:"String",
		sku:"String",
		heightCm:"String",
		lengthCm:"String",
		depthCm:"String",
		weightG:"String",
		disabledAt:"String",
		typology:"Typology",
		brand:"Brand",
		defaultOffer:"Offer",
		offers:"Offer",
		archivedAt:"String",
		archivingReason:"String",
		pictureUrl:"String",
		name:"String",
		logistician:"Logistician"
	},
	ItemManagementFilter:{
		typologies:"OptionElement",
		logisticians:"OptionElement",
		families:"OptionElement",
		brands:"OptionElement",
		models:"OptionElement",
		names:"OptionElement",
		skus:"OptionElement",
		prices:"OptionElement",
		status:"OptionElement"
	},
	ItemManagement:{
		items:"Item",
		filterOptions:"ItemManagementFilter"
	},
	Category:{
		uuid:"ID",
		nameEN:"String",
		nameFR:"String",
		name:"String",
		pictureMiniUrl:"String",
		pictureWideUrl:"String",
		whiteTheme:"Boolean",
		products:"Product"
	},
	ProductPicture:{
		uuid:"ID",
		pictureUrl:"String",
		rank:"Int"
	},
	DisabledGroup:{
		uuid:"ID",
		name:"String",
		disabledReason:"DisabledProductReason"
	},
	Product:{
		uuid:"ID",
		categories:"Category",
		items:"Item",
		item:"Item",
		name:"String",
		brand:"Brand",
		description:"String",
		information:"String",
		pictureUrl:"String",
		pictures:"ProductPicture",
		heightCm:"String",
		lengthCm:"String",
		depthCm:"String",
		monitor:"String",
		connection:"String",
		displayMode:"DisplayMode",
		isDisabled:"Boolean",
		disabledGroups:"DisabledGroup",
		offer:"Offer",
		displayPriority:"Int",
		archivedAt:"String",
		isANewProduct:"Boolean",
		seenByAdmin:"Boolean",
		isInCompanyStore:"Boolean",
		allowedGroups:"CompanyGroup",
		merchantCompany:"MerchantCompany",
		attributes:"ProductProductAttribute"
	},
	ProductProductAttribute:{
		uuid:"ID",
		stringValue:"String",
		booleanValue:"Boolean",
		productAttribute:"ProductAttribute"
	},
	ProductStaff:{
		uuid:"ID",
		productName:"String",
		productPictureUrl:"String",
		brandName:"String",
		familyName:"String",
		typologyNameEN:"String",
		defaultPrice:"Float",
		offersCount:"Int",
		status:"Boolean",
		order:"Int"
	},
	ProductManagementFilter:{
		categories:"OptionElement",
		names:"OptionElement",
		numberOfItems:"OptionElement",
		defaultPrices:"OptionElement"
	},
	ProductManagement:{
		products:"ProductListElement",
		filterOptions:"ProductManagementFilter"
	},
	ProductListElement:{
		uuid:"ID",
		categories:"Category",
		name:"String",
		numberOfItems:"Int",
		defaultDisplay:"DisplayMode",
		defaultPrice:"Float",
		status:"Boolean"
	},
	OfferStaffCountry:{
		uuid:"ID",
		country:"String",
		additionalShippingFees:"Float",
		deliveryDelay:"Int"
	},
	Offer:{
		uuid:"ID",
		deliveryDelay:"Int",
		engagement:"Int",
		priorNotice:"Int",
		price:"Float",
		startAt:"String",
		endAt:"String",
		company:"Company",
		item:"Item",
		merchantCompany:"MerchantCompany",
		countries:"OfferStaffCountry"
	},
	ItemOptionsResponse:{
		value:"ID",
		label:"String",
		defaultOffer:"Offer"
	},
	MerchantCatalogElement:{
		itemUuid:"ID",
		productUuid:"ID",
		categories:"Category",
		productName:"String",
		defaultDisplay:"DisplayMode",
		companyDisplay:"Boolean",
		companyEnabled:"Boolean",
		defaultPrice:"Float",
		companyPrice:"Float",
		status:"Boolean"
	},
	StaffCatalogElement:{
		itemUuid:"ID",
		productUuid:"ID",
		productName:"String",
		brandName:"String",
		pictureUrl:"String",
		typologyName:"String",
		hasGroupAffected:"Boolean",
		hasSpecificOffer:"Boolean",
		price:"Float"
	},
	ListItem:{
		uuid:"ID",
		name:"String"
	},
	ArchivedItemsFilterOptions:{
		names:"OptionElement",
		skus:"OptionElement",
		archivingDates:"OptionElement"
	},
	ArchivedItems:{
		items:"Item",
		filterOptions:"ArchivedItemsFilterOptions"
	},
	ArchivedProductsFilterOptions:{
		names:"OptionElement",
		archivingDates:"OptionElement"
	},
	ArchivedProducts:{
		products:"Product",
		filterOptions:"ArchivedProductsFilterOptions"
	},
	ToggleAllowedGroupResult:{
		product:"Product",
		groupName:"String",
		allowed:"Boolean"
	},
	OfferStaffFilters:{
		companies:"OptionElement",
		merchants:"OptionElement",
		typologies:"OptionElement"
	},
	OfferStaffResponse:{
		offers:"Offer",
		filterOptions:"OfferStaffFilters"
	},
	OfferCreationResponse:{
		items:"OptionElement",
		companies:"OptionElement",
		merchants:"OptionElement"
	},
	ReplaceOfferStaffResponse:{
		oldOffer:"Offer",
		newOffer:"Offer"
	},
	BasketItem:{
		uuid:"ID",
		quantity:"Int",
		product:"Product",
		isAvailable:"Boolean"
	},
	BasketSummary:{
		uuid:"ID",
		items:"BasketItem"
	},
	BudgetSummary:{
		uuid:"ID",
		basketCount:"Int",
		totalBasket:"Float",
		availableBudget:"Float",
		group:"CompanyGroup"
	},
	Basket:{
		basket:"BasketSummary",
		budgetSummary:"BudgetSummary"
	},
	Flux:{
		uuid:"ID",
		kind:"FluxKind",
		canceledAt:"String",
		createdAt:"String",
		updatedAt:"String",
		events:"FluxEvent",
		lastEvent:"FluxEvent",
		sub:"Sub",
		unitItem:"UnitItem"
	},
	FluxEvent:{
		uuid:"ID",
		kind:"FluxEventKind",
		createdAt:"String",
		updatedAt:"String"
	},
	SubState:{
		lastFlux:"Flux",
		lastEvent:"FluxEvent",
		shortStatus:"String"
	},
	Sub:{
		uuid:"String",
		createdAt:"String",
		updatedAt:"String",
		startedAt:"String",
		canceledAt:"String",
		firstDeliveredAt:"String",
		lastDeliveredAt:"String",
		terminatedAt:"String",
		commandId:"String",
		subscriptionId:"String",
		expeditionId:"String",
		client:"Client",
		item:"Item",
		product:"Product",
		address:"Address",
		offer:"Offer",
		comments:"SubComment",
		fluxes:"Flux",
		deliveryFlux:"Flux",
		retrievalFlux:"Flux",
		lastFlux:"Flux",
		state:"SubState",
		supportFlagEnabledAt:"String",
		standbyFlagEnabledAt:"String"
	},
	PaginationInfos:{
		count:"Int",
		offset:"Int",
		cursor:"String",
		hasMore:"Boolean",
		initial:"Boolean"
	},
	OptionElement:{
		value:"String",
		label:"String"
	},
	GroupedOptionElement:{
		options:"OptionElement",
		label:"String"
	},
	PaginatedSubsFilters:{
		commandIds:"OptionElement",
		expeditionIds:"OptionElement",
		subIds:"OptionElement",
		status:"GroupedOptionElement",
		companies:"OptionElement",
		logisticians:"OptionElement",
		customers:"OptionElement",
		items:"OptionElement",
		deliveryDates:"OptionElement"
	},
	PaginatedSubs:{
		pagination:"PaginationInfos",
		subs:"Sub",
		filterOptions:"PaginatedSubsFilters"
	},
	SubAdminGlobal:{
		uuid:"ID",
		productName:"String",
		typologyName:"String",
		brandName:"String",
		pictureUrl:"String",
		price:"Float",
		productAllocated:"Int"
	},
	SubComment:{
		uuid:"ID",
		textContent:"String",
		shownToClient:"Boolean",
		author:"MerchantUser",
		createdAt:"String",
		updatedAt:"String"
	},
	PaginatedFlowsFilters:{
		commandIds:"OptionElement",
		subIds:"OptionElement",
		fluxKind:"FluxKindOptionElement",
		fluxEventKind:"OptionElement",
		customers:"OptionElement",
		items:"OptionElement"
	},
	FluxKindOptionElement:{
		value:"FluxKind",
		label:"String"
	},
	FluxEventKindOptionElement:{
		value:"FluxEventKind",
		label:"String"
	},
	PaginatedFlows:{
		pagination:"PaginationInfos",
		flows:"Flux",
		filterOptions:"PaginatedFlowsFilters"
	},
	Usage:{
		totalSubs:"Int",
		activeClients:"Int"
	},
	SubCreationData:{
		companyOptions:"OptionElement",
		productOptions:"OptionElement"
	},
	SellsyInvoiceResponse:{
		invoices:"SellsyInvoice"
	},
	SellsyInvoice:{
		link:"String",
		totalIncludingVAT:"Float",
		totalExcludingVAT:"Float",
		status:"String",
		number:"String",
		date:"String"
	},
	NamedValue:{
		name:"String",
		value:"Int"
	},
	AdminDashboardItem:{
		name:"String",
		brand:"String",
		pictureUrl:"String",
		quantity:"Int",
		productUuid:"ID",
		categoryUuid:"ID"
	},
	AdminDashboardDataV2:{
		pendingInvites:"Int",
		signedUpUsers:"Int",
		activeUsers:"Int",
		offboardingUsers:"Int",
		disabledUsers:"Int",
		subsStatus:"NamedValue",
		families:"NamedValue",
		familiesOptions:"OptionElement",
		topItems:"AdminDashboardItem"
	},
	CompanyGroupsData:{
		groups:"CompanyGroup",
		financialModel:"FinancialModel"
	},
	UsersAdminFiltersOptions:{
		groups:"OptionElement",
		countries:"OptionElement"
	},
	ClientsStaffFiltersOptions:{
		countries:"OptionElement"
	},
	ClientsStaffData:{
		clients:"Client",
		filterOptions:"ClientsStaffFiltersOptions"
	},
	UsersAdminData:{
		clients:"Client",
		filterOptions:"UsersAdminFiltersOptions",
		financialModel:"FinancialModel",
		companyUuid:"ID"
	},
	SignupResponse:{
		accountActivationToken:"String",
		email:"String",
		logoToken:"String",
		linkedToGoogle:"Boolean",
		authResponse:"AuthResponse",
		clientUuid:"ID"
	},
	AuthAsResponse:{
		token:"String",
		zendeskToken:"String"
	},
	AuthResponse:{
		isNew:"Boolean",
		refreshToken:"String",
		accessToken:"String",
		error:"String",
		type:"String",
		clientUuid:"String",
		zendeskToken:"String"
	},
	Onboarding:{
		isDone:"Boolean",
		onboardingSteps:"OnboardingStep",
		companyName:"String"
	},
	OnboardingStep:{
		value:"String",
		isDone:"Boolean"
	},
	LocationCostCapResponse:{
		company:"Company",
		budgetSummary:"BudgetSummary",
		basketSummary:"BasketSummary"
	},
	Address:{
		uuid:"ID",
		createdAt:"String",
		updatedAt:"String",
		recipientName:"String",
		recipientPhone:"String",
		address:"String",
		zip:"String",
		city:"String",
		country:"String",
		comment:"String"
	},
	LogoResponse:{
		userLogo:"String",
		companyLogo:"String"
	},
	CompanyBudget:{
		uuid:"ID",
		locationCostCap:"Float",
		serviceSubscriptionCost:"Float"
	},
	Company:{
		uuid:"ID",
		name:"String",
		legalCompanyName:"String",
		registrationNumber:"String",
		estimatedWorkerCount:"String",
		sepa:"String",
		email:"String",
		phone:"String",
		logoUrl:"String",
		billingContactFirstName:"String",
		billingContactLastName:"String",
		billingContactEmail:"String",
		billingContactPhone:"String",
		creator:"Client",
		clients:"Client",
		billingAddress:"Address",
		updatedAt:"String",
		createdAt:"String",
		disabledProducts:"CompanyDisabledProduct",
		companySettings:"CompanySettings",
		domainNames:"CompanyDomainName",
		budgets:"CompanyBudget",
		groups:"CompanyGroup",
		clientsCount:"Int",
		pendingInvitesCount:"Int",
		inactiveUsersCount:"Int",
		activeUsersCount:"Int",
		formerActiveUsersCount:"Int",
		deactivatedUsersCount:"Int",
		estimatedWorkersCount:"String",
		deliveryCountries:"String"
	},
	CompanySettings:{
		financialModel:"FinancialModel",
		shouldHidePrices:"Boolean",
		allowSelfSignup:"Boolean",
		primaryColor:"String",
		mrrEngaged:"Float",
		workosOrganizationId:"String",
		workosDirectoryId:"String",
		ssoLoginOnly:"Boolean",
		disableSSOInvitation:"Boolean",
		lockAllowSelfSignup:"Boolean",
		gConnectOnly:"Boolean"
	},
	CompanyDomainName:{
		uuid:"ID",
		label:"String"
	},
	Client:{
		uuid:"ID",
		updatedAt:"String",
		createdAt:"String",
		createdBy:"Client",
		lastLoggedIn:"String",
		passwordUpdatedAt:"String",
		company:"Company",
		shippingAddress:"Address",
		companyRole:"String",
		accountActivatedAt:"String",
		accountDisabledAt:"String",
		accountOffboardedAt:"String",
		offboardedRequestedAt:"String",
		status:"ClientStatus",
		statusV2:"ClientStatusV2",
		offboardingStatus:"OffboardingStatus",
		activeSubsCount:"Int",
		firstName:"String",
		lastName:"String",
		email:"String",
		googleEmail:"String",
		personalEmail:"String",
		phone:"String",
		job:"String",
		gender:"String",
		pictureUrl:"String",
		kind:"String",
		subs:"Sub",
		companyGroup:"CompanyGroup",
		offboardedBy:"Client"
	},
	CompanyDisabledProduct:{
		uuid:"String",
		productId:"ID"
	},
	StaffUser:{
		uuid:"ID",
		updatedAt:"String",
		createdAt:"String",
		passwordUpdatedAt:"String",
		firstName:"String",
		lastName:"String",
		pictureUrl:"String",
		phone:"String",
		email:"String",
		accountActivatedAt:"String",
		accountActivationToken:"String",
		accountDisabledAt:"String",
		gender:"String",
		kind:"String",
		lastLoggedIn:"String"
	},
	MerchantUser:{
		uuid:"ID",
		updatedAt:"String",
		createdAt:"String",
		passwordUpdatedAt:"String",
		firstName:"String",
		lastName:"String",
		pictureUrl:"String",
		phone:"String",
		email:"String",
		accountActivatedAt:"String",
		accountActivationToken:"String",
		accountDisabledAt:"String",
		job:"String",
		gender:"String",
		kind:"String",
		lastLoggedIn:"String",
		company:"MerchantCompany"
	},
	MerchantCompany:{
		uuid:"ID",
		updatedAt:"String",
		createdAt:"String",
		name:"String",
		logoUrl:"String",
		registrationNumber:"String",
		intraCommunityVatNumber:"String",
		email:"String",
		phone:"String",
		description:"String",
		contactFirstName:"String",
		contactLastName:"String",
		contactEmail:"String",
		contactPhone:"String",
		address:"Address"
	},
	InvitedUser:{
		uuid:"ID"
	},
	GroupOptionElement:{
		value:"String",
		label:"String",
		color:"String",
		budget:"CompanyBudget"
	},
	CompanyGroup:{
		uuid:"ID",
		name:"String",
		description:"String",
		budget:"CompanyBudget",
		usersCount:"Int",
		activeUsersCount:"Int",
		inactiveUsersCount:"Int",
		productsCount:"Int",
		allowedProductsCount:"Int",
		averageConsumption:"Float",
		users:"Client",
		products:"Product",
		countries:"String",
		creator:"Client",
		isDefault:"Boolean",
		color:"String",
		monthly:"Float",
		workosId:"String"
	},
	AddUsersToGroupResult:{
		clientsInBudget:"Client",
		clientsOutOfBudget:"Client"
	},
	StaffDashboardCompany:{
		uuid:"ID",
		name:"String",
		activeUsers:"Int",
		budget:"Float",
		totalPrices:"Float",
		activeSubs:"Int",
		mrrEngaged:"Float",
		realMRR:"Float"
	},
	StaffDashboardData:{
		pendingInvites:"Int",
		inactiveUsers:"Int",
		formerActiveUsers:"Int",
		deactivatedUsers:"Int",
		companies:"StaffDashboardCompany"
	},
	DeliverySatisfactionSurvey:{
		firstName:"String",
		answered:"Boolean"
	},
	DeliverySatisfactionSurveyRow:{
		lastName:"String",
		firstName:"String",
		email:"String",
		company:"String",
		rating:"Int",
		commentRating:"String",
		support:"Boolean",
		commentSupport:"String",
		answeredAt:"String"
	},
	ProductSatisfactionSurveyItem:{
		uuid:"ID",
		name:"String",
		pictureUrl:"String"
	},
	ProductSatisfactionSurvey:{
		firstName:"String",
		items:"ProductSatisfactionSurveyItem",
		answered:"Boolean"
	},
	ProductSatisfactionSurveyRow:{
		lastName:"String",
		firstName:"String",
		email:"String",
		company:"String",
		item:"String",
		rating:"Int",
		comment:"String",
		answeredAt:"String"
	},
	UsersStaffResponse:{
		pagination:"PaginationInfos",
		users:"Client",
		filterOptions:"UsersFiltersV2"
	},
	UsersFiltersV2:{
		countries:"OptionElement",
		companies:"OptionElement"
	},
	SetUserOffboardingResponse:{
		uuid:"ID",
		accountOffboardedAt:"String",
		personalEmail:"String",
		statusV2:"ClientStatusV2",
		offboardingStatus:"OffboardingStatus"
	},
	StockUnitItem:{
		item:"Item",
		requested:"Int",
		inStock:"Int",
		quantityNeeded:"Int",
		allocated:"Int",
		toBeDelivered:"Int",
		rented:"Int",
		returnRequested:"Int",
		deliveryBack:"Int",
		quarantined:"Int",
		exit:"Int"
	},
	UnitItem:{
		uuid:"ID",
		unitItemId:"String",
		item:"Item",
		mode:"SupplierMode",
		entryAt:"String",
		exitAt:"String",
		canceledAt:"String",
		buyingPrice:"Float",
		supplier:"Supplier",
		logistician:"Logistician",
		requestOrder:"RequestOrder",
		events:"UnitItemEvent",
		lastEvent:"UnitItemEvent",
		flux:"Flux",
		comments:"UnitItemComment"
	},
	UnitItemEvent:{
		uuid:"ID",
		kind:"UnitItemEventKind",
		updatedAt:"String"
	},
	UnitItemComment:{
		uuid:"ID",
		textContent:"String",
		author:"MerchantUser",
		createdAt:"String",
		updatedAt:"String"
	},
	RequestOrders:{
		requestOrders:"RequestOrder",
		filterOptions:"RequestOrderOptions"
	},
	RequestOrder:{
		uuid:"ID",
		purchaseOrderNumber:"String",
		requestOrderNumber:"String",
		mode:"SupplierMode",
		createdAt:"String",
		status:"RequestOrderKind",
		supplier:"Supplier",
		logistician:"Logistician",
		unitItems:"UnitItem",
		totalQuantity:"Int",
		totalReceived:"Int",
		totalBuyingPrice:"Float",
		comments:"RequestOrderComment",
		deliveryReceipts:"DeliveryReceipt"
	},
	RequestOrderComment:{
		uuid:"ID",
		textContent:"String",
		author:"MerchantUser",
		createdAt:"String",
		updatedAt:"String"
	},
	DeliveryReceipt:{
		uuid:"ID",
		deliveryReceiptNumber:"String",
		invoiceNumber:"String",
		deliveryItems:"DeliveryItem",
		createdAt:"String",
		requestOrder:"RequestOrder",
		totalBuyingPrice:"Float",
		stockValue:"Float",
		totalReceived:"Int"
	},
	DeliveryItem:{
		itemName:"String",
		qtyReceived:"Int"
	},
	RequestOrderOptions:{
		purchaseOrderNumbers:"OptionElement",
		requestOrderNumbers:"OptionElement",
		suppliers:"OptionElement",
		logisticians:"OptionElement",
		realPrices:"OptionElement",
		buyingPrices:"OptionElement",
		status:"OptionElement",
		createdAt:"OptionElement"
	},
	ItemResponseForm:{
		itemUuid:"ID",
		qtyOrdered:"Int",
		buyingPrice:"Float",
		name:"String",
		qtyReceived:"Int"
	},
	RequestOrderResponse:{
		requestOrder:"RequestOrder",
		itemsResponseForm:"ItemResponseForm"
	},
	DeliveryReceiptResponse:{
		deliveryReceipts:"DeliveryReceipt",
		filterOptions:"DeliveryReceiptOptions"
	},
	DeliveryReceiptOptions:{
		deliveryReceiptNumbers:"OptionElement",
		invoiceNumbers:"OptionElement",
		purchaseOrderNumbers:"OptionElement"
	},
	ItemOrdered:{
		itemUuid:"ID",
		name:"String",
		qtyOrdered:"Int",
		qtyReceived:"Int"
	},
	StockOptions:{
		brands:"OptionElement",
		names:"OptionElement",
		families:"OptionElement"
	},
	StockManagement:{
		items:"StockUnitItem",
		filterOptions:"StockOptions",
		stockDashboard:"StockDashboard"
	},
	StockDashboard:{
		labels:"String",
		values:"Int",
		totalItems:"Int"
	},
	StockDetailedOptions:{
		itemsNames:"OptionElement",
		status:"OptionElement"
	},
	StockDetailed:{
		unitItems:"UnitItem",
		filterOptions:"StockDetailedOptions"
	},
	QuantityUnitItems:{
		itemUuid:"ID",
		inStock:"Int",
		quantityNeeded:"Int"
	},
	UpdateProductReturnRequestResponse:{
		googleCloudStorageSignedUrls:"String",
		productReturnRequest:"ProductReturnRequest"
	},
	ProductReturn:{
		uuid:"ID",
		reason:"ProductReturnReason",
		subReason:"ProductReturnSubReason",
		sub:"Sub",
		productState:"ProductReturnState",
		userComment:"String",
		productPicture:"String",
		productReturnRequest:"ProductReturnRequest",
		status:"ProductReturnStatus"
	},
	ProductReturnRequest:{
		id:"String",
		uuid:"ID",
		isKit:"Boolean",
		status:"ProductReturnStatus",
		createdAt:"String",
		updatedAt:"String",
		productReturns:"ProductReturn",
		client:"Client"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}