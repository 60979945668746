export enum UPLOAD_TYPE {
  Img = 'Img',
}

export const convertMimeType = (type: UPLOAD_TYPE) => {
  if (type === UPLOAD_TYPE.Img) {
    return 'image/png;image/jpg;image/jpeg'
  }

  throw new Error('Missing Mime Type')
}

export const readableFileFormat = (type: UPLOAD_TYPE) => {
  if (type === UPLOAD_TYPE.Img) {
    return 'JPEG, PNG'
  }

  throw new Error('Missing Mime Type')
}

export const bytesToSize = (bytes: number) => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
}
