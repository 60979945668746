import React from 'react'
import './style.scss'

export default function IncompatibleBrowser() {
  return (
    <div className="incompatible-browser-page">
      <img
        src="https://storage.googleapis.com/flexlab-287521.appspot.com/icons/authentication/signup_admin_success.png"
        alt=""
        className="incompatible-browser-page__image"
      />
      <div className="incompatible-browser-page__content">
        <img alt="Fleex logo" src={require('../../../assets/icons/logo.svg')} />
        <h1>Make work happen from anywhere</h1>
        <p>
          Toutes nos excuses. Ce navigateur n'est plus supporté depuis 2015.
          <br />
          Nous vous conseillons d'utiliser un navigateur à jour.
        </p>
        <p>
          Pour vous aider, voici quelques liens utiles :
          <br />- Lien d'installation de <a href="https://www.google.fr/chrome/">Google Chrome</a>
          <br />- Lien d'installation de <a href="https://www.mozilla.org/fr/firefox/new/">Firefox</a>
          <br />- Lien d'installation de <a href="https://www.microsoft.com/fr-fr/edge">Microsoft Edge</a>
        </p>
      </div>
    </div>
  )
}
