import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Error404 from '../../assets/icons_V2/error404.svg'
import Error500 from '../../assets/icons_V2/error500.svg'
import { Button } from '../../components/Atoms/ButtonV2/Button'
import useScreenSize from '../../components/hooks/useScreenSize'
import SideMenu from '../../components/organisms/SideMenu'
import SideMenuMobile from '../../components/organisms/SideMenuMobile'
import { getCurrentAuth } from '../../constants/utils'
import { helpNavItem } from '../shared'

import './style.scss'

const HOMEPAGE = {
  market: '/market/dashboard',
  admin: '/admin/dashboard',
  staff: '/staff/dashboard',
  merchant: '/merchant/subs',
}

const buildContent = () => ({
  404: {
    title: "Il n'y a rien à voir ici...",
    text: 'Nous ne trouvons pas la page que vous cherchez.',
    icon: Error404,
  },
  500: {
    title: 'Quelque chose est cassé...',
    text: 'Il semblerait que nous ayons quelques problèmes techniques, nous en sommes désolés. Essayez de rafraîchir cette page ou de cliquer sur le bouton ci-dessous pour vous reconnecter.',
    icon: Error500,
  },
})

const Errors = ({ code = '500' }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { phone } = useScreenSize()
  const currentAuth = getCurrentAuth()
  const content = buildContent()
  const nav = [{ ...helpNavItem('-1rem'), isBottomNav: true }]

  const [withSidemenu, setWithSidemenu] = useState()
  useEffect(() => {
    const hideAuthentication = Boolean(document.querySelector('.authentication-layout'))
    if (hideAuthentication) {
      setWithSidemenu(true)
      document.querySelector('.authentication-layout').style.background = 'white'
      document.querySelector('.authentication-layout__image-wrapper').style.display = 'none'
      document.querySelector('.authentication-layout__content').style.maxWidth = 'unset'
    } else {
      setWithSidemenu(code === '500')
    }

    return () => {
      if (hideAuthentication) {
        document.querySelector('.authentication-layout').style.removeProperty('background')
        document.querySelector('.authentication-layout__image-wrapper').style.removeProperty('display')
        document.querySelector('.authentication-layout__content').style.removeProperty('maxWidth')
      }
    }
  }, [location, code])

  return (
    <div className={`error error-${code}`}>
      {withSidemenu &&
        (phone ? (
          <SideMenuMobile nav={nav} />
        ) : (
          <SideMenu nav={nav} onSectionSelection={() => {}} onExpand={() => {}} />
        ))}
      <div className="error__content">
        <img className="error__icon" src={content[code].icon} alt={content[code].title} />
        <p className="error__title">Whoops!</p>
        <p className="error__title">{content[code].title}</p>
        <p className="error__text">{content[code].text}</p>
        <Button
          kind="primary"
          onClick={() => {
            let redirectUrl = '/'
            if (code === '404') {
              redirectUrl = HOMEPAGE[currentAuth]
            }
            navigate(redirectUrl)
          }}
        >
          Revenir à la page d'accueil
        </Button>
      </div>
    </div>
  )
}

export default Errors
