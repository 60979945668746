import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import useClickOutside from '../../hooks/useClickOutside'
import Dropdown from '../Dropdown/Dropdown'
import { FORM_ELEMENT_SIZE } from '../FormElementV2/FormElementV2.model'
import '../FormElementV2/style.scss'
import { GroupLabel } from '../GroupLabel/GroupLabel'
import { Icon, ICONS, ICON_SIZES } from '../Icon/Icon'

const Select = ({
  name,
  onChange,
  options,
  value = '',
  onlyReadMode,
  register,
  hideLabel,
  label,
  size,
  className,
  color,
  kind,
  error,
}) => {
  const [labelWidth, setLabelWidth] = useState(null)

  useEffect(() => {
    if (size === FORM_ELEMENT_SIZE.SMALL) {
      setLabelWidth(document.getElementById(name)?.clientWidth)
    }
  }, [name, size])

  const ref = useRef()
  const [showDropdown, setShowDropDown] = useState()

  useClickOutside(ref, () => setShowDropDown(false))

  const toggleDropdown = () => setShowDropDown((previousValue) => !previousValue)

  const formElementClassNames = classNames('form-element-v2', className, {
    'form-element-v2--lg': size === FORM_ELEMENT_SIZE.LARGE,
    'form-element-v2--md': size === FORM_ELEMENT_SIZE.MEDIUM,
    'form-element-v2--sm': size === FORM_ELEMENT_SIZE.SMALL,
  })

  return (
    <div ref={ref} className={formElementClassNames}>
      <span className="select-separator" />
      {showDropdown && options && options.length > 0 && (
        <Dropdown
          showDropdown={showDropdown}
          options={options}
          currentValues={value}
          multi={false}
          color={color}
          kind={kind}
          onItemClick={(option) => {
            setShowDropDown(false)
            onChange(option.value)
          }}
        />
      )}
      {kind === 'group' ? (
        <>
          <div
            className={classNames(
              'form-element-v2__input form-element-v2__input--select form-element-v2__input--select--group',
              {
                'form-element-v2__input--select--sm': size === FORM_ELEMENT_SIZE.SMALL,
              },
              { error },
            )}
            readOnly="readonly"
            placeholder=" "
            {...(register ? register(name) : undefined)}
            disabled={onlyReadMode}
            style={{
              paddingLeft: size === FORM_ELEMENT_SIZE.SMALL ? labelWidth : '0.9375rem',
            }}
            onClick={toggleDropdown}
          >
            {value && <GroupLabel color={value.color} label={value.label} />}
          </div>
          {!hideLabel && value?.length <= 0 && label && (
            <label id={name} className="form-element-v2__label">
              {label}
            </label>
          )}
        </>
      ) : (
        <>
          <input
            className={classNames(
              'form-element-v2__input form-element-v2__input--select',
              {
                'form-element-v2__input--select--sm': size === FORM_ELEMENT_SIZE.SMALL,
              },
              { error },
            )}
            readOnly="readonly"
            placeholder=" "
            value={options?.find((option) => option.value === value)?.label || ''}
            disabled={onlyReadMode}
            style={{
              paddingLeft: size === FORM_ELEMENT_SIZE.SMALL ? labelWidth : '0.9375rem',
            }}
            onClick={toggleDropdown}
          />
          {!hideLabel && label && (
            <label id={name} className="form-element-v2__label">
              {label}
            </label>
          )}
          <input type="hidden" value={value} {...(register ? register(name) : undefined)} />
        </>
      )}

      <span
        className={classNames('select-chevron-icon', {
          'select-chevron-icon--sm': size === FORM_ELEMENT_SIZE.SMALL,
          'select-chevron-icon--md': size === FORM_ELEMENT_SIZE.MEDIUM,
        })}
      >
        <Icon icon={ICONS.ChevronDown} size={ICON_SIZES.SM} />
      </span>
    </div>
  )
}

export default Select
