import classnames from 'classnames'
import React from 'react'
import './style.scss'

const ToggleSwitch = ({ label, name, checked, onChange, onClick, disabled }) => {
  return (
    <div className="toggle-switch">
      {label && <p className="toggle-switch__name">{label}</p>}
      <div className={classnames('toggle-switch__wrapper', { clickable: onClick })} onClick={() => onClick?.()}>
        <div className="toggle-switch__button">
          <input
            type="checkbox"
            name={name}
            className="toggle-switch__button-checkbox"
            id={name}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
          />
          <label className={`toggle-switch__button-label ${disabled ? 'is-disabled' : ''}`} htmlFor={name}>
            <span className="toggle-switch__button-inner" />
            <span className="toggle-switch__button-switch" />
          </label>
        </div>
        <p className="toggle-switch__label">{checked ? 'Yes' : 'No'}</p>
      </div>
    </div>
  )
}

export default ToggleSwitch
