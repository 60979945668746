import { createHttpLink, Observable, useApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'

import { getAuthFromLocalStorage, saveTokens } from '../constants/utils'
import { apiBaseUrl } from '../constants/vars'

export const refreshToken = async (): Promise<string | undefined> => {
  type JSONRefreshResponse = {
    data?: {
      authenticate: {
        zendeskToken: string
        accessToken: string
        refreshToken: string
        type: string
        error: string
      }
    }
    errors?: Array<{ message: string }>
  }

  const refreshTokenQuery = `
      query authenticate($refreshToken: String!, $type: String!) {
        authenticate(refreshToken: $refreshToken, type: $type) {
          accessToken
          zendeskToken
          refreshToken
          clientUuid
          type
          error
        }
      }
    `

  const authType = getAuthFromLocalStorage('authorizationKind').split('/')[1]
  const refreshToken = getAuthFromLocalStorage('refreshToken')

  const refreshResponse = await fetch(`${apiBaseUrl}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      query: refreshTokenQuery,
      variables: { refreshToken, type: authType },
    }),
  })

  const { data, errors }: JSONRefreshResponse = await refreshResponse.json()

  if (refreshResponse.ok && data?.authenticate) {
    const { accessToken, refreshToken, type, zendeskToken } = data.authenticate

    if (accessToken && refreshToken && type) {
      const role = { main: type, authorizationKind: `jwt/${type}` }

      saveTokens({ accessToken, refreshToken, role, zendeskToken })
      return accessToken
    }
  } else {
    localStorage.removeItem('auth')
    const error = new Error(errors?.map((e) => e.message).join('\n') ?? 'unknown')
    Promise.reject(error)
  }

  return undefined
}

export const useLogout = () => {
  localStorage.removeItem('auth')

  const client = useApolloClient()
  client.clearStore()
}

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          // ignore 401 error for a refresh request
          if (operation.operationName === 'refreshToken') {
            return
          }

          return new Observable((observer) => {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(async () => {
              try {
                const accessToken = await refreshToken()

                if (!accessToken) {
                  throw new GraphQLError('Empty AccessToken')
                }

                // Retry the failed request
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                forward(operation).subscribe(subscriber)
              } catch (err) {
                observer.error(err)
              }
            })()
          })
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }

  return undefined
})

export const httpLink = createHttpLink({ uri: apiBaseUrl + '/graphql' })

export const authLink = setContext((operation, { headers }) => {
  const token =
    operation.operationName === 'refreshToken'
      ? getAuthFromLocalStorage('refreshToken')
      : getAuthFromLocalStorage('accessToken')

  const authorizationKind =
    operation.operationName === 'refreshToken' ? 'jwt/refresh' : getAuthFromLocalStorage('authorizationKind')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Authorization-Kind': authorizationKind,
    },
  }
})
