/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, ReactElement, ReactNode } from 'react'
import { Control, FieldValues, Path, PathValue, UseFormRegister, UseFormWatch } from 'react-hook-form'

import { UPLOAD_TYPE } from '@fleex/shared'

export enum FORM_ELEMENT_SIZE {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export enum FORM_ELEMENT_TYPE {
  SELECT = 'select',
  SELECT_INPUT = 'select-input',
  CONTENT = 'content',
  IMAGE = 'image',
  WYSIWYG = 'wysiwyg',
  CHECKBOX = 'checkbox',
  PASSWORD = 'password',
  DATE = 'date',
  TEXTAREA = 'textarea',
  TEXT = 'text',
  COLOR = 'color',
  NUMBER = 'number',
  SWITCH = 'switch',
  FILE = 'file',
  HIDDEN = 'hidden',
  RADIO = 'radio',
}

type CommonProps<T extends FieldValues = any> = {
  name: Path<T>
  size?: FORM_ELEMENT_SIZE
  className?: string
  register?: UseFormRegister<T>
  control?: Control<T, unknown>
  defaultValue?: PathValue<T, Path<T>>
  error?: any
  style?: CSSProperties
  watch?: UseFormWatch<T>
  onlyReadMode?: boolean
  onChange?: (newValue: string) => void
  iconLeft?: boolean
  disableAutocomplete?: boolean
  isDisabled?: boolean
  hint?: string | null
  dynamicHint?: (v: Path<T> | null) => ReactElement
  hideLabel?: boolean
  label?: string | null
  children?: ReactNode
}

export type ImageProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.IMAGE
  value: string
} & CommonProps<T>

export type SelectProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.SELECT
  label: string
  options: { value: string; label: string }[]
  onlyReadMode?: boolean
  hideLabel?: boolean
  onChange?: (value: string) => void
} & CommonProps<T>

export type ColorProps<T extends FieldValues = any> = Omit<SelectProps<T>, 'type'> & {
  type: FORM_ELEMENT_TYPE.COLOR
}

export type FileProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.FILE
  accept: UPLOAD_TYPE
  label: string
} & CommonProps<T>

export type CheckboxProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.CHECKBOX
  label?: string | ReactNode
  disabled?: boolean
} & CommonProps<T>

export type PasswordProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.PASSWORD
  placeholder?: string
  onChange?: (s: string) => void
} & CommonProps<T>

export type DateProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.DATE
  minDate: Date
  label: string
} & CommonProps<T>

export type ContentProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.CONTENT
  value: ReactElement
} & CommonProps<T>

export type SelectInputProps<T extends FieldValues = any> = Omit<SelectProps<T>, 'type'> & {
  type: FORM_ELEMENT_TYPE.SELECT_INPUT
}

export type WysiwygProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.WYSIWYG
  value: string
  onlyReadMode: boolean
} & CommonProps<T>

export type TextAreaProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.TEXTAREA
  value?: string
  onlyReadMode?: boolean
  onChange?: (s: string) => void
} & CommonProps<T>

export type SwitchProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.SWITCH
  value: string
  onlyReadMode: boolean
  onChange?: (s: string) => void
  label: string
} & CommonProps<T>

export type NumberProps<T extends FieldValues = any> = {
  type: FORM_ELEMENT_TYPE.NUMBER
  label: string

  typeNumberStep: number
  min: number
  max?: number
} & CommonProps<T>

export type HiddenProps<T extends FieldValues = any> = {
  type?: FORM_ELEMENT_TYPE.HIDDEN
} & CommonProps<T>

export type RadioProps<T extends FieldValues = any> = {
  id?: string
  type?: FORM_ELEMENT_TYPE.RADIO
  label?: string | ReactNode
  disabled?: boolean
  value?: string
} & CommonProps<T>

export type DefaultProps<T extends FieldValues = any> = {
  type?: FORM_ELEMENT_TYPE.TEXT
} & CommonProps<T>

export type FormElementV2Props<T extends FieldValues = any> =
  | ImageProps<T>
  | SelectProps<T>
  | ColorProps<T>
  | FileProps<T>
  | CheckboxProps<T>
  | PasswordProps<T>
  | DateProps<T>
  | ContentProps<T>
  | SelectInputProps<T>
  | WysiwygProps<T>
  | TextAreaProps<T>
  | SwitchProps<T>
  | NumberProps<T>
  | HiddenProps<T>
  | RadioProps<T>
  | DefaultProps<T>
