import { useMutation } from '@apollo/client'
import { useGoogleLogin } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { boolean, object, string } from 'yup'

import { currentTosVersion, $, typedGql } from '@fleex/shared'

import { Button } from '../../../components/Atoms/ButtonV2/Button'
import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import { ICONS, ICON_SIZES } from '../../../components/Atoms/Icon/Icon'
import { DynamicPasswordHint } from '../../../components/Atoms/PasswordHint'
import { useAuth } from '../../../components/contexts/auth/AuthProvider'
import useScreenSize from '../../../components/hooks/useScreenSize'
import WizardFormStepV2 from '../../../components/organisms/WizardFormStepV2'
import { extractErrorMessage } from '../../../constants/errors'

import './SignupForm.scss'

const namesInputs = (t) => [
  { name: 'firstName', label: t('common.first_name'), type: 'text', placeholder: 'Julie', className: 'sign-input' },
  { name: 'lastName', label: t('common.last_name'), type: 'text', placeholder: 'Doe', className: 'sign-input' },
]

const formSection = (t, mobile) => ({
  submitText: t('signup.confirm_signup'),
  formInputs: [
    ...(mobile ? namesInputs(t) : [namesInputs(t)]),
    {
      name: 'email',
      label: t('login.email'),
      type: FORM_ELEMENT_TYPE.TEXT,
      placeholder: 'julie@societe.com',
      className: 'sign-input',
    },
    {
      name: 'personalEmail',
      label: t('login.personal_email'),
      type: FORM_ELEMENT_TYPE.TEXT,
      placeholder: 'julie@gmail.com',
      className: 'sign-input',
    },
    {
      name: 'password',
      label: t('login.password'),
      type: FORM_ELEMENT_TYPE.PASSWORD,
      className: 'sign-input',
      dynamicHint: (value) => <DynamicPasswordHint value={value} />,
    },
    {
      name: 'cgu',
      label: (
        <>
          {t('signup.cgu.i_accept')}
          <a
            href={`${process.env.REACT_APP_STORAGE_URL}/term/tos_employee/v${currentTosVersion.worker}.pdf`}
            target="_blank"
            rel="noreferrer"
            className="button-FL-V2 signup-form-V2__link button-FL-V2--empty"
          >
            {t('signup.cgu.tos')}
          </a>{' '}
          {t('signup.cgu.data')}
          <a
            className="button-FL-V2 signup-form-V2__link button-FL-V2--empty"
            rel="noreferrer"
            target="_blank"
            href="https://fleex.com/confidentialite"
          >
            {t('signup.cgu.privacy_policy')}
          </a>
        </>
      ),
      type: FORM_ELEMENT_TYPE.CHECKBOX,
    },
  ],
  validationSchema: object().shape({
    firstName: string().required(t('common.required')).label(t('common.first_name')),
    lastName: string().required(t('common.required')).label(t('common.last_name')),
    email: string().email(t('signup.errors.email')).required(t('common.required')).label(t('common.email')),
    personalEmail: string().email(t('signup.errors.email')).required(t('common.required')).label(t('common.email')),
    password: string()
      .min(12)
      .matches(/[A-Z]/, t('signup.errors.upper'))
      .matches(/[a-z]/, t('signup.errors.lower'))
      .matches(/[0-9]/, t('signup.errors.number'))
      .matches(/[*.@#$%^&(){}[\]:;<>,.?/~_+=|\\!]/, t('signup.errors.special'))
      .required(t('common.required'))
      .label(t('login.password')),
    cgu: boolean().required(t('signup.errors.cgu')).oneOf([true], t('signup.errors.cgu')),
  }),
})

const GOOGLE_AUTHENTICATION_MUTATION = typedGql('mutation')({
  googleAuthentication: [
    { code: $('code', 'String!') },
    { accessToken: true, refreshToken: true, zendeskToken: true, clientUuid: true, isNew: true, type: true },
  ],
})

const GoogleLoginButton = ({ isGoogleConnectAvailable }) => {
  const [googleAuthentication] = useMutation(GOOGLE_AUTHENTICATION_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  const { getIdentityFromToken } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const signup = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      const { data } = await googleAuthentication({ variables: { code } })
      const identity = getIdentityFromToken(data.googleAuthentication)
      navigate('/google-signup', { state: identity })
    },
  })

  const handleClick = () => {
    signup()
  }

  if (!isGoogleConnectAvailable) {
    return null
  }

  return (
    <Button
      kind="secondary"
      className="full-width"
      iconLeft={ICONS.Google}
      iconSize={ICON_SIZES.SM}
      onClick={handleClick}
    >
      {t('signup.google')}
    </Button>
  )
}

const SignupForm = ({ onSubmit, formData, invited, stepProps, isGoogleConnectAvailable = true }) => {
  const formSectionData = {
    ...formData,
    ...(invited ? { email: invited } : {}),
  }

  const { t } = useTranslation()
  const { desktop } = useScreenSize()

  return (
    <div className="signup-form-V2__container">
      <div className="signup-form-V2__titles">
        <h1 className="authentication-layout__title">{t('signup.welcome_at_fleex')}</h1>
        <h2 className="authentication-layout__subtitle">{t('signup.great_to_see_you')}</h2>
      </div>

      <div className="signup-form-V2__content">
        <GoogleLoginButton isGoogleConnectAvailable={isGoogleConnectAvailable} />

        <div className="separator">
          <div className="separator__line"></div>
          <div className="separator__content">{t('common.or')}</div>
          <div className="separator__line"></div>
        </div>

        <WizardFormStepV2
          formSection={formSection(t, !desktop)}
          formData={formSectionData}
          onSubmit={onSubmit}
          hasPrevious={false}
          showStepper={false}
          currentStep={0}
          totalSteps={1}
          {...stepProps}
        />
      </div>

      <div className="signup-form-V2__bottom-actions">
        <p className="signup-form-V2__bottom-actions__text">{t('signup.already_registered')}</p>
        <Button kind="empty" className="signup-form-V2__bottom-actions__sign-in" linkTo={'/'}>
          {t('login.login')}
        </Button>
      </div>
    </div>
  )
}

export default SignupForm
