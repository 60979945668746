import { normalizeString, STORE_CARD_ORIENTATION } from '@fleex/shared'

import { ICONS } from '../components/Atoms/Icon/Icon'
import { Catalog, Product } from '../components/hooks/providers/useCatalog_V2'
import { showAndOpenZendesk } from '../components/hooks/useZendeskAPI'

export const helpNavItem = (bottomOffset: string) => ({
  id: 'help',
  title: 'Aide',
  icon: ICONS.BubbleQuestion,
  bottomOffset,
  floatingContent: (hide: () => void) => (
    <>
      <a
        href="https://fleexsupport.zendesk.com/hc/fr"
        className="help-bar__link"
        target="_blank"
        rel="noopener noreferrer"
        onClick={hide}
      >
        Consulter notre FAQ
      </a>
      <label
        onClick={() => {
          showAndOpenZendesk()
          hide()
        }}
      >
        Contacter notre équipe support
      </label>
    </>
  ),
})

export const PRODUCTS_ORDER = {
  RECOMMENDATIONS: 'RECOMMENDATIONS',
  NEW: 'NEW',
  LOW_TO_HIGH: 'LOW_TO_HIGH',
  HIGH_TO_LOW: 'HIGH_TO_LOW',
}

export const PRODUCTS_ORDER_OPTIONS = [
  { value: PRODUCTS_ORDER.RECOMMENDATIONS, label: 'Recommandations' },
  { value: PRODUCTS_ORDER.NEW, label: 'Nouveautés' },
  { value: PRODUCTS_ORDER.LOW_TO_HIGH, label: 'Prix croissant' },
  { value: PRODUCTS_ORDER.HIGH_TO_LOW, label: 'Prix décroissant' },
]

export const searchProducts = (products: Product[], search: string) => {
  if (!search.length) {
    return products
  }

  const regexp = new RegExp(normalizeString(search), 'i')
  return products.filter(({ name }) => regexp.test(normalizeString(name)))
}

export const getSectionProducts = (
  store: Catalog,
  sectionUuid: string,
  search?: string
): {
  title: string
  prods: Product[]
  orientation: typeof STORE_CARD_ORIENTATION[keyof typeof STORE_CARD_ORIENTATION]
} => {
  for (const { uuid, name, typologies } of store) {
    if (uuid === sectionUuid) {
      return {
        title: name,
        prods:
          typologies?.reduce<Product[]>(
            (acc, { products }) => [...acc, ...(search ? searchProducts(products ?? [], search) : products ?? [])],
            []
          ) ?? [],
        orientation: STORE_CARD_ORIENTATION.HORIZONTAL,
      }
    }

    for (const { uuid, nameFR, products, storeCardOrientation } of typologies ?? []) {
      if (uuid === sectionUuid) {
        return {
          title: nameFR,
          prods: search ? searchProducts(products ?? [], search) : products ?? [],
          orientation:
            (storeCardOrientation as STORE_CARD_ORIENTATION | undefined) ?? STORE_CARD_ORIENTATION.HORIZONTAL,
        }
      }
    }
  }

  return {
    title: '',
    prods: [],
    orientation: STORE_CARD_ORIENTATION.HORIZONTAL,
  }
}

export const getAllProducts = (store: Catalog, search?: string) =>
  store.reduce<Product[]>((acc, { uuid }) => [...acc, ...getSectionProducts(store, uuid, search).prods], [])
