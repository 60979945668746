import 'moment/locale/fr'
import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import { Controller } from 'react-hook-form'

import { formatDate } from '@fleex/shared'

import { Icon, ICONS } from '../Icon/Icon'

export const DatePicker = ({ selected, onChange, minDate, label, error }) => {
  const [value, setValue] = useState(selected)

  const renderInput = React.forwardRef((props, ref) => {
    return (
      <>
        <input ref={ref} className={error && 'form-element-v2__input error'} {...props} />
        <label className="form-element-v2__label">{label}</label>
        <span className="form-element-v2__icon">
          <Icon icon={ICONS.Calendar} />
        </span>
      </>
    )
  })

  return (
    <DayPickerInput
      placeholder=" "
      formatDate={formatDate}
      onDayChange={(newValue) => {
        onChange(newValue)
        setValue(newValue)
      }}
      component={renderInput}
      value={value}
      dayPickerProps={{
        disabledDays: { before: minDate, after: new Date('2050-01-01') },
        localeUtils: MomentLocaleUtils,
        locale: 'fr',
      }}
    />
  )
}

const DateInput = ({ name, defaultValue, control, minDate, label, error }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    control={control}
    render={({ onChange, value, field: { ref: _, ...field } }) => (
      <DatePicker
        minDate={minDate}
        label={label}
        data-lpignore="true"
        data-form-type="other"
        autocomplete="off"
        ref={null}
        onChange={onChange}
        selected={value}
        error={error}
        {...field}
      />
    )}
  />
)

export default DateInput
