/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { ProductReturnState, ProductReturnReason } from '@prisma/client'
import moment from 'moment'
import 'moment/locale/fr'

import { FluxKind, FluxEventKind, ModelTypes, ProductReturnSubReason } from '../zeus/index'
import { Sub } from './models'
import { typedObjectKeys } from './object'

export type FamilyTransaction = keyof typeof FAMILY_TRANSLATION
export const LANG = {
  FR: 'FR',
  EN: 'EN',
}

export const FLAG_DISPLAYED = {
  HAVE_FLAG: 'have_flag',
  NO_FLAG: 'no_flag',
}

export const WL_BL_ACTION = {
  INSERT: 'insert',
  DELETE: 'delete',
}

export const DISPLAY_MODE = {
  BLACKLIST: 'blacklist',
  WHITELIST: 'whitelist',
} as const

export type DISPLAY_MODE = typeof DISPLAY_MODE[keyof typeof DISPLAY_MODE]

export const DISABLED_PRODUCT_REASON = {
  USER_REQUEST: 'USER_REQUEST',
  NEW_PRODUCT: 'NEW_PRODUCT',
}

export const SUPPLIER_MODE = {
  RENTED: 'rented',
  PURCHASED: 'purchased',
}

export const LOGISTICIAN = {
  AXE_LOGISTIQUE: 'Axe Logistique',
  AGEDISS_ORDER: 'Agediss Order',
  AGEDISS_RETURN: 'Agediss Return',
  AXE_ORDER: 'Axe Order',
  AXE_RETURN: 'Axe Return',
}

export const ACCOUNT_STATE = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
}

export const USER_TYPE = {
  CLIENT: 'client',
  MERCHANT: 'merchant',
  STAFF: 'staff',
}

export const USER_KIND = {
  ADMIN: 'admin',
  USER: 'user',
} as const

export const SUB_FLAG_KIND = {
  STANDBY: 'STANDBY',
  SUPPORT: 'SUPPORT',
}

export const USER_STATE = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DISABLED: 'disabled',
} as const

// TO DO : Remove after merge V2
export const USER_STATUS = {
  pending: 'pending',
  inactive: 'inactive',
  active: 'active',
  formerActive: 'formerActive',
  deactivated: 'deactivated',
} as const

export const USER_STATUS_V2 = {
  pending: 'pending',
  inactive: 'inactive',
  active: 'active',
  offboardingPending: 'offboardingPending',
  offboardingOngoing: 'offboardingOngoing',
  deactivated: 'deactivated',
}

export const OFFBOARDING_STATUS = {
  none: 'none',
  pending: 'pending',
  ongoing: 'ongoing',
  done: 'done',
}

export const UNIT_ITEM_STATUS = {
  requested: 'requested',
  in_stock: 'inStock',
  allocated: 'allocated',
  to_be_delivered: 'toBeDelivered',
  rented: 'rented',
  return_requested: 'returnRequested',
  delivery_back: 'deliveryBack',
  quarantined: 'quarantined',
  exit: 'exit',
}

export const REQUEST_ORDER_STATUS = {
  received: 'received',
  partlyReceived: 'partlyReceived',
  ongoing: 'ongoing',
}

export const ITEM_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
}

export const PRODUCT_STATUS = {
  activated: 'Activated',
  deactivated: 'Deactivated',
}

export const GOOGLE_REQUEST_TYPE = {
  ASSOCIATION: 'ASSOCIATION',
  ASSOCIATION_V2: 'ASSOCIATION_V2',
  AUTHENTICATION: 'AUTHENTICATION',
}

export type GOOGLE_REQUEST_TYPE = typeof GOOGLE_REQUEST_TYPE[keyof typeof GOOGLE_REQUEST_TYPE]

export const SURVEY_TYPES = {
  DELIVERY_SATISFACTION: 'DELIVERY_SATISFACTION',
  PRODUCT_SATISFACTION: 'PRODUCT_SATISFACTION',
}

export const DELIVERY_SATISFACTION_SUPPORT_OPTIONS = {
  NULL: "Je n'ai pas été en contact avec votre équipe support !",
  YES: 'Oui, échange très fluide, merci !',
  NO: 'Non je ne suis pas satisfait, et je vous explique pourquoi ci-dessous',
}

export const FLAG_KIND = {
  SUPPORT: 'flagged_support',
  STANDBY: 'flagged_standby',
}

export const FINANCIAL_MODEL = {
  rentalAYG: 'rentalAYG',
  rentalPackage: 'rentalPackage',
  rentalPlatform: 'rentalPlatform',
} as const

export type FINANCIAL_MODEL = typeof FINANCIAL_MODEL[keyof typeof FINANCIAL_MODEL]

export enum STORE_CARD_ORIENTATION {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export const PRODUCT_ATTRIBUTE_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  BOOLEAN: 'boolean',
}

export const translateError = (s: string) =>
  ({
    FORBIDDEN: "Vous n'avez pas les permissions nécessaires pour cette action.",
    UNAUTHENTICATED: 'Vous devez être authentifié pour accéder à cette page.',
    ACCOUNT_NOT_FOUND: "Ce compte n'existe pas.",
    ACCOUNT_PENDING_ACTIVATION: "Ce compte n'est pas encore activé.",
    ACCOUNT_PENDING_ACTIVATION_EN: 'This account is not activated',
    ACCOUNT_DISABLED: 'Ce compte a été désactivé.',
    ACCOUNT_DISABLED_EN: 'This user is inactive',
    MISSING_ADDRESS: 'Vous devez renseigner une adresse de livraison avant de pouvoir commander',
    MISSING_ADDRESS_EN: 'This user has no address defined',
    ACCOUNT_OFFBOARDED: "Ce compte est en cours d'offboarding ou a déjà été offboardé.",
    ACCOUNT_GOOGLE_SIGNEDUP:
      "Vous n'avez pas défini de mot de passe. Vous pouvez soit en créer un en cliquant sur 'J'ai oublié mon mot de passe', soit vous connecter avec Google.",
    COMPANY_NOT_FOUND:
      "Nous n'avons pas trouvé votre société. Assurez-vous d'utiliser votre adresse mail professionnelle.",
    WRONG_CURRENT_PASSWORD: 'Le mot de passe actuel est incorrect.',
    NEW_PASSWORDS_DONT_MATCH: 'Les champs nouveaux mot de passe ne sont pas égaux.',
    UNKNOWN_TOKEN: "Ce lien d'activation est incorrect.",
    WRONG_PASSWORD: 'Adresse email ou mot de passe incorrect.',
    REGISTRATION_NUMBER_ALREADY_USED: 'This SIREN number is already used.',
    UNKNOWN_COMPANY: "This company doesn't exist.",
    USER_NOT_INVITED: "Vous n'avez pas encore été invité à rejoindre votre espace Fleex.",
    EMAIL_ALREADY_USED: "L'adresse mail est déjà utilisée.",
    EMAIL_ALREADY_LINKED: "L'adresse mail est déjà liée à un compte.",
    EMAIL_DOMAIN_NAME_ALREADY_USED: `Une société existe déjà avec cette adresse email.`,
    FORBIDDEN_DOMAIN_NAME:
      "Veuillez utiliser votre adresse email d'entreprise. Les noms de domaines communs comme gmail.com ou laposte.net ne sont pas autorisés.",
    COST_EXCEEDS_CAP: `Vous ne pouvez pas choisir ce produit car il dépasse le budget de votre entreprise.`,
    COST_EXCEEDS_CAP_MERCHANT: 'Subbing to this product would exceed this user budget.',
    EMAIL_SENDING_ERROR: "Une erreur a eu lieu lors de l'envoi des mails.",
    EMAIL_DELETE_ERROR: "Une erreur a eu lieu lors de la suppression des mails d'utilisateurs.",
    BASKET_SEPA_INVALID: "Impossible de commander, le compte de votre société n'est pas encore validé.",
    SUB_CANT_BE_CANCELLED: "Close or cancel all the sub's fluxes before canceling it.",
    ACCOUNT_DISABLE_CURRENT_SUBS: 'Ce collaborateur a encore des produits chez lui ou en transit.',
    ADMIN_CANT_DISABLE_ITSELF: 'Vous ne pouvez pas désactiver votre propre compte',
    BRAND_ALREADY_EXISTS: 'Sorry, this brand already exists',
    FAMILY_ALREADY_EXISTS: 'Sorry, this family already exists',
    LOGISTICIAN_ALREADY_EXISTS: 'Sorry, this logistician already exists',
    SUPPLIER_ALREADY_EXISTS: 'Sorry, this supplier already exists',
    TYPLOGY_ALREADY_EXISTS: 'Sorry, this typology already exists',
    TYPOLOGY_NAME_EN_ALREADY_EXISTS: 'Sorry, this typology already exists (name EN)',
    TYPOLOGY_NAME_FR_ALREADY_EXISTS: 'Sorry, this typology already exists (name FR)',
    CATEGORY_NAME_EN_ALREADY_EXISTS: 'Sorry, this category already exists (name EN)',
    CATEGORY_NAME_FR_ALREADY_EXISTS: 'Sorry, this category already exists (name FR)',
    FORBIDDEN_PRODUCT: "Vous n'avez pas accès à ce produit",
    EXISTING_OPEN_OFFER: 'A custom offer already exists for this product',
    PRODUCT_OR_ITEM_IS_DISABLED:
      'Malheureusement, un ou plusieurs produits ne sont plus disponibles. Merci de vider votre panier et de passer à nouveau une commande.',
    COMPANY_GROUP_HAS_USERS: 'Ce groupe ne peut pas ête supprimé car il reste des collaborateurs dedans.',
    COMPANY_GROUP_HAS_INVITED_USERS:
      'Ce groupe ne peut pas ête supprimé car il reste des invitations actives associées',
    CANNOT_DELETE_DEFAULT_COMPANY_GROUP: 'Le groupe par défault ne peux pas être supprimé',
    SUBS_HIGHER_THAN_DEFAULT_BUDGET:
      'Ce groupe ne peut pas ête supprimé car au moins un collaborateur à des abonnements supérieurs au budget du groupe par défaut',
    BUDGET_USED_BY_GROUP: "This budget cannot be remove because it's used by a group.",
    BUDGET_USER_ABOVE_LOCATION_COST_CAP:
      'This budget cannot be modified because at least one user has spent more points than the new budget.',
    USER_ALREADY_ASSOCIATED_TO_GOOGLE: 'Vous avez déjà une adresse Google associée à votre compte',
    UNKNOWN_SURVEY: "Nous n'avons pas pu récupérer ce questionnaire",
    QUANTITY_ORDER_TOO_LOW: 'The quantity is insufficient',
    DIFFERENT_MERCHANT_COMPANY: 'There is an error on the merchant account',
    CANCELED_SUB: 'The subscription is on cancelled, no action can be taken',
    FROZEN_SUB: 'The subscription is on standby, no action can be taken',
    CANNOT_CANCEL_DELIVERY_FLUX: 'A delivery flux cannot be cancelled',
    ARCHIVING_REASON_MANDATORY: 'You must provide a reason to disable an item',
    OFFER_ITEM_ARCHIVED: 'The item link to this offer is archived',
    PRODUCT_HAS_ARCHIVED_ITEM: 'This product cannot be unarchived because it has at least an archived item',
    UNKNOWN_PRODUCT: "Ce produit n'existe pas",
    ARCHIVED_PRODUCT: "Ce produit n'est plus disponible",
    ARCHIVED_ITEM: "Cet item n'est plus disponible",
    DISABLED_PRODUCT: "Ce produit n'est plus disponible",
    UNKNOWN_UNIT_ITEM: "This unit item doesn't exist",
    UNKNOWN_REQUEST_ORDER: "This request order doesn't exist",
    EMPTY_COMMENT: 'This comment is empty',
    UNIT_ITEMS_ARE_NOT_REQUESTED: "Some unit items don't have the requested status.",
    RO_PO_NUMBER_ALREADY_TAKEN: 'This purchase order number is already taken.',
    TOO_MANY_UNIT_ITEMS_REQUEST_ORDER: 'There are more unit items than in the RO.',
    TOO_MANY_UNIT_ITEMS_REQUEST_ORDER_CANCELLED: 'Some unit items are not in stock but already rented or in delivery.',
    MRR_ENGAGED_UNDER_ZERO: "The MRR engaged can't be under zero.",
    SAME_EMAIL_PROFESSIONAL_PERSONNAL: "L'email professionel doit être différent de l'email personnel.",
    RN_FILL_INVOICE_NUMBER: "You have to fill the field 'Invoice Number'.",
    RO_ALREADY_DONE: 'This request order is already done.',
    WORKOS_SSO_NOT_ENABLED: "Votre entreprise n'a pas activé la connection par SSO SAML",
    SSO_LOGIN_ONLY: 'Votre entreprise à restreint vos méthodes de connexion, veuillez vous identifier via SAML/SSO',
    GCONNECT_LOGIN_ONLY:
      'Votre entreprise à restreint vos méthodes de connexion, veuillez vous identifier via le bouton Google.',
    UNKNOWN_DOMAIN: "Ce nom de domaine n'est pas répertorié chez Fleex",
    CLIENT_ACCOUNT_ALREADY_ACTIVATED: 'Ce compte client est déjà activé',
    UNKNOWN_MERCHANT: "This merchant doesn't exist",
    UNKNOWN_PRODUCT_ATTRIBUTE: "This product attribute doesn't exist",
    PRODUCT_ATTRIBUTE_ALREADY_EXISTS: 'Sorry, this product attribute already exists',
    PRODUCT_ATTRIBUTE_SELECT_EMPTY: 'A mutliple choice product attribute must have values',
    COUNTRY_NOT_ALLOWED: 'Votre entreprise ne permet la livraison dans ce pays !',
    DOMTOM_NOT_ALLOWED: "La livraison en Corse et dans les DOM-TOM n'est pas disponible !",
    NOT_A_PRODUCT_STAFF: 'This product is not related to a staff user.',
    NOT_AN_ITEM_STAFF: 'This item is not related to a staff user.',
    UNKNOWN_FINANCIAL_CATEGORY: 'This financial category does not exist',
    CLIENT_IN_PENDING_GROUP: 'This client is in a pending group, sub are not allowed yet',
    CANT_CHANGE_RETURN_REQUEST_STATUS: 'Status change is not allowed',
    CANCEL_OFFBOARDING_ACTIVE_LEAVING_RETURN_REQUEST:
      "Cancellation aborted: you can't cancel an offboarding with an open offboarding product return request.",
    CANCEL_OFFBOARDING_DONE_LEAVING_RETURN_REQUEST:
      "Cancellation aborted: you can't cancel an offboarding with a done offboarding product return request.",
  }[s] || 'Une erreur est survenue.')

export enum SUB_LABELS {
  SUB_PROCESSING = 'sub_processing',
  SUB_VALIDATED = 'sub_validated',
  SHIPPING_IN_PROGRESS = 'shipping_in_progress',
  RENTING = 'renting',
  BOUGHT_BACK = 'bought_back',
  BUY_BACK_IN_PROGRESS = 'buy_back_in_progress',
  BUY_BACK_REQUESTED = 'buy_back_requested',
  CUSTOMER_SERVICE_REQUESTED = 'customer_service_requested',
  CUSTOMER_SERVICE_GRANTED = 'customer_service_granted',
  CUSTOMER_SERVICE_SHIPPING_IN_PROGRESS = 'customer_service_shipping_in_progress',
  CUSTOMER_SERVICE_DELIVERY_FINISHED = 'customer_service_delivery_finished',
  CUSTOMER_SERVICE_RETRIEVAL_REQUESTED = 'customer_service_retrieval_requested',
  CUSTOMER_SERVICE_RETRIEVAL_IN_PROGRESS = 'customer_service_retrieval_in_progress',
  CUSTOMER_SERVICE_RETRIEVAL_FINISHED = 'customer_service_retrieval_finished',
  RETRIEVAL_REQUESTED = 'retrieval_requested',
  RETRIEVAL_IN_PROGRESS = 'retrieval_in_progress',
  TERMINATED = 'terminated',
  STANDBY = 'sub_processing',
  PRODUCT_ALLOCATED = 'sub_validated',
  NO_FLUX_ATTACHED = 'no_flux_attached',
  FLUX_CREATED_BUT_NO_EVENTS = 'flux_created_but_no_events',
  IN_PROGRESS = 'retrieval_in_progress',
  FLAGGED_STANDBY = 'flagged_standby',
  CANCELED_FLUX = 'canceled_flux',
}

type MakeFluxStatusReturn =
  | SUB_LABELS.SUB_PROCESSING
  | SUB_LABELS.SUB_VALIDATED
  | SUB_LABELS.SHIPPING_IN_PROGRESS
  | SUB_LABELS.RENTING
  | 'customer_service_requested'
  | 'customer_service_granted'
  | 'customer_service_shipping_in_progress'
  | 'customer_service_delivery_finished'
  | 'customer_service_retrieval_requested'
  | 'customer_service_retrieval_in_progress'
  | 'customer_service_retrieval_finished'
  | SUB_LABELS.RETRIEVAL_REQUESTED
  | SUB_LABELS.RETRIEVAL_IN_PROGRESS
  | SUB_LABELS.TERMINATED
  | SUB_LABELS.BUY_BACK_REQUESTED
  | SUB_LABELS.BUY_BACK_IN_PROGRESS
  | SUB_LABELS.BOUGHT_BACK
  | 'N/A'

// eslint-disable-next-line complexity
export const makeFluxStatus = (
  fluxKind: FluxKind,
  eventKind?: FluxEventKind,
  allowCustomerService = false,
): MakeFluxStatusReturn => {
  if (fluxKind === 'delivery') {
    if (eventKind === 'standby') {
      return SUB_LABELS.SUB_PROCESSING
    }
    if (eventKind === 'product_allocated') {
      return SUB_LABELS.SUB_VALIDATED
    }
    if (eventKind === 'in_progress') {
      return SUB_LABELS.SHIPPING_IN_PROGRESS
    }
    if (eventKind === 'done') {
      return SUB_LABELS.RENTING
    }
  }
  if (fluxKind === 'customer_service_delivery' || fluxKind === 'customer_service_retrieval') {
    if (!allowCustomerService) {
      return SUB_LABELS.RENTING
    }
    if (fluxKind === 'customer_service_delivery') {
      if (eventKind === 'standby') {
        return 'customer_service_requested'
      }
      if (eventKind === 'product_allocated') {
        return 'customer_service_granted'
      }
      if (eventKind === 'in_progress') {
        return 'customer_service_shipping_in_progress'
      }
      if (eventKind === 'done') {
        return 'customer_service_delivery_finished'
      }
    }
    if (fluxKind === 'customer_service_retrieval') {
      if (eventKind === 'standby') {
        return 'customer_service_retrieval_requested'
      }
      if (eventKind === 'in_progress') {
        return 'customer_service_retrieval_in_progress'
      }
      if (eventKind === 'done') {
        return 'customer_service_retrieval_finished'
      }
    }
  }
  if (fluxKind === 'retrieval') {
    if (eventKind === 'standby') {
      return SUB_LABELS.RETRIEVAL_REQUESTED
    }
    if (eventKind === 'in_progress') {
      return SUB_LABELS.RETRIEVAL_IN_PROGRESS
    }
    if (eventKind === 'done') {
      return SUB_LABELS.TERMINATED
    }
  }
  if (fluxKind === 'buy_back') {
    if (eventKind === 'standby') {
      return SUB_LABELS.BUY_BACK_REQUESTED
    }
    if (eventKind === 'in_progress') {
      return SUB_LABELS.BUY_BACK_IN_PROGRESS
    }
    if (eventKind === 'done') {
      return SUB_LABELS.BOUGHT_BACK
    }
  }

  return 'N/A'
}

export const displaySubStatus = (sub: ModelTypes['Sub'], lang = LANG.FR) => {
  const status = computeSubStatus(sub)
  let flag = computeSubFlag(sub)

  const translate = lang === LANG.FR ? translateSubStatus : translateSubStatusEN

  const flagStatus = flag && translate(flag)
  flag = flagStatus && ['(', flagStatus, ')'].join('')

  return {
    status: translate(status),
    flag: flagStatus,
    fullStatus: [translate(status), flag].join(' '),
  }
}

export const computeSubFlag = (
  sub: Pick<ModelTypes['Sub'], 'standbyFlagEnabledAt' | 'supportFlagEnabledAt'>,
): string | undefined => {
  if (sub.standbyFlagEnabledAt) {
    return FLAG_KIND.STANDBY
  } else if (sub.supportFlagEnabledAt) {
    return FLAG_KIND.SUPPORT
  }
  return undefined
}

export const computeSubStatus = (sub: Partial<ModelTypes['Sub']>, allowCustomerService = false) => {
  if (sub.lastFlux?.kind === FluxKind.buy_back && sub.lastFlux?.lastEvent?.kind === FluxEventKind.done) {
    return 'bought_back'
  }
  if (sub.terminatedAt) {
    return 'terminated'
  }
  if (sub.canceledAt) {
    return 'canceled'
  }
  if (!sub?.lastFlux && !sub.state?.lastFlux && sub.fluxes?.length !== 0) {
    return 'no_flux_attached'
  }

  let lastFlux = sub?.lastFlux
  if (!lastFlux) {
    if (sub.state?.lastFlux) {
      lastFlux = { ...sub.state.lastFlux }
      lastFlux.lastEvent = sub.state.lastEvent
    } else {
      const activeFluxes = sub.fluxes?.filter((flux) => !flux?.canceledAt)
      if (activeFluxes && activeFluxes.length > 0) {
        lastFlux = activeFluxes[activeFluxes.length - 1]
      }
    }
  }

  if (!lastFlux?.kind) {
    return 'no_flux_attached'
  }

  return computeFluxStatus(lastFlux, allowCustomerService)
}

export const computeFluxStatus = (
  flux: Pick<ModelTypes['Flux'], 'events' | 'lastEvent' | 'kind'>,
  allowCustomerService = false,
) => {
  if ((!flux.events || !flux.events.length) && !flux.lastEvent) {
    return 'flux_created_but_no_events'
  }

  const lastEvent = flux.lastEvent || (flux?.events?.length ? flux.events[flux.events.length - 1] : undefined)

  return makeFluxStatus(flux.kind, lastEvent?.kind, allowCustomerService)
}

export const translateSubStatus = (s: string) => {
  if (!s || typeof s !== 'string') {
    console.error('wrong type for string:', s)
    return 'Erreur'
  }

  s = s.toUpperCase()
  return (
    {
      CANCEL: 'Annuler',
      CANCELED: 'Annulé',
      SUB_PROCESSING: 'Traitement en cours',
      SUB_VALIDATED: 'Validé',
      SHIPPING_IN_PROGRESS: 'Livraison en cours',
      RENTING: 'Livré',
      CUSTOMER_SERVICE_REQUESTED: 'Traitement en cours',
      CUSTOMER_SERVICE_GRANTED: 'Validé',
      CUSTOMER_SERVICE_SHIPPING_IN_PROGRESS: 'Livraison en cours',
      CUSTOMER_SERVICE_DELIVERY_FINISHED: 'Livré',
      CUSTOMER_SERVICE_RETRIEVAL_REQUESTED: 'Retour demandé',
      CUSTOMER_SERVICE_RETRIEVAL_IN_PROGRESS: 'Retour en cours',
      CUSTOMER_SERVICE_RETRIEVAL_FINISHED: 'Retourné',
      BOUGHT_BACK: 'Racheté',
      BUY_BACK_REQUESTED: 'Rachat demandé',
      BUY_BACK_IN_PROGRESS: 'Rachat en cours',
      RETRIEVAL_REQUESTED: 'Retour demandé',
      RETRIEVAL_IN_PROGRESS: 'Retour en cours',
      TERMINATED: 'Retourné',
      STANDBY: 'En standby',
      PRODUCT_ALLOCATED: 'Produit alloué',
      NO_FLUX_ATTACHED: 'Commande en erreur', // we prefer to display "error" so the clients warn the support and we, developers, investigate to find out why the sub reached this state
      FLUX_CREATED_BUT_NO_EVENTS: 'Commande en erreur', // we prefer to display "error" so the clients warn the support and we, developers, investigate to find out why the sub reached this state
      IN_PROGRESS: 'Transport en cours',
      FLAGGED_STANDBY: 'Commande en attente',
      FLAGGED_SUPPORT: 'Support en cours',
      DONE: 'En location',
    }[s] || s
  )
}

export const translateFluxKind = (s: string) => {
  if (!s || typeof s !== 'string') {
    console.error('wrong type for string:', s)
    return 'Erreur'
  }
  s = s.toUpperCase()
  return (
    {
      DELIVERY: 'Livraison',
      RETRIEVAL: 'Retour',
      CUSTOMER_SERVICE_DELIVERY: 'Support livraison',
      CUSTOMER_SERVICE_RETRIEVAL: 'Support retour',
      DELIVERY_CONSUMABLE: 'Livraison consommable',
    }[s] || s
  )
}

export const translateSubStatusEN = (s: string) => {
  if (!s || typeof s !== 'string') {
    console.error('wrong type for string:', s)
    return 'Erreur'
  }

  s = s.toUpperCase()
  return (
    {
      CANCEL: 'Cancel',
      CANCELED: 'Canceled',
      SUB_PROCESSING: 'Pending',
      DO_SUB_PROCESSING: 'Product allocated',
      DO_SUB_VALIDATED: 'Allocate product',
      SUB_VALIDATED: 'Validated',
      UNDO_SUB_VALIDATED: 'Deallocate product',
      DO_SHIPPING_IN_PROGRESS: 'Ship product',
      SHIPPING_IN_PROGRESS: 'Shipping in progress',
      UNDO_SHIPPING_IN_PROGRESS: 'Unship product',
      DO_RENTING: 'Deliver',
      RENTING: 'Delivered',
      UNDO_RENTING: 'Cancel delivery',
      CUSTOMER_SERVICE_REQUESTED: 'Pending',
      DO_CUSTOMER_SERVICE_GRANTED: 'Allocate support product',
      CUSTOMER_SERVICE_GRANTED: 'Validated',
      UNDO_CUSTOMER_SERVICE_GRANTED: 'Deallocate support product',
      DO_CUSTOMER_SERVICE_SHIPPING_IN_PROGRESS: 'Ship support product',
      CUSTOMER_SERVICE_SHIPPING_IN_PROGRESS: 'Shipping in progress',
      UNDO_CUSTOMER_SERVICE_SHIPPING_IN_PROGRESS: 'Unship support product',
      DO_CUSTOMER_SERVICE_DELIVERY_FINISHED: 'Deliver support product', // @to change
      CUSTOMER_SERVICE_DELIVERY_FINISHED: 'Delivered',
      UNDO_CUSTOMER_SERVICE_DELIVERY_FINISHED: 'Cancel support product delivery',
      CUSTOMER_SERVICE_RETRIEVAL_REQUESTED: 'Pending',
      DO_CUSTOMER_SERVICE_RETRIEVAL_IN_PROGRESS: 'Validate support return shipping',
      CUSTOMER_SERVICE_RETRIEVAL_IN_PROGRESS: 'Return shipping in progress',
      UNDO_CUSTOMER_SERVICE_RETRIEVAL_IN_PROGRESS: 'Unvalidate support return shipping',
      DO_CUSTOMER_SERVICE_RETRIEVAL_FINISHED: 'Validate support product delivery', // @to change
      CUSTOMER_SERVICE_RETRIEVAL_FINISHED: 'Returned',
      UNDO_CUSTOMER_SERVICE_RETRIEVAL_FINISHED: 'Cancel support product delivery back',
      DO_RETRIEVAL_REQUESTED: 'Return requested',
      RETRIEVAL_REQUESTED: 'Return pending',
      DO_RETRIEVAL_IN_PROGRESS: 'Validate return shipping',
      RETRIEVAL_IN_PROGRESS: 'Return shipping in progress',
      UNDO_RETRIEVAL_IN_PROGRESS: 'Unvalidate return shipping',
      DO_TERMINATED: 'Validate delivery back',
      TERMINATED: 'Returned',
      UNDO_TERMINATED: 'Cancel delivery back',
      BOUGHT_BACK: 'Bought back',
      BUY_BACK_REQUESTED: 'User asked to buy back',
      DO_BUY_BACK_REQUESTED: 'Ask to buy back',
      UNDO_BUY_BACK_REQUESTED: 'Cancel request to buy back',
      BUY_BACK_IN_PROGRESS: 'Currently buying back',
      DO_BUY_BACK_IN_PROGRESS: 'Buy back in progress',
      UNDO_BUY_BACK_IN_PROGRESS: 'Buy back cancelled',
      BUY_BACK_DONE: 'Bought back',
      DO_BUY_BACK_DONE: 'Bought back',
      UNDO_BUY_BACK_DONE: 'Cancel bought back',
      NO_FLUX_ATTACHED: '(!) No flux attached',
      FLUX_CREATED_BUT_NO_EVENTS: '(!) No event',
      FLAGGED_SUPPORT: 'Support',
      FLAGGED_STANDBY: 'Standby',
      STANDBY: 'Pending product',
      PRODUCT_ALLOCATED: 'Product allocated',
      IN_PROGRESS: 'Transport in progress',
      DONE: 'Transport done',
    }[s] || s
  )
}

export const translateFluxKindEN = (s: FluxKind, canceled = false) => {
  if (canceled) {
    return 'Cancelled'
  }

  if (!s || typeof s !== 'string') {
    console.error('wrong type for string:', s)
    return 'Erreur'
  }

  return (
    {
      BUY_BACK: 'Buy back',
      DELIVERY: 'Delivery',
      RETRIEVAL: 'Return',
      CUSTOMER_SERVICE_DELIVERY: 'Support Delivery',
      CUSTOMER_SERVICE_RETRIEVAL: 'Support Return',
      DELIVERY_CONSUMABLE: 'Delivery Consumable',
    }[s.toUpperCase()] || s.toUpperCase()
  )
}

export const translateFluxEventKindEN = (s: string) => {
  if (!s || typeof s !== 'string') {
    console.error('wrong type for string:', s)
    return 'Erreur'
  }
  s = s.toUpperCase()
  return (
    {
      STANDBY: 'Pending product',
      PRODUCT_ALLOCATED: 'Product allocated',
      IN_PROGRESS: 'Transport in progress',
      DONE: 'Transport done',
    }[s] || s
  )
}

export const fluxContextualWording = {
  delivery: {
    standby: {
      state: 'sub_processing',
      do: 'do_sub_processing',
      undo: 'undo_sub_processing',
    },
    product_allocated: {
      state: 'sub_validated',
      do: 'do_sub_validated',
      undo: 'undo_sub_validated',
    },
    in_progress: {
      state: 'shipping_in_progress',
      do: 'do_shipping_in_progress',
      undo: 'undo_shipping_in_progress',
    },
    done: {
      state: 'renting',
      do: 'do_renting',
      undo: 'undo_renting',
    },
  },
  customer_service_delivery: {
    standby: {
      state: 'customer_service_requested',
      do: 'do_customer_service_requested',
      undo: 'undo_customer_service_requested',
    },
    product_allocated: {
      state: 'customer_service_granted',
      do: 'do_customer_service_granted',
      undo: 'undo_customer_service_granted',
    },
    in_progress: {
      state: 'customer_service_shipping_in_progress',
      do: 'do_customer_service_shipping_in_progress',
      undo: 'undo_customer_service_shipping_in_progress',
    },
    done: {
      state: 'customer_service_delivery_finished',
      do: 'do_customer_service_delivery_finished',
      undo: 'undo_customer_service_delivery_finished',
    },
  },
  customer_service_retrieval: {
    standby: {
      state: 'customer_service_retrieval_requested',
      do: 'do_customer_service_retrieval_requested',
      undo: 'undo_customer_service_retrieval_requested',
    },
    in_progress: {
      state: 'customer_service_retrieval_in_progress',
      do: 'do_customer_service_retrieval_in_progress',
      undo: 'undo_customer_service_retrieval_in_progress',
    },
    done: {
      state: 'customer_service_retrieval_finished',
      do: 'do_customer_service_retrieval_finished',
      undo: 'undo_customer_service_retrieval_finished',
    },
  },
  retrieval: {
    standby: {
      state: 'retrieval_requested',
      do: 'do_retrieval_requested',
      undo: 'undo_retrieval_requested',
    },
    in_progress: {
      state: 'retrieval_in_progress',
      do: 'do_retrieval_in_progress',
      undo: 'undo_retrieval_in_progress',
    },
    done: {
      state: 'terminated',
      do: 'do_terminated',
      undo: 'undo_terminated',
    },
  },
  buy_back: {
    standby: {
      state: 'buy_back_requested',
      do: 'do_buy_back_requested',
      undo: 'undo_buy_back_requested',
    },
    in_progress: {
      state: 'buy_back_in_progress',
      do: 'do_buy_back_in_progress',
      undo: 'undo_buy_back_in_progress',
    },
    done: {
      state: 'buy_back_done',
      do: 'do_buy_back_done',
      undo: 'undo_buy_back_done',
    },
  },
}

export const fluxesFlow = {
  delivery: {
    standby: { next: 'product_allocated' },
    product_allocated: { previous: 'standby', next: 'in_progress' },
    in_progress: { previous: 'product_allocated', next: 'done' },
    done: { previous: 'in_progress' },
  },
  customer_service_delivery: {
    standby: { next: 'product_allocated' },
    product_allocated: { previous: 'standby', next: 'in_progress' },
    in_progress: { previous: 'product_allocated', next: 'done' },
    done: { previous: 'in_progress' },
  },
  customer_service_retrieval: {
    standby: { next: 'in_progress' },
    in_progress: { previous: 'in_progress', next: 'done' },
    done: { previous: 'in_progress' },
  },
  retrieval: {
    standby: { next: 'in_progress' },
    in_progress: { previous: 'in_progress', next: 'done' },
    done: { previous: 'in_progress' },
  },
  buy_back: {
    standby: { next: 'in_progress' },
    in_progress: { previous: 'in_progress', next: 'done' },
    done: { previous: 'in_progress' },
  },
}

export const translateAccountState = (s: string) => {
  return (
    {
      ACTIVATED: 'Activated',
      DEACTIVATED: 'Deactivated',
    }[s] || s.toUpperCase()
  )
}

export const translateKind = (s: string) => {
  return (
    {
      admin: 'Admin',
      user: 'User',
    }[s] || s.toUpperCase()
  )
}

export const translateKindFR = (s: string) => {
  return (
    {
      admin: 'Administrateur',
      user: 'Utilisateur',
    }[s] || s.toUpperCase()
  )
}

export const translateInformationName = (s: string) => {
  return (
    {
      connection: 'connectique',
      screenSize: "taille d'écran",
      processor: 'processeur',
      storage: 'stockage',
      vendor: 'marque',
      color: 'couleur',
      depthCm: 'profondeur',
      widthCm: 'longueur',
      heightCm: 'hauteur',
      dimensions: 'dimensions',
      weightG: 'poids',
      deliveryDelayDays: 'délai de livraison',
      commitmentPeriodDays: "période d'engagement",
    }[s] || s
  )
}

export const translateCategoryName = (s: string) => {
  return (
    {
      desk: 'Bureaux',
      chair: 'Chaises',
      light: 'Lampes',
      screen: 'Écrans',
      pack: 'Packs',
      highTech: 'High tech',
      computer: 'Ordinateurs',
      mouse: 'Souris',
      coffee: 'Café',
      seat: 'Assises',
      snacking: 'Snacking',
      accessories: 'Accessoires',
    }[s] || s
  )
}

export const translateUnitItemStatus = (status: string) =>
  ({
    requested: 'Requested',
    inStock: 'In-stock',
    quantityNeeded: 'Quantity Needed',
    allocated: 'Allocated',
    toBeDelivered: 'To-be-delivered',
    rented: 'Rented',
    returnRequested: 'Return Requested',
    deliveryBack: 'Delivery Back',
    quarantined: 'Quarantined',
    exit: 'Exit',
  }[status] || status)

export const translateSupplierMode = (s: string) => {
  return (
    {
      rented: 'Rented',
      purchased: 'Purchased',
      all: 'All',
    }[s] || s.toUpperCase()
  )
}

export const translateRequestOrderStatus = (s: string) => {
  return (
    {
      received: 'Received',
      partlyReceived: 'Partly Received',
      ongoing: 'Ongoing',
    }[s] || s?.toUpperCase()
  )
}

export const translateUserStatus = (status: string) => {
  if (!status) {
    return null
  }
  switch (status) {
    case USER_STATUS.pending:
      return 'Pending'
    case USER_STATUS.inactive:
      return 'Inactive'
    case USER_STATUS.active:
      return 'Active'
    case USER_STATUS.formerActive:
      return 'Former Active'
    case USER_STATUS.deactivated:
      return 'Deactivated'
    default:
      return 'Unknown'
  }
}

export const translateUserStatusV2 = (s: string) =>
  ({
    pending: 'Invitation en attente',
    inactive: 'Non équipé',
    active: 'Équipé',
    offboardingPending: 'Offboarding demandé',
    offboardingOngoing: 'Offboarding en cours',
    deactivated: 'Désactivé',
  }[s])

export const translateOffboardingStatus = (s: string) => {
  return {
    none: 'None',
    pending: 'Pending',
    ongoing: 'Ongoing',
    done: 'Done',
  }[s]
}

export const translateFinancialModel = (s: string) => {
  return {
    rentalAYG: 'Rental as-you-go',
    rentalPackage: 'Rental package',
    rentalPlatform: 'Rental platform',
  }[s]
}

export const translateProductAttributeType = (s: string) => {
  return {
    text: 'Text field',
    select: 'Multiple choice',
    boolean: 'Yes/No',
  }[s]
}

export const booleanToEnglish = (bool: boolean) => {
  return bool === true ? 'Yes' : bool === false ? 'No' : '-'
}

export const computeUserStatus = ({ accountDisabledAt, accountActivatedAt, subs }: any) => {
  if (accountDisabledAt) {
    return USER_STATUS.deactivated
  }
  if (!accountActivatedAt) {
    return USER_STATUS.pending
  }
  if (!subs?.length) {
    return USER_STATUS.inactive
  }
  if (!subs.some((sub: any) => !(sub.canceledAt || sub.terminatedAt))) {
    return USER_STATUS.formerActive
  }
  return USER_STATUS.active
}

export const computeUserStatusV2 = ({ accountDisabledAt, accountOffboardedAt, accountActivatedAt, subs }: any) => {
  if (!accountActivatedAt) {
    return USER_STATUS_V2.pending
  }
  if (accountDisabledAt) {
    return USER_STATUS_V2.deactivated
  }
  if (accountOffboardedAt) {
    if (subs.some(({ canceledAt, terminatedAt }: any) => !(canceledAt || terminatedAt))) {
      return USER_STATUS_V2.offboardingPending
    }
    return USER_STATUS_V2.offboardingOngoing
  }
  if (!subs.length || subs.every(({ canceledAt, terminatedAt }: any) => canceledAt || terminatedAt)) {
    return USER_STATUS_V2.inactive
  }
  return USER_STATUS_V2.active
}

export const computeOffboardingStatus = ({ accountOffboardedAt, subs }: any) => {
  if (!accountOffboardedAt) {
    return OFFBOARDING_STATUS.none
  }
  if (
    accountOffboardedAt < new Date() &&
    (!subs.length || subs.every(({ canceledAt, terminatedAt }: any) => canceledAt || terminatedAt))
  ) {
    return OFFBOARDING_STATUS.done
  }
  if (
    subs.some(
      ({ canceledAt, terminatedAt, lastFlux }: any) =>
        !canceledAt &&
        !terminatedAt &&
        (lastFlux.kind === FluxKind.delivery || lastFlux.kind === FluxKind.customer_service_delivery),
    )
  ) {
    return OFFBOARDING_STATUS.pending
  }
  return OFFBOARDING_STATUS.ongoing
}

export const ONBOARDING_LABELS = {
  CREATE_PLATFORM: 'create_platform',
  ADD_LOGO: 'add_logo',
  ADD_COLOR: 'add_color',
  ADD_MEMBERS: 'add_members',
}

export const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  notation: 'standard',
})

export const formatDateV2 = (inputDate: string | number, localize = 'fr', missingDateIndicator = '-') => {
  if (!inputDate) {
    return missingDateIndicator
  }
  moment.locale(localize)
  if (typeof inputDate === 'string') {
    inputDate = parseInt(inputDate)
  }
  return moment(inputDate).format(localize === 'fr' ? 'D MMM YYYY' : 'D MMM. YYYY')
}

export const formatDate = (inputDate: string | number, customFormat?: string, missingDateIndicator = '-') => {
  if (!inputDate) {
    return missingDateIndicator
  }
  if (typeof inputDate === 'string') {
    inputDate = parseInt(inputDate)
  }
  if (customFormat) {
    return moment(inputDate).format(customFormat)
  }

  const date = new Date(inputDate)
  const options: any = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return date.toLocaleDateString('fr-FR', options)
}

export const formatTime = (inputDate: string | number) => {
  if (typeof inputDate === 'string') {
    inputDate = parseInt(inputDate)
  }
  const date = new Date(inputDate)
  const options: any = { hour: 'numeric', minute: 'numeric' }
  return date.toLocaleTimeString('fr-FR', options)
}

export const formatDateTime = (inputDate: string | number) => {
  if (typeof inputDate === 'string') {
    inputDate = parseInt(inputDate)
  }
  const date = new Date(inputDate)
  const options: any = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  return date.toLocaleString('fr-FR', options)
}

export const emptyStringToNull = <T>(value: T, originalValue: unknown) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }

  return value
}

export const daysTo = (firstDate: any, secondDate: any) => {
  return moment(firstDate).diff(moment(secondDate), 'days')
}

export const parsePrice = (price: string) => {
  // we replace "," by "." because parseFloat doesn't recognize commas
  return price ? parseFloat(price.replace(',', '.')) : null
}

export const parseDate = (value: any) => {
  return value ? moment(value, 'DD/MM/YYYY', true).toDate() : null
}

export const addDays = (date: any, days: number) => {
  return moment(date, 'DD/MM/YYYY', true).add(days, 'days').toDate()
}

export const isDate = (value: any, format = 'DD/MM/YYYY') => moment(value, format, true).isValid()

export const escapeRegex = (s: string) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')

export const debounce = (func: any, ms: number) => {
  let timer: any
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args) // eslint-disable-line  no-invalid-this
    }, ms)
  }
}

export const hasCommonElements = <T>(arr1: T[], arr2: T[]) => arr1.some((el) => arr2.indexOf(el) > -1)

export const checkCanCancelSub = (sub: Sub, onCancelingDelivery: any) =>
  sub.fluxes?.every(
    ({ kind, canceledAt, events }) =>
      canceledAt ||
      events[events.length - 1].kind === FluxEventKind.done ||
      (onCancelingDelivery && kind === FluxKind.delivery),
  )

export const EURO_TO_POINTS_COEF = 50
export const convertEuroToPoints = (price: number) => Math.round(price) * EURO_TO_POINTS_COEF
export const displayPointsValue = (price: number) =>
  typeof price === 'number' ? Math.floor(convertEuroToPoints(price)) : 0
export const displayPoints = (price: number) => `${displayPointsValue(price)} points`

export const formattingPrice = (price: number, shouldHidePrices?: boolean) =>
  shouldHidePrices ? displayPoints(price) : priceFormatter.format(price)

export const compareStringBase = (a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0

export const sortMultiselectOptions = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label)
export const sortSelectOptions = (a: { value: string }, b: { value: string }) => a.value.localeCompare(b.value)

export const normalizeString = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

// prefix all required escaped characters (. ^ $ * + - ? ( ) [ ] { } \ | — /) with a backslash
export const cleanForRegex = (str: string) => str.replace(/[.^\-{}|?—*+()[]\\\/]/g, '\\$&')

export const capitalize = (str: string) => str && str[0].toUpperCase() + str.slice(1)
export const computeItemName = ({ typology, brand, model, variant }: any) => {
  return [capitalize(typology?.nameFR), brand?.name, model, variant].join(' ').trim()
}

export const alternativeUuid = (uuid: string) => `alt-${uuid}`
export const normalizeUuid = (uuid: string) => uuid.slice(4)

const processField = (newField?: string) => {
  const field = newField?.replace(/[^a-zA-Z0-9]/g, '')
  if (!field) {
    return 'XXX'
  }
  if (field.length === 1) {
    return field[0].toUpperCase() + 'XX'
  }
  if (field.length === 2) {
    return field.slice(0, 2).toUpperCase() + 'X'
  }
  return field.slice(0, 3).toUpperCase()
}

export const computeItemSku = ({ familyName, nameEN, brand, model }: any) => {
  return [processField(familyName), processField(nameEN), processField(brand), processField(model)].join('').trim()
}

export const extractSelected = (map: any) =>
  Object.entries(map).reduce<any>((acc, [key, value]) => (value ? [...acc, key] : acc), [])

export const compareMapSelections = (s1: any, s2: any) => {
  const entries1 = Object.entries(s1)
  if (entries1.length !== Object.keys(s2).length) {
    return false
  }

  for (const [key, value] of entries1) {
    if (!(key in s2) || value !== s2[key]) {
      return false
    }
  }

  return true
}

export const createNameWithIndex = (name: string, index: number) => [name, index].join('-')

export const getRandomHexColor = () => `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16).toString().padStart(2, '0'),
        g: parseInt(result[2], 16).toString().padStart(2, '0'),
        b: parseInt(result[3], 16).toString().padStart(2, '0'),
      }
    : null
}

export const getColorMatchingBackground = (background: string) => {
  const rgb = hexToRgb(background)
  if (rgb === null) {
    return 'black'
  }
  const brightness = Math.round((parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) / 1000)

  return brightness > 125 ? 'black' : 'white'
}

export const fluxesName: FluxKind[] = [
  FluxKind.delivery,
  FluxKind.retrieval,
  FluxKind.buy_back,
  FluxKind.customer_service_delivery,
  FluxKind.customer_service_retrieval,
]

export const fluxesEvent: FluxEventKind[] = [
  FluxEventKind.standby,
  FluxEventKind.product_allocated,
  FluxEventKind.in_progress,
  FluxEventKind.done,
]
export const subEndingEvents = ['canceled', 'terminated']

export const getAllFlux = ({ lang = LANG.FR, allowCustomerService = false, fluxNames = fluxesName }) =>
  fluxNames.map((fluxName) => {
    const events = fluxesEvent.reduce<any>((acc, fluxEvent) => {
      if (
        fluxEvent !== FluxEventKind.product_allocated ||
        fluxName === FluxKind.delivery ||
        fluxName === FluxKind.customer_service_delivery
      ) {
        return [
          ...acc,
          {
            key: fluxEvent,
            value:
              lang === LANG.FR
                ? translateSubStatus(makeFluxStatus(fluxName, fluxEvent, allowCustomerService))
                : translateSubStatusEN(makeFluxStatus(fluxName, fluxEvent, allowCustomerService)),
          },
        ]
      }
      return acc
    }, [])

    const fluxNameValue = lang === LANG.FR ? translateFluxKind(fluxName) : translateFluxKindEN(fluxName)
    return { title: fluxNameValue, key: fluxName, values: events }
  })

export const PENDING_BUDGET_UUID = 'PENDING_BUDGET'
export const defaultPendingBudget = { uuid: PENDING_BUDGET_UUID, locationCostCap: 0, serviceSubscriptionCost: 0 }

const vowelRegex = '^[aieouAIEOU].*'
export const prefixDe = (word: string) => (word.match(vowelRegex) ? "d'" : 'de ')

// TODO remove once we have international app
const FAMILY_TRANSLATION = {
  Accessories: 'Accessoires',
  'High Tech': 'High-tech',
  Lamp: 'Lampes',
  Desk: 'Bureaux',
  Chair: 'Chaises',
  Coffee: 'Café',
} as const

export const translateFamilyName = (nameEN: FamilyTransaction) => FAMILY_TRANSLATION[nameEN] || nameEN

export const groupColors = [
  { label: 'Violet', value: '#8472F4' },
  { label: 'Turquoise', value: '#9CD8D4' },
  { label: 'Jaune', value: '#FBBC05' },
  { label: 'Rose', value: '#DBAEA3' },
  { label: 'Vert', value: '#ACCD92' },
  { label: 'Bleu', value: '#1371FD' },
  { label: 'Rouge', value: '#E98383' },
  { label: 'Corail', value: '#FF7F50' },
  { label: 'Orange', value: '#F29C4D' },
  { label: 'Fuschia', value: '#EC64E6' },
  { label: 'Or', value: '#DFC877' },
  { label: 'Lavande', value: '#CFCFFB' },
]

export const compareStringArrays = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const a1 = [...arr1].sort()
  const a2 = [...arr2].sort()
  for (let i = 0; i < arr1.length; i++) {
    if (a1[i] !== a2[i]) {
      return false
    }
  }

  return true
}

export const displayReturnMotiv = (
  motiv: ProductReturnReason,
): 'Je souhaite retourner un produit' | 'Receptionné il y a moins de 72h' | 'Je quitte mon entreprise' => {
  switch (motiv) {
    case ProductReturnReason.leaving:
      return 'Je quitte mon entreprise'
    case ProductReturnReason.less72h:
      return 'Receptionné il y a moins de 72h'
    case ProductReturnReason.more72h:
      return 'Je souhaite retourner un produit'
  }
}

export const displaySubReason = (
  subReason: ProductReturnSubReason,
):
  | 'Le produit est endommagé et je souhaite le remplacer'
  | 'Le produit est endommagé et je ne souhaite pas le remplacer'
  | 'Je ne souhaite plus utiliser ce produit / ce produit ne me convient pas' => {
  switch (subReason) {
    case ProductReturnSubReason.brokenReplace:
      return 'Le produit est endommagé et je souhaite le remplacer'
    case ProductReturnSubReason.brokenNoReplace:
      return 'Le produit est endommagé et je ne souhaite pas le remplacer'
    case ProductReturnSubReason.notSuitable:
      return 'Je ne souhaite plus utiliser ce produit / ce produit ne me convient pas'
  }
}

type DisplayProductStateLocalized = { as_new: string; partially_broken: string; broken: string }

export const displayProductState = (
  productState: ProductReturnState,
  localizedString: DisplayProductStateLocalized,
): string => {
  switch (productState) {
    case ProductReturnState.asNew:
      return localizedString.as_new
    case ProductReturnState.partiallyBroken:
      return localizedString.partially_broken
    case ProductReturnState.fullyBroken:
      return localizedString.broken
  }
}

export const PRODUCT_STATE_OPTIONS = (localizedString: DisplayProductStateLocalized) =>
  typedObjectKeys(ProductReturnState).map((key) => ({
    value: key,
    label: displayProductState(key, localizedString),
  }))
