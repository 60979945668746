import React from 'react'
import { object, string } from 'yup'

import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import { DynamicPasswordHint } from '../../../components/Atoms/PasswordHint'
import WizardFormStep from '../../../components/organisms/WizardFormStepV2'

const formSections = [
  {
    submitText: 'Changer mon mot de passe',
    formInputs: [
      {
        name: 'newPassword',
        label: 'Mot de passe',
        type: FORM_ELEMENT_TYPE.PASSWORD,
        className: 'sign-input',
        dynamicHint: (value) => <DynamicPasswordHint value={value} />,
      },
    ],
    validationSchema: object().shape({
      newPassword: string()
        .min(12)
        .matches(/[A-Z]/, 'Doit contenir au moins une majuscule')
        .matches(/[a-z]/, 'Doit contenir au moins une minuscule')
        .matches(/[0-9]/, 'Doit contenir au moins un chiffre')
        .matches(/[*.@#$%^&(){}[\]:;<>,.?/~_+=|\\!]/, 'Doit contenir au moins un caractère spécial')
        .required('Obligatoire')
        .label('Mot de passe'),
    }),
  },
]

const ResetCallbackForm = ({ onSubmit }) => {
  return (
    <div className="reset-callback-form-V2">
      <h1 className="reset-callback-form-V2__title">Entrez un nouveau mot de passe</h1>

      <div className="reset-callback-form-V2__content">
        <WizardFormStep
          formSection={formSections[0]}
          formData={{}}
          onSubmit={onSubmit}
          hasPrevious={false}
          showStepper={false}
          currentStep={0}
          totalSteps={1}
        />
      </div>
    </div>
  )
}

export default ResetCallbackForm
