import { FluxEventKind, FluxKind } from '@prisma/client' // Can't use zeus type because zeus is using shitty const enum

type LastFluxWithLastEvent = {
  lastFlux: {
    kind: FluxKind
    lastEvent: {
      kind: FluxEventKind
    }
  }
}

export const isSubDelivered = (sub: LastFluxWithLastEvent) =>
  (sub.lastFlux.kind === FluxKind.delivery || sub.lastFlux.kind === FluxKind.customer_service_delivery) &&
  sub.lastFlux.lastEvent.kind === FluxEventKind.done

export const isSubShippingInProgress = (sub: LastFluxWithLastEvent) =>
  (sub.lastFlux.kind === FluxKind.delivery || sub.lastFlux.kind === FluxKind.customer_service_delivery) &&
  sub.lastFlux.lastEvent.kind === FluxEventKind.in_progress

export const isSubActive = (sub: { terminatedAt?: string | Date | null; canceledAt?: string | Date | null }) => {
  return !sub.canceledAt && !sub.terminatedAt
}

export const isSubStandbyDelivery = (sub: LastFluxWithLastEvent) =>
  (sub.lastFlux.kind === FluxKind.delivery || sub.lastFlux.kind === FluxKind.customer_service_delivery) &&
  sub.lastFlux.lastEvent.kind === FluxEventKind.standby

export const isSubProductAllocatedDelivery = (sub: LastFluxWithLastEvent) =>
  (sub.lastFlux.kind === FluxKind.delivery || sub.lastFlux.kind === FluxKind.customer_service_delivery) &&
  sub.lastFlux.lastEvent.kind === FluxEventKind.product_allocated

export const isSubBoughtBack = (sub: LastFluxWithLastEvent) =>
  sub.lastFlux.kind === FluxKind.buy_back && sub.lastFlux.lastEvent.kind === FluxEventKind.done

export const isSubBuyingBack = (sub: LastFluxWithLastEvent) =>
  sub.lastFlux.kind === FluxKind.buy_back && sub.lastFlux.lastEvent.kind === FluxEventKind.in_progress

export const isSubReturned = (sub: LastFluxWithLastEvent) =>
  (sub.lastFlux.kind === FluxKind.retrieval || sub.lastFlux.kind === FluxKind.customer_service_retrieval) &&
  sub.lastFlux.lastEvent.kind === FluxEventKind.done
