import { useState } from 'react'

export const ZendeskAPI = (...args: unknown[]) => {
  if (!window.zE) {
    console.warn('Zendesk not initialized')
    return
  }

  window.zE(...args)
}

export const initZendeskMessengerBehaviour = () => {
  if (!window.zE) {
    console.warn('Zendesk not initialized')
    return
  }

  window.zE('messenger:on', 'close', () => {
    window.zE('messenger', 'hide')
  })
}

export const useCountZendeskNotifications = () => {
  const [countNotifications, setCountNotifications] = useState(0)

  if (!window.zE) {
    console.warn('Zendesk not initialized')
    return
  }

  window.zE('messenger:on', 'unreadMessages', (count: number) => {
    if (count !== countNotifications) {
      setCountNotifications(count)
    }
  })

  return countNotifications
}

export const showAndOpenZendesk = () => {
  ZendeskAPI('messenger', 'show')
  ZendeskAPI('messenger', 'open')
}

export const showZendesk = () => {
  ZendeskAPI('messenger', 'show')
}
