import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client'
import * as Sentry from '@sentry/react'
import Axios from 'axios'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { setLocale } from 'yup'

import { RequireAuth } from '../components/RequireAuth'
import { AuthProvider } from '../components/contexts/auth/AuthProvider'
import { Zendesk } from '../components/molecules/Zendesk'
import { apiBaseUrl } from '../constants/vars'
import { authLink, errorLink, httpLink } from './apollo-utils'
import Authentication from './authentication'
import SignoutPage from './authentication/signout'
import Errors from './errors'

import 'minireset.css'
import 'react-toastify/dist/ReactToastify.min.css'
import './App.scss'

const Market = React.lazy(() => import('./market'))
const Admin = React.lazy(() => import('./admin'))
const Merchant = React.lazy(() => import('./merchant'))
const Staff = React.lazy(() => import('./staff'))
const Surveys = React.lazy(() => import('./surveys'))

const appVersion = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || 'development'
const fleexEnvironment = process.env.REACT_APP_VERCEL_ENV || 'development'

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Typology: {
        keyFields: ['uuid'],
        fields: {
          product: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
        },
      },
      Item: {
        keyFields: ['uuid'],
      },
      Product: {
        keyFields: ['uuid'],
        fields: {
          brand: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
        },
      },
      Sub: {
        keyFields: ['uuid'],
      },
      Client: {
        keyFields: ['uuid'],
      },
      Company: {
        keyFields: ['uuid'],
        fields: {
          companySettings: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
        },
      },
      Flux: {
        keyFields: ['uuid'],
      },
      FluxEvent: {
        keyFields: ['uuid'],
      },
      UnitItem: {
        keyFields: ['uuid'],
      },
      UnitItemEvent: {
        keyFields: ['uuid'],
      },
      RequestOrder: {
        keyFields: ['uuid'],
      },
      DeliveryReceipt: {
        keyFields: ['uuid'],
      },
      Address: {
        keyFields: ['uuid'],
      },
      BudgetSummary: {
        keyFields: ['uuid'],
      },
      BasketSummary: {
        keyFields: ['uuid'],
      },
      Offer: {
        keyFields: ['uuid'],
      },
      InvitedUser: {
        keyFields: ['uuid'],
      },
      Category: {
        keyFields: ['uuid'],
      },
      ProductListElement: {
        keyFields: ['uuid'],
      },
      MerchantCatalogElement: {
        keyFields: ['productUuid'],
      },
      CompanyGroup: {
        keyFields: ['uuid'],
      },
      CompanyBudget: {
        keyFields: ['uuid'],
      },
      Logistician: {
        keyFields: ['uuid'],
      },
      Supplier: {
        keyFields: ['uuid'],
      },
      MerchantCompany: {
        keyFields: ['uuid'],
      },
      ProductAttribute: {
        keyFields: ['uuid'],
      },
      ProductAttributeValue: {
        keyFields: ['uuid'],
      },
      Query: {
        fields: {
          paginatedSubs: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            merge(existing = { subs: [] }, incoming) {
              return incoming.pagination.initial
                ? incoming
                : { ...incoming, subs: [...existing.subs, ...incoming.subs] }
            },
          },
          paginatedFlows: {
            keyArgs: false,
            merge(existing = { flows: [] }, incoming) {
              return incoming.pagination.initial
                ? incoming
                : { ...incoming, flows: [...existing.flows, ...incoming.flows] }
            },
          },
          usersStaff: {
            keyArgs: false,
            merge(existing = { users: [] }, incoming) {
              return incoming.pagination.initial
                ? incoming
                : { ...incoming, users: [...existing.users, ...incoming.users] }
            },
          },
          usersStaffOffboarding: {
            keyArgs: false,
            merge(existing = { users: [] }, incoming) {
              return incoming.pagination.initial
                ? incoming
                : { ...incoming, users: [...existing.users, ...incoming.users] }
            },
          },
        },
      },
    },
  }),
})

export const App = () => {
  useEffect(() => {
    logOnAppInit()
  }, [])

  const { t } = useTranslation()

  if (typeof t('form_validation', { returnObjects: true }) === 'object') {
    setLocale(t('form_validation', { returnObjects: true }))
  }

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <React.Suspense fallback={<span>{t('common.loading')}</span>}>
            <SentryRoutes>
              <Route path="/signout/*" element={<SignoutPage />} />
              <Route
                path="/market/*"
                element={
                  <RequireAuth rights={['user', 'admin']}>
                    <Market />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <RequireAuth rights={['admin']}>
                    <Admin />
                  </RequireAuth>
                }
              />
              <Route
                path="/merchant/*"
                element={
                  <RequireAuth rights={['merchant']}>
                    <Merchant />
                  </RequireAuth>
                }
              />
              <Route
                path="/staff/*"
                element={
                  <RequireAuth rights={['staff']}>
                    <Staff />
                  </RequireAuth>
                }
              />
              <Route path="/survey/*" element={<Surveys />} />
              <Route path="/error" element={<Errors code="500" />} />
              <Route path="*" element={<Authentication />} />
            </SentryRoutes>
          </React.Suspense>
        </ApolloProvider>
        <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} hideProgressBar={true} />
      </AuthProvider>
      <Zendesk />
    </>
  )
}
const logOnAppInit = async () => {
  const apiVersion = await Axios.get(apiBaseUrl + '/version')
  setTimeout(() => {
    // eslint-disable-next-line no-console
    console.log(
      `Environnement: \t\t${fleexEnvironment}` +
        `\nVersion interface:\t${appVersion}` +
        `\nVersion serveur:\t${apiVersion.data.version ? apiVersion.data.version : apiVersion}`
    )
  }, 500)
}
