import classnames from 'classnames'
import * as React from 'react'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/tooltip-arrow.svg'
import useClickOutside from '../../hooks/useClickOutside'
import './style.scss'

export const TOOLTIP_TRIGGER = {
  HOVER: 'HOVER',
  CLICK: 'CLICK',
}

type TooltipProps = {
  direction?: 'top' | 'right' | 'bottom' | 'left'
  content?: string
  maxWidth?: string
  trigger?: string
  className?: string
  disabled?: boolean
  children: React.ReactNode
}

export const Tooltip = ({
  direction = 'top',
  content,
  maxWidth,
  trigger = TOOLTIP_TRIGGER.HOVER,
  className,
  disabled,
  children,
}: TooltipProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [active, setActive] = React.useState<boolean>()
  const [visible, setVisible] = React.useState<boolean>()

  const showTip = () => {
    setActive(true)
    setTimeout(() => {
      setVisible(true)
    }, 0)
  }

  const hideTip = () => {
    setActive(false)
    setVisible(false)
  }

  useClickOutside(ref, trigger === TOOLTIP_TRIGGER.CLICK && hideTip)

  const triggeringProps =
    trigger === TOOLTIP_TRIGGER.HOVER ? { onMouseEnter: showTip, onMouseLeave: hideTip } : { onClick: showTip }

  return (
    <div ref={ref} className={classnames('tooltip-V2', className)} {...triggeringProps}>
      {children}
      {active && !disabled && (
        <div
          className={classnames(`tooltip-V2__content ${direction}`, { visible })}
          style={maxWidth ? { width: maxWidth } : { whiteSpace: 'nowrap' }}
        >
          <ArrowIcon className={`tooltip-V2__arrow arrow-${direction}`} />
          {content}
        </div>
      )}
    </div>
  )
}
