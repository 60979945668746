import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { $, typedGql } from '@fleex/shared'

import { Button } from '../../../components/Atoms/ButtonV2/Button'
import FormElement from '../../../components/Atoms/FormElementV2/FormElementV2'
import { FORM_ELEMENT_TYPE } from '../../../components/Atoms/FormElementV2/FormElementV2.model'
import { extractErrorMessage } from '../../../constants/errors'

const SSO_CONNECTION_URL = typedGql('query')({
  getSSOConnectionUrl: [{ domain: $('domain', 'String!') }, true],
})

const SSOForm = ({ switchToBasicAuthentication }) => {
  const [domain, setDomain] = useState('')

  const { t } = useTranslation()

  const [getSSOConnectionUrl] = useLazyQuery(SSO_CONNECTION_URL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getSSOConnectionUrl }) => (window.location.href = getSSOConnectionUrl),
    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  const onSubmit = () => {
    getSSOConnectionUrl({ variables: { domain } })
  }

  return (
    <div className="sso-form-V2">
      <h1 className="sso-form-V2__title">{t('login.sso.title')}</h1>
      <p className="sso-form-V2__subtitle">{t('login.sso.subtitle')}</p>
      <FormElement
        type={FORM_ELEMENT_TYPE.TEXT}
        name="domain"
        value={domain}
        onChange={(value) => setDomain(value)}
        label={t('login.sso.domain')}
      />
      <Button className="sso-form-V2__submit full-width" kind="primary" onClick={onSubmit}>
        {t('login.login')}
      </Button>
      <Button kind="empty" className="sso-form-V2__back" onClick={switchToBasicAuthentication}>
        {t('login.sso.login_without_sso')}
      </Button>
    </div>
  )
}

export default SSOForm
