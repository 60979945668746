import { useCallback, useState, useEffect } from 'react'

import { UPLOAD_TYPE } from '@fleex/shared'

import { Dropzone } from './Dropzone'
import { ImageList } from './ImageList'

import './style.scss'

type FilePickerProps = {
  name: string
  label: string
  value: File[]
  accept: UPLOAD_TYPE
  maxFiles?: number
  maxSize?: number
  onChange: (file: File[]) => void
}

export type Image = { file: File & { preview: string; id: number } }

export const FilePicker = ({
  label,
  maxFiles = 1,
  maxSize = 5 * 1024 * 1024,
  value,
  onChange,
  accept,
}: FilePickerProps) => {
  const [images, setImages] = useState<File[]>([])

  useEffect(() => {
    if (value) {
      setImages(value)
    }
  }, [value])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (images.length >= maxFiles) {
        onChange([...images.slice(acceptedFiles.length), ...acceptedFiles])
      } else {
        onChange([...images, ...acceptedFiles])
      }
    },
    [images, onChange, maxFiles]
  )

  return (
    <div className="file-picker">
      <Dropzone label={label} onDrop={onDrop} maxFiles={maxFiles} maxSize={maxSize} accept={accept} />
      <ImageList files={images} remove={(index) => onChange(images.filter((_, i) => i !== index))} />
    </div>
  )
}
