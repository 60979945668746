import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { $, typedGql } from '@fleex/shared'

import { Button } from '../../../components/Atoms/ButtonV2/Button'
import { QueryHandler } from '../../../components/Atoms/QueryHandler'
import ResetCallbackForm from './ResetCallbackForm'

import './style.scss'

const QUERY_CHECK_RESET_TOKEN = typedGql('query')({
  checkResetToken: [{ token: $('token', 'String!') }, { uuid: true, firstName: true }],
})

const MUTATION_PASSWORD_RESET = typedGql('mutation')({
  resetPassword: [{ token: $('token', 'String!'), password: $('password', 'String!') }, true],
})

export default function Page() {
  const navigate = useNavigate()
  const { token } = useParams()
  const { loading, error, data } = useQuery(QUERY_CHECK_RESET_TOKEN, { variables: { token } })
  const [resetPassword, { data: resetData, error: resetError }] = useMutation(MUTATION_PASSWORD_RESET)

  useEffect(() => {
    if (resetError) {
      toast.error('Une erreur est survenue.')
    } else if (resetData && resetData.resetPassword) {
      navigate('/?passwordHasBeenReset=1')
    }
  }, [navigate, resetData, resetError])

  const onSubmit = ({ newPassword }) => {
    resetPassword({
      variables: { token, password: newPassword },
    })
  }

  if (data?.checkResetToken) {
    return <ResetCallbackForm onSubmit={onSubmit} />
  }

  return (
    <QueryHandler loading={loading} error={error}>
      <div className="reset-callback-form-V2__invalid">
        <h1 className="reset-callback-form-V2__invalid__title">
          Votre demande de reinitialisation de mot de passe est invalide.
        </h1>
        <Button linkTo="/reset-password" kind="primary" className="full-width">
          Faire une nouvelle demande
        </Button>
      </div>
    </QueryHandler>
  )
}
