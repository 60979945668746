import { useQuery } from '@apollo/client'
import { Navigate } from 'react-router-dom'

import { $, typedGql } from '@fleex/shared'

import { useAuth } from './contexts/auth/AuthProvider'

type Roles = 'user' | 'staff' | 'admin' | 'merchant'

type RequireAuthProps = {
  rights: Roles[]
  children: React.ReactElement
}

const QUERY_CLIENT = typedGql('query')({
  client: [{ userUuid: $('userUuid', 'ID!') }, { uuid: true, createdBy: { uuid: true }, passwordUpdatedAt: true }],
})

export const RequireAuth = ({ children, rights }: RequireAuthProps) => {
  const { user } = useAuth()
  const { loading, error, data } = useQuery(QUERY_CLIENT, {
    variables: {
      userUuid: user?.userUuid,
    },
    skip: !user?.userUuid,
  })

  if (loading) {
    return <p>Loading...</p>
  }
  if (error || !user) {
    return <Navigate replace to="/signout" />
  }

  if (data?.client && data.client.createdBy && !data.client.passwordUpdatedAt) {
    return <Navigate replace to="/account?warning=TEMPORARY_PASSWORD&tab=password" />
  }

  if (!rights.includes(user?.userType === 'client' ? user?.userKind : user?.userType)) {
    return <Navigate replace to="/" />
  }

  return children
}
