import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { $, typedGql, ValueTypes } from '@fleex/shared'

import { Identity, useAuth } from '../../../components/contexts/auth/AuthProvider'
import { extractErrorMessage } from '../../../constants/errors'
import SignupFormStep2 from '../signup-company-client/SignupFormStep2'

const SIGNUP_MUTATION_STEP_2 = typedGql('mutation')({
  signupCompanyClientAddress: [
    { clientUuid: $('clientUuid', 'ID!'), formData: $('formData', 'CompanyClientAddressCreationForm!') },
    true,
  ],
})

export const GoogleSignup = () => {
  const { state } = useLocation() as { state: Identity }
  const { setIdentity } = useAuth()
  const [isDone, setIsDone] = useState(false)

  const [signupCompanyClientAddress] = useMutation(SIGNUP_MUTATION_STEP_2, {
    onCompleted: () => {
      setIsDone(true)
    },
    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  useEffect(() => {
    if (isDone) {
      setIdentity(state)
    }
  }, [state, isDone, setIdentity])

  return (
    <SignupFormStep2
      onSubmit={(data: string | ValueTypes['CompanyClientAddressCreationForm']) => {
        typeof data === 'string'
          ? data === 'next' && setIsDone(true)
          : signupCompanyClientAddress({ variables: { clientUuid: state.userUuid, formData: data } })
      }}
      formData={{}}
      invited={undefined}
      stepProps={undefined}
    />
  )
}
