import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { $, typedGql } from '@fleex/shared'

const GOOGLE_URL_QUERY = typedGql('query')({
  googleConnectionUrl: [{ type: $('type', 'GoogleUrlType!') }, true],
})

const useGoogleUrl = (type) => {
  const { loading, error, data, refetch } = useQuery(GOOGLE_URL_QUERY, {
    variables: { type },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  })

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      refetch()
    }
  }, [location, refetch])

  return { loading, error, googleUrl: data?.googleConnectionUrl }
}

export default useGoogleUrl
