import classnames from 'classnames'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'

import { ReactComponent as EyeOff } from '../../../assets/icons/eye-off.svg'
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg'
import { PasswordProps } from './FormElementV2.model'

export const PasswordInput = <T extends FieldValues>({
  name,
  register,
  placeholder,
  error,
  style,
  onChange,
}: Omit<PasswordProps<T>, 'type'>) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const eyeButtonIcon = passwordShown ? <EyeOff /> : <Eye />
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown)

  return (
    <>
      <div
        className="form-element-v2__input--password-visible"
        key={eyeButtonIcon.key}
        onClick={() => togglePasswordVisibility()}
      >
        {eyeButtonIcon}
      </div>
      <input
        className={classnames('form-element-v2__input form-element-v2__input--password', { error })}
        style={{ ...style }}
        type={passwordShown ? 'text' : 'password'}
        onChange={(event) => onChange?.(event.target.value)}
        placeholder={placeholder}
        {...(register && name
          ? register(name, {
              onChange: (event) => {
                onChange?.(event.target.value)
              },
            })
          : undefined)}
      />
    </>
  )
}
