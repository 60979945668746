import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { $, typedGql } from '@fleex/shared'

import { extractErrorMessage } from '../../../constants/errors'
import { ResetForm } from './ResetForm'

import './style.scss'

const FORGET_PASSWORD_MUTATION = typedGql('mutation')({
  requestCompanyClientPasswordReset: [{ email: $('email', 'String!') }, true],
})

export default function Page() {
  const [resetPassword] = useMutation(FORGET_PASSWORD_MUTATION, {
    onCompleted: () => (window.location.href = '/reset-password/confirm'),

    onError: (error) => toast.error(extractErrorMessage(error)),
  })

  return <ResetForm makeOnSubmit={(data) => resetPassword({ variables: { email: data.email } })} />
}
