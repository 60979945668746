import { useLazyQuery } from '@apollo/client'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { translateError, $, typedGql } from '@fleex/shared'

import { QueryHandler } from '../../../components/Atoms/QueryHandler'
import { useAuth } from '../../../components/contexts/auth/AuthProvider'
import { extractErrorMessage } from '../../../constants/errors'
import { removeUrlParam } from '../../../constants/utils'
import SSOForm from './SSOForm'
import { SigninForm } from './SigninForm'

import './style.scss'

const SIGNIN_QUERY = typedGql('query')({
  authenticate: [
    {
      email: $('email', 'String'),
      password: $('password', 'String'),
      refreshToken: $('refreshToken', 'String'),
      type: $('type', 'String'),
    },
    {
      accessToken: true,
      refreshToken: true,
      zendeskToken: true,
      clientUuid: true,
      type: true,
      error: true,
    },
  ],
})

const getAlertNotification = (t, error, params, onSuccess, onError) => {
  if (error) {
    return { action: onError, message: translateError(error) }
  }

  if (params.error) {
    return {
      action: onError,
      message: (
        <p>
          {translateError(params.error)} Si vous avez besoin d'aide, envoyez un mail à{' '}
          <a href="mailto:support@fleex.com" style={{ color: 'white', textDecoration: 'underline' }}>
            support@fleex.com
          </a>
        </p>
      ),
    }
  }

  if (params.isJustActivated) {
    return { action: onSuccess, message: t('login.result.activated') }
  }

  if (params.passwordHasBeenReset) {
    return { action: onSuccess, message: t('login.result.password_reset') }
  }

  if (params.passwordResetSent) {
    return { action: onSuccess, message: t('login.result.password_reset_email_sent') }
  }

  if (params.linkedToGoogle) {
    return {
      action: onSuccess,
      message: t('login.result.linked_to_google'),
    }
  }

  return {}
}

export const authenticateUser = (result, login) => {
  login(result)
}

const SigninPage = () => {
  const location = useLocation()
  const { login } = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [shouldNotify, setShouldNotify] = useState(true)
  const { t } = useTranslation()

  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [loginType, setLoginType] = useState(params?.type !== 'SSO')
  const isBackOffice = params?.login === 'staff' || params?.login === 'merchant'

  const [authenticate, { loading }] = useLazyQuery(SIGNIN_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.authenticate) {
        if (data.authenticate.error) {
          setError(data.authenticate.error)
        } else {
          authenticateUser(data.authenticate, login)
        }
      }
    },
    onError: (error) => {
      setError(error)
    },
  })

  useEffect(() => {
    if (params.googleAuthentication) {
      toast.error(extractErrorMessage(params.googleAuthentication))
      removeUrlParam(location.search, navigate, 'googleAuthentication')
    }
  }, [params.googleAuthentication, navigate, location.search])

  useEffect(() => {
    const withParams = Object.keys(params).length
    if (withParams || error) {
      const { action, message } = getAlertNotification(t, error, params, toast.success, toast.error)
      if (error) {
        setError()
      }
      if (action && shouldNotify) {
        action(message)
        setShouldNotify(false)
      }
    }
  }, [error, params, shouldNotify]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (formData) => {
    const type = params.login || (isBackOffice ? 'merchant' : 'client')
    authenticate({
      variables: {
        email: formData.email,
        password: formData.password,
        type,
      },
      fetchPolicy: 'no-cache',
    })
  }

  return (
    <QueryHandler loading={loading}>
      {loginType ? (
        <SigninForm
          onSubmit={onSubmit}
          googleUrl={true}
          isBackOffice={isBackOffice}
          switchToSSO={() => setLoginType(false)}
        />
      ) : (
        <SSOForm switchToBasicAuthentication={() => setLoginType(true)} />
      )}
    </QueryHandler>
  )
}

export default SigninPage
