import classnames from 'classnames'
import React from 'react'

import './GroupLabel.scss'

type GroupLabelProps = {
  label: string
  color?: string
  className?: string
}

export const GroupLabel = ({ label, color, className }: GroupLabelProps) => (
  <span className={classnames('group-label', className)} style={{ borderColor: color }}>
    {label}
  </span>
)
