import { useMutation } from '@apollo/client'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { GOOGLE_REQUEST_TYPE, currentTosVersion, $, typedGql } from '@fleex/shared'

import useGoogleUrl from '../../../components/hooks/providers/useGoogleUrl'
import { extractErrorMessage } from '../../../constants/errors'
import { removeUrlParam } from '../../../constants/utils'
import SignupForm from './SignupForm'
import SignupFormStep2 from './SignupFormStep2'
import { SignupFormSuccess } from './SignupFormSuccess'

import './style.scss'

const SIGNUP_MUTATION = typedGql('mutation')({
  signupCompanyClient: [
    { formData: $('formData', 'CompanyClientCreationForm!') },
    { accountActivationToken: true, email: true, clientUuid: true },
  ],
})

const SIGNUP_MUTATION_STEP_2 = typedGql('mutation')({
  signupCompanyClientAddress: [
    { clientUuid: $('clientUuid', 'ID!'), formData: $('formData', 'CompanyClientAddressCreationForm!') },
    true,
  ],
})

const SEND_ACTIVATION_EMAIL = typedGql('mutation')({
  sendActivationEmail: [{ clientUuid: $('clientUuid', 'ID!') }, true],
})

export default function Page() {
  const navigate = useNavigate()
  const location = useLocation()
  const onError = (error) => toast.error(extractErrorMessage(error)) // eslint-disable-line react-hooks/exhaustive-deps

  const [currentStep, setCurrentStep] = useState(1)
  const [email, setEmail] = useState('')
  const [clientUuid, setClientUuid] = useState()
  const { invited, group, googleAuthentication } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [sendActivationEmail] = useMutation(SEND_ACTIVATION_EMAIL, {
    onCompleted: () => toast.success('Email renvoyé !'),
    onError,
  })

  const [signupCompanyClient] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      setClientUuid(data.signupCompanyClient.clientUuid)
      setEmail(data.signupCompanyClient.email)
      setCurrentStep(2)
      if (data && data.signupCompanyClient && data.signupCompanyClient.accountActivationToken) {
        setTimeout(() => {
          window.location.href = window.location.href.replace(
            '/signup/collaborator',
            `/activate-user/${data.signupCompanyClient.accountActivationToken}`,
          )
        })
      }
    },
    onError,
  })

  const [signupCompanyClientAddress] = useMutation(SIGNUP_MUTATION_STEP_2, {
    onCompleted: () => {
      setCurrentStep(3)
    },
    onError,
  })

  const { googleUrl } = useGoogleUrl(GOOGLE_REQUEST_TYPE.AUTHENTICATION)

  useEffect(() => {
    if (googleAuthentication) {
      onError(googleAuthentication)
      removeUrlParam(location.search, navigate, 'googleAuthentication')
    }
  }, [googleAuthentication, navigate, location.search, onError])

  switch (currentStep) {
    case 1:
      return (
        <SignupForm
          onSubmit={(data) =>
            signupCompanyClient({
              variables: {
                clientUuid,
                formData: { ...data, companyGroupUuid: group, tosSignedVersion: currentTosVersion.worker },
              },
            })
          }
          formData={{}}
          invited={invited}
          googleUrl={googleUrl}
        />
      )
    case 2:
      return (
        <SignupFormStep2
          onSubmit={(data) => {
            if (data === 'next') {
              return setCurrentStep(3)
            }
            delete data.email
            return signupCompanyClientAddress({ variables: { clientUuid, formData: data } })
          }}
          formData={{}}
          invited={invited}
        />
      )
    default:
      return (
        <SignupFormSuccess
          email={email}
          sendActivationEmail={() => sendActivationEmail({ variables: { clientUuid } })}
        />
      )
  }
}
