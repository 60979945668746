import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { Icon, IconSize, ICONS, ICON_SIZES } from '../Icon/Icon'

import './style.scss'

type ButtonProps = {
  kind?: 'primary' | 'secondary' | 'tertiary' | 'empty' | 'filter'
  linkTo?: string
  className?: string
  children?: React.ReactNode
  badge?: number
  iconLeft?: ICONS
  iconRight?: ICONS
  iconSize?: IconSize
  target?: string
  external?: boolean
  // All other props
  [x: string]: unknown
}

export const Button = ({
  children,
  linkTo,
  kind,
  className,
  iconLeft,
  iconRight,
  iconSize = ICON_SIZES.MD,
  badge,
  target,
  external,
  ...restProps
}: ButtonProps) => {
  const wrapperClassNames = classNames('button-FL-V2', className, {
    'button-FL-V2--primary': kind === 'primary',
    'button-FL-V2--secondary': kind === 'secondary',
    'button-FL-V2--tertiary': kind === 'tertiary',
    'button-FL-V2--empty': kind === 'empty',
    'button-FL-V2--filters': kind === 'filter',
  })

  const leftIcon = iconLeft && <Icon icon={iconLeft} size={iconSize} />
  const rightIcon = iconRight && <Icon icon={iconRight} size={iconSize} />

  const buttonComponent = (
    <button className={wrapperClassNames} {...restProps}>
      {leftIcon}
      <span className="content">{children}</span>
      {rightIcon}
      {badge ? <span className="badge">{badge}</span> : ''}
      {rightIcon}
      {badge ? <span className="badge">{badge}</span> : ''}
    </button>
  )

  if (external) {
    return (
      <a href={linkTo} target="_blank" rel="noreferrer">
        {buttonComponent}
      </a>
    )
  }

  return linkTo ? (
    <Link to={linkTo} className={wrapperClassNames} {...restProps} target={target}>
      {leftIcon}
      <span className="content">{children}</span>
      {rightIcon}
    </Link>
  ) : (
    <button className={wrapperClassNames} {...restProps}>
      {leftIcon}
      <span className="content">{children}</span>
      {rightIcon}
      {badge ? <span className="badge">{badge}</span> : ''}
    </button>
  )
}
