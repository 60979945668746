import classNames from 'classnames'
import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { bytesToSize, convertMimeType, readableFileFormat, UPLOAD_TYPE } from '@fleex/shared'

import './style.scss'

type DropzoneProps = {
  label: string
  accept: UPLOAD_TYPE
  maxFiles: number
  maxSize: number
  onDrop: (acceptedFiles: File[]) => void
}

export const Dropzone = ({ label, onDrop, accept, maxFiles, maxSize }: DropzoneProps) => {
  const mimeType = convertMimeType(accept)
    .split(';')
    .reduce((acc, mimeType) => ({ ...acc, [mimeType]: [] }), {})
  const { getRootProps, getInputProps, fileRejections, open, isDragAccept } = useDropzone({
    onDrop,
    accept: mimeType,
    maxFiles,
    maxSize,
    noClick: true,
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (fileRejections.length) {
      toast.error(t(`file-picker.errors.${fileRejections[0].errors[0].code}`), {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 5000,
      })
    }
  }, [fileRejections]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <div
        className={classNames('file-picker__dropzone', {
          'file-picker__dropzone--hover-accept': isDragAccept,
        })}
      >
        <input className="input-zone" {...getInputProps()} />
        <span className="file-picker__label">
          <span className="file-picker__label__label">{label}</span>
          <span className="file-picker__label__or">{t('file-picker.dropzone.or')}</span>
          <button type="button" onClick={open} className="file-picker__label__browse">
            {t('file-picker.dropzone.browse')}
          </button>
          <span className="file-picker__label__constraints">
            {t('file-picker.dropzone.accepted')} {readableFileFormat(accept)} ({bytesToSize(maxSize)} Max.)
          </span>
        </span>
      </div>
    </div>
  )
}
