import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { currentTosVersion, $, typedGql } from '@fleex/shared'

import { extractErrorMessage } from '../../../constants/errors'
import SignupForm from '../signup-company-client/SignupForm'
import SignupCompanyForm from './SignupCompanyForm'
import { SignupCompanyFormSuccess } from './SignupCompanyFormSuccess'

import './style.scss'

const SIGNUP_MUTATION = typedGql('mutation')({
  signupCompanyAdmin: [
    { formData: $('formData', 'CompanyAdminCreationForm') },
    { accountActivationToken: true, logoToken: true },
  ],
})

export default function Page() {
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({})

  const [SignupCompanyAdmin] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      setStep(3)

      // Redirect to activation if accountActivationToken was received
      if (data?.signupCompanyAdmin?.accountActivationToken) {
        setTimeout(() => {
          window.location.href = window.location.href.replace(
            '/signup/admin',
            `/activate-user/${data.signupCompanyAdmin.accountActivationToken}`,
          )
        })
      }
    },
    onError: (error) => {
      console.error('SignupCompany: Requesting error notification')
      toast.error(extractErrorMessage(error))
    },
  })

  switch (step) {
    case 1:
      return (
        <SignupForm
          formData={formData}
          onSubmit={(data) => {
            setFormData((oldValue) => ({ ...oldValue, ...data, tosSignedVersion: currentTosVersion.admin }))
            setStep(2)
          }}
          isGoogleConnectAvailable={false}
        />
      )
    case 2:
      return (
        <SignupCompanyForm
          formData={formData}
          onSubmit={(data) => {
            SignupCompanyAdmin({ variables: { formData: { ...formData, ...data } } })
          }}
          onBack={() => setStep(1)}
          hasPrevious={true}
        />
      )
    default:
      return <SignupCompanyFormSuccess />
  }
}
