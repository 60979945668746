import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { $, typedGql } from '@fleex/shared'

import { setValueFromLocalStorage } from '../../../constants/utils'

const MUTATION_ACTIVATE = typedGql('mutation')({
  activateCompanyClient: [{ token: $('token', 'String!') }, true],
})

export const ActivateCompanyClientPage = () => {
  setValueFromLocalStorage('authToken', '')
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>()

  const [activate, { loading, error, data }] = useMutation(MUTATION_ACTIVATE)

  useEffect(() => {
    activate({
      variables: { token: token ?? '' },
      errorPolicy: 'all',
    })
  }, [activate, token])

  if (!token) {
    return <Navigate replace to={'/?error=UNKNOWN_TOKEN'} />
  }
  if (loading) {
    return <p>{t('common.loading')}</p>
  }
  if (error) {
    const code = typeof error === 'string' ? (error as string).split(':')[1].trim() : error.message
    return <Navigate replace to={`/?error=${code}`} />
  }

  if (data?.activateCompanyClient) {
    return <Navigate replace to="/?isJustActivated=true" />
  }

  return <p></p>
}
