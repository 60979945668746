import { ReactComponent as FleexFullIcon } from '../../../assets/icons/fleex-logo-full.svg'
import { ReactComponent as FleexIcon } from '../../../assets/icons/fleex.svg'
import useScreenSize from '../../hooks/useScreenSize'
import { showAndOpenZendesk } from '../../hooks/useZendeskAPI'
import './style.scss'

const testimonials = [
  {
    name: 'Marie Charuit',
    role: 'People Experience Partner',
    image: 'https://storage.googleapis.com/fleex-assets/testimonials/swile/marie-charuit.png',
    text: '“Fleex est un grand succès ! Les employés sont ravis de recevoir chez eux leur matériel choisi de manière autonome.”',
    companyLogo: 'https://storage.googleapis.com/fleex-assets/clients/swile.svg',
    companyName: 'Swile',
  },
  {
    name: 'Pierre Mottais',
    role: 'Culture Manager',
    image: 'https://storage.googleapis.com/fleex-assets/testimonials/backmarket/pierre-mottais.png',
    text: "“La plateforme répond à un besoin fondamental chez nous\u00a0: le bien-être des employés, où qu'ils travaillent.”",
    companyLogo: 'https://storage.googleapis.com/fleex-assets/clients/backmarket.svg',
    companyName: 'Backmarket',
  },
  {
    name: 'Arthur Bequin',
    role: 'Chief Financial Officer',
    image: 'https://storage.googleapis.com/fleex-assets/testimonials/accessfirst/arthur-bequin.png',
    text: "“Fleex apporte l'effet waouh à l'onboarding de nos nouveaux employés !”",
    companyLogo: 'https://storage.googleapis.com/fleex-assets/clients/accessfirst.png',
    companyName: 'Accessfirst',
  },
]

const clientsLogo = [
  { src: 'https://storage.googleapis.com/fleex-assets/clients/swile.svg', name: 'swile' },
  { src: 'https://storage.googleapis.com/fleex-assets/clients/alan.svg', name: 'alan' },
  { src: 'https://storage.googleapis.com/fleex-assets/clients/efounders.svg', name: 'efounders' },
  { src: 'https://storage.googleapis.com/fleex-assets/clients/lifen.svg', name: 'lifen' },
]

const AuthenticationLayout = ({ children }) => {
  showAndOpenZendesk()
  const { phone, desktop } = useScreenSize()

  const randomTestimonial = testimonials[Math.floor(Math.random() * testimonials.length)]

  return (
    <div className="authentication-layout">
      {desktop && (
        <div className="authentication-layout__image-wrapper" style={{ height: '100vh', display: 'flex' }}>
          <div className="authentication-layout__image">
            <div className="authentication-layout__image__content">
              <a href="https://fleex.com" className="authentication-layout__image__content__logo no-hover">
                <FleexFullIcon />
              </a>
              <div className="authentication-layout__testimonials">
                <img
                  className="authentication-layout__testimonials__picture"
                  src={randomTestimonial.image}
                  alt={`${randomTestimonial.name}`}
                />
                <p className="authentication-layout__testimonials__name">{randomTestimonial.name}</p>
                <p className="authentication-layout__testimonials__job">{randomTestimonial.role}</p>
                <img
                  className="authentication-layout__testimonials__company"
                  src={randomTestimonial.companyLogo}
                  alt={`${randomTestimonial.companyName}`}
                />
                <p className="authentication-layout__testimonials__text">{randomTestimonial.text}</p>
              </div>
              <div className="authentication-layout__partners">
                <p className="authentication-layout__partners__text">Ils utilisent Fleex</p>
                <div className="authentication-layout__partners__logos">
                  {clientsLogo.map((client) => (
                    <img
                      className="authentication-layout__partners__logos__svg"
                      alt={client.name}
                      key={client.name}
                      src={client.src}
                    ></img>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {phone && (
        <a href="https://fleex.com" className="no-hover">
          <FleexIcon className="authentication-layout__mobile-icon" />
        </a>
      )}
      <div className="authentication-layout__container">
        <div className="authentication-layout__content">{children}</div>
      </div>
    </div>
  )
}

export default AuthenticationLayout
