import { useTranslation } from 'react-i18next'

import { Button } from '../../../components/Atoms/ButtonV2/Button'

import './style.scss'

export const ResetPasswordConfirm = () => {
  const { t } = useTranslation()
  return (
    <div className="reset-form-V2">
      <h1 className="reset-form-V2__title">{t('reset.confirm.title')}</h1>
      <div className="reset-form-V2__subtitles">
        <p className="reset-form-V2__subtitle">{t('reset.confirm.subtitle_1')}</p>
        <p className="reset-form-V2__subtitle">
          {t('reset.confirm.subtitle_2')}
          <a href="mailto:support@fleex.com">
            <Button kind="empty" className="reset-form-V2__subtitle__link">
              {t('reset.confirm.contact_our_team')}
            </Button>
          </a>
        </p>
        <p className="reset-form-V2__subtitle">{t('reset.confirm.subtitle_3')}</p>
      </div>

      <Button linkTo="/" className="reset-form-V2__button full-width" kind="primary">
        {t('buttons.back')}
      </Button>
    </div>
  )
}
