import { Icon, ICONS } from '../Icon/Icon'

type ImageListProps = {
  files: File[]
  remove: (id: number) => void
}

export const ImageList = ({ files, remove }: ImageListProps) => {
  return (
    <div>
      {files.map((file, index) => {
        const preview = URL.createObjectURL(file)

        return (
          <div className="file-picker__files" key={index}>
            <img className="file-picker__files__image" src={preview} alt={file.name}></img>
            <span className="file-picker__files__name">{file.name}</span>
            <button type="button" className="file-picker__files__remove-button" onClick={() => remove(index)}>
              <Icon className="file-picker__files__icon" icon={ICONS.Trash} />
            </button>
          </div>
        )
      })}
    </div>
  )
}
