import { useEffect } from 'react'

import { ZendeskAPI } from '../hooks/useZendeskAPI'

const zendeskId = process.env.REACT_APP_ZENDESK_WIDGET_ID || 'zendesk_widget_id_not_defined'

export const Zendesk = () => {
  const onLoad = () => {
    ZendeskAPI('messenger', 'hide')
    ZendeskAPI('messenger:set', 'zIndex', 500)
  }

  useEffect(() => {
    if (document.getElementById('ze-snippet')) {
      return
    }

    const script = document.createElement('script')
    script.id = 'ze-snippet'
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskId}`

    script.addEventListener('load', onLoad)
    document.body.appendChild(script)
  }, [])

  return null
}
