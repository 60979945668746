import { FluxEventKind, FluxKind, ProductReturnStatus } from '@prisma/client'

import { isSubBoughtBack, isSubBuyingBack, isSubDelivered, isSubShippingInProgress } from '../sub'

type LastFluxWithLastEvent = {
  lastFlux: {
    kind: FluxKind
    lastEvent: {
      kind: FluxEventKind
    }
  }
}

export const isProductReturnFinal = (statuses: ProductReturnStatus[]) => {
  return Boolean(
    statuses.find(
      (productReturnStatus) =>
        productReturnStatus === ProductReturnStatus.cancelled || productReturnStatus === ProductReturnStatus.refused,
    ),
  )
}

export const canChangeRequestStatus = (
  statuses: ProductReturnStatus[],
  subs: LastFluxWithLastEvent[],
  newStatus: ProductReturnStatus,
) => {
  if (isProductReturnFinal(statuses)) {
    return false
  }
  switch (newStatus) {
    case ProductReturnStatus.activated:
      return canActivateRequest(subs)
    case ProductReturnStatus.cancelled:
      return canCancelRequest(subs)
    case ProductReturnStatus.ongoing:
      return canSetOngoingRequest(subs)
    case ProductReturnStatus.refused:
      return canRefuseRequest(subs)
    default:
      return true
  }
}

export const canRefuseRequest = (subs: LastFluxWithLastEvent[]) =>
  subs.every((sub) => isSubDelivered(sub) || isSubShippingInProgress(sub) || isSubBoughtBack(sub))

export const canCancelRequest = (subs: LastFluxWithLastEvent[]) => canRefuseRequest(subs) //same conditions for the moment

export const canActivateRequest = (subs: LastFluxWithLastEvent[]) =>
  subs.every(
    (sub) => isSubDelivered(sub) || isSubShippingInProgress(sub) || isSubBuyingBack(sub) || isSubBoughtBack(sub),
  )

export const canSetOngoingRequest = (subs: LastFluxWithLastEvent[]) =>
  subs.every((sub) => isSubDelivered(sub) || isSubBoughtBack(sub))

export const isProductReturnActive = (productReturnStatus: ProductReturnStatus) =>
  productReturnStatus === ProductReturnStatus.requested ||
  productReturnStatus === ProductReturnStatus.activated ||
  productReturnStatus === ProductReturnStatus.ready ||
  productReturnStatus === ProductReturnStatus.ongoing

export const isSubUuidInProductRequest = (subUuid: string, productReturns: { sub: { uuid: string } }[]) =>
  Boolean(productReturns.find(({ sub }) => subUuid === sub.uuid))

export const isSubInActiveProductRequest = (
  subUuid: string,
  productReturns: { status: ProductReturnStatus; sub: { uuid: string } }[],
) =>
  isSubUuidInProductRequest(
    subUuid,
    productReturns.filter((productReturn) => isProductReturnActive(productReturn.status)),
  )
