import classnames from 'classnames'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { checkIsCurrentPath } from '../../../constants/utils'
import { Icon, ICONS, ICON_SIZES } from '../../Atoms/Icon/Icon'

import './style.scss'

const ExpandableSection = ({ to, label, subSections, path, matchExactPath }) => {
  const [expanded, setExpanded] = useState(
    subSections.some(({ to }) => checkIsCurrentPath(to, path, matchExactPath)) || path === to,
  )

  let hasChildCurrent = false
  const subSection = subSections.map(({ id, label, to }) => {
    const isCurrent = checkIsCurrentPath(to, path, matchExactPath)
    hasChildCurrent = hasChildCurrent || isCurrent

    return (
      <Link
        key={id}
        to={to}
        className={classnames('vertical-tabs__expandable-subsection vertical-tabs__tab', {
          expanded,
          current: isCurrent,
        })}
      >
        {label}
      </Link>
    )
  })

  return (
    <div className="vertical-tabs__expandable">
      <Link
        className={classnames('vertical-tabs__expandable-header vertical-tabs__tab', {
          current: checkIsCurrentPath(to, path, matchExactPath),
          'current-parent': hasChildCurrent,
        })}
        to={to}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span>{label}</span>
        <Icon
          icon={ICONS.ChevronRight}
          size={ICON_SIZES.SM}
          className={classnames('vertical-tabs__expandable-arrow', { expanded })}
        />
      </Link>
      <div className="vertical-tabs__expandable-subsections">{subSection}</div>
    </div>
  )
}

const VerticalTabs = ({ title, tabs, largeTitle, matchExactPath, large }) => {
  const location = useLocation()

  return (
    <div className={classnames('vertical-tabs', { 'vertical-tabs-large': large })}>
      {title && <div className={classnames('vertical-tabs__title', { 'large-title': largeTitle })}>{title}</div>}
      {tabs.map(({ id, to, label, subSections, exactPath, withNotification }) => {
        if (!to) {
          return null
        }
        if (subSections) {
          return (
            <ExpandableSection
              key={id}
              matchExactPath={matchExactPath}
              {...{ to, label, subSections, path: location.pathname }}
            />
          )
        }

        return (
          <Link
            key={id}
            to={to}
            className={classnames('vertical-tabs__tab', {
              current: checkIsCurrentPath(to, location.pathname, exactPath || matchExactPath),
            })}
          >
            <div className="vertical-tabs__tab__content">
              <span className="vertical-tabs__tab__text">{label}</span>
              {withNotification && <div className="notification-dot-V2"></div>}
            </div>
            {!large && checkIsCurrentPath(to, location.pathname, matchExactPath) && (
              <Icon icon={ICONS.ChevronRight} size={ICON_SIZES.SM} className={classnames('vertical-tabs-arrow')} />
            )}
          </Link>
        )
      })}
    </div>
  )
}

export default VerticalTabs
