import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { translateError } from '@fleex/shared'

import { Button } from '../ButtonV2/Button'
import { Icon, ICONS } from '../Icon/Icon'

import './style.scss'

type QueryHandlerProps = {
  loading: boolean
  error?: ApolloError
  errorMessage?: string
  backTo?: string
  children: React.ReactNode
}

export const QueryHandler = ({ loading, error, errorMessage, backTo, children }: QueryHandlerProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (loading) {
    return <p>{t('common.loading')}</p>
  } else if (error) {
    console.error({ error, errorMessage })
    return (
      <div className="query-handler__error">
        <Icon icon={ICONS.EmptyBox} />
        <div className="query-handler__error-message">{errorMessage || translateError(error?.message)}</div>
        {backTo ? (
          <Button kind="primary" linkTo={backTo}>
            {t('common.back')}
          </Button>
        ) : (
          <Button kind="primary" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
        )}
      </div>
    )
  }
  return <> {children} </>
}
