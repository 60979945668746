import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

import { translateError } from '@fleex/shared'

export const extractErrorMessage = (errors: ApolloError | GraphQLError[] | string) => {
  if (typeof errors !== 'string' && !Array.isArray(errors) && errors.graphQLErrors && errors.graphQLErrors.length) {
    return errors.graphQLErrors
      .map((graphQLError) => translateError(graphQLError.message || (graphQLError.extensions.code as string)))
      .join('\n')
  }
  if (Array.isArray(errors)) {
    return errors.map((error) => translateError(error.message || (error.extensions.code as string))).join('\n')
  }
  return translateError(errors as string)
}
